import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBalanceScale } from "@fortawesome/free-solid-svg-icons/faBalanceScale";
import { faBriefcaseMedical } from "@fortawesome/free-solid-svg-icons/faBriefcaseMedical";
import { faChartBar } from "@fortawesome/free-solid-svg-icons/faChartBar";
import { faHandshake } from "@fortawesome/free-solid-svg-icons/faHandshake";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faBook } from "@fortawesome/free-solid-svg-icons/faBook";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons/faMoneyBill";

const useStyles = makeStyles((theme) => ({
  fontIcons: {
    fontSize: "14px",
  },
}));

export const useMainPageModules = () => {
  const classes = useStyles();

  const user = JSON.parse(sessionStorage.getItem("user"));
  const singleUser = !user ? {} : user;

  const permissions = !singleUser.permission ? {} : singleUser.permission;
  //invoice
  const credit_sales = !permissions.credit_sales
    ? {}
    : permissions.credit_sales;
  const manage_sales = !permissions.manage_sales
    ? {}
    : permissions.manage_sales;
  const cash_sales = !permissions.cash_sales ? {} : permissions.cash_sales;
  const gui_pos = !permissions.gui_pos ? {} : permissions.gui_pos;
  //customer
  const add_customer = !permissions.add_customer
    ? {}
    : permissions.add_customer;
  const manage_customer = !permissions.manage_customer
    ? {}
    : permissions.manage_customer;
  const credit_customer = !permissions.credit_customer
    ? {}
    : permissions.credit_customer;
  const paid_customer = !permissions.paid_customer
    ? {}
    : permissions.paid_customer;
  //medicine
  const add_category = !permissions.add_category
    ? {}
    : permissions.add_category;
  const medicine_type = !permissions.medicine_type
    ? {}
    : permissions.medicine_type;
  const add_medicine = !permissions.add_medicine
    ? {}
    : permissions.add_medicine;
  const import_medicine_csv = !permissions.import_medicine_csv
    ? {}
    : permissions.import_medicine_csv;
  const manage_medicine = !permissions.manage_medicine
    ? {}
    : permissions.manage_medicine;
  const add_unit = !permissions.add_unit ? {} : permissions.add_unit;
  const unit_list = !permissions.unit_list ? {} : permissions.unit_list;
  //manufacturer
  const add_supplier = !permissions.add_supplier
    ? {}
    : permissions.add_supplier;
  const manage_supplier = !permissions.manage_supplier
    ? {}
    : permissions.manage_supplier;
  const supplier_ledger = !permissions.supplier_ledger
    ? {}
    : permissions.supplier_ledger;
  const supplier_sales_details = !permissions.supplier_sales_details
    ? {}
    : permissions.supplier_sales_details;
  //purchase
  const add_purchase = !permissions.add_purchase
    ? {}
    : permissions.add_purchase;
  const manage_purchase = !permissions.manage_purchase
    ? {}
    : permissions.manage_purchase;
  const add_lpo = !permissions.add_lpo ? {} : permissions.add_lpo;
  const edit_lpo = !permissions.edit_lpo ? {} : permissions.edit_lpo;
  const approve_lpo = !permissions.approve_lpo ? {} : permissions.approve_lpo;
  const cancel_lpo = !permissions.cancel_lpo ? {} : permissions.cancel_lpo;
  //stock
  const stock_report = !permissions.stock_report
    ? {}
    : permissions.stock_report;
  const stock_report_batch_wise = !permissions.stock_report_batch_wise
    ? {}
    : permissions.stock_report_batch_wise;
  //report
  const todays_report = !permissions.todays_report
    ? {}
    : permissions.todays_report;
  const sales_report = !permissions.sales_report
    ? {}
    : permissions.sales_report;
  const purchase_report = !permissions.purchase_report
    ? {}
    : permissions.purchase_report;
  const sales_report_medicine_report = !permissions.sales_report_medicine_wise
    ? {}
    : permissions.sales_report_medicine_wise;
  const profit_loss = !permissions.profit_loss ? {} : permissions.profit_loss;
  //return
  const invoice_return = !permissions.return ? {} : permissions.return;
  const stock_return_list = !permissions.stock_return_list
    ? {}
    : permissions.stock_return_list;

  const add_user = !permissions.add_user ? {} : permissions.add_user;
  const manage_users = !permissions.manage_users
    ? {}
    : permissions.manage_users;
  const add_role = !permissions.add_role ? {} : permissions.add_role;
  const role_list = !permissions.role_list ? {} : permissions.role_list;
  const user_assign_role = !permissions.user_assign_role
    ? {}
    : permissions.user_assign_role;

  //accounting
  const chart_of_accounts = permissions.chart_of_accounts
    ? permissions.chart_of_accounts
    : {};
  const journals = permissions.journals ? permissions.journals : {};
  const expenses = permissions.expenses ? permissions.expenses : {};

  const debit = permissions.debit ? permissions.debit : {};
  // const credit = permissions.credit ? permissions.credit : {};

  const arr = [
    {
      module_name: "Dashboard",
      icon: <FontAwesomeIcon className={classes.fontIcons} icon={faHome} />,
      link: "/",
      modules: [],
      isVisible: true,
    },

    {
      module_name: "Sales",
      icon: (
        <FontAwesomeIcon className={classes.fontIcons} icon={faBalanceScale} />
      ),
      modules: [
        {
          text: "Make Cash Sales",
          route: "/new-receipt",
          isVisible:
            cash_sales.create ||
            cash_sales.read ||
            cash_sales.update ||
            cash_sales.delete,
        },
        {
          text: "Make Credit Sales",
          route: "/createinvoice/new-invoice",
          isVisible:
            credit_sales.create ||
            credit_sales.read ||
            credit_sales.update ||
            credit_sales.delete,
        },
        {
          text: "Manage Sales",
          route: "/sales-receipts",
          isVisible:
            manage_sales.create ||
            manage_sales.read ||
            manage_sales.update ||
            manage_sales.delete,
        },
      ],
      isVisible:
        (cash_sales || credit_sales || manage_sales) &&
        (credit_sales.create ||
          credit_sales.read ||
          credit_sales.update ||
          credit_sales.delete ||
          manage_sales.create ||
          manage_sales.read ||
          manage_sales.update ||
          manage_sales.delete ||
          cash_sales.create ||
          cash_sales.read ||
          cash_sales.update ||
          cash_sales.delete ||
          gui_pos.create ||
          gui_pos.read ||
          gui_pos.update ||
          gui_pos.delete),
    },

    {
      module_name: "Customer",
      icon: (
        <FontAwesomeIcon className={classes.fontIcons} icon={faHandshake} />
      ),
      modules: [
        {
          text: "Add Customer",
          route: "/addcustomer",
          isVisible:
            add_customer.create ||
            add_customer.read ||
            add_customer.update ||
            add_customer.delete,
        },

        {
          text: "Customers",
          route: "/managecustomer/manage_customer",
          isVisible:
            manage_customer.create ||
            manage_customer.read ||
            manage_customer.update ||
            manage_customer.delete ||
            add_customer.create ||
            add_customer.read ||
            add_customer.update ||
            add_customer.delete,
        },
        {
          text: "Deactivated Customers",
          route: "/managecustomer/deactivated_customers",
          isVisible:
            manage_customer.create ||
            manage_customer.read ||
            manage_customer.update ||
            manage_customer.delete,
        },
        {
          text: "Accounts Receivable",
          route: "/accounting/debtors/customer",
          isVisible:
            manage_customer.create ||
            manage_customer.read ||
            manage_customer.update ||
            manage_customer.delete ||
            add_customer.create ||
            add_customer.read ||
            add_customer.update ||
            add_customer.delete,
        },
      ],
      isVisible:
        (permissions.add_customer ||
          permissions.manage_customer ||
          permissions.paid_customer ||
          permissions.credit_customer) &&
        (add_customer.create ||
          add_customer.read ||
          add_customer.update ||
          add_customer.delete ||
          manage_customer.create ||
          manage_customer.read ||
          manage_customer.update ||
          manage_customer.delete ||
          credit_customer.create ||
          credit_customer.read ||
          credit_customer.update ||
          credit_customer.delete ||
          paid_customer.create ||
          paid_customer.read ||
          paid_customer.update ||
          paid_customer.delete),
    },

    {
      module_name: "Product",
      icon: (
        <FontAwesomeIcon
          className={classes.fontIcons}
          icon={faBriefcaseMedical}
        />
      ),
      modules: [
        {
          text: "Add Product",
          route: "/addmedicine",
          isVisible:
            add_medicine.create ||
            add_medicine.read ||
            add_medicine.update ||
            add_medicine.delete,
        },
        {
          text: "Manage Product",
          route: "/managemedicine",
          isVisible:
            manage_medicine.create ||
            manage_medicine.read ||
            manage_medicine.update ||
            manage_medicine.delete,
        },
        {
          text: "Product Type",
          route: "/medicinetype",
          isVisible:
            medicine_type.create ||
            medicine_type.read ||
            medicine_type.update ||
            medicine_type.delete,
        },
        {
          text: "Unit",
          route: "/addunit",
          isVisible:
            add_unit.create ||
            add_unit.read ||
            add_unit.update ||
            add_unit.delete,
        },
        {
          text: "Category",
          route: "/managecategory",
          isVisible:
            add_category.create ||
            add_category.read !== 0 ||
            add_category.update ||
            add_category.delete,
        },
      ],
      isVisible:
        (permissions.add_category ||
          permissions.medicine_type ||
          permissions.add_medicine ||
          permissions.import_medicine_csv ||
          permissions.manage_medicine ||
          permissions.add_unit ||
          permissions.unit_list) &&
        (add_category.create ||
          add_category.read !== 0 ||
          add_category.update ||
          add_category.delete ||
          medicine_type.create ||
          medicine_type.read ||
          medicine_type.update ||
          medicine_type.delete ||
          add_unit.create ||
          add_unit.read ||
          add_unit.update ||
          add_unit.delete ||
          unit_list.create ||
          unit_list.read ||
          unit_list.update ||
          unit_list.delete ||
          add_medicine.create ||
          add_medicine.read ||
          add_medicine.update ||
          add_medicine.delete ||
          import_medicine_csv.create ||
          import_medicine_csv.read ||
          import_medicine_csv.update ||
          import_medicine_csv.delete ||
          manage_medicine.create ||
          manage_medicine.read ||
          manage_medicine.update ||
          manage_medicine.delete),
    },

    {
      module_name: "Purchase",
      icon: (
        <FontAwesomeIcon className={classes.fontIcons} icon={faShoppingCart} />
      ),
      modules: [
        {
          text: "Purchases",
          route: "/managepurchase",
          isVisible:
            manage_purchase.create ||
            manage_purchase.read ||
            manage_purchase.update ||
            manage_purchase.delete,
        },
        {
          text: "Purchase Orders",
          route: "/managepurchaseorder",
          isVisible:
            add_lpo.create ||
            add_lpo.read ||
            add_lpo.update ||
            add_lpo.delete ||
            edit_lpo.create ||
            edit_lpo.read ||
            edit_lpo.update ||
            edit_lpo.delete ||
            approve_lpo.create ||
            approve_lpo.read ||
            approve_lpo.update ||
            approve_lpo.delete ||
            cancel_lpo.create ||
            cancel_lpo.read ||
            cancel_lpo.update ||
            cancel_lpo.delete,
        },
      ],
      isVisible:
        (permissions.add_purchase ||
          permissions.manage_purchase ||
          add_lpo ||
          edit_lpo ||
          approve_lpo ||
          cancel_lpo) &&
        (add_purchase.create ||
          add_purchase.read ||
          add_purchase.update ||
          add_purchase.delete ||
          manage_purchase.create ||
          manage_purchase.read ||
          manage_purchase.update ||
          manage_purchase.delete ||
          add_lpo.create ||
          add_lpo.read ||
          add_lpo.update ||
          add_lpo.delete ||
          edit_lpo.create ||
          edit_lpo.read ||
          edit_lpo.update ||
          edit_lpo.delete ||
          approve_lpo.create ||
          approve_lpo.read ||
          approve_lpo.update ||
          approve_lpo.delete ||
          cancel_lpo.create ||
          cancel_lpo.read ||
          cancel_lpo.update ||
          cancel_lpo.delete),
    },

    {
      module_name: "Stock",
      icon: <FontAwesomeIcon className={classes.fontIcons} icon={faChartBar} />,
      modules: [
        {
          text: "Stock Report",
          route: "/stockreport",
          isVisible:
            stock_report.create ||
            stock_report.read ||
            stock_report.update ||
            stock_report.delete ||
            stock_report_batch_wise.create ||
            stock_report_batch_wise.read ||
            stock_report_batch_wise.update ||
            stock_report_batch_wise.delete,
        },
        {
          text: "Stock Adjustment",
          route: "/stockadjustment",
          isVisible:
            stock_report.create ||
            stock_report.read ||
            stock_report.update ||
            stock_report.delete,
        },
        {
          text: "Stock Adjustment List",
          route: "/stock-adjustment-list",
          isVisible:
            stock_report.create ||
            stock_report.read ||
            stock_report.update ||
            stock_report.delete,
        },
        {
          text: "Inconsistence Batches",
          route: "/inconsistence-batches",
          isVisible:
            stock_report.create ||
            stock_report.read ||
            stock_report.update ||
            stock_report.delete,
        },
      ],
      isVisible:
        (permissions.stock_report || permissions.stock_report_batch_wise) &&
        (stock_report.create ||
          stock_report.read ||
          stock_report.update ||
          stock_report.delete ||
          stock_report_batch_wise.create ||
          stock_report_batch_wise.read ||
          stock_report_batch_wise.update ||
          stock_report_batch_wise.delete),
    },

    // {module_name:'Refund',  icon:<FontAwesomeIcon className={classes.fontIcons} icon={faRetweet}/>,
    //     modules:[
    //         {text:'Refund Customer', route:'/return', isVisible:(
    //                 (invoice_return.create || invoice_return.read || invoice_return.update || invoice_return.delete)
    //             )},
    //         {text:'Refund List', route:'/stockreturnlist', isVisible:(
    //                 stock_return_list.create || stock_return_list.read || stock_return_list.update
    //                 || stock_return_list.delete
    //             )},
    //     ],
    //     isVisible:(
    //         (permissions.return || permissions.stock_return_list || permissions.manufacturer_return_list ||
    //             permissions.wastage_return_list)
    //     )},

    {
      module_name: "Supplier",
      icon: <FontAwesomeIcon className={classes.fontIcons} icon={faUser} />,
      modules: [
        {
          text: "Add Supplier",
          route: "/addmanufacturer",
          isVisible:
            add_supplier.create ||
            add_supplier.read ||
            add_supplier.update ||
            add_supplier.delete,
        },
        {
          text: "Manage Supplier",
          route: "/managemanufacturer",
          isVisible:
            manage_supplier.create ||
            manage_supplier.read ||
            manage_supplier.update ||
            manage_supplier.delete,
        },
        {
          text: "Accounts Payable",
          route: "/creditors/supplier",
          isVisible:
            manage_customer.create ||
            manage_customer.read ||
            manage_customer.update ||
            manage_customer.delete ||
            add_customer.create ||
            add_customer.read ||
            add_customer.update ||
            add_customer.delete,
        },
        // {text:'Supplier Sales Details', route:'/suppliersales', isVisible:(
        //         supplier_sales_details.create || supplier_sales_details.read ||
        //         supplier_sales_details.update || supplier_sales_details.delete
        //     )},
      ],
      isVisible:
        (permissions.add_supplier ||
          permissions.manage_supplier ||
          permissions.supplier_ledger ||
          permissions.supplier_sales_details) &&
        (add_supplier.create ||
          add_supplier.read ||
          add_supplier.update ||
          add_supplier.delete ||
          manage_supplier.create ||
          manage_supplier.read ||
          manage_supplier.update ||
          manage_supplier.delete ||
          supplier_ledger.create ||
          supplier_ledger.read ||
          supplier_ledger.update ||
          supplier_ledger.delete ||
          supplier_sales_details.create ||
          supplier_sales_details.read ||
          supplier_sales_details.update ||
          supplier_sales_details.delete),
    },

    {
      module_name: "Accounting",
      icon: (
        <FontAwesomeIcon className={classes.fontIcons} icon={faMoneyBill} />
      ),
      modules: [
        {
          text: "Chart of accounts",
          route: "/accounting/coa",
          isVisible:
            chart_of_accounts.create ||
            chart_of_accounts.read ||
            chart_of_accounts.update ||
            chart_of_accounts.delete,
        },
        // {text:'Setup Accounts', route:'/accounting/setup', isVisible:(
        //         chart_of_accounts.create || chart_of_accounts.read ||
        //         chart_of_accounts.update || chart_of_accounts.delete
        //     )},
        {
          text: "General Ledger",
          route: "/accounting/generalledger",
          isVisible:
            journals.create ||
            journals.read ||
            journals.update ||
            journals.delete,
        },
        {
          text: "Journals",
          route: "/accounting/journals",
          isVisible:
            journals.create ||
            journals.read ||
            journals.update ||
            journals.delete,
        },
        {
          text: "Expenses",
          route: "/accounting/expenses/active",
          isVisible:
            expenses.create ||
            expenses.read ||
            expenses.update ||
            expenses.delete,
        },
        {
          text: "Payment Vouchers",
          route: "/accounting/payment_vouchers",
          isVisible:
            expenses.create ||
            expenses.read ||
            expenses.update ||
            expenses.delete,
        },
        {
          text: "Debit Notes",
          route: "/accounting/managedebitnotes",
          // isVisible: debit.create || debit.read || debit.update || debit.delete,
          isVisible: true,
        },
        {
          // isVisible: debit.create || debit.read || debit.update || debit.delete,
          text: "Credit Notes",
          route: "/accounting/managecreditnotes",
          isVisible: true,
        },
      ],
      isVisible:
        chart_of_accounts.create ||
        chart_of_accounts.read ||
        chart_of_accounts.update ||
        chart_of_accounts.delete ||
        chart_of_accounts.create ||
        chart_of_accounts.read ||
        chart_of_accounts.update ||
        chart_of_accounts.delete ||
        journals.create ||
        journals.read ||
        journals.update ||
        journals.delete ||
        expenses.create ||
        expenses.read ||
        expenses.update ||
        expenses.delete ||
        debit.create ||
        debit.read ||
        debit.update ||
        debit.delete,
    },

    {
      module_name: "Reports",
      icon: <FontAwesomeIcon className={classes.fontIcons} icon={faBook} />,
      link: "/allreports",
      modules: [],
      isVisible:
        (permissions.todays_report ||
          permissions.sales_report ||
          permissions.purchase_report ||
          permissions.sales_report_medicine_wise ||
          permissions.profit_loss) &&
        (todays_report.create ||
          todays_report.read ||
          todays_report.update ||
          todays_report.delete ||
          sales_report.create ||
          sales_report.read ||
          sales_report.update ||
          sales_report.delete ||
          purchase_report.create ||
          purchase_report.read ||
          purchase_report.update ||
          purchase_report.delete ||
          sales_report_medicine_report.create ||
          sales_report_medicine_report.read ||
          sales_report_medicine_report.update ||
          sales_report_medicine_report.delete ||
          profit_loss.create ||
          profit_loss.read ||
          profit_loss.update ||
          profit_loss.delete),
    },

    {
      module_name: "Settings",
      icon: <FontAwesomeIcon className={classes.fontIcons} icon={faCog} />,
      modules: [
        {
          text: "Manage Users",
          route: "/manageusers",
          isVisible:
            manage_users.create ||
            manage_users.read ||
            manage_users.update ||
            manage_users.delete ||
            add_user.create ||
            add_user.read ||
            add_user.update ||
            add_user.delete,
        },
        {
          text: "Roles",
          route: "/rolelist",
          isVisible:
            role_list.create ||
            role_list.read ||
            role_list.update ||
            role_list.delete ||
            add_role.create ||
            add_role.read ||
            add_role.update ||
            add_role.delete,
        },
        {
          text: "Assign User Role",
          route: "/assignuserrole",
          isVisible:
            user_assign_role.create ||
            user_assign_role.read ||
            user_assign_role.update ||
            user_assign_role.delete,
        },
        {
          text: "Setup Payment Methods",
          route: "/paymentmethods",
          isVisible:
            user_assign_role.create ||
            user_assign_role.read ||
            user_assign_role.update ||
            user_assign_role.delete,
        },
        {
          text: "Company Details",
          route: "/companydetails",
          isVisible:
            user_assign_role.create ||
            user_assign_role.read ||
            user_assign_role.update ||
            user_assign_role.delete,
        },
        {
          text: "Manual Backup",
          route: "/manualbackup",
          isVisible:
            user_assign_role.create ||
            user_assign_role.read ||
            user_assign_role.update ||
            user_assign_role.delete,
        },
        {
          text: "Support",
          route: "/support",
          isVisible: true,
          icon: (
            <FontAwesomeIcon
              className={classes.fontIcons}
              icon={faQuestionCircle}
            />
          ),
        },
      ],
      isVisible: true,
    },
  ];

  return { arr };
};
