import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import { history } from "../../Helpers/history";
import { useManageCustomer } from "./CustomHooks/useManageCustomer";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {
  CustomPagination,
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPaper,
} from "../../Utils/Tables/CustomTable";
import Form from "react-bootstrap/Form";
import { splitText, titleCase } from "../../Utils/titleCaseFunction";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Link } from "react-router-dom";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import DeactivateDialog from "../../Utils/DeactivateDialog";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import DetailsDialog from "../../Utils/Dialog/DetailsDialog";
import { RowCustomerForm } from "./CustomerForm";
import AddProvider from "./AddProvider";
import { CustomSelect } from "../../Utils/FormInputs/SelectInput";
import { LoadingGif } from "../../Utils/Loader";
import { formatAmount } from "../../Utils/formatAmount";
import { UploadCsvDialog } from "../../Utils/Dialog/UploadCSVDialog";
import {
  useExcelReports,
  usePdfReports,
} from "../Invoice/CustomHooks/useExcelReports";
import { RightAlignedContainer } from "../../Utils/styledComponents";

const ManageCustomer = ({ actions, snackbars, match }) => {
  const { component } = match.params;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const singleUser = !user ? {} : user;
  const permission = !singleUser.permission ? {} : singleUser.permission;
  const manage_customer = !permission.manage_customer
    ? {}
    : permission.manage_customer;
  const { variant, message, open } = snackbars;
  const {
    customer_count,
    activePage,
    customers,
    searchValue,
    openDeactivate,
    providerProps,
    openDeposit,
    deposit_amount,
    submitted,
    isSubmitted,
    handleChange,
    handleCloseBar,
    handleOpenDeactivate,
    handleCloseDeactivate,
    deactivateCustomer,
    handlePageChange,
    handleCloseDeposit,
    handleChangeDeposit,
    handleSubmitDeposit,
    customerProps,
    uploadProps,
    customer_category,
    handleChangeCategory,
    isLoading,
    isSuccess,
    isError,
  } = useManageCustomer(actions, component);
  const {
    openCustomerDialog,
    handleOpenCustomerDialog,
    handleCloseCustomerDialog,
  } = customerProps;
  const allCustomers = !customers ? [] : customers;

  const title = {
    manage_customer: "All Customers",
    deactivated_customers: "Deactivated Customers",
  };

  const isActiveCustomers = component === "manage_customer";

  const dialogButtons = (
    <>
      <button
        className={`btn btn-sm ${
          isActiveCustomers ? "pharmacy-btn-dismiss" : "pharmacy-info-btn"
        } mr-3`}
        onClick={deactivateCustomer}
      >
        Yes
      </button>
      <button
        className="btn btn-sm pharmacy-gray-btn mr-3"
        onClick={deactivateCustomer}
      >
        No
      </button>
    </>
  );

  const { customer_categories } = customerProps;
  const headData = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Customer Name",
    },
    { id: "address", numeric: false, disablePadding: false, label: "Address" },
    { id: "mobile", numeric: false, disablePadding: false, label: "Mobile" },
    {
      id: "credit_limit",
      numeric: false,
      disablePadding: false,
      label: "Credit Limit",
    },
    manage_customer.create || manage_customer.update || manage_customer.delete
      ? { id: "action", numeric: false, disablePadding: false, label: "Action" }
      : { id: "nonaction", numeric: false, disablePadding: false, label: "" },
  ];

  const { handleOpenCsvDialog } = uploadProps;

  const { openDialog: openCategory, handleCloseDialog: handleCloseCategory } =
    providerProps;

  const obj = {
    file_name: "All Customers",
    url: isActiveCustomers
      ? "Ccustomer/customer_list_excel"
      : "Ccustomer/deactivated_customer_list_excel",
  };

  const objPDF = {
    file_name: "Customers",
    url: isActiveCustomers
      ? "Ccustomer/customer_list_pdf"
      : "Ccustomer/deactivated_customer_list_pdf",
  };

  const { exportExcel } = useExcelReports(actions, obj);
  const { exportPDF } = usePdfReports(actions, objPDF);

  const downloadButtons = (
    <>
      <button
        style={{ marginLeft: "15px" }}
        type="button"
        className="btn btn-sm  pharmacy-info-btn mr-4"
        onClick={exportExcel}
      >
        Download Excel
      </button>
      <button
        type="button"
        className="btn btn-sm  pharmacy-info-btn mr-4"
        onClick={exportPDF}
      >
        Download PDF
      </button>
    </>
  );

  return (
    <div>
      <ReusableDashboardHeader
        component={title[component]}
        heading="Customers"
        subHeading={title[component]}
        link={history.location.pathname}
      />
      <UploadCsvDialog {...{ ...uploadProps, title: "Upload Customers" }} />
      <div className="receipt-container">
        {isActiveCustomers ? (
          <div className="row">
            <div className="col-lg-6">
              <Form.Group as={Row}>
                <Col sm={3}>
                  <Label name="Filter by:" column />
                </Col>
                <Col sm={6}>
                  <CustomSelect
                    value={customer_category}
                    onChange={handleChangeCategory}
                    options={customer_categories}
                  />
                </Col>
              </Form.Group>
            </div>
            <div className="col-lg-6">
              <div style={{ display: "flex" }} className="text-md-right">
                <button
                  type="button"
                  className="btn btn-sm  pharmacy-info-btn mr-4"
                  onClick={() => history.push("/addcustomer")}
                >
                  Add Customer
                </button>

                <button
                  type="button"
                  className="btn btn-sm  pharmacy-btn"
                  onClick={handleOpenCsvDialog}
                >
                  Upload Customers
                </button>
                {downloadButtons}
              </div>
            </div>
          </div>
        ) : (
          <RightAlignedContainer>{downloadButtons}</RightAlignedContainer>
        )}

        <DetailsDialog
          title="New Category"
          openDialog={openCategory}
          handleClose={handleCloseCategory}
        >
          <AddProvider {...providerProps} />
        </DetailsDialog>
        <DetailsDialog
          title="Edit Customer"
          openDialog={openCustomerDialog}
          handleClose={handleCloseCustomerDialog}
        >
          <RowCustomerForm {...customerProps} />
        </DetailsDialog>
        <ReusableDialog
          handleClose={handleCloseDeposit}
          openDialog={openDeposit}
          isSubmitted={isSubmitted}
          handleSubmit={handleSubmitDeposit}
          width="sm"
          message="Make Deposit"
        >
          <Form.Group as={Row}>
            <Label name="Amount" type column sm={2} />
            <Col sm="8">
              <TextField
                type="number"
                value={deposit_amount}
                name="deposit_amount"
                onChange={handleChangeDeposit}
              />
              {submitted && !deposit_amount ? (
                <div>Amount is required</div>
              ) : null}
            </Col>
          </Form.Group>
        </ReusableDialog>
        <PharmacySnackbar
          open={open}
          message={message}
          variant={variant}
          handleCloseBar={handleCloseBar}
        />
        <DetailsDialog
          title={`${isActiveCustomers ? "Deactivate" : "Activate"} customer`}
          openDialog={openDeactivate}
          handleClose={handleCloseDeactivate}
          buttons={dialogButtons}
        >
          <p>
            You are about to {isActiveCustomers ? "deactivate" : "activate"}{" "}
            this customer, are you sure you want to continue?
          </p>
        </DetailsDialog>

        <MuiPaper>
          <EnhancedTableToolbar title={title[component]}>
            <Form.Control
              data-testid="customer-list-search"
              type="text"
              value={searchValue ? titleCase(searchValue) : ""}
              onChange={handleChange}
              className="form__control"
              placeholder="Search"
              style={{ float: "right" }}
            />
          </EnhancedTableToolbar>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead headCells={headData} />

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={headData.length} align="center">
                    <LoadingGif />
                  </TableCell>
                </TableRow>
              ) : null}
              {isSuccess ? (
                allCustomers.length > 0 ? (
                  allCustomers.slice(0, 10).map((d, index) => {
                    const activeButtons = (
                      <>
                        <button
                          data-testid={`edit_customer-${index}`}
                          onClick={() =>
                            handleOpenCustomerDialog(
                              d.customer_id,
                              d.customer_category_id
                            )
                          }
                          className="btn pharmacy-info-btn btn-sm mr-1 mb-1"
                        >
                          Edit
                        </button>
                        <button
                          data-testid={`deactivate_customer-${index}`}
                          className="btn pharmacy-grey-btn btn-sm mb-1"
                          onClick={() =>
                            handleOpenDeactivate(d.customer_id, d.status)
                          }
                        >
                          Deactivate
                        </button>
                      </>
                    );

                    const deactivateButton = (
                      <button
                        data-testid={`activate_customer-${index}`}
                        className="btn pharmacy-info-btn btn-sm"
                        onClick={() =>
                          handleOpenDeactivate(d.customer_id, d.status)
                        }
                      >
                        Activate
                      </button>
                    );
                    const buttons = {
                      manage_customer: activeButtons,
                      deactivated_customers: deactivateButton,
                    };
                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={index}>
                        <TableCell data-testid={`customer_name-${index}`}>
                          <Link
                            to={{
                              pathname: `/customerpurchases/${d.customer_id}`,
                            }}
                            style={{ color: "#09905c", textDecoration: "none" }}
                          >
                            <span>{d.customer_name}</span>
                          </Link>
                        </TableCell>
                        <TableCell data-testid={`customer_address-${index}`}>
                          <span>{splitText(d.customer_address)}</span>
                        </TableCell>
                        <TableCell data-testid={`customer_mobile-${index}`}>
                          <span>{d.customer_mobile}</span>
                        </TableCell>
                        <TableCell data-testid={`credit_limit-${index}`}>
                          <span>
                            {d.credit_limit
                              ? formatAmount(d.credit_limit)
                              : d.credit_limit}
                          </span>
                        </TableCell>
                        <TableCell id="mainbuttons">
                          {d.customer_id !== 1 ? buttons[component] : null}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={headData.length} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )
              ) : null}
              {isError ? (
                <TableRow>
                  <TableCell colSpan={headData.length} align="center">
                    The server did not return a valid response
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
          <CustomPagination
            data={allCustomers}
            records={10}
            activePage={activePage}
            total_count={customer_count}
            handleChangeNextPage={handlePageChange}
          />
        </MuiPaper>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCustomer);
