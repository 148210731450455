import React from "react"
import {makeStyles} from "@material-ui/core/styles";
import Form from "react-bootstrap/Form"
import Label from "../../../Utils/FormInputs/Label"
import TextField from "../../../Utils/FormInputs/TextField";
import Table from "react-bootstrap/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import {formatAmount} from "../../../Utils/formatNumbers";
import {dateStringConvert} from "../../../Utils/ConvertDate";
import {RightAlignedContainer} from "../../../Utils/styledComponents";
import {CustomSelect} from "../../../Utils/FormInputs/SelectInput";



const useStyles = makeStyles(()=>({
    container:{
        padding:'16px 60px',
    },
    right:{
        display:'flex',
        alignItems:'flex-end',
        flexDirection:'column'
    }
}))

const headData = [{name:'Date'},{name:'Invoice#'},{name:'Total Amount'}]

function ReceiveDebtorsPayments({state, isPending, submitted, isFull, amountReceived, customer, invoices,
                                     handleChange, handleChangeReceived,  handleIsFullBill,
                                    handleSubmit, debitAccount, accounts, handleChangeDebitAccount}){
    const {due_amount,} = customer
    const {payment_date} = state



    const isFullSpan = (
        <span>Receive full amount(<strong>{due_amount ? formatAmount(due_amount) : due_amount}</strong>)</span>
    )
    return (
            <form onSubmit={handleSubmit}>
                <div className='row mb-3'>
                    <div className='col-lg-6'>
                        {/*<Form.Group>*/}
                        {/*    <Label name='Customer'/>*/}
                        {/*    <TextField type='text' readOnly value={customer_name}/>*/}
                        {/*</Form.Group>*/}
                        <Form.Group>
                            <Label name='Payment Date' type/>
                            <TextField type='date'  value={payment_date} name='payment_date' submitted={submitted} onChange={handleChange}/>
                            {submitted && !payment_date ? <div className='help-block'>Payment date is required</div>:null}
                        </Form.Group>

                        <Form.Group>
                            <Label name='Amount Received' type/>
                            <TextField type='number' data-testid="amount_received" value={amountReceived} submitted={submitted} onChange={handleChangeReceived}/>
                            {submitted && !amountReceived ? <div className='help-block'>Amount received is required</div>:null}
                            <div className='mt-2'>
                                <Form.Check checked={isFull} onChange={handleIsFullBill} label={isFullSpan}/>
                            </div>

                        </Form.Group>
                        <Form.Group data-testid="debtors_debit_to">
                            <Label name='Debit To' type />
                            <CustomSelect options={accounts} prefix='debit_to'  onChange={handleChangeDebitAccount} value={debitAccount} submitted={submitted}
                                         name='debit_account' defaultOption='Select payment method...'/>
                            {submitted && !debitAccount ? <div className='help-block'>Account to debit is required</div>:null}
                        </Form.Group>
                    </div>
                    <div className='col-lg-1' />

                    <div className='col-lg-4'>
                        <p style={{marginTop: 24, fontSize:16}}>Amount Due : <strong>{due_amount ? formatAmount(due_amount) : due_amount}</strong></p>
                        {/* <Form.Group>
                            <Label name='Amount Due'/>
                            <TextField type='number' value={due_amount ? formatAmount(due_amount) : due_amount} readOnly/>
                        </Form.Group> */}

                        {/*<Form.Group>*/}
                        {/*    <Label name='Amount Received' type/>*/}
                        {/*    <TextField type='number' value={amountReceived} submitted={submitted} onChange={handleChangeReceived}/>*/}
                        {/*    {submitted && !amountReceived ? <div className='help-block'>Amount received is required</div>:null}*/}
                        {/*    <div className='mt-2'>*/}
                        {/*        <Form.Check checked={isFull} onChange={handleIsFullBill} label={`Receive full amount(${due_amount ? formatAmount(due_amount) : due_amount})`}/>*/}
                        {/*    </div>*/}

                        {/*</Form.Group>*/}
                    </div>
                </div>
                <p><strong>Outstanding Transactions</strong></p>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headData.map((item, index)=>(
                                    <TableCell key={index}>{item.name}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoices?.map(item=>{
                                return(
                                    <TableRow key={item.invoice_id}>
                                        <TableCell>
                                            {item.date ? dateStringConvert(item.date) : item.date}
                                        </TableCell>
                                        <TableCell>
                                            {item.invoice_number}
                                        </TableCell>
                                        <TableCell>
                                            {formatAmount(item.total_amount)}
                                        </TableCell>
                                        {/*<TableCell>*/}
                                        {/*    {item.prevous_due === 0 ? formatAmount(item.total_amount):formatAmount(item.prevous_due)}*/}
                                        {/*</TableCell>*/}
                                        {/*<TableCell>*/}
                                        {/*    <TextField value={item.payment} onChange={(e)=>handleChangePayment(e, item.invoice_id)}/>*/}
                                        {/*</TableCell>*/}
                                    </TableRow>
                                )
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
                <RightAlignedContainer>
                    <button type='submit' disabled={isPending} className='btn btn-sm pharmacy-btn'>{isPending ? 'Submitting....' : 'Submit'}</button>
                </RightAlignedContainer>
            </form>
    )
}

export {ReceiveDebtorsPayments}