import React from "react";
import { usePrint } from "../../../Utils/Template/usePrint";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { history } from "../../../Helpers/history";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import Card from "react-bootstrap/Card";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import { PaymentVoucherReceipt } from "../../Report/PaymentVouchers";
import {
  MiniCenteredDiv,
  RightAlignedContainer,
} from "../../../Utils/styledComponents";
import { ExpenseVoucher } from "../../Report/ExpensesReceipt";
import { BackButton } from "../../../Utils/Buttons/DataExportationButton";

const ExpenseReceipt = ({ actions, snackbars, match: { params } }) => {
  const { voucher_id } = params;
  const handleCloseSnackbar = () => {
    actions.snackbarActions.hideSnackBar();
  };
  const { variant, message, open } = snackbars;
  const { handlePrint, componentRef, getA4PageMargins } = usePrint("");

  return (
    <div>
      <ReusableDashboardHeader
        component="Receipt"
        heading="Expenses"
        subHeading="Receipt"
        link={history.location.pathname}
      />
      <PharmacySnackbar
        open={open}
        variant={variant}
        message={message}
        handleCloseBar={handleCloseSnackbar}
      />

      <MiniCenteredDiv>
      <div className="row">
            <div className="col-lg-6">
              <div className="text-left mr-3">
              <BackButton text="Expenses" to="/accounting/expenses/active" />
              </div>
            </div>
          </div>
          <div className="mb-0">
           
          </div>
        <RightAlignedContainer>
          <div className="row">
            <div className="col-lg-6">
              <div className="text-right mr-5">
                <button
                  onClick={handlePrint}
                  className="btn pharmacy-info-btn mb-2 mr-2"
                >
                  Print
                </button>
              </div>
            </div>
          </div>
        </RightAlignedContainer>
        <Card>
          <div
            ref={componentRef}
            style={{ padding: "16px 30px" }}
            id="posPrint"
          >
            <style>{getA4PageMargins()}</style>
            <ExpenseVoucher voucher_id={voucher_id} actions={actions} />
          </div>
        </Card>{" "}
      </MiniCenteredDiv>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseReceipt);
