/** @jsx jsx */ //please do not remove this implementation because the css will not be loaded without it
import { jsx, css } from "@emotion/react";
import React from "react";
import {
  ItemsTable,
  ReceiptTemplate,
} from "../../Utils/Template/ReceiptTemplate";
import { tableCell } from "../../styles/tableStyles";
import { formatAmount, formatDigits } from "../../Utils/formatNumbers";
import { dateStringTimeConvert } from "../../Utils/titleCaseFunction";

const headData = [
  { value: "Product" },
  { value: "Batch#" },
  { value: "Qty" },
  { value: "Price" },
  { value: "Total" },
];

const wordBreak = css`
  word-break: break-word;
`;

const SalesReceiptTemplate = ({ products, customer = {} }) => {
  const user_name = `${!customer?.first_name ? "" : customer?.first_name} ${
    !customer?.last_name ? "" : customer?.last_name
  }`;

  const customerDetails = (
    <tbody>
      <tr>
        <td>
          <td data-testid="sale_number">
            <span>
              Receipt#: <strong>{customer.receipt_number}</strong>
            </span>
          </td>
        </td>
        <td>
          <td data-testid="sale_date">
            <span>
              Date:{" "}
              <strong>
                {customer.date_paid
                  ? dateStringTimeConvert(customer.date_paid)
                  : ""}
              </strong>
            </span>
          </td>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            Name:{" "}
            <strong data-testid="customer">{customer.customer_name}</strong>
          </span>
        </td>
        <td>
          <td data-testid="received_by">
            <span>
              Received by: <strong>{user_name}</strong>
            </span>
          </td>
        </td>
      </tr>
    </tbody>
  );

  const itemDetails = (
    <ItemsTable headData={headData}>
      <tbody>
        {products.map((info, index) => {
          const qty = info.quantity ? info.quantity : 0;
          const rate = info.rate ? info.rate : 0;
          return (
            <tr key={index}>
              <td css={[wordBreak]} data-testid={"product_name-" + index}>
                <span>{info.product_name}</span>
              </td>
              <td css={[wordBreak]} data-testid={"batch-" + index}>
                <span>{info.batch_id}</span>
              </td>
              <td data-testid={"quantity-" + index}>
                <span>{info.quantity}</span>
              </td>
              <td data-testid={"rate-" + index}>
                <span>{info.rate}</span>
              </td>
              <td data-testid={"total_rate" + index}>
                <span>{formatDigits(+qty * +rate)}</span>
              </td>
            </tr>
          );
        })}
        <tr>
          <td css={[tableCell]} colSpan="5"></td>
        </tr>

        <tr>
          <td colSpan="4" align="right">
            <span>Total Amount</span>
          </td>
          <td data-testid="total_amount">
            <span>
              <strong>{formatDigits(customer.total_amount)}</strong>
            </span>
          </td>
        </tr>

        <tr>
          <td colSpan="4" align="right">
            <span>Amount Received</span>
          </td>
          <td data-testid="paid_amount">
            <span>
              <strong>{formatDigits(customer.amount_paid+customer.balance)}</strong>
            </span>
          </td>
        </tr>
        <tr>
          <td colSpan="4" align="right">
            <span>Change</span>
          </td>
          <td data-testid="paid_amount">
            <span>
              <strong>{formatDigits(customer.balance)}</strong>
            </span>
          </td>
        </tr>
      </tbody>
    </ItemsTable>
  );

  return <ReceiptTemplate {...{ customerDetails, itemDetails }} />;
};

export { SalesReceiptTemplate };
