import { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import config from "../../../../Helpers/config.json";
import { errorMessages } from "./../../../../Helpers/ErrorMessages";
import { titleCase } from "./../../../../Utils/titleCaseFunction";
import { useQuery } from "react-query";
import { history } from "../../../../Helpers/history";

const useDebitNote = (actions) => {
  const initialProductsState = [
    {
      product_name: "",
      batch_id: "",
      batch_info: [],
      available_quantity: "",
      expiry: "",
      unit: "",
      product_quantity: "",
      product_rate: "",
      discount: "",
      total_price: "",
      discount_type: 1,
      related_product: "",
      total_discount: 0,
      tax: "",
      dosage: "",
      product_id: "",
      showProduct: false,
      isError: false,
      uuid_id: uuidv4(),
    },
  ];

  const [totals, setTotals] = useState({
    grand_total_price: 0,
    n_total: 0,
    change: 0,
    due_amount: 0,
    paid_amount: "",
    previous_amount: 0,
    total_discount_amount: 0,
  });

  const [debitNoteDate, setDebitNoteDate] = useState("");

  const [selectedPreviousInvoiceNumber, setSelectedPreviousInvoiceNumber] =
    useState(null);

  const [invoiceNumberDetails, setInvoiceNumberDetails] = useState([]);

  const [debitNoteDetails, setDebitNoteDetails] = useState("");

  const [rows, setRows] = useState(initialProductsState);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [debitMemoNumber, setDebitMemoNumber] = useState("");

  const [submitted, setSubmitted] = useState(false);

  const [showSupplier, setShowSupplier] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);

  const [drugs, setDrugs] = useState([]);

  const [isBlocking, setIsBlocking] = useState(false);

  const [grandTotal,setGrandTotal] = useState(0)

  const [supplier, setSupplier] = useState({
    supplier_name: "",
    supplier_id: "",
  });

  const [loading, setLoading] = useState("idle");

  // const [suppliers, setSuppliers] = useState([]);
  //
  // useEffect(() => {
  //   const handleFetchSuppliers = async () => {
  //     const response = await axios.get(
  //       `${config.epharmUrl}/Cmanufacturer/retrieve_all_manufacturers`
  //     );
  //     setSuppliers(response.data.total_manufacturers);
  //   };
  //
  //   handleFetchSuppliers();
  // }, []);

  useEffect(() => {
   
    if (!selectedPreviousInvoiceNumber) {
      return;
    }
  
    setLoading("loading");
    const queryString = `?invoice_no=${selectedPreviousInvoiceNumber}`;
    axios.get(`${config.epharmUrl}/Cdebitmemo/get_invoice_details${queryString}`)
      .then((response) => {
        const data = response.data;
        const dt = !data.data ? [] : data.data;
        const total = data.grand_total_amount ?? 0
        console.log(dt);

        const batchData = dt.map((item) => ({
          ...item,
          batch_id: item.batch_id,
          expiry_date: item.expiry_date,
          unit: item.unit,
          quantity: item.quantity,
          price: item.price,
          total_amount: item.total_amount
        }));
        setLoading("success");
        setRows(batchData);
        setGrandTotal(total)
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setLoading("error");
      });
  }, [selectedPreviousInvoiceNumber]);

  console.log(invoiceNumberDetails)

  const supplierListQuery = useQuery(
    ["supplierListQuery"],
    async () => {
      try {
        const response = await axios.get(
          `${config.epharmUrl}/Cmanufacturer/retrieve_all_manufacturers`,
        );

        // supplierList
        return response?.data?.total_manufacturers ?? [];
      } catch (error) {
        errorMessages(error, null, actions);
      }
    },
    { retry: 1 },
  );

  const suppliers = supplierListQuery?.isSuccess ? supplierListQuery?.data : [];

  const invoiceNumbersQuery = useQuery(
    ["invoiceNumbersQuery", supplier],
    async () => {
      try {
        const response = await axios.get(
          `${config.epharmUrl}/Cdebitmemo/previous_debit_invoice_number`,
          {
            params: {
              supplier_id: supplier?.supplier_id,
            },
          },
        );

        return response?.data ?? [];
      } catch (error) {
        errorMessages(error, null, actions);
      }
    },
    { retry: 1 },
  );

  const previousInvoiceNumbers = invoiceNumbersQuery?.isSuccess
    ? invoiceNumbersQuery?.data
    : [];

  const handleClickSupplier = async (event) => {
    // const { paid_amount } = totals;
    // if (!event["innerText"]) {
    // return;
    // }

    // event.target doesnot exist here, weird huh!
    // console.log(event)

    setSupplier({
      ...supplier,
      supplier_name: event?.innerText ?? "",
      supplier_id: event?.value ?? 0,
    });

    // await fetchPreviousInvoiceNumbers(event.value);

    setShowSupplier(false);

    setIsBlocking(true);
  };

  const handleChangeSupplier = (event) => {
    console.log("here");
    // const { name, value } = event.target;
    // setSupplier({ ...supplier, [name]: value });
    setSupplier({ ...supplier, ...event });
    setIsBlocking(true);
  };

  // const fetchPreviousInvoiceNumbers = async (supplierId) => {
  //   console.log(supplierId)
  //   try {
  //     const response = await axios.get(
  //       `${config.epharmUrl}/Cdebitmemo/previous_debit_invoice_number`,
  //       {
  //         params: { manufacturer_id: titleCase(supplierId) },
  //       }
  //     );
  //     console.log(response.data);
  //     setPreviousInvoiceNumbers(response.data);
  //   } catch (error) {
  //     errorMessages(error, null, actions);
  //   }
  // };

  const handleSaveDebitNote = async (event) => {
    event.preventDefault();
    try {
      const { supplier_id } = supplier;

      const payload = {
        supplier_id,
        memo_date: debitNoteDate,
        memo_details: debitNoteDetails,
        total_amount: totalAmount, // Implement the function to calculate total amount
        memo_no: debitMemoNumber,
        previous_invoice_no: selectedPreviousInvoiceNumber,
        item_details: rows,
      };

      // debugger;

      const response = await axios.post(
        `${config.epharmUrl}/Cdebitmemo/debit_memo`,
        payload,
      );

      setSnackbarOpen(false);

      history.push("/accounting/managedebitnotes");

      // setSnackbarOpen(false);
    } catch (error) {
      errorMessages(error, null, actions);
    }
  };

  const handleRetrieveDrugs = (eventTxt, index) => {
    let tmp = eventTxt.trim();
    if (tmp.includes("(")) {
      tmp = tmp.substring(0, tmp.indexOf("("));
    }
    const arr = rows.map((item, idx) => {
      if (idx === index) {
        if (tmp !== "") {
          axios
            .get(`${config.epharmUrl}/Cinvoice/autocompleteproductsearch`, {
              params: { product_name: titleCase(tmp) },
            })
            .then((res) => {
              const data = res.data;
              const dt = !data ? [] : data;
              const arr = dt.map((item) => ({
                value: item.value,
                label: `${item.label}-${item.generic_name}`,
                generic_name: item.generic_name,
              }));
              setDrugs(arr);
            })
            .catch((error) => {
              errorMessages(error, null, actions);
            });
          return { ...item, product_name: tmp, showProduct: true };
        } else {
          return {
            ...item,
            product_name: tmp,
            showProduct: false,
            batch_id: "",
            batch_info: [],
            available_quantity: 0,
            product_rate: 0,
            product_quantity: "",
            expiry: "",
            unit: "",
            total_price: 0,
            discount: "",
          };
        }
      }
      return item;
    });
    setRows(arr);
    setIsBlocking(true);
  };

  const handleClickDrug = (event, index) => {
    const { value, innerText } = event;
    if (value === "" || innerText === "") {
      return;
    }
    let arr = rows.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          showProduct: false,
          product_name: innerText,
          product_id: value,
        };
      }
      return item;
    });
    axios
      .post(`${config.epharmUrl}/Cinvoice/retrieve_product_data_inv`, null, {
        params: { product_id: value },
      })
      .then((response) => {
        const data = response.data;
        const dt = !data ? {} : data;
        const batchArr = dt.batch ? dt.batch : [];
        if (batchArr.length > 0) {
          const batches =
            batchArr.length > 0
              ? batchArr.map(({ batch_id, purchase_detail_id }) => {
                  return { purchase_detail_id, batch_id };
                })
              : [];
          const batch = batches[0].purchase_detail_id
            ? batches[0].purchase_detail_id
            : "";
          arr = arr.map((item, idx) => {
            const batchObj = getBatchDetails(batch, batchArr);
            const exp_date = batchObj.expiry ? batchObj.expiry : "";
            const daysLeft = Math.floor(
              (Date.parse(exp_date) - Date.now()) / (24 * 60 * 60 * 1000),
            );
            if (idx === index) {
              if (daysLeft < 0) {
                return {
                  ...item,
                  available_quantity: "",
                  expiry: "",
                  expired: true,
                  unit: dt.unit ? dt.unit : "",
                  batch_id: "",
                  dosage: "",
                  total_stock: dt.total_product ? dt.total_product : 0,
                  batch_info: batchArr,
                  product_rate: dt.price,
                };
              } else {
                return {
                  ...item,
                  ...getBatchDetails(batch, batchArr),
                  expired: false,
                  batch_info: batchArr,
                  unit: dt.unit ? dt.unit : "",
                  total_stock: dt.total_product ? dt.total_product : 0,
                  batch_id: batches[0],
                  product_rate: dt.price,
                };
              }
            }
            return item;
          });
          const isExpired = arr.every((item) => item.expired);
          if (isExpired) {
            actions.snackbarActions.infoSnackbar(
              "Batch Expired, Please Choose another",
            );
          }
          setRows(arr);
        } else {
          actions.snackbarActions.infoSnackbar(
            "This product has no batches. Please choose another",
          );
        }
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
    // setIsBlocking(true);
  };

  const handleChangeBatch = (event, index) => {
    const batch_value = event.target ? event.target.value : event;
    const arr = rows.map((item, idx) => {
      const batchObj = getBatchDetails(batch_value, item.batch_info);
      const obj = batchObj ? batchObj : {};
      const exp_date = obj.expiry ? obj.expiry : "";
      const daysLeft = Math.floor(
        (Date.parse(exp_date) - Date.now()) / (24 * 60 * 60 * 1000),
      );
      if (idx === index) {
        if (daysLeft < 0) {
          return {
            ...item,
            available_quantity: "",
            expiry: "",
            expired: true,
            batch_id: "",
            dosage: "",
          };
        } else {
          return {
            ...item,
            ...getBatchDetails(batch_value, item.batch_info),
            batch_id: obj,
            expired: false,
          };
        }
      }
      return item;
    });
    const isExpired = arr.every((item) => item.expired);
    if (isExpired) {
      actions.snackbarActions.infoSnackbar(
        "Batch Expired, Please Choose another",
      );
    }
    setRows(arr);
    //  setIsBlocking(true);
  };

  const handleAddRow = () => {
    const item = {
      product_name: "",
      batch_id: "",
      batch_info: [],
      available_quantity: "",
      expiry: "",
      unit: "",
      product_quantity: "",
      product_rate: "",
      discount: 0,
      total_price: "",
      discount_type: 1,
      total_discount: 0,
      product_id: "",
      tax: 0,
      dosage: "",
      showProduct: false,
      uuid_id: uuidv4(),
    };
    setRows([...rows, item]);
    // setIsBlocking(true);
  };

  const getBatchDetails = (b_id, batch_data = []) => {
    const arr = batch_data
      .filter((item) => item.purchase_detail_id === b_id)
      .map((item) => ({
        ...item,
        expiry: item.expiry_date,
        available_quantity: item.stock,
      }));
    return arr[0];
  };

  const calculateTotalAmounts = (arr, p_amount, prev_amount) => {
    const sum = arr.reduce((a, row) => {
      return Number(a + row.total_price);
    }, 0);
    const discount = arr.reduce((a, row) => {
      let total_apparent = +(row.product_rate * row.product_quantity);
      let dis = !row.discount ? 0 : +(row.discount * total_apparent);
      let disc = dis / 100;
      return a + disc;
    }, 0);
    const net_total = sum; //+(sum + prev_amount);
    const amount = +(net_total - p_amount);
    const final_amount = amount > 0 ? amount : 0;
    const change = +(p_amount - net_total);
    const final_change = change > 0 ? change : 0;
    setTotals({
      ...totals,
      grand_total_price: sum,
      n_total: +net_total,
      due_amount: final_amount,
      change: final_change,
      total_discount_amount: +discount,
      paid_amount: p_amount,
      previous_amount: prev_amount,
    });
  };

  const handleRemoveSpecificRow = (idx) => {
    const { paid_amount, previous_amount } = totals;
    // const updatedRows=[...rows]
    // updatedRows.splice(idx,0)
    const arr = rows.filter((_, index) => index !== idx);
    setRows(arr);
    calculateTotalAmounts(arr, paid_amount, previous_amount);
    setIsBlocking(true);
  };

  const handleChange = (event, index) => {
    const { value, name } = event.target;
    const arr = rows.map((item, idx) => {
      if (idx === index) {
        return { ...item, [name]: value };
      }
      return value;
    });
    setRows(arr);
    setIsBlocking(true);
  };

  const handleChangeQuantity = (event, index) => {
    const { value } = event.target;
    const { paid_amount, previous_amount } = totals;
    const arr = rows.map((item, idx) => {
      const total = +value * item.manufacturer_price;
      const discount = total * (+item.discount / 100);
      const final_total = total - discount;
      if (index === idx) {
        return {
          ...item,
          quantity: value,
          total_price: total,
          isError: +value > item.available_quantity,
        };
      }
      return item;
    });
    setRows(arr);
    calculateTotalAmounts(arr, paid_amount, previous_amount);
    // setIsBlocking(true);
  };

  const handleDebitMemoNumber = (event) => {
    setDebitMemoNumber(event.target.value);
  };
  const handlePreviousInvoiceNumber = (event) => {
    setSelectedPreviousInvoiceNumber(event.target.value);
  };
  const handleDebitMemoDetailsChange = (event) => {
    setDebitNoteDetails(event.target.value);
  };

  const handleDebitNoteDateChange = (event) => {
    setDebitNoteDate(event?.target?.value);
  };

  // Function to calculate total amount based on product details
  const calculateTotalAmount = () => {
    const total_amount = rows.reduce(
      (total, product) => total + product.total_price,
      0,
    );

    setTotalAmount(total_amount);

    return totalAmount;
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [rows]);

  return {
    supplier,
    suppliers,
    debitNoteDate,
    selectedPreviousInvoiceNumber,
    debitNoteDetails,
    rows,
    snackbarOpen,
    handleSaveDebitNote,
    previousInvoiceNumbers,
    handleClickSupplier,
    handleDebitNoteDateChange,
    handleDebitMemoDetailsChange,
    handleDebitMemoNumber,
    debitMemoNumber,
    showSupplier,
    handleChangeSupplier,
    handlePreviousInvoiceNumber,
    totalAmount,
    drugs,
    handleRetrieveDrugs,
    handleClickDrug,
    handleChangeBatch,
    handleAddRow,
    handleRemoveSpecificRow,
    handleChange,
    handleChangeQuantity,
    invoiceNumberDetails,
    grandTotal
  };
};

export default useDebitNote;
