import { useState } from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { history } from "../../../Helpers/history";
import { useReusableNewSales } from "./useReusableNewSales";
import { useAddProvider } from "../../Customer/CustomHooks/useAddProvider";
import { validateCurrentDate } from "../../../Utils/ConvertDate";


export const useCreateInvoice = (actions, invoice_number = '') => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const user_id = !singleUser.user_id ? 1 : singleUser.user_id;
    const [submitted, setSubmitted] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [invoice_id, setInvoiceId] = useState('');
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [provider, setProvider] = useState('')
    const [providerDetails, setProviderDetails] = useState({ account_name: '', card_number: '' })
    const { stateFunctions, stateVariables } = useReusableNewSales(actions, invoice_number)
    const [isCardNumber, setIsCardNumber] = useState(false)
    const [invoice_customer_id, setInvoiceCustomerId] = useState('')
    const [receiptData, setReceiptData] = useState({ products: [], customer: {}, loading: 'idle' });
    const { providerProps } = useAddProvider(actions)
    const {
        customer, rows, totals, initialState, customerDetails, state
    } = stateVariables
    const {
        setRows, setCustomer, setTotals, setCustomerDetails, setIsBlocking
    } = stateFunctions



    const handleChangeIsCard = (event) => {
        setIsCardNumber(event.target.checked)
    }


    const handleChangeProvider = (event) => {
        setProvider(event.target.value)
    }

    const handleChangeProviderDetails = (event) => {
        const { name, value } = event.target;
        setProviderDetails({ ...providerDetails, [name]: value })
    }


    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const retrieveInvoiceDetails = (item) => {
        const { invoice_number: inv_number, customer_id: cus_id } = item
        const formData = new FormData()
        formData.append('receipt_number', '')
        formData.append('invoice_number', inv_number)
        formData.append('customer_id', cus_id)
        setReceiptData({ ...receiptData, loading: 'loading' })
        axios.post(`${config.epharmUrl}/Cinvoice/view_receipt`, formData).then(response => {
            const dataRes = response.data;
            const dt = dataRes ? dataRes : {};
            const arr = dt.bill ? dt.bill : [];
            const customer_details = dt.customer_details ? dt.customer_details : {}
            const obj = arr[0] ? arr[0] : {}
            setReceiptData({ ...receiptData, products: arr, customer: { ...customer_details, ...obj }, loading: 'success' })
            setOpenDialog(true);
        }).catch(error => {
            errorMessages(error, null, actions)
            setReceiptData({ ...receiptData, loading: 'error' })
        })
    }

    const handleSaveInvoice = event => {
        event.preventDefault();

        const { account_name, card_number } = providerDetails

        const { n_total, grand_total_price } = totals

        const { credit_limit } = customerDetails

        const { customer_id } = customer

        const { invoice_date } = state

        const insuranceRequired = isCardNumber && account_name && card_number;

        const organizationRequired = !isCardNumber && account_name

        const arr = rows.length > 0 && rows.every(item => item.batch_id && item.product_name && item.product_quantity)

        setSubmitted(true)

        const requiredLimit = (credit_limit > 0 && credit_limit >= n_total) || credit_limit === 0

        const validate_invoice_date = validateCurrentDate(invoice_date)

        if (arr && account_name && (insuranceRequired || organizationRequired) && requiredLimit && validate_invoice_date) {
            setIsSubmitted('pending')

            setIsBlocking(false)

            // const data = rows.map(({ product_id, batch_id, product_quantity, product_rate, related_product, expiredReason }) => ({
            //     product_id,
            //     ...batch_id,
            //     quantity: +product_quantity,
            //     rate: +product_rate,
            //     id: related_product,
            //     expiredReason,
            // }))

            const data = rows.map((row) => ({
                ...row,
                ...row.batch_id,
                product_id: row?.product_id,
                quantity: +row?.product_quantity,
                rate: +row?.product_rate,
                id: row?.related_product,

            }))

            // debugger;

            axios.post(
                `${config.epharmUrl}/Cinvoice/insert_invoice`,
                {
                    payment_type: 2,
                    customer_id,
                    total_amount: +grand_total_price,
                    bill_details: data,
                    invoice_date
                },
                {
                    headers: { user_id },
                })
                .then(response => {

                    const data = response.data ?? {};

                    const dt = data.data ? data.data : {}

                    const recep = dt[0].receipt ? dt[0].receipt : '';

                    const inv_id = recep.invoice_no ? recep.invoice_no : ''

                    const cus_id = dt[0].customer_id ? dt[0].customer_id : ''

                    retrieveInvoiceDetails({ invoice_number: inv_id, customer_id: cus_id })

                    actions.snackbarActions.successSnackbar("Invoice saved successfully!");

                    setIsSubmitted('resolved')

                    setSubmitted(false)

                    setRows(initialState)

                    setCustomer({ customer_name: '', customer_id: '' })

                    setTotals({
                        grand_total_price: 0,
                        n_total: 0,
                        change: 0,
                        due_amount: 0,
                        paid_amount: '',
                        previous_amount: 0,
                        total_discount_amount: 0
                    })

                    setCustomerDetails({ credit_limit: 0 })

                    setInvoiceId(inv_id)

                    setInvoiceCustomerId(cus_id)

                }).catch(error => {
                    errorMessages(error, null, actions)

                    setIsSubmitted('rejected')
                })
        }

    };

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar();
    };


    const handlePreview = () => {
        history.push(`/invoice_print/${invoice_customer_id}/${invoice_id}`)
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    return {
        submitted, stateFunctions, stateVariables, openDialog, invoice_id,
        isPending, isResolved, isRejected, providerProps, handleCloseDialog, handleSaveInvoice,
        handleCloseSnackbar, handlePreview, providerDetails, handleChangeProviderDetails,
        handleChangeProvider, provider, isCardNumber, handleChangeIsCard, receiptData
    }
}
