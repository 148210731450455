import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

const SelectInput = ({
  name,
  value,
  onChange,
  options,
  submitted = false,
  defaultOption,
  ...props
}) => {
  const isSubmitted = submitted && !value;
  return (
    <Form.Control
      style={{ border: isSubmitted ? "1px solid red" : "" }}
      as="select"
      name={name}
      value={value}
      onChange={onChange}
      className="form__control"
      {...props}
    >
      {defaultOption && <option value="">{defaultOption}</option>}
      {options.map((option) => (
        <option
          key={option.value}
          value={option.value}
          data-testid={option.text}
        >
          {option.text}
        </option>
      ))}
    </Form.Control>
  );
};
export default SelectInput;

export const CustomSelect = ({
  onChange,
  value,
  dataTest,
  submitted = false,
  options = [],
  ...rest
}) => {
  const isSubmitted = submitted && !value?.value;
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderRadius: ".7rem",
      fontSize: "13px",
      border: isSubmitted ? "1px solid red" : "",
    }),
    option: (provided) => {
      return {
        ...provided,
        backgroundColor: "#ffff",
        color: "#181818",
        cursor: "pointer",
        fontSize: "13px",
        ":hover": {
          ...provided[":provided"],
          backgroundColor: "rgba(20,1,125,0.1)",
        },
        ":active": {
          ...provided[":active"],
          backgroundColor: "#ffff",
          color: "#0b0b0b",
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
    }),
  };
  return (
    <Select
      onChange={onChange}
      styles={customStyles}
      value={value}
      options={options}
      data-test={dataTest}
      {...rest}
    />
  );
};

export const CreatableCustomSelect = ({
  isLoading,
  onChange,
  onCreateOption,
  submitted = false,
  options,
  value,
  ...props
}) => {
  const isSubmitted = submitted && !value?.value;
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderRadius: ".7rem",
      fontSize: "13px",
      border: isSubmitted ? "1px solid red" : "",
    }),
    option: (provided) => {
      return {
        ...provided,
        backgroundColor: "#ffff",
        color: "#181818",
        cursor: "pointer",
        fontSize: "13px",
        ":hover": {
          ...provided[":provided"],
          backgroundColor: "rgba(20,1,125,0.1)",
        },
        ":active": {
          ...provided[":active"],
          backgroundColor: "#ffff",
          color: "#0b0b0b",
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
    }),
  };
  return (
    <CreatableSelect
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={onChange}
      onCreateOption={onCreateOption}
      options={options}
      value={value}
      styles={customStyles}
      {...props}
    />
  );
};
