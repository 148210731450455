import React from 'react'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions"
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {MedicineContainer, ProfitContainer} from '../../../Utils/styledComponents';
import {CoaList,  CustomCell } from '../../../Utils/AccountingUtils/CoaList';
import { usePrint } from '../../../Utils/Templates/usePrint';
import { BackButton } from '../../../Utils/Buttons/DataExportationButton';
import {PrintPdfButtons} from "./PrintPdfButtons";
import Card  from '@material-ui/core/Card';
import { formatDate } from '../../../Utils/ConvertDate';
import { AccountingDateFilter } from '../../../Utils/AccountingUtils/AccountingDateFilter';
import { useProfitLossStatement } from './hooks/useProfitLossStatement';
import {NoTransaction} from "../COA/ReusableJournalTable";
import { LoadingGif } from '../../../Utils/Loader';
import TableRow from "@material-ui/core/TableRow";
import TableHead from '@material-ui/core/TableHead';
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../../Helpers/history";
import {ReportsTreeView} from "../../../Utils/AccountingUtils/ReportsTreeView";
import {useCompany} from "../../../Context/company-context";


const ProfitLossStatement = ({actions, snackbars}) => {

    const {date, data,  closeSnackbar,handleChangeDate, isLoading, isSuccess, isError,
        isPending, submitted, handleSubmit} = useProfitLossStatement(actions, 'new_profit_and_loss')
    const {open, variant, message} = snackbars;

    const {from_date, to_date} = date;
    const incomeStatement = data ? data : [];
    const {componentRef, handlePrint} = usePrint( `Profit&Loss(${to_date})`)
    const {state} = useCompany();
    const {logo} = state
    const header = (
        <TableHead>
            <TableRow>
                <CustomCell><strong></strong></CustomCell>
                <CustomCell align='center'><strong>Amount(UGX)</strong></CustomCell>
            </TableRow>
        </TableHead>
    )
    const printDoc = (
    <div  ref={componentRef}>
        <ProfitContainer>
            <table className="table table-sm table-borderless prescription-table">
                <tr>
                    <td align="center"><img style={{marginTop:20,width:'100px'}}  src={logo} alt="mpeke logo"/></td>
                </tr>
                <tr>
                    <td align='center'><h5><strong>PROFIT & LOSS</strong></h5></td>
                </tr>
                <tr>
                    <td align='center'><h6><strong>{formatDate(to_date)}</strong></h6></td>
                </tr>
            </table>
            <CoaList  {...{ data:incomeStatement, isPrint:true,header}}/>
            <p style={{textAlign: "left", fontStyle: "italic"}}  className="mpeke-footer">Powered by Mara Scientific</p>  
        </ProfitContainer>
    </div>
    )


    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Profit & Loss Statement' heading="Accounting"
                                     subHeading='Profit & Loss Statement' link={history.location.pathname}/>
            <PharmacySnackbar message={message} open={open} handleCloseBar={closeSnackbar} variant={variant}/>
                <MedicineContainer>
                    <div className='my-3'>
                        <BackButton to='/allreports' text='Reports'/>
                    </div>
                    <AccountingDateFilter {...{from_date,to_date, handleChange:handleChangeDate,
                    submitted,handleSubmit,isPending}}/>
                        {isLoading ? <div className='text-center mt-5'>
                            <LoadingGif/>
                        </div>:null}
                        {isSuccess ? incomeStatement.length > 0 ?
                        <Card>
                            <div style={{padding:'8px 16px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                                <PrintPdfButtons   handlePrint={handlePrint}/>
                            </div>
                            <div className='p-3'>
                                <div  id="profit-loss-statement-pdf" style={{overflow: "hidden"}}>
                                    {/*<ProfitContainer>*/}
                                        <table className="table table-sm table-borderless prescription-table">
                                            <thead>
                                            <tr>
                                                <td align="center"><img style={{marginTop:20,width:'100px'}}  src={logo} alt="mpeke logo"/></td>
                                            </tr>
                                            </thead>
                                            <tr>
                                                <td align='center'><h5><strong>PROFIT & LOSS</strong></h5></td>
                                            </tr>
                                            <tr>
                                                <td align='center'><h6><strong>{formatDate(to_date)}</strong></h6></td>
                                            </tr>
                                        </table>
                                        <ReportsTreeView  {...{ data:incomeStatement, reports:true,header, start_date:from_date, end_date:to_date}}/>
                                        <p style={{textAlign: "center", fontStyle: "italic"}}>Powered by Mara Scientific</p>
                                    {/*</ProfitContainer>*/}

                                </div>

                            </div>
                        </Card>: <NoTransaction text='transactions'/>: null}
                        {isError ? <div className="mt-3 text-center">The server did not return a valid response</div>:null}
                        <div style={{display:'none'}}>
                            {printDoc}
                        </div>
                </MedicineContainer>
        </div>
    );
}

function mapStateToProps(state) {
    const {snackbars} = state;
    return {snackbars}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfitLossStatement);
