import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import { usePeriodsFilter } from "../../Report/CustomHooks/usePeriodsFilter";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { convDate, convertDate } from "../../../Utils/titleCaseFunction";
import { exportPDF } from "../../../Utils/exportPDF";
import { titleCase } from "../../../Utils/titleCaseFunction";
import { useMuiPagination } from "../../../Containers/Dasboard/DashboardTables";

export const useProductHistoryReport = (actions,params = {}) => {
  const { product_id: prdt_id, product_name: prdt_name } = params;
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({
    product_name: "",
    product_id: prdt_id,
    selected_product: prdt_name,
  });
  const [showProduct, setShowProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const [drugs, setDrugs] = useState([]);
  const [isBlocking, setIsBlocking] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState("idle");
  const { product_id, selected_product } = product;

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useMuiPagination();
  // const {product_name} = rows
  const {
    period,
    from_date,
    to_date,
    handleChangeDate,
    setDate,
    year,
    inputTypes,
    handleChangePeriod,
    handleChangeYear,
  } = usePeriodsFilter();
  const csvRef = useRef();

  const today = convertDate();

  useEffect(() => {
    if (!product_id) {
      return;
    }
    setLoading("loading");
    axios
      .get(`${config.epharmUrl}/Creport/find_product_history_report`,
          {params:{
              start_date:from_date,
              end_date:to_date,
              page:page,
              product_id: product_id ? product_id : ''
            }
          }
      )
      .then((response) => {
        const data = response.data;
        const dt = !data ? {} : data;
        const arr = dt.data ?? [];
        setLoading("success");
        setProducts(arr);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setLoading("error");
      });
  }, [page, product_id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted("pending");
    setLoading("loading");
    axios
      .get(`${config.epharmUrl}/Creport/find_product_history_report`,
          {params:{
              start_date:from_date,
              end_date:to_date,
              page:page,
              product_id: product_id ? product_id : ''
            }
          }
      )
      .then((response) => {
        const data = response.data;
        const dt = !data ? {} : data;
        const arr = dt.data ?? [];
        setLoading("success");
        setProducts(arr);
        setIsSubmitted("resolved");
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setLoading("error");
        setIsSubmitted("rejected");
      });
    
  };

const handleRetrieveDrugs = (event) => {
  const val = event.target ? event.target.value : event;
  setProduct({ ...product, product_name: val });
  let tmp = val.trim();
  if (tmp !== "") {
    axios
      .get(`${config.epharmUrl}/Cinvoice/autocompleteproductsearch`, {
        params: { product_name: titleCase(tmp) },
      })
      .then((response) => {
        const drugs = response.data;
        const dt = !drugs ? [] : drugs;
        const arr = dt.map((item) => ({
          value: item.value,
          label: `${item.label}-${item.generic_name}`,
          generic_name: item.generic_name,
        }));
        setDrugs(arr);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
    setShowProduct(true);
  } else {
    setShowProduct(false);
  }
  setIsBlocking(true);
};

const handleClickDrug = (event) => {
  setProduct({
    ...product,
    product_id: event.value,
    product_name: "",
    selected_product: event.innerText,
  });
  setShowProduct(false);
  setIsBlocking(true);
};

const downloadPDF = () => {
  exportPDF(
    "Product Report",
    [
      [
        "Date",
        "Invoice NO",
        "Supplier Name",
        "Transaction Type",
        "Quantity",
        "Unit Cost",
        "Total Amount",
      ],
    ],
    products.map((product) => [
      product.date,
      product.invoice,
      product.supplier_name,
      product.product_name,
      product.quantity,
      product.unit_cost,
      product.total_amount,
    ]),
    "Product History Report.pdf"
  );
};

  const downloadCsv = () => {
    csvRef.current.link.click();
  };

  return {
    product,
    handleRetrieveDrugs,
    handleClickDrug,
    products,
    drugs,
    page, 
    rowsPerPage,
    handleChangeRowsPerPage, 
    handleChangePage,
    from_date,
    to_date,
    handleChangeDate,
    period,
    loading,
    submitted,
    isSubmitted,
    handleSubmit,
    downloadPDF,
    downloadCsv,
    csvRef,
    today,
  };
};
