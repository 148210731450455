import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Label from "../../Utils/FormInputs/Label";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";

import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import DeleteDialog from "../../Utils/DeleteDialog";
import { titleCase } from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import { useManageMedicine } from "./CustomHooks/useManageMedicine";
import {
  CustomPagination,
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPaper,
} from "../../Utils/Tables/CustomTable";
import Form from "react-bootstrap/Form";
import { LoadingGif } from "../../Utils/Loader";
import {
  useExcelReports,
  usePdfReports,
} from "../Invoice/CustomHooks/useExcelReports";
import { AutoCompleteControlled } from "../../Utils/FormInputs/AutoCompleteInput";
import { Container } from "../../Utils/styledComponents";

const ManageMedicine = ({ actions, snackbars }) => {
  const {
    openDialog,
    product_count,
    activePage,
    products,
    loading,
    searchValue,
    handleChange,
    handleOpenDialog,
    handleCloseDialog,
    handleCloseBar,
    handlePageChange,
    handleDelete,
    isLoading,
    isSuccess,
    isError,
    product_categories,
    handleClickProductCategories,
    categoryList,
  } = useManageMedicine(actions);

  const currentObj = {
    file_name: "Products",
    url: "Cproduct/product_list_report",
  };

  const currentObj_Pdf = {
    file_name: "Products PDF",
    url: "Cproduct/product_list_report_pdf",
  };

  const { exportExcel } = useExcelReports(actions, currentObj);
  const { exportPDF } = usePdfReports(actions, currentObj_Pdf);
  const { variant, message, open } = snackbars;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const singleUser = !user ? {} : user;
  const permissions = !singleUser.permission ? {} : singleUser.permission;
  const manage_medicine = !permissions.manage_medicine
    ? {}
    : permissions.manage_medicine;
  console.log(permissions);
  console.log(user);
  const headData = [
    // {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Product Name",
    },
    {
      id: "generic",
      numeric: false,
      disablePadding: false,
      label: "Generic Name",
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: "Product Category",
    },
    { id: "unit", numeric: false, disablePadding: false, label: "Unit" },
    { id: "shelf", numeric: false, disablePadding: false, label: "Shelf" },
    {
      id: "strength",
      numeric: false,
      disablePadding: false,
      label: "Strength",
    },
    { id: "stock", numeric: false, disablePadding: false, label: "Stock" },
    {
      id: "sellprice",
      numeric: false,
      disablePadding: false,
      label: "Selling Price",
    },
    manage_medicine.create || manage_medicine.update || manage_medicine.delete
      ? { id: "action", numeric: false, disablePadding: false, label: "Action" }
      : { id: "nonaction", numeric: false, disablePadding: false, label: "" },
  ];

  return (
    <div className="journals">
      <ReusableDashboardHeader
        component="Manage Products"
        heading="Products"
        subHeading="Manage Products"
        link="/managemedicine"
      />
      <DeleteDialog
        openDialog={openDialog}
        handleDelete={handleDelete}
        handleClose={handleCloseDialog}
        message="Product"
      />

      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={handleCloseBar}
      />
      <Container>
        <div style={{ paddingTop: "5px", marginBottom: "10px" }}>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-2">
                  <Label name="Sort by:" />
                </div>

                <div className="col-md-5">
                  <AutoCompleteControlled
                    options={categoryList}
                    handleChange={handleClickProductCategories}
                    medicine_value={product_categories}
                    label_text={"category_name"}
                    value_text={"category_id"}
                    placeholder="Enter Product Category"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-right">
                <Link
                  to="/addmedicine"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <button type="button" className="btn mr-2 pharmacy-btn">
                    <FontAwesomeIcon icon={faPlus} /> Add New Product
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <MuiPaper>
          <EnhancedTableToolbar title="Manage Products">
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  onClick={exportExcel}
                  className="btn pharmacy-btn btn-sm mr-1"
                >
                  Download Excel
                </button>
              </span>
            </span>
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  onClick={exportPDF}
                  className="btn pharmacy-btn btn-sm mr-1"
                >
                  Download PDF
                </button>
              </span>
            </span>
            <Form.Control
              type="text"
              value={searchValue ? titleCase(searchValue) : ""}
              onChange={handleChange}
              className="form__control"
              placeholder="Search"
              style={{ float: "right" }}
            />
          </EnhancedTableToolbar>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead headCells={headData} />
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={headData.length} align="center">
                    <LoadingGif />
                  </TableCell>
                </TableRow>
              ) : null}
              {isSuccess ? (
                products.length > 0 ? (
                  products.slice(0, 10).map((info, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        className="mui-table-row"
                      >
                        {/*<TableCell component="th" scope="row" padding="default"*/}
                        {/*           className='mui-table-cell'>*/}
                        {/*    <span>{info.sl}</span>*/}
                        {/*</TableCell>*/}
                        <TableCell>
                          <span>
                            <Link
                              to={{
                                pathname: `/medicinedetails/${info.product_id}`,
                              }}
                              style={{ color: "#09905c" }}
                            >
                              {titleCase(info.product_name)}
                            </Link>
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>{titleCase(info.generic_name)}</span>
                        </TableCell>
                        <TableCell>{info.category_name}</TableCell>
                        <TableCell>{!info.unit ? "" : info.unit}</TableCell>
                        <TableCell>{info.product_location}</TableCell>
                        <TableCell>{info.strength}</TableCell>
                        <TableCell>{info.stock}</TableCell>
                        <TableCell>
                          {info.price_type === 1
                            ? `${info.price}%`
                            : info.price}
                        </TableCell>
                        {manage_medicine.update || manage_medicine.delete ? (
                          <TableCell id="mainbuttons">
                            {manage_medicine.update ? (
                              <Tooltip title="Update" placement="left-start">
                                <Link
                                  to={{
                                    pathname: `/editmedicine/${info.product_id}`,
                                  }}
                                >
                                  <button className="btn btn-sm pharmacy-info-btn mr-3">
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                  </button>
                                </Link>
                              </Tooltip>
                            ) : null}
                            {manage_medicine.delete ? (
                              <Tooltip title="Delete" placement="left-start">
                                <button
                                  className="btn btn-sm pharmacy-btn-dismiss"
                                  onClick={() =>
                                    handleOpenDialog(info.product_id)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </Tooltip>
                            ) : null}
                            {/*{manage_medicine.delete ?*/}
                            {/*    <Tooltip title="Delete" placement="left-start">*/}
                            {/*        <button*/}
                            {/*            className="btn btn-sm pharmacy-btn-dismiss"*/}
                            {/*            onClick={() => handleOpenDialog(info.product_id)}>*/}
                            {/*            <FontAwesomeIcon icon={faTrash}/></button>*/}
                            {/*    </Tooltip> : null}*/}
                          </TableCell>
                        ) : (
                          <TableCell />
                        )}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={headData.length} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )
              ) : null}
              {isError ? (
                <TableRow>
                  <TableCell colSpan={headData.length} align="center">
                    The server did not return a valid response
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
          <CustomPagination
            data={products}
            records={10}
            activePage={activePage}
            total_count={product_count}
            handleChangeNextPage={handlePageChange}
          />
        </MuiPaper>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageMedicine);
