import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { exportPDF } from "../../Utils/exportPDF";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import axios from "axios";
import config from "../../Helpers/config";
import { errorMessages } from "../../Helpers/ErrorMessages";
import { titleCase, removeTime } from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import { history } from "../../Helpers/history";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPaper,
  CustomPagination,
} from "../../Utils/Tables/CustomTable";
import Form from "react-bootstrap/Form";
import { Container } from "../../Utils/styledComponents";
import { BackButton } from "../../Utils/FormInputs/BackButton";
import { useReturnItems } from "../Return/CustomHooks/useReturnItems";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import InvoicePrintDialog from "../Invoice/InvoicePrintDialog";
import DetailsDialog from "../../Utils/Dialog/DetailsDialog";
import { ManufacturerReturnForm } from "../Return/ManufacturerReturnForm";
import {
  useExcelReports,
  usePdfReports,
} from "../Invoice/CustomHooks/useExcelReports";
import { RightAlignedContainer } from "../../Utils/styledComponents";
import { convertDate } from "../../Utils/titleCaseFunction";
import { DateFilter } from "../../Containers/DateFilter";

const returnHeadData = [
  { name: "Product" },
  { name: "Batch#" },
  { name: "Purchase Quantity" },
  { name: "Stock" },
  { name: "Return Quantity" },
  { name: "Unit Cost" },
  { name: `Discount(%)` },
  { name: "Total" },
  { name: "Check Return" },
];

const headData = [
  {
    id: "medicine_name",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },
  {
    id: "generic_name",
    numeric: false,
    disablePadding: false,
    label: "Generic Name",
  },
  { id: "strength", numeric: false, disablePadding: false, label: "Strength" },
  {
    id: "batch_id",
    numeric: false,
    disablePadding: false,
    label: "Batch Number",
  },
  {
    id: "expiry_date",
    numeric: false,
    disablePadding: false,
    label: "Expiry Date",
  },
  { id: "stock", numeric: false, disablePadding: false, label: "Stock" },
  { id: "amount", numeric: false, disablePadding: false, label: "Amount" },
];

const ExpiryListReport = ({ actions, snackbars }) => {
  const [expiryList, setExpiryList] = useState([]);
  const [stockCount, setStockCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [filter_dates, setFilterDates] = useState({
    start_date: convertDate(),
    end_date: convertDate(),
  });
  const [data, setData] = useState([]);
  const {
    returnProps,
    isResolved,
    setOpenDialog,
    openDialog,
    handleCloseDialog,
    openReturn,
    setOpenReturn,
    handleOpenReceipt,
    setRows,
  } = useReturnItems(actions);
  const csvRef = useRef();
  const isPending = submitted;

  const { start_date, end_date } = filter_dates;

  const getExpiryData = () => {
    const formData = new FormData();
    formData.append("draw", 1);
    formData.append("columns[0][data]", "sl");
    formData.append("columns[0][name]", "");
    formData.append("columns[0][searchable]", "true");
    formData.append("columns[0][orderable]", false);
    formData.append("columns[0][search][value]", "");
    formData.append("columns[0][search][regex]", false);
    formData.append("columns[1][data]", "product_id");
    formData.append("columns[1][name]", "");
    formData.append("columns[1][searchable]", true);
    formData.append("columns[1][orderable]", true);
    formData.append("columns[1][search][value]", "");
    formData.append("columns[1][search][regex]", false);
    formData.append("columns[2][data]", "batch_number");
    formData.append("columns[2][name]", "");
    formData.append("columns[2][searchable]", true);
    formData.append("columns[2][orderable]", false);
    formData.append("columns[2][search][value]", "");
    formData.append("columns[2][search][regex]", false);
    formData.append("columns[3][data]", "expire_date");
    formData.append("columns[3][name]", "");
    formData.append("columns[3][searchable]", true);
    formData.append("columns[3][orderable]", false);
    formData.append("columns[3][search][value]", "");
    formData.append("columns[3][search][regex]", false);
    formData.append("columns[4][data]", "stock");
    formData.append("columns[4][name]", "");
    formData.append("columns[4][searchable]", true);
    formData.append("columns[4][orderable]", false);
    formData.append("columns[4][search][value]", "");
    formData.append("columns[4][search][regex]", false);
    formData.append("order[0][column]", 2);
    formData.append("order[0][dir]", "asc");
    formData.append("start", 1);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    //formData.append("start", activePage);
    formData.append("length", 10);
    formData.append("search[value]", searchValue);
    formData.append("search[regex]", false);
    formData.append("aging_type", "normal");
    formData.append("aging_period", 0);
    formData.append("page_number", activePage);
    setLoading(true);
    axios
      .post(`${config.epharmUrl}/Creport/checkExpireList`, formData)
      .then((res) => {
        const data = res.data;
        const dt = data ?? {};
        const arr = dt.aaData ?? [];
        const stock_count = dt.iTotalRecords ?? 0;
        setExpiryList(arr);
        setStockCount(stock_count);
        setLoading(false);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setLoading(false);
      });
  };

  useEffect(() => {
    getExpiryData();
  }, [actions, activePage, searchValue, isResolved]);

  const handleOpenReturn = (_id) => {
    setOpenReturn(true);
    const formData = new FormData();
    formData.append("product_id", _id);
    axios
      .post(`${config.epharmUrl}/Creport/drugs_with_in_expiry_limit`, formData)
      .then((response) => {
        const data = response.data;
        const dt = !data ? [] : data;
        setData(dt);
      })
      .catch((err) => {
        errorMessages(err, null, actions);
      });
  };

  useEffect(() => {
    const arr = data.map((item) => ({
      ...item,
      purchase_detail_id: item.batch_id,
      product_quantity: "",
      total: 0,
      available_quantity: item.stock ? item.stock : 0,
      rtn: false,
      discount: "",
      rate: item.manufacturer_price,
      purchase_quantity: item.quantity_purchased,
    }));
    setRows(arr);
  }, [data]);

  const handleCloseReturn = () => {
    setOpenReturn(false);
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleCloseBar = () => {
    actions.snackbarActions.hideSnackBar();
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleCancelReturn = () => {
    setOpenDialog(false);
  };

  const downloadPDF = () => {
    axios
      .get(`${config.epharmUrl}/Creport/retrieve_all_out_of_expired_drugs`)
      .then((response) => {
        const data = response.data;
        const arr = data ?? [];
        exportPDF(
          "Expiry List",
          [
            [
              "SL",
              "Medicine Name",
              "Generic Name",
              "Strength",
              "Batch ID",
              "Expiry Date",
              "Stock",
            ],
          ],
          arr.map((stock, index) => [
            (index += 1),
            stock.product_name,
            stock.generic_name,
            stock.strength,
            stock.batch_id,
            stock.expeire_date,
            stock.stock,
          ]),
          "Expired Items.pdf"
        );
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  };

  const fetchCsvData = () => {
    axios
      .get(`${config.epharmUrl}/Creport/retrieve_all_out_of_expired_drugs`)
      .then((response) => {
        const data = response.data;
        const arr = data ?? [];
        setCsvData(arr);
        if (arr) {
          csvRef.current.link.click();
        }
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  };

  const currentObj = {
    file_name: "Expired Items Report",
    url: "Creport/expiry_aging_excel_report",
    params: { aging_type: "normal", aging_period: 0, start_date, end_date },
  };

  const objPDF = {
    file_name: "Expired Items Report",
    url: "Creport/expiry_aging_pdf_report",
    params: { aging_type: "normal", aging_period: 0, start_date, end_date },
  };
  const { exportExcel } = useExcelReports(actions, currentObj);
  const { exportPDF } = usePdfReports(actions, objPDF);

  const handleDateFilterChange = (event) => {
    const { name, value } = event.target;
    setFilterDates({ ...filter_dates, [name]: value });
  };

  const handleDateFilterSubmit = (event) => {
    event.preventDefault();
    getExpiryData();
  };

  const headers = [
    { key: "sl", label: "Sl" },
    { key: "product_name", label: "Medicine Name" },
    { key: "generic_name", label: "Generic Name" },
    { key: "strength", label: "Strength" },
    { key: "batch_id", label: "Batch#" },
    { key: "expeire_date", label: "Expiry Date" },
    { key: "stock", label: "Stock" },
  ];

  const { open, message, variant } = snackbars;

  const { totals } = returnProps;

  const { total_discount, grand_total_price } = totals;

  const supplierDetails = (
    <div className="row">
      <div className="col-md-4">
        <Form.Group as={Col}>
          <Label name="Total Deduction" />

          <TextField type="number" value={total_discount} readOnly />
        </Form.Group>
        <Form.Group as={Col}>
          <Label name="Net Return" />
          <TextField type="number" value={grand_total_price} readOnly />
        </Form.Group>
      </div>
      <div className="col-md-5" />
    </div>
  );

  return (
    <div>
      <ReusableDashboardHeader
        component="Expired Items"
        heading="Stock"
        subHeading="Expired items"
        link={history.location.pathname}
      />
      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={handleCloseBar}
      />
      <Container>
        <div className="mb-2">
          <BackButton text="Reports" to="/allreports" />
        </div>
        <InvoicePrintDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          message="Receipt"
          text="receipt"
        >
          <RightAlignedContainer>
            <button
              onClick={handleOpenReceipt}
              type="button"
              className="btn pharmacy-info-btn mr-3"
            >
              Yes
            </button>
            <button
              onClick={handleCancelReturn}
              type="button"
              className="btn pharmacy-grey-btn "
            >
              No
            </button>
          </RightAlignedContainer>
        </InvoicePrintDialog>
        <DetailsDialog
          openDialog={openReturn}
          handleClose={handleCloseReturn}
          title="Return Product(s)"
          maxWidth="lg"
        >
          <ManufacturerReturnForm
            {...{ ...returnProps, supplierDetails, headData: returnHeadData }}
          />
        </DetailsDialog>
        <MuiPaper>
          <div
            className="col-lg-7"
            style={{ float: "left", padding: "10px" }}
            data-testid="date-filter-manufacturer-sales-details"
          >
            <DateFilter
              start_date={start_date}
              end_date={end_date}
              handleChange={handleDateFilterChange}
              submitted={submitted}
              handleSubmit={handleDateFilterSubmit}
              isPending={isPending}
            />
          </div>
          <EnhancedTableToolbar title="">
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  className="btn btn-sm pharmacy-btn mr-1"
                  onClick={exportExcel}
                >
                  Download Excel
                </button>
              </span>
              <span>
                <button
                  className="btn btn-sm pharmacy-btn mr-1"
                  onClick={exportPDF}
                >
                  Download PDF
                </button>
              </span>

              {/* <span>
                <Link to={`/stockadjustment/${product_id}`}>
                  <button className="btn btn-sm pharmacy-btn mr-1">
                    Adjust Stock
                  </button>
                </Link>
              </span> */}
            </span>
            <Form.Control
              type="text"
              value={searchValue ? titleCase(searchValue) : ""}
              onChange={handleChange}
              className="form__control"
              placeholder="Search"
              style={{ float: "right" }}
            />
          </EnhancedTableToolbar>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead headCells={headData} />
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={headData.length} align="center">
                    <img
                      src="/images/listPreloader.gif"
                      alt="loader"
                      style={{ width: "5%" }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : expiryList.length > 0 ? (
              <TableBody>
                {expiryList.slice(0, 10).map((d, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      className="mui-table-row"
                    >
                      <TableCell>
                        <Link
                          to={{ pathname: `/medicinedetails/${d.product_id}` }}
                          style={{ color: "#09905c" }}
                        >
                          {titleCase(d.product_name)}
                        </Link>
                      </TableCell>
                      <TableCell>{d.generic_name}</TableCell>
                      <TableCell>{d.strength}</TableCell>
                      <TableCell>{d.batch_id}</TableCell>
                      <TableCell>
                        {d.expeire_date ? removeTime(d.expeire_date) : ""}
                      </TableCell>
                      <TableCell>{d.stock}</TableCell>
                      <TableCell>{d.total_cost}</TableCell>
                      {/*<TableCell>
                                                // THIS HAS BEEN REMOVED UNTIL THE FUNCTIONALITY IS WORKED ON COMPLETELY
                                               <button onClick={()=>handleOpenReturn(d.product_id)} className="btn btn-sm pharmacy-btn mr-1">Return</button>
                                            </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={headData.length} align="center">
                    No records found
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          <CustomPagination
            data={expiryList}
            records={10}
            activePage={activePage}
            total_count={stockCount}
            handleChangeNextPage={handlePageChange}
          />
        </MuiPaper>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpiryListReport);
