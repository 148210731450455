import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import { usePeriodsFilter } from "./usePeriodsFilter";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { convDate, convertDate } from "../../../Utils/titleCaseFunction";
import { exportPDF } from "../../../Utils/exportPDF";

export const usePurchaseReport = (actions) => {
  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState("");
  const [loading, setLoading] = useState(false);
  const [purchaseReport, setPurchaseReport] = useState([]);
  const [purchase_amount, setPurchaseAmount] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState("idle");
  const {
    period,
    from_date,
    to_date,
    handleChangeDate,
    setDate,
    year,
    inputTypes,
    handleChangePeriod,
    handleChangeYear,
  } = usePeriodsFilter();
  const csvRef = useRef();

  const today = convertDate();

  useEffect(() => {
    axios
      .get(`${config.epharmUrl}/Cmanufacturer/retrieve_all_manufacturers`)
      .then((res) => {
        const data = res.data;
        const dt = !data ? {} : data;
        const supps = dt.total_manufacturers ?? [];
        const arr = supps.map((item) => ({
          value: item.manufacturer_id,
          label: item.manufacturer_name,
        }));
        setSuppliers(arr);
      })
      .catch((err) => {
        // errorMessages(err, null, actions)
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${config.epharmUrl}/admin/todays_purchase_report`)
      .then((response) => {
        const dt = response.data;
        const purchasesList = dt ? dt : {};
        const data = purchasesList.purchase_report ?? [];
        const amount = purchasesList.purchase_amount ?? 0;
        setPurchaseReport(data);
        setPurchaseAmount(amount);
        setLoading(false);
      })
      .catch((error) => {
        // errorMessages(error, null, actions);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${config.epharmUrl}/admin/todays_purchase_report`)
      .then((response) => {
        const dt = response.data;
        const purchasesList = dt ? dt : {};
        const data = purchasesList.purchase_report ?? [];
        setPurchaseReport(data);
        setLoading(false);
      })
      .catch((error) => {
        // errorMessages(error, null, actions);
        setLoading(false);
      });
  }, []);

  const downloadPDF = () => {
    exportPDF(
      "Purchase Report",
      [
        [
          "Invoice NO",
          "Purchase Date",
          "Supplier Name",
          "Product Name",
          "Quantity Sold",
          "Purchase Price",
          "Total Amount",
        ],
      ],
      purchaseReport.map((sale) => [
        sale.chalan_no,
        convDate(sale.purchase_date),
        sale.manufacturer_name,
        sale.product_name,
        sale.quantity,
        sale.manufacturer_price,
        sale.total_amount,
      ]),
      "Purchase Report.pdf",
      [["", "", "", "", "", "Total Purchase Amount", purchase_amount]]
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (
      (period.value === "custom" && from_date && to_date) ||
      (period.value !== "custom" && from_date)
    ) {
      const formData = new FormData();
      formData.append(
        "from_date",
        period.value === "today" ? today : from_date
      );
      formData.append("to_date", period.value === "today" ? today : to_date);
      formData.append("manufacturer_id", supplier.value ? supplier.value : "");
      setIsSubmitted("pending");
      setLoading(true);
      axios
        .post(
          `${config.epharmUrl}/admin/retrieve_dateWise_PurchaseReports`,
          formData
        )
        .then((response) => {
          const dt = response.data;
          const purchasesList = dt ? dt : {};
          const data = purchasesList.purchase_report ?? [];
          const amount = purchasesList.purchase_amount ?? 0;
          setPurchaseReport(data);
          setPurchaseAmount(amount);
          setLoading(false);
          setIsSubmitted("resolved");
        })
        .catch((error) => {
          // errorMessages(error, null, actions);
          setLoading(false);
          setIsSubmitted("rejected");
        });
    }
  };

  const handleChangeSupplier = (value) => {
    setSupplier(value);

    const formData = new FormData();
    formData.append("from_date", period.value === "today" ? today : from_date);
    formData.append("to_date", period.value === "today" ? today : to_date);
    formData.append("manufacturer_id", value.value);
    setLoading(true);
    axios
      .post(
        `${config.epharmUrl}/admin/retrieve_dateWise_PurchaseReports`,
        formData
      )
      .then((response) => {
        const dt = response.data;
        const purchasesList = dt ? dt : {};
        const data = purchasesList.purchase_report ?? [];
        const amount = purchasesList.purchase_amount ?? 0;
        setPurchaseReport(data);
        setPurchaseAmount(amount);
        setLoading(false);
      })
      .catch((error) => {
        // errorMessages(error, null, actions);
        setLoading(false);
      });
  };

  const downloadCsv = () => {
    csvRef.current.link.click();
  };

  return {
    supplier,
    suppliers,
    handleChangeSupplier,
    period,
    from_date,
    to_date,
    handleChangeDate,
    setDate,
    year,
    inputTypes,
    handleChangePeriod,
    handleChangeYear,
    loading,
    purchaseReport,
    purchase_amount,
    submitted,
    isSubmitted,
    handleSubmit,
    downloadPDF,
    downloadCsv,
    csvRef,
    today,
  };
};
