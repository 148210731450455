import { useState } from 'react';
import { dateConvert } from '../../../../Utils/ConvertDate';


export const useDates = () => {
    const [date, setDate] = useState({ from_date: dateConvert(), to_date: dateConvert() });
    const handleChangeDate = (event) => {
        // console.log("Handle? ", event)
        const { value, name } = event.target ?? event;
        // console.log("Val: ", value,  " name: ", name)
        setDate({ ...date, [name]: value })
    }

    return [date, handleChangeDate, setDate]
}
