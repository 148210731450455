import React, { useEffect, useState, useCallback } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { bindActionCreators } from "redux";
import config from "../../../Helpers/config.json";
import { errorMessages } from "../../../Helpers/ErrorMessages";

import { connect } from "react-redux";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import { history } from "../../../Helpers/history";
import axios from "axios";

import { BackButton } from "../../../Utils/FormInputs/BackButton";
import {
  CenteredContainer,
  Container,
  RightAlignedContainer,
} from "../../../Utils/styledComponents";
import TableHead from "@material-ui/core/TableHead";
import {
  CustomPagination,
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPagination,
} from "../../../Utils/Tables/CustomTable";
import {
  CustomTableCell,
  StickyHeadTable,
  CustomHeadCell,
} from "../../../Utils/Tables/StickyHeaderTable";
import { LoadingGif } from "../../../Utils/Loader";
import { MuiPaper } from "../../../Utils/Tables/CustomTable";
import {
  useExcelReports,
  usePdfReports,
} from "../../Invoice/CustomHooks/useExcelReports";
import styled from "@emotion/styled/macro";
import * as colors from "../../../styles/colors";
import Grid from "@material-ui/core/Grid";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Label from "../../../Utils/FormInputs/Label";
import TextField from "../../../Utils/FormInputs/TextField";
import { dateConvert } from "../../../Utils/ConvertDate";
import { titleCase } from "../../../Utils/titleCaseFunction";
import { CustomSelect } from "../../../Utils/FormInputs/SelectInput";
import { makeStyles } from "@material-ui/core/styles";
import { formatDigits } from "../../../Utils/formatAmount";
import { useMuiPagination } from "../../../Containers/Dasboard/DashboardTables";
import { Link } from "react-router-dom";
import { index } from "d3";

const BorderedCell = styled(CustomTableCell)({
  borderRight: `1px solid ${colors.gray500}`,
});

export const useStyles = makeStyles((theme) => ({
  borderCell: {
    borderRight: `1px solid ${colors.gray500}`,
  },
}));

const headColumns = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Particulars",
    isBorder: true,
  },
  {
    id: "openingquantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },

  {
    id: "openingamount",
    numeric: false,
    disablePadding: false,
    label: "Value",
    isBorder: true,
  },
  {
    id: "inwardquantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "inwardamount",
    numeric: false,
    disablePadding: false,
    label: "Value",
    isBorder: true,
  },
  {
    id: "outwardquantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "outwardamount",
    numeric: false,
    disablePadding: false,
    label: "Value",
    isBorder: true,
  },
  {
    id: "closingquantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "closingamount",
    numeric: false,
    disablePadding: false,
    label: "Value",
  },
];

const parentHeadColumns = [
  {
    id: "opening_stock",
    numeric: false,
    disablePadding: false,
    label: "Opening",
    colSpan: 2,
  },
  {
    id: "quantity_in",
    numeric: false,
    disablePadding: false,
    label: "Inwards",
    colSpan: 2,
  },
  {
    id: "quantity_out",
    numeric: false,
    disablePadding: false,
    label: "Outwards",
    colSpan: 2,
  },
  {
    id: "closing_stock",
    numeric: false,
    disablePadding: false,
    label: "Closing Balnce",
    colSpan: 2,
  },
];

export function TotalHead({ totalColumns }) {
  const classes = useStyles();
  console.log(totalColumns);
  return (
    <TableRow tabIndex={-1}>
      {totalColumns.map((column, index) => {
        return (
          <CustomTableCell
            key={index}
            colSpan={column.colSpan}
            className={column.isBorder ? classes.borderCell : null}
            align={column.align}
          >
            {column.id}
          </CustomTableCell>
        );
      })}
    </TableRow>
  );
}

const StockSummary = ({
  actions,
  snackbars,
  data,
  setData,
  to,
  url,
  heading,
  manipulate,
  totalHeadData,
  otherParams = {},
  headData = headColumns,
  parentHeadData = parentHeadColumns,
}) => {
  const begin_date = otherParams?.start_date
    ? otherParams.start_date
    : dateConvert();
  const last_date = otherParams?.end_date
    ? otherParams.end_date
    : dateConvert();

  const classes = useStyles();
  const storage = JSON.parse(sessionStorage.getItem("total_count"));
  const dataArr = JSON.parse(sessionStorage.getItem("stock"));
  const item_count = storage ? storage : 0;
  const stockArr = dataArr ? dataArr : [];
  const stockItems = () => stockArr || [];
  const [activePage, setActivePage] = useState(1);

  const count = item_count;

  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [date, setDate] = useState({
    start_date: begin_date,
    end_date: last_date,
  });
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState("idle");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const { start_date, end_date } = date;

  useEffect(() => {
    const unlisten = history.listen(() => {
      sessionStorage.removeItem("stock");
      sessionStorage.removeItem("total_count");
    });

    return unlisten;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrieveReport = useCallback(
    (st_dte, ed_dte, isSubmit = false) => {
      setSubmitted(true);

      isSubmit && setIsSubmitted("pending");
      setData({ ...data, loading: "loading" });
      const { start_date: s_date, end_date: e_date, ...others } = otherParams;
      axios
        .get(`${config.epharmUrl}/${url}`, {
          params: {
            start_date: st_dte,
            end_date: ed_dte,
            page_number: activePage,
            search: searchValue,
            ...others,
          },
        })
        .then((response) => {
          const res = response.data;
          const dt = !res ? {} : res;
          const total_pages = dt.total_pages ? dt.total_pages : 0;
          const grand_totals = dt.grand_totals ? dt.grand_totals : {};
          const stock_count = dt.total_count ? dt.total_count : 0;
          const newArr = dt?.data.map((item) => ({
            ...item,
            start_date: st_dte,
            end_date: ed_dte,
          }));
          const itemsArr = manipulate(newArr);
          if (activePage === 1) {
            sessionStorage.setItem("total_count", JSON.stringify(stock_count));
            setData({
              loading: "success",
              total_count: stock_count,
              stockList: itemsArr,
              totals: grand_totals,
            });
            sessionStorage.setItem("stock", JSON.stringify(dt));
          } else {
            sessionStorage.setItem("stock", JSON.stringify(itemsArr));
            setData({
              loading: "success",
              total_count: count,
              stockList: itemsArr,
              totals: grand_totals,
            });
          }
          isSubmit && setIsSubmitted("resolved");
          setTotalPages(total_pages);
        })
        .catch((error) => {
          errorMessages(error, null, actions);
          setData({ ...data, loading: "error" });
          isSubmit && setIsSubmitted("rejected");
        });
    },
    [activePage, searchValue]
  );
  console.log(data);

  useEffect(() => {
    retrieveReport(start_date, end_date);
  }, [retrieveReport, activePage, searchValue]);

  const handleChangeDate = (event) => {
    const { name, value } = event.target;
    setDate({ ...date, [name]: value });
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setActivePage(1);
    setPage(0);
    retrieveReport(start_date, end_date, "true");
  };

  const handleChangeActivePage = (new_page) => {
    setActivePage(new_page + 1);
  };

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackBar();
  };

  const isLoading = data?.loading === "loading";
  const isSuccess = data?.loading === "success";
  const isError = data?.loading === "error";

  const isPending = isSubmitted === "pending";
  const isResolved = isSubmitted === "resolved";
  const isRejected = isSubmitted === "rejected";

  const { open, variant, message } = snackbars;

  const currentObj = {
    file_name: "Stock Summary",
    url: "Creport/get_stock_summary_report_excel",
    params: { start_date, end_date, ...otherParams },
  };
  const currentObjPDF = {
    file_name: "Stock Summary",
    url: "Creport/get_stock_summary_report_pdf",
    params: { start_date, end_date, ...otherParams },
  };
  console.log("currentObjPDF:", currentObjPDF);

  const currentObjAll = {
    file_name: "All stock summaries",
    url: "Creport/get_stock_summary_report_excel_all",
    params: { start_date, end_date, ...otherParams },
  };

  const { exportExcel, isLoading: isLoadingExcel } = useExcelReports(
    actions,
    currentObj
  );
  const { exportPDF, isLoading: isLoadingPDF } = usePdfReports(
    actions,
    currentObjPDF
  );
  const { exportExcel: exportAll, isLoading: isLoadingAll } = useExcelReports(
    actions,
    currentObjAll
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleChangeActivePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const parentHead = (
    <TableRow>
      <CustomHeadCell />
      {parentHeadData.map((column) => (
        <CustomHeadCell
          key={column.id}
          align="center"
          style={{ minWidth: column.minWidth }}
          // className={{root:{}}}
          colSpan={column.colSpan}
        >
          {column.label}
        </CustomHeadCell>
      ))}
    </TableRow>
  );

  const summaryList = data?.stockList?.map((item) => {
    return {
      ...item,
      name: item.url ? (
        <Link data-testid="particular-name" to={item.url}>
          {item.name}
        </Link>
      ) : (
        item.name
      ),
      openingamount: formatDigits(item.openingamount),
      inwardamount: formatDigits(item.inwardamount),
      outwardamount: formatDigits(item.outwardamount),
      closingamount: formatDigits(item.closingamount),
    };
  });

  return (
    <div>
      <ReusableDashboardHeader
        component="Stock Summary"
        heading="Stock Summary"
        subHeading="Stock Summary"
        link={history.location.pathname}
      />
      <Container>
        <div className="mb-3">
          <BackButton text="Reports" to="/allreports" />
        </div>
        <div className="general-ledger-header mb-3">
          <Grid container spacing={0}>
            <Grid item xs={6} md={8}>
              <form onSubmit={handleSubmit}>
                <Form.Group as={Row} style={{ marginBottom: "-1%" }}>
                  <Col sm={4}>
                    <Form.Group as={Row}>
                      <Label
                        name="From"
                        column
                        sm={2}
                        style={{ textAlign: "right" }}
                      />
                      <Col sm={10}>
                        <TextField
                          type="date"
                          value={start_date}
                          onChange={handleChangeDate}
                          name="start_date"
                          submitted={submitted}
                          data-testid="start-date"
                        />
                        {submitted && !start_date ? (
                          <div className="help-block">
                            From date is required
                          </div>
                        ) : null}
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col sm={4}>
                    <Form.Group as={Row}>
                      <Label name="To" column sm={2} />
                      <Col sm={10}>
                        <TextField
                          type="date"
                          name="end_date"
                          value={end_date}
                          onChange={handleChangeDate}
                          submitted={submitted}
                          data-testid="end-date"
                        />
                        {submitted && !end_date ? (
                          <div className="help-block">To date is required</div>
                        ) : null}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={1}>
                    <button
                      data-testid="filter-btn"
                      type="submit"
                      disabled={isPending || isLoadingExcel}
                      className="btn btn-sm pharmacy-btn-outline"
                    >
                      {isPending ? "Filtering..." : "Filter"}
                    </button>
                  </Col>
                  <Col sm={1}>
                    <button
                      type="button"
                      onClick={exportAll}
                      disabled={isPending || isLoadingAll}
                      className="btn btn-sm pharmacy-btn-outline"
                    >
                      {isPending ? "Downloading..." : "Download All"}
                    </button>
                  </Col>
                </Form.Group>
              </form>
            </Grid>

            <Grid item xs={6} md={4}></Grid>
          </Grid>
        </div>
        <PharmacySnackbar
          open={open}
          message={message}
          variant={variant}
          handleCloseBar={closeSnackbar}
        />
        <div className="row">
          <div className="col-lg-6">
            <h5>{heading}</h5>
          </div>
          <div className="col-lg-6">
            <RightAlignedContainer>
              <button
                type="button"
                data-testid="download-pdf-btn"
                onClick={exportPDF}
                disabled={isLoadingPDF}
                className="btn btn-sm pharmacy-info-btn mb-2"
              >
                {isLoadingExcel ? "Exporting...." : "Export to PDF"}
              </button>
              <button
                type="button"
                data-testid="download-excel-btn"
                style={{ margin: "0 10px" }}
                onClick={exportExcel}
                disabled={isLoadingExcel}
                className="btn btn-sm pharmacy-info-btn mb-2"
              >
                {isLoadingExcel ? "Exporting...." : "Export to Excel"}
              </button>
            </RightAlignedContainer>
          </div>
        </div>
        <EnhancedTableToolbar title="">
          <TextField
            type="text"
            placeholder="Search..."
            value={searchValue}
            onChange={handleChange}
          />
        </EnhancedTableToolbar>
        <MuiPaper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                {parentHead}
                <TableRow aria-label="sticky table">
                  {headData.map((column) => (
                    <CustomTableCell
                      aria-label="sticky table"
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, top: 57 }}
                      className={column.isBorder ? classes.borderCell : null}
                      classes={{ stickyHeader: classes.stickyHeader }}
                    >
                      <strong>{column.label}</strong>
                    </CustomTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && activePage === 1 ? (
                  <TableRow>
                    <TableCell align="center" {...{ colSpan: headData.length }}>
                      <LoadingGif />
                    </TableCell>
                  </TableRow>
                ) : null}
                {isSuccess ? (
                  summaryList?.length > 0 ? (
                    <>
                      {summaryList
                        .slice(0, recordsPerPage)
                        .map((row, index) => {
                          return (
                            <TableRow tabIndex={-1} key={index}>
                              {headData.map((column) => {
                                // console.log("row", row[column.id]);
                                return (
                                  <CustomTableCell
                                    // data-testid={row[column.id]}
                                    key={column.id}
                                    className={
                                      column.isBorder
                                        ? classes.borderCell
                                        : null
                                    }
                                  >
                                    {row[column.id]}
                                  </CustomTableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </>
                  ) : null
                ) : null}
                {/* {totalHead} */}
                {summaryList?.length > 0 ? totalHeadData : null}
                {isSuccess && summaryList?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={headData.length} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                ) : null}
                {isLoading && activePage !== 1 ? (
                  <TableRow>
                    <TableCell colSpan={headData.length} align="center">
                      <LoadingGif />
                    </TableCell>
                  </TableRow>
                ) : null}

                {isError ? (
                  <TableRow>
                    <TableCell align="center" {...{ colSpan: headData.length }}>
                      The server did not return a valid response
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
          {isSuccess ? (
            <MuiPagination
              rowsPerPage={rowsPerPage}
              page={page}
              total_count={data.total_count}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              isTotalCount={true}
              handleChangePage={handleChangePage}
              rows={10}
              colSpan={6}
            />
          ) : null}
        </MuiPaper>
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  return { snackbars: state.snackbars };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StockSummary);
