import React, { useState, useEffect } from "react";
import TextField from "../../../Utils/FormInputs/TextField";
import { ErrorMessage } from "../../../Utils/styledComponents";
import { CustomSelect } from "../../../Utils/FormInputs/SelectInput";
import ExpensePrintDialog from "./ExpensePrintDialog";
import Textarea from "../../../Utils/FormInputs/Textarea";
import { v4 as uuidv4 } from "uuid";
import { formatAmount } from "../../../Utils/formatAmount";
import axios from "axios";
import config from "../../../Helpers/config.json";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import SelectInput from "../../../Utils/FormInputs/SelectInput";

const formContainerStyles = { border: "1px solid #dee2e6", padding: "40px" };

const formStyles = {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "4 1fr",
  columnGap: "20px",
  rowGap: "20px",
  marginBottom: "20px",
};

const formItemStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const fornInputWidth = {
  width: "80%",
};

const paymentMethods = [
  { label: "MTN" },
  { label: "Airtel" },
  { label: "Cash" },
  { label: "VISA" },
  { label: "Bank" },
];

function NewExpense({
  submitted,
  openExpenseDialog,
  handleCloseExpenseDialog,
  handlePrint,
  handleClose,
  actions,
  handleSubmitExpense,
  isPendingSaveNew,
  isPendingSaveExit,
  isPending,
  vendorList,
  ledgers,
  expenseState,
  state,
  handleChangeExpenseState,
  handleChangeState,
  handleChangePaymentMethod,
  payTypes,
  paytype,
  grand_total,
  handleAddRow,
  handleChangeMemo,
  handleRemoveSpecificRow,
  handleChangeAmount,
  rows,
  handleChangeAccount,
}) {
  const { description, expense_date } = state;
  const { vendor, expense } = expenseState;

  return (
    <div>
      <ExpensePrintDialog
        text="expense"
        openExpenseDialog={openExpenseDialog}
        handleCloseExpenseDialog={handleCloseExpenseDialog}
        print={handlePrint}
        handleClose={handleClose}
        actions={actions}
        message="Expenses"
      />
      <div>
        <form
          data-testid="create-new-expense"
          style={formContainerStyles}
          autoComplete="off"
        >
          <div style={formStyles}>
            <div style={formItemStyles}>
              <label>
                Date<span className="help-block">*</span>
              </label>
              <div style={fornInputWidth}>
                <TextField
                  {...{ submitted }}
                  value={expense_date}
                  type="date"
                  name="expense_date"
                  data-testid="expense_date"
                  min="0"
                  onChange={handleChangeState}
                />
              </div>
              {submitted && !expense_date ? (
                <ErrorMessage>Expense date is required</ErrorMessage>
              ) : null}
            </div>
            <div style={formItemStyles}>
              <label>
                Payment Method<span className="help-block">*</span>
              </label>
              <div style={fornInputWidth}>
                <SelectInput
                  data-testid="payment_method"
                  value={paytype}
                  onChange={handleChangePaymentMethod}
                  options={payTypes}
                  defaultOption="Select payment method..."
                  submitted={submitted}
                />
              </div>
              {submitted && !expense_date ? (
                <ErrorMessage>Payment Method is required</ErrorMessage>
              ) : null}
            </div>
            <div style={formItemStyles}>
              <label>
                Pay to the order of<span className="help-block">*</span>
              </label>
              <div style={fornInputWidth} data-testid='pay_to'>
                <CustomSelect
                  // data-testid="vendor"
                  dataTest='vendor'
                  value={vendor}
                  options={vendorList}
                  name="vendor"
                  onChange={(value) =>
                    handleChangeExpenseState({ value, id: "vendor" })
                  }
                  submitted={submitted}
                />
              </div>
              {submitted && !vendor ? (
                <ErrorMessage>Order of is required</ErrorMessage>
              ) : null}
            </div>
            <div style={formItemStyles}>
              <label>Amount</label>
              <div style={fornInputWidth}>
                <td colSpan={2}>
                  <TextField
                    type="text"
                    disabled={true}
                    name="grand_total"
                    data-testid="grand_total"
                    value={
                      grand_total ? formatAmount(grand_total) : grand_total
                    }
                  />
                </td>
              </div>
            </div>

            <div style={formItemStyles}>
              <label>Memo</label>
              <div style={fornInputWidth}>
                <Textarea
                  data-testid="expense-memo"
                  value={description}
                  type="text"
                  name="description"
                  onChange={handleChangeState}
                  placeholder="Enter description"
                />
              </div>
            </div>
          </div>
          <div>
            <table className="table table-bordered p-0 smallFontSize add-purchase-table">
              <thead>
                <tr>
                  <td width="150" style={{ verticalAlign: "middle" }}>
                    Account
                    <span className="text-danger asterics ml-1">*</span>
                  </td>
                  <td width="100" style={{ verticalAlign: "middle" }}>
                    Amount<span className="text-danger asterics ml-1">*</span>
                  </td>
                  <td width="150" style={{ verticalAlign: "middle" }}>
                    Memo
                    {/* <span className="text-danger asterics ml-1">*</span> */}
                  </td>
                  <td width="40" style={{ verticalAlign: "middle" }}>
                    Action
                  </td>
                </tr>
              </thead>
              <tbody>
                {rows.map((item, idx) => {
                  return (
                    <tr id="addr0" key={item.uuid_id}>
                      <td width="15%" data-testid={`expense_account_${idx}`}>
                        <CustomSelect
                          value={item.account_name}
                          options={ledgers}
                          name="debit_ledger"
                          onChange={(value) =>
                            handleChangeAccount({
                              value,
                              uuid_id: item.uuid_id,
                            })
                          }
                          submitted={submitted}
                          data-testid={"account-" + idx}

                        />
                        {submitted && !item.account_name ? (
                          <ErrorMessage>Account Name is required</ErrorMessage>
                        ) : null}
                      </td>
                      <td>
                        <TextField
                          submitted={submitted}
                          type="number"
                          name="amount"
                          data-testid={"amount-" + idx}
                          value={item.amount}
                          style={{ fontSize: "11px" }}
                          onChange={(e) => handleChangeAmount(e, idx)}
                        />
                        {submitted && !item.amount ? (
                          <ErrorMessage>Amount is required</ErrorMessage>
                        ) : null}
                      </td>
                      <td>
                        <Textarea
                          submitted={submitted}
                          type="memo"
                          name="memo"
                          data-testid={"memo-" + idx}
                          value={item.memo}
                          onChange={(e) => handleChangeMemo(e, idx)}
                        />
                      </td>

                      <td>
                        <button
                          type="button"
                          className="btn pharmacy-btn-dismiss btn-sm small-btn"
                          data-testid={"delete-" + idx}
                          onClick={handleRemoveSpecificRow(item.uuid_id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={2}>
                    <button
                      type="button"
                      className="btn pharmacy-grey-btn btn-sm"
                      data-testid="add_row"
                      onClick={handleAddRow}
                    >
                      Add New Item
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            style={{
              display: "flex",
              gap: "20px",
              marginTop: "40px",
              justifyContent: "flex-end",
            }}
          >
            <button
              type="submit"
              data-testid="save-create-new"
              disabled={isPending || isPendingSaveNew}
              className="btn btn-sm pharmacy-btn"
              onClick={(e) => handleSubmitExpense(e, false)}
            >
              {isPendingSaveNew ? "Saving..." : "Save & Create New"}
            </button>
            <button
              data-testid="save-exit"
              type="submit"
              disabled={isPending}
              className="btn btn-sm pharmacy-btn"
              onClick={(e) => handleSubmitExpense(e, true)}
            >
              {isPending ? "Saving..." : "Save & Exit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
export { NewExpense };
