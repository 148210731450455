import React, { useEffect, useState } from "react";

import { ReusableJournalTable } from "./ReusableJournalTable";

import { formatAmount } from "../../../Utils/formatNumbers";
import { RightAlignedContainer } from "../../../Utils/styledComponents";
import { AccountingDateFilter } from "../../../Utils/AccountingUtils/AccountingDateFilter";
import { exportPDF } from "../../../Utils/exportPDF";
import {
  dateConvert,
  formatDate,
  formatLocalTime,
} from "../../../Utils/ConvertDate";
import axios from "axios";
import config from "../../../Helpers/config.json";

export const JournalList = ({ actions, item, isResolved }) => {
  const current_date = new Date();
  const [journal, setJournal] = useState([]);
  const [loading, setLoading] = useState("idle");
  const [running_balance, setBalance] = useState(0);
  const [state, setState] = React.useState({
    from_date: dateConvert(),
    to_date: dateConvert(),
  });
  const [submitted, setSubmitted] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState("idle");
  const [activePage, setActivePage] = useState(1);
  const [total_count, setTotalCount] = useState(0);
  const { from_date, to_date } = state;

  const { id, head_name } = item;

  useEffect(() => {
    if (id) {
      setLoading("pending");
      axios
        .post(
          `${config.epharmUrl}/accounting/ledger_details?ledger_id=${id}&start_date=${from_date}&end_date=${to_date}&page=${activePage}`
        )
        .then((res) => {
          const data = res.data ? res.data : {};
          const dt = data.data[0] ? data.data[0] : {};
          const bal = dt.openingbalance ? dt.openingbalance : "";
          const count = dt.total_count ? dt.total_count : "";
          const arr = dt.details ? dt.details : [];
          const list = arr.map((item) => ({
            account: item.Account,
            credit: item.Credit,
            date: item.Date,
            debit: item.Debit,
            balance: item.balance,
            details: item.details,
            journalId: item.journal,
            ...item,
          }));
          setJournal(list);
          setBalance(bal);
          setTotalCount(count);
          setLoading("success");
        })
        .catch((err) => {
          setLoading("error");
        });
    }
  }, [id, isResolved, activePage]);
  // console.log(journal)

  const handleChangePage = (newPage) => {
    setActivePage(newPage);
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { id } = item;
    setSubmitted(true);
    if (from_date && to_date && to_date >= from_date) {
      setLoading("pending");
      axios
        .post(
          `${config.epharmUrl}/accounting/ledger_details?ledger_id=${id}&start_date=${from_date}&end_date=${to_date}&page=${activePage}`
        )
        .then((res) => {
          const data = res.data ? res.data : {};
          const dt = data.data[0] ? data.data[0] : {};
          const bal = dt.openingbalance ? dt.openingbalance : "";
          const count = dt.total_count ? dt.total_count : "";
          const arr = dt.details ? dt.details : [];
          const list = arr.map((item) => ({
            account: item.Account,
            credit: item.Credit,
            date: item.date,
            debit: item.Debit,
            balance: item.balance,
            details: item.details,
            journalId: item.journal,
            ...item,
          }));
          setJournal(list);
          setBalance(bal);
          setTotalCount(count);
          setLoading("success");
        })
        .catch((err) => {
          setLoading("error");
        });
    }
  };

  const isLoading = loading === "pending";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  const isPending = isSubmitted === "pending";

  const downloadPDF = () => {
    exportPDF(
      `${head_name} Journal(${formatLocalTime(current_date)})`,
      [["Date", "Description", "Credit", "Debit", "Balance", "Journal Id"]],
      journal
        .filter((item) => Boolean(item))
        .map((item) => [
          formatDate(item.date),
          item.details,
          item.credit,
          item.debit,
          formatAmount(item.balance),
          item.journalId,
        ]),
      `${head_name} Journal(${formatLocalTime(current_date)}).pdf`,
      [["", "", "", "Running Balance", formatAmount(running_balance), ""]]
    );
  };

  return (
    <div>
      <div className="sub-journal-bar">
        <RightAlignedContainer>
          <h5 data-testid="running_balance">
            Opening Balance : <strong>{formatAmount(running_balance)}</strong>
          </h5>
        </RightAlignedContainer>
      </div>
      <div className="pt-3">
        <div className="mx-3">
          <AccountingDateFilter
            {...{
              from_date,
              to_date,
              handleChange,
              submitted,
              handleSubmit,
              isPending,
            }}
          />
        </div>
        <RightAlignedContainer>
          {journal.length > 0 ? (
            <button
              onClick={downloadPDF}
              className="btn btn-sm pharmacy-info-btn"
            >
              Download PDF
            </button>
          ) : null}
        </RightAlignedContainer>
        <ReusableJournalTable
          data={journal}
          {...{
            data: journal,
            isLoading,
            isResolved,
            id,
            isSuccess,
            isError,
            activePage,
            handleChangePage,
            total_count,
          }}
        />
      </div>
    </div>
  );
};
