import React from "react";
import { Grid, Tooltip, makeStyles } from "@material-ui/core";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";
import { Col, Form, Row } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Label from "../../../Utils/FormInputs/Label";
import TextField from "../../../Utils/FormInputs/TextField";
import { Link } from "react-router-dom";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import {
  CustomPagination,
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPaper,
} from "../../../Utils/Tables/CustomTable";
import { LoadingGif } from "../../../Utils/Loader";
import {
  dateStringTimeConvert,
  titleCase,
} from "../../../Utils/titleCaseFunction";
import { formatAmount } from "../../../Utils/formatNumbers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowRestore } from "@fortawesome/free-solid-svg-icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import { PurchaseDetailsPrint } from "../../Purchase/PurchaseDetailsPrint";
import { useManageDebitNote } from "../../Purchase/CustomHooks/useManageDebitNote";
import {
  useExcelReports,
  usePdfReports,
} from "../../Invoice/CustomHooks/useExcelReports";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "0.8em",
    height: "0.8em",
  },
  approved: {
    color: "green",
  },
  canceled: {
    color: "#D9512C",
  },
  pending: {
    color: "#F29339",
  },
}));

const headData = [
  {
    id: "date_created",
    numeric: false,
    disablePadding: false,
    label: "Date Created",
  },

  {
    id: "debitMemoNo",
    numeric: false,
    disablePadding: false,
    label: "Debit Memo No",
  },

  // {id: 'purchaseID', numeric: false, disablePadding: false, label: 'Purchase ID'},

  {
    id: "supplier",
    numeric: false,
    disablePadding: false,
    label: "Supplier Name",
  },

  {
    id: "previousInvoiceNo",
    numeric: false,
    disablePadding: false,
    label: "Previous Invoice No",
  },

  {
    id: "tAmount",
    numeric: false,
    disablePadding: false,
    label: "Total Amount",
  },

  //   manage_purchase.create || manage_purchase.update || manage_purchase.delete
  // ?

  { id: "action", numeric: false, disablePadding: false, label: "Action" },

  //     : { id: "nonaction", numeric: false, disablePadding: false, label: "" },
];

const ManageDebitNotes = ({ actions, snackbars }) => {
  const {
    debitNotes,
    totalAmount,
    handleChangeDate,
    date,
    handleChangeSearch,
    search,
    handleSearch,
    handleChangePage,
    isLoading,
    isSuccess,
    isError,
    openDialog,
    handleCloseBar,
    activePage,
  } = useManageDebitNote(actions);

  const currentObj = {
    file_name: "Purchase List",
    url: "Cdebitmemo/debit_list_pdf",
    params: {
      start_date: date?.startDate,
      end_date: date?.endDate,
    },
  };

  const objPDF = {
    file_name: "Purchase List PDF",
    url: "Cdebitmemo/debit_list_pdf",
    params: {
      start_date: date?.startDate,
      end_date: date?.endDate,
    },
  };

  const { exportExcel } = useExcelReports(actions, currentObj);

  const { exportPDF } = usePdfReports(actions, objPDF);

  let printCreditNote = true;

  const classes = useStyles();

  const { open, message, variant } = snackbars;

  //  const buttons = (
  //    <>
  //      <button
  //        className="btn pharmacy-info-btn  btn-sm mr-3"
  //        onClick={handleOpenDraftDialog}
  //      >
  //        Continue
  //      </button>
  //      <button
  //        className="btn pharmacy-grey-btn btn-sm"
  //        onClick={handleCloseDialog}
  //      >
  //        No
  //      </button>
  //    </>
  //  );

  return (
    <div>
      <DashboardHeader debit debitNote />

      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={handleCloseBar}
      />
      {/* <CustomDialog
        maxWidth="md"
        title="Cancel Purchase"
        open={openDialog}
        handleClose={handleCloseDialog}
        buttons={buttons}
        isOverflow={false}
      >
        <PurchaseDetailsPrint
          {...{ ...productPurchase, loading: loadingPurchase }}
        />
      </CustomDialog> */}
      <div className="general-ledger-header mb-3">
        <Grid container spacing={0}>
          <Grid item xs={6} md={8}>
            <form onSubmit={handleSearch}>
              <Form.Group as={Row} style={{ marginBottom: "-1%" }}>
                <Col sm={4}>
                  <Form.Group as={Row}>
                    <Label
                      name="From"
                      column
                      sm={2}
                      style={{ textAlign: "right" }}
                    />
                    <Col sm={10}>
                      <TextField
                        data-testid="startDate"
                        type="date"
                        onChange={handleChangeDate}
                        value={date?.startDate}
                        name="startDate"
                      />
                    </Col>
                  </Form.Group>
                </Col>

                <Col sm={4}>
                  <Form.Group as={Row}>
                    <Label name="To" column sm={2} />
                    <Col sm={10}>
                      <TextField
                        data-testid="endDate"
                        type="date"
                        value={date?.endDate}
                        onChange={handleChangeDate}
                        name="endDate"
                      />
                    </Col>
                  </Form.Group>
                </Col>

                {/* <Col sm={1}>
                  <button
                    data-testid="submit_date_filter"
                    type="submit"
                    className="btn btn-sm pharmacy-btn-outline"
                  >
                    Filter
                  </button>
                </Col> */}
              </Form.Group>
            </form>
          </Grid>

          <Grid item xs={6} md={4}>
            <div className="text-right">
              <Link to="/accounting/debitnote">
                <button
                  data-testid="add_debit_note"
                  className="btn btn-sm pharmacy-info-btn py-1"
                >
                  <AddIcon classes={{ root: classes.icon }} />
                  Add Debit Note
                </button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="mui-table">
        <MuiPaper>
          <EnhancedTableToolbar title="Debit Notes">
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  onClick={exportExcel}
                  className="btn btn-sm mr-1 pharmacy-btn"
                >
                  Download Excel
                </button>
              </span>
            </span>
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  onClick={exportPDF}
                  className="btn btn-sm mr-1 pharmacy-btn"
                >
                  Download PDF
                </button>
              </span>
            </span>
            <Form.Control
              type="text"
              value={search ? titleCase(search) : ""}
              onChange={handleChangeSearch}
              className="form__control"
              placeholder="Search"
              style={{ float: "right" }}
            />
          </EnhancedTableToolbar>
          <div className="sub-journal-bar" style={{ marginLeft: "0" }}>
            <h6 data-testid="running_balance">
              Total Debit Amount :<strong>{formatAmount(totalAmount)}</strong>
            </h6>
          </div>
          <div>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead headCells={headData} />
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={headData.length} align="center">
                      <LoadingGif />
                    </TableCell>
                  </TableRow>
                ) : null}
                {isSuccess ? (
                  debitNotes.length > 0 ? (
                    <>
                      {debitNotes.map((d, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            className="mui-table-row"
                          >
                            <TableCell>
                              {d?.date ? dateStringTimeConvert(d.date) : "N/A"}
                            </TableCell>
                            <TableCell>
                              <span data-testid={"debit_memo_no-" + index}>
                                {d?.debit_no ?? "N/A"}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span data-testid={"supplier_name-" + index}>
                                {d?.supplier_name ? titleCase(d?.supplier_name) : "N/A"}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span data-testid={"previous_memo_no-" + index}>
                                {d?.previous_no?.chalan_no ?? "N/A"}
                              </span>
                            </TableCell>

                            <TableCell>
                              <span data-testid={"grand_total_amount-" + index}>
                                {d?.total_amount
                                  ? formatAmount(d?.total_amount)
                                  : d?.total_amount}
                              </span>
                            </TableCell>

                            <TableCell id="mainbuttons">
                              {printCreditNote ? (
                                <span>
                                  <Link
                                    to={{
                                      pathname: `/debitnoteinvoice/${d.debit_id}`,
                                    }}
                                  >
                                    <button
                                      data-testid={"view_purchase-" + index}
                                      className="btn pharmacy-btn btn-sm mr-3 "
                                    >
                                      <FontAwesomeIcon icon={faWindowRestore} />
                                    </button>
                                  </Link>
                                  {/* <Tooltip
                                    title="Cancel Purchase"
                                    placement="left-start"
                                  ></Tooltip> */}
                                  {/*<Tooltip title="Update" placement="left-start">*/}
                                  {/*manage_purchase.update ?
                                                                <Link
                                                                    to={{pathname: `/editpurchase/${d.purchase_id}`}}>
                                                                    <button
                                                                        data-testid={"edit_purchase-"+index}
                                                                        className="btn pharmacy-info-btn btn-sm mr-1 ">
                                                                        <FontAwesomeIcon
                                                                            icon={faPencilAlt}/>
                                                                    </button>
                                                                </Link> : null */}
                                  {/*</Tooltip>*/}
                                </span>
                              ) : (
                                <span />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        data-testid="no_records_found"
                        colSpan={headData.length}
                        align="center"
                      >
                        No records found
                      </TableCell>
                    </TableRow>
                  )
                ) : null}
                {isError ? (
                  <TableRow>
                    <TableCell colSpan={headData.length} align="center">
                      The server did not return a valid response
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </div>

          <CustomPagination
            data={debitNotes}
            records={10}
            activePage={activePage}
            total_count={debitNotes?.length}
            handleChangeNextPage={handleChangePage}
          />

        </MuiPaper>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageDebitNotes);
