import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import axios from "axios";
import React, { useState } from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useQuery } from "react-query";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import config from "../../Helpers/config.json";
import { errorMessages } from "../../Helpers/ErrorMessages";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import { AccountingDrawer } from "../../Utils/AccountingUtils/AccountingDrawer";
import { CustomCell } from "../../Utils/AccountingUtils/CoaList";
import { formatDate } from "../../Utils/ConvertDate";
import { formatAmount } from "../../Utils/formatAmount";
import { BackButton } from "../../Utils/FormInputs/BackButton";
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import {
    CollapsibleList,
    DataCell,
    ItemText,
    ModuleTable
} from "../../Utils/List/CollapsibleList";
import { LoadingGif } from "../../Utils/Loader";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import { usePrint } from "../../Utils/Template/usePrint";
import { convertDate } from "../../Utils/titleCaseFunction";
import { PayablesDetailsPrint } from "../Accounting/Reports/PayablesDetailsPrint";
import { usePeriodsFilter } from "../Report/CustomHooks/usePeriodsFilter";





const useStyles = makeStyles((theme) => ({
    drawerWidth: {
        width: 800,
        padding: "16px",
    },
    paragraph: {
        fontSize: "15px",
        display: "inline-block",
        marginBottom: "0.8rem",
        marginRight: "16px",
    },
    icon: {
        width: ".9em",
        height: ".9em",
    },
    iconHolder: {
        minWidth: "38px ",
    },
    item: {
        borderRight: "1px solid #c7c6c7",
        borderLeft: "1px solid #c7c6c7",
        height: "100vh",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#c7c6c7",
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
        },
    },
    pItem: {
        borderLeft: "1px solid #c7c6c7",
    },
    rounded: {
        borderRadius: 0,
    },
    listText: {
        textAlign: "right",
    },
    approved: {
        color: "green",
    },
    purchased: {
        color: "#1d5dd3",
    },
    canceled: {
        color: "#D9512C",
    },
    pending: {
        color: "#F29339",
    },
    transactions: {
        padding: "20px 30px",
    },
}));

const headData = [
    { item: "Product", align: "left" },
    { item: "Batch#", align: "left" },
    { item: "Quantity", align: "left" },
    { item: "Rate", align: "left" },
    { item: "Amount", align: "left" },
];


// const headData = [{ name: 'Date' }, { name: 'Invoice No.' }, { name: 'Amount' }, { name: 'Status' }, { name: '' }]

const CustomerPurchases = ({ actions, match, snackbars }) => {

    const classes = useStyles();

    const { handlePrint, componentRef, getPageMargins } = usePrint("");

    const { customer_id } = match.params;

    const { period, from_date, to_date, handleChangeDate,
        year, inputTypes, handleChangePeriod, handleChangeYear } = usePeriodsFilter();

    const durationList = [
        { value: "today", text: "Today" },
        { value: "yesterday", text: "Yesterday" },
        { value: "this_week", text: "This Week" },
        { value: "this_month", text: "This Month" },
        { value: "custom", text: "Custom" },
    ];

    const [purchases, setPurchases] = useState([]);
    const [purchase_count, setPurchaseCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [customerInfo, setCustomerInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');

    const [openDrawer, setOpenDrawer] = useState(false);

    const [filters, setFilters] = useState({
        search: "",
        start_date: "",
        end_date: "",
        selectedDuration: "",
        page: 1,
    });


    const customerPurchaseDetailsQuery = useQuery(
        ["customerPurchaseDetailsQuery", filters],
        async () => {
            try {
                if (!filters?.start_date || !filters?.end_date) {

                    return { customer: {}, total_count: 0, customer_purchase_details: [] };

                }

                const formData = new FormData();

                formData.append('start_date', filters?.start_date);
                formData.append('end_date', filters?.end_date);
                formData.append('customer_id', customer_id);
                formData.append('page', filters?.page);

                const response = await axios.post(`${config.epharmUrl}/Ccustomer/customer_purchase_details`, formData);

                const data = response?.data;

                const customer = data?.customer ?? {}

                const totalCount = data?.total_count ?? 0;

                const customerPurchaseDetails = data?.customer_purchase_details ?? [];

                // setPurchases(pur);
                // setCustomerInfo(customer);
                // setPurchaseCount(t_count);

                return data ?? { customer: {}, total_count: 0, customer_purchase_details: [] };
            } catch (error) {
                errorMessages(error, null, actions);

                return { customer: {}, total_count: 0, customer_purchase_details: [] };

            }
        },
        {
            retry: 1
        }
    );

    const customerPurchaseDetailsQueryData = customerPurchaseDetailsQuery?.isSuccess ? customerPurchaseDetailsQuery?.data : { customer: {}, total_count: 0, customer_purchase_details: [] };


    const customerInvoicesQuery = useQuery(
        ["customerInvoicesQuery"],
        async () => {
            try {
                const formData = new FormData();

                const response = await axios.get(`${config?.epharmUrl}/`, formData);

                return response?.data ?? { invoices: [] };
            } catch (error) {
                errorMessages(error, null, actions);

                // return {};
            }
        },
        {
            retry: 1
        }
    );

    const customerInvoicesQueryData = customerInvoicesQuery?.isSuccess ? customerInvoicesQuery?.data : { invoices: [] };

    const handleChange = (event, args) => {
        setFilters({
            ...filters,
            [event?.target?.name]: event?.target?.value,
        })
    }

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar();

    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const handleOpenDrawer = () => setOpenDrawer(true);

    const handleCloseDrawer = () => setOpenDrawer(false);

    const handleOpen = (value) => {}

    const handleSubmitSearch = event => {
        event.preventDefault();

        setSubmitted(true)

        const end_date = (period.value === 'custom' && to_date) || (period.value !== 'custom' && !to_date)

        if (from_date && end_date) {
            const formData = new FormData();

            formData.append('start_date', period.value === "today" ? convertDate() : from_date);
            formData.append('end_date', period.value === "today" ? convertDate() : to_date);
            formData.append('customer_id', customer_id);
            formData.append('page', +(activePage));

            setLoading(true)
            setIsSubmitted('pending')

            axios
                .post(`${config.epharmUrl}/Ccustomer/customer_purchase_details`, formData)
                .then(response => {
                    const data = response.data;
                    const dt = !data ? {} : data;
                    const customer = dt.customer ? dt.customer : {}
                    const t_count = dt.total_count ? dt.total_count : 0;
                    const pur = dt.customer_purchase_details ? dt.customer_purchase_details : [];

                    setIsSubmitted('resolved')
                    setPurchases(pur);
                    setCustomerInfo(customer);
                    setPurchaseCount(t_count);
                    setLoading(false)
                }).catch(error => {
                    setLoading(false)
                    setIsSubmitted('rejected')
                })
        }
    };

    const { open, variant, message } = snackbars;

    const periods = [
        { value: 'today', label: 'Today' },
        // {value: 'weekly', label: 'Weekly'},
        // {value: 'monthly', label: 'Monthly'},
        { value: 'custom', label: 'Custom' },
    ];

    return (
        <div className='journals'>
            <ReusableDashboardHeader
                component='Customer Purchases'
                heading="Customer"
                subHeading='Customer Purchases'
                link={`/customerpurchases/${customer_id}`}
            />

            <AccountingDrawer
                open={openDrawer}
                title={`Payment for: `}
                handleClose={handleCloseDrawer}
                width={classes.drawerWidth}
            >
                test
            </AccountingDrawer>

            <PharmacySnackbar
                open={open}
                variant={variant}
                message={message}
                handleCloseBar={handleCloseSnackbar}
            />

            <Card elevation={0} classes={{ root: classes.rounded }}>
                <Grid container spacing={0}>
                    <Grid item xs={6} md={4} classes={{ item: classes.item }}>
                        <div className="top-bar">
                            <BackButton
                                text="Customers"
                                to="/managecustomer/manage_customer"
                            />

                            <div className="row mt-2">
                                <div className="col-lg-6">
                                    <SelectInput
                                        value={filters?.selectedDuration}
                                        onChange={handleChange}
                                        options={durationList}
                                        name="selectedDuration"
                                    />
                                </div>

                                <div className="col-lg-6">
                                    <TextField
                                        type="text"
                                        name="search"
                                        placeholder="Search..."
                                        value={filters?.search}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            {filters?.selectedDuration === "custom" ? (
                                <div className="mt-3">
                                    <Form.Group as={Row}>
                                        <Col sm={5}>
                                            <Label name="From" type />
                                            <TextField
                                                type="date"
                                                onChange={handleChange}
                                                value={filters?.start_date}
                                                name="start_date"
                                                submitted={submitted}
                                                max={filters?.end_date}
                                            />

                                            {submitted && !filters?.start_date ? (
                                                <div className="invalid-text">From date is required</div>
                                            )
                                                : null
                                            }
                                        </Col>

                                        <Col sm={5}>
                                            <Label name="To" type />
                                            <TextField
                                                type="date"
                                                value={filters?.end_date}
                                                onChange={handleChange}
                                                name="end_date"
                                                submitted={submitted}
                                                min={filters?.start_date}
                                            />
                                            {submitted && !filters?.end_date ? (
                                                <div className="invalid-text">To date is required</div>
                                            ) : null}
                                        </Col>

                                    </Form.Group>

                                </div>
                            ) : null
                            }

                        </div>

                        {
                            customerPurchaseDetailsQuery?.isLoading ? (
                                <div className="text-center">
                                    <LoadingGif />
                                </div>
                            ) : null
                        }

                        {
                            customerPurchaseDetailsQuery?.isSuccess ? <></> : <></>
                        }

                        {
                            customerPurchaseDetailsQuery?.isError ? (
                                <div className="text-center mt-3">
                                    No results found
                                </div>
                            ) : null}

                        {/*isSuccessDebtors ? (
                            debtors.length > 0 ? (
                                <>
                                    {debtors.map((item) => {
                                        const isActive = item.isActive === "active";
                                        const styles = {
                                            0: classes.pending,
                                            1: classes.approved,
                                            2: classes.canceled,
                                            3: classes.purchased,
                                        };
                                        const text = {
                                            0: "PENDING APPROVAL",
                                            1: "APPROVED",
                                            2: "CANCELED",
                                            3: "FULFILLED",
                                        };

                                        const itemObj = {
                                            name: item["Customer Name"],
                                            id: item["Customer No"],
                                            details: `${item["Customer No"]} | ${
                                                item.date ? formatDate(item.date) : ""
                                                }`,
                                            amount:
                                                item["Total Debt"] !== null
                                                    ? formatAmount(item["Total Debt"])
                                                    : item["Total Debt"],
                                        };

                                        return (
                                            <ReportsItemList
                                                styles={styles}
                                                isActive={isActive}
                                                handleClick={() => handleClickCustomer(item)}
                                                text={text}
                                                item={itemObj}
                                            />
                                        );
                                    })}

                                    {totalPageCount > 10 ? pagination : null}
                                </>
                            ) : (
                                    <div className="text-center mt-3">No records found</div>
                                )
                        ) : null*/}

                    </Grid>

                    <Grid item xs={6} md={8} classes={{ item: classes.pItem }}>
                        {customerInvoicesQuery?.isLoading ? (
                            <div className="text-center mt-5">
                                <LoadingGif />
                            </div>
                        ) : null}

                        {customerInvoicesQuery?.isError ? (
                            <div className="text-center mt-5">
                                The server did not return a valid response
                            </div>
                        )
                            : null
                        }

                        {customerInvoicesQuery?.isSuccess ? (
                            customerInvoicesQueryData?.invoices?.length > 0 ? (
                                <>
                                    <div className="top-bar">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <p
                                                    style={{
                                                        fontSize: "18px",
                                                        marginTop: "4px",
                                                        marginBottom: 0,
                                                    }}
                                                >
                                                    Transactions
                                                </p>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="text-right">
                                                    <div className="btn-group">
                                                        <Tooltip title="Print">
                                                            <button
                                                                type="button"
                                                                onClick={handlePrint}
                                                                className="btn btn-sm pharmacy-grey-btn"
                                                            >
                                                                <PrintOutlinedIcon
                                                                    classes={{ root: classes.icon }}
                                                                />
                                                            </button>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.transactions}>
                                        <div className="text-right">
                                            <button
                                                type="button"
                                                style={{ marginTop: "0.6rem" }}
                                                onClick={handleOpenDrawer}
                                                className="btn btn-sm pharmacy-info-btn mr-2 mb-3"
                                            >
                                                Receive Payment
                                            </button>

                                            <div style={{ display: "none" }}>
                                                <div ref={componentRef}>
                                                    {getPageMargins}

                                                    {customerInvoicesQuery?.isSuccess ? customerInvoicesQueryData?.invoices.map((invoice, index) => {
                                                        const products = invoice.products
                                                            ? invoice.products
                                                            : [];

                                                        const purchaseInfo = {
                                                            invoice_number: invoice.invoice_number,
                                                            paid_amount: invoice.paid_amount,
                                                            total_amount: invoice.total_amount,
                                                            purchase_date: formatDate(invoice.date),
                                                            paid_amount: invoice.paid_amount,
                                                        };

                                                        return (
                                                            <PayablesDetailsPrint
                                                                key={index}
                                                                {...{ products, purchaseInfo }}
                                                            />
                                                        );
                                                    }) : null}

                                                </div>
                                            </div>
                                        </div>

                                        {customerInvoicesQuery?.isSuccess ? customerInvoicesQueryData?.invoices.map((item, index) => {
                                            const products = item.products ? item.products : [];

                                            const tableData = products.map((row) => {
                                                const rate = row.rate ? row.rate : 0;
                                                const quantity = row.quantity ? row.quantity : 0;
                                                return (
                                                    <TableRow key={row.id}>
                                                        <DataCell>{row.product_name}</DataCell>
                                                        <DataCell>{row.batch_id}</DataCell>
                                                        <DataCell>{row.quantity}</DataCell>
                                                        <DataCell>{row.rate}</DataCell>
                                                        <DataCell>{formatAmount(quantity * rate)}</DataCell>
                                                    </TableRow>
                                                );
                                            });

                                            const date = (
                                                <span>
                                                    Date:{" "}
                                                    <strong>
                                                        {item.date ? formatDate(item.date) : ""}
                                                    </strong>
                                                </span>
                                            );

                                            const total_amount = item.total_amount
                                                ? formatAmount(item.total_amount)
                                                : 0;

                                            const paid_amount = item.paid_amount
                                                ? formatAmount(item.paid_amount)
                                                : 0;

                                            const due_amount = item.due_amount
                                                ? formatAmount(item.due_amount)
                                                : formatAmount(item.total_amount);

                                            const buttons = (
                                                <>
                                                    <ItemText primary={date} />
                                                    {/*<ItemText  primary={amount}  />*/}
                                                </>
                                            );

                                            return (
                                                <div key={item.id}>
                                                    <CollapsibleList
                                                        key={index}
                                                        open={item.open}
                                                        name={item.invoice_number}
                                                        handleOpen={() => handleOpen(item)}
                                                        button={buttons}
                                                    >
                                                        <ModuleTable headData={headData}>
                                                            {tableData}
                                                            <TableRow>
                                                                <DataCell
                                                                    align="right"
                                                                    colSpan={headData.length - 1}
                                                                >
                                                                    Total Amount
                                                                </DataCell>

                                                                <DataCell>
                                                                    <strong>{total_amount}</strong>
                                                                </DataCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <CustomCell
                                                                    align="right"
                                                                    colSpan={headData.length - 1}
                                                                >
                                                                    Payment Received
                                                                </CustomCell>

                                                                <CustomCell>
                                                                    <strong>{paid_amount}</strong>
                                                                </CustomCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <CustomCell
                                                                    align="right"
                                                                    colSpan={headData.length - 1}
                                                                >
                                                                    Amount Due
                                                                </CustomCell>

                                                                <CustomCell>
                                                                    <strong>{due_amount}</strong>
                                                                </CustomCell>
                                                            </TableRow>
                                                        </ModuleTable>
                                                    </CollapsibleList>
                                                </div>
                                            );
                                        }) : null}
                                    </div>
                                </>
                            ) : (
                                    <div className="text-center mt-5">No records found</div>
                                )
                        )
                            : null
                        }
                    </Grid>
                </Grid>
            </Card>

            {/*<div className='general-ledger-header'>
                <form onSubmit={handleSubmitSearch}>
                    <ReusableSearchForm
                        handleChangePeriod={handleChangePeriod}
                        period={period}
                        inputType={inputTypes}
                        from_date={from_date}
                        year={year}
                        to_date={to_date}
                        handleChangeYear={handleChangeYear}
                        handleChangeDate={handleChangeDate}
                        isSubmitted={isSubmitted}
                        submitted={submitted}
                        periods={periods}
                    />
                </form>
            </div>

            <div className="customer-info text-center mb-3 my-5">
                <p style={{ margin: 0 }}>{customer_name ? titleCase(customer_name) : ''}</p>

                {customer_mobile && <p style={{ margin: 0 }}>{customer_mobile ? customer_mobile : ''}</p>}

                {customer_email && <p style={{ margin: 0 }}>{customer_email ? customer_email : ''}</p>}

                <p style={{ margin: 0 }}>{customer_address ? titleCase(customer_address) : ''}</p>
            </div>

            {loading ?
                <div className='text-center'>
                    <img src="/images/listPreloader.gif"
                        alt="loader"
                        style={{ width: '5%' }}
                    />
                </div>
                :
                purchases.length > 0 ?
                    <div>
                        <CollapsibleTable headData={headData}>
                            {purchases.slice(0, 10).map((row) => (
                                <CustomerRow actions={actions} key={row.invoice_number} row={row} />
                            ))}
                        </CollapsibleTable>

                        <div className="row">
                            <div className="col-md-6" />

                            <div className="col-md-6">
                                <div className="text-right">
                                    {purchases.length > 0 && <Pagination
                                        prevPageText='Prev'
                                        nextPageText='Next'
                                        firstPageText='First'
                                        lastPageText='Last'
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={activePage}
                                        itemsCountPerPage={10}
                                        totalItemsCount={purchase_count}
                                        pageRangeDisplayed={5}
                                        onChange={handlePageChange}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <p className="text-center">No Purchase made</p>
            } */}

        </div>
    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPurchases);
