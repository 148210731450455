import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    paddingTop: 0,
    paddingBottom: 0,
  },
  icon: {
    width: ".9em",
    height: ".9em",
  },
  iconHolder: {
    minWidth: "38px ",
  },
  listItem: {
    borderBottom: "1px solid #e8e8e8",
    paddingTop: "10px",
    paddingBottom: "10px",
    cursor: "pointer",
  },
  primary: {
    fontSize: "0.98em",
  },
  secondary: {
    fontSize: "0.85em",
  },
  activeLi: {
    backgroundColor: "#f5f5f5",
  },
}));

const ReusableList = React.memo(function ({
  primaryText,
  icon,
  isActive,
  onClick,
  secondaryText = "",
  ...props
}) {
  const classes = useStyles();

  return (
    <List  className={classes.root} {...props}>
      <ListItem
        classes={{
          root: `${classes.listItem} ${isActive && classes.activeLi}`,
        }}
        onClick={onClick}
      >
        <ListItemIcon classes={{ root: classes.iconHolder }}>
          {icon ?? <LibraryBooksIcon classes={{ root: classes.icon }} />}
        </ListItemIcon>
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          classes={{ primary: classes.primary, secondary: classes.secondary }}
        />
      </ListItem>
    </List>
  );
});

export default ReusableList;
