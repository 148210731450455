import React from "react";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import { LoadingGif } from "../../../Utils/Loader";
import { formatDate } from "../../../Utils/ConvertDate";
import { formatAmount } from "../../../Utils/formatAmount";
import { history } from "../../../Helpers/history";
import { BackButton } from "../../../Utils/FormInputs/BackButton";
import { ReportsItemList } from "./ItemList";
import { useDebtorsReportDetails } from "./hooks/useDebtorsReportDetails";
import {
  CollapsibleList,
  DataCell,
  ItemText,
  ModuleTable,
} from "../../../Utils/List/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import { ReceiveDebtorsPayments } from "./ReceiveDebtorsPayments";
import { AccountingDrawer } from "../../../Utils/AccountingUtils/AccountingDrawer";
import { CustomCell } from "../../../Utils/AccountingUtils/CoaList";
import {
  CustomPagination,
  MuiPagination,
} from "../../../Utils/Tables/CustomTable";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectInput from "../../../Utils/FormInputs/SelectInput";
import Label from "../../../Utils/FormInputs/Label";
import TextField from "../../../Utils/FormInputs/TextField";
// import SelectInput from "../../../Utils/FormInputs/SelectInput";
// import Label from "../../../Utils/FormInputs/Label";
// import TextField from "../../../Utils/FormInputs/TextField";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { usePrint } from "../../../Utils/Template/usePrint";
import { PayablesDetailsPrint } from "./PayablesDetailsPrint";

const useStyles = makeStyles((theme) => ({
  drawerWidth: {
    width: 800,
    padding: "16px",
  },
  paragraph: {
    fontSize: "15px",
    display: "inline-block",
    marginBottom: "0.8rem",
    marginRight: "16px",
  },
  icon: {
    width: ".9em",
    height: ".9em",
  },
  iconHolder: {
    minWidth: "38px ",
  },
  item: {
    borderRight: "1px solid #c7c6c7",
    borderLeft: "1px solid #c7c6c7",
    height: "100vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c7c6c7",
      "-webkit-border-radius": "10px",
      borderRadius: "10px",
    },
  },
  pItem: {
    borderLeft: "1px solid #c7c6c7",
  },
  rounded: {
    borderRadius: 0,
  },
  listText: {
    textAlign: "right",
  },
  approved: {
    color: "green",
  },
  purchased: {
    color: "#1d5dd3",
  },
  canceled: {
    color: "#D9512C",
  },
  pending: {
    color: "#F29339",
  },
  transactions: {
    padding: "20px 30px",
  },
}));

const headData = [
  { item: "Product", align: "left" },
  { item: "Batch#", align: "left" },
  { item: "Quantity", align: "left" },
  { item: "Rate", align: "left" },
  { item: "Amount", align: "left" },
];

const DebtorsReportDetails = ({ actions, snackbars, match }) => {
  const classes = useStyles();
  const {
    data,
    handleOpen,
    closeSnackbar,
    handleClickCustomer,
    isLoadingDebtors,
    isLoadingInvoices,
    searchValue,
    totalPageCount,
    isSuccessDebtors,
    isSuccessInvoices,
    isErrorDebtors,
    isErrorInvoices,
    allDebtors,
    customer,
    paymentProps,
    handleChangeSearch,
    handleChangeActivePage,
    date,
    handleChange,
    filters,
    handleSubmit,
    submitted,
    activePage,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
  } = useDebtorsReportDetails(actions, match);
  const { invoices } = data;
  const { debtors } = allDebtors;
  const { open, variant, message } = snackbars;
  const { name } = customer;
  const { handlePrint, componentRef, getPageMargins } = usePrint("");

  const { openDialog, handleCloseDialog, handleOpenDialog } = paymentProps;

  const { filter_by, start_date, end_date } = date;
  const pagination = (
    <MuiPagination
      rowsPerPage={rowsPerPage}
      page={page}
      total_count={totalPageCount}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      isTotalCount={true}
      handleChangePage={handleChangePage}
      rows={10}
      colSpan={2}
      data={debtors}
    />
  );

  const dateFilter = (
    <form onSubmit={handleSubmit}>
      <Form.Group as={Row}>
        <Col sm={5}>
          <Label name="From" type />
          <TextField
            type="date"
            onChange={handleChange}
            value={start_date}
            name="start_date"
            submitted={submitted}
          />
          {submitted && !start_date ? (
            <div className="invalid-text">From date is required</div>
          ) : submitted && start_date > end_date ? (
            <div className="invalid-text">
              From date should be less than to date
            </div>
          ) : null}
        </Col>
        <Col sm={5}>
          <Label name="To" type />

          <TextField
            type="date"
            value={end_date}
            onChange={handleChange}
            name="end_date"
            submitted={submitted}
          />
          {submitted && !end_date ? (
            <div className="invalid-text">To date is required</div>
          ) : null}
        </Col>
        <Col sm={1}>
          <Label />
          <button
            type="submit"
            className="btn btn-sm pharmacy-btn-outline mt-3 mr-2"
          >
            Filter
          </button>
        </Col>
      </Form.Group>
    </form>
  );
  return (
    <div className="journals">
      <ReusableDashboardHeader
        component="Accounts Receivables"
        heading="Customer Balances"
        subHeading="Bills"
        link={history.location.pathname}
      />
      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={closeSnackbar}
      />
      <AccountingDrawer
        open={openDialog}
        title={`Payment for ${name}`}
        handleClose={handleCloseDialog}
        width={classes.drawerWidth}
      >
        <ReceiveDebtorsPayments {...paymentProps} />
      </AccountingDrawer>
      <Card elevation={0} classes={{ root: classes.rounded }}>
        <Grid container spacing={0}>
          <Grid item xs={6} md={4} classes={{ item: classes.item }}>
            <div className="top-bar">
              <BackButton
                text="Receivables"
                to="/accounting/debtors/customer"
              />
              <div className="row mt-2">
                <div className="col-lg-6">
                  <SelectInput
                    value={filter_by}
                    onChange={handleChange}
                    options={filters}
                    name="filter_by"
                  />
                  {/* <InvoiceDateFilter {...{filters,date, handleChange, handleFilter:handleSubmit, submitted}}/>  */}
                </div>
                <div className="col-lg-6">
                  <TextField
                    type="text"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={handleChangeSearch}
                  />
                </div>
              </div>
              {filter_by === "custom" ? (
                <div className="mt-3">{dateFilter}</div>
              ) : null}

              {/*<button disabled={!bulkPayment} className='btn btn-sm pharmacy-info-btn'>Record Bulk Payment</button>*/}
            </div>
            {isLoadingDebtors ? (
              <div className="text-center">
                <LoadingGif />
              </div>
            ) : null}
            {isSuccessDebtors ? (
              debtors.length > 0 ? (
                <>
                  {debtors.map((item) => {
                    const isActive = item.isActive === "active";
                    const styles = {
                      0: classes.pending,
                      1: classes.approved,
                      2: classes.canceled,
                      3: classes.purchased,
                    };
                    const text = {
                      0: "PENDING APPROVAL",
                      1: "APPROVED",
                      2: "CANCELED",
                      3: "FULFILLED",
                    };

                    const itemObj = {
                      name: item["Customer Name"],
                      id: item["Customer No"],
                      details: `${item["Customer No"]} | ${
                        item.date ? formatDate(item.date) : ""
                      }`,
                      amount:
                        item["Total Debt"] !== null
                          ? formatAmount(item["Total Debt"])
                          : item["Total Debt"],
                    };

                    return (
                      <ReportsItemList
                        styles={styles}
                        isActive={isActive}
                        handleClick={() => handleClickCustomer(item)}
                        text={text}
                        item={itemObj}
                      />
                    );
                  })}
                  {totalPageCount > 10 ? pagination : null}
                </>
              ) : (
                <div className="text-center mt-3">No records found</div>
              )
            ) : null}
            {isErrorDebtors ? (
              <div className="text-center mt-3">
                The server did not return a valid response
              </div>
            ) : null}
          </Grid>
          <Grid item xs={6} md={8} classes={{ item: classes.pItem }}>
            {isLoadingInvoices ? (
              <div className="text-center mt-5">
                <LoadingGif />
              </div>
            ) : null}
            {isSuccessInvoices ? (
              invoices.length > 0 ? (
                <>
                  <div className="top-bar">
                    <div className="row">
                      <div className="col-lg-6">
                        <p
                          style={{
                            fontSize: "18px",
                            marginTop: "4px",
                            marginBottom: 0,
                          }}
                        >
                          {name}'s Transactions
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <div className="text-right">
                          <div className="btn-group">
                            <Tooltip title="Print">
                              <button
                                type="button"
                                onClick={handlePrint}
                                className="btn btn-sm pharmacy-grey-btn"
                              >
                                <PrintOutlinedIcon
                                  classes={{ root: classes.icon }}
                                />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={classes.transactions}>
                    <div className="text-right">
                      <button
                        type="button"
                        style={{ marginTop: "0.6rem" }}
                        onClick={handleOpenDialog}
                        className="btn btn-sm pharmacy-info-btn mr-2 mb-3"
                      >
                        Receive Payment
                      </button>
                      <div style={{ display: "none" }}>
                        <div ref={componentRef}>
                          {getPageMargins}
                          {invoices.map((invoice, index) => {
                            console.log("Invoice", invoice);
                            const products = invoice.products
                              ? invoice.products
                              : [];
                            const purchaseInfo = {
                              invoice_number: invoice.invoice_number,
                              paid_amount: invoice.paid_amount,
                              total_amount: invoice.total_amount,
                              purchase_date: formatDate(invoice.date),
                              paid_amount: invoice.paid_amount,
                            };
                            return (
                              <PayablesDetailsPrint
                                key={index}
                                {...{ products, purchaseInfo }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {invoices.map((item, index) => {
                      const products = item.products ? item.products : [];
                      const tableData = products.map((row) => {
                        const rate = row.rate ? row.rate : 0;
                        const quantity = row.quantity ? row.quantity : 0;
                        return (
                          <TableRow key={row.id}>
                            <DataCell>{row.product_name}</DataCell>
                            <DataCell>{row.batch_id}</DataCell>
                            <DataCell>{row.quantity}</DataCell>
                            <DataCell>{row.rate}</DataCell>
                            <DataCell>{formatAmount(quantity * rate)}</DataCell>
                          </TableRow>
                        );
                      });
                      const date = (
                        <span>
                          Date:{" "}
                          <strong>
                            {item.date ? formatDate(item.date) : ""}
                          </strong>
                        </span>
                      );
                      const total_amount = item.total_amount
                        ? formatAmount(item.total_amount)
                        : 0;
                      const paid_amount = item.paid_amount
                        ? formatAmount(item.paid_amount)
                        : 0;
                      const due_amount = item.due_amount
                        ? formatAmount(item.due_amount)
                        : formatAmount(item.total_amount);
                      const buttons = (
                        <>
                          <ItemText primary={date} />
                          {/*<ItemText  primary={amount}  />*/}
                        </>
                      );

                      return (
                        <div key={item.id}>
                          <CollapsibleList
                            key={index}
                            open={item.open}
                            name={item.invoice_number}
                            handleOpen={() => handleOpen(item)}
                            button={buttons}
                          >
                            <ModuleTable headData={headData}>
                              {tableData}
                              <TableRow>
                                <DataCell
                                  align="right"
                                  colSpan={headData.length - 1}
                                >
                                  Total Amount
                                </DataCell>
                                <DataCell>
                                  <strong>{total_amount}</strong>
                                </DataCell>
                              </TableRow>
                              <TableRow>
                                <CustomCell
                                  align="right"
                                  colSpan={headData.length - 1}
                                >
                                  Payment Received
                                </CustomCell>
                                <CustomCell>
                                  <strong>{paid_amount}</strong>
                                </CustomCell>
                              </TableRow>
                              <TableRow>
                                <CustomCell
                                  align="right"
                                  colSpan={headData.length - 1}
                                >
                                  Amount Due
                                </CustomCell>
                                <CustomCell>
                                  <strong>{due_amount}</strong>
                                </CustomCell>
                              </TableRow>
                            </ModuleTable>
                          </CollapsibleList>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div className="text-center mt-5">No records found</div>
              )
            ) : null}
            {isErrorInvoices ? (
              <div className="text-center mt-5">
                The server did not return a valid response
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DebtorsReportDetails);
