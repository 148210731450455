import { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { titleCase } from "../../../../Utils/titleCaseFunction";
import config from "../../../../Helpers/config.json";
import { errorMessages } from "../../../../Helpers/ErrorMessages";
import { history } from "../../../../Helpers/history";

const useCreditNote = (actions, is_cash = false) => {
  const defaultCustomer = is_cash
    ? { customer_name: "Walk-in Customer", customer_id: 1 }
    : "";

  const initialProductsState = [
    {
      product_name: "",
      batch_id: "",
      batch_info: [],
      available_quantity: "",
      expiry: "",
      unit: "",
      product_quantity: "",
      product_rate: "",
      discount: "",
      total_price: "",
      discount_type: 1,
      related_product: "",
      total_discount: 0,
      tax: "",
      dosage: "",
      product_id: "",
      showProduct: false,
      isError: false,
      uuid_id: uuidv4(),
    },
  ];

  const [totals, setTotals] = useState({
    grand_total_price: 0,
    n_total: 0,
    change: 0,
    due_amount: 0,
    paid_amount: "",
    previous_amount: 0,
    total_discount_amount: 0,
  });

  const [customer, setCustomer] = useState(defaultCustomer);

  const [customersList,
    setCustomersList] = useState([]);

  const [creditNoteDate, setCreditNoteDate] = useState(null);

  const [selectedPreviousInvoiceNumber, setSelectedPreviousInvoiceNumber] =
    useState(null);

  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const [previousInvoiceNumbers, setPreviousInvoiceNumbers] = useState([]);

  const [invoiceNumberDetails, setInvoiceNumberDetails] = useState([]);

  const [creditNoteDetails, setCreditNoteDetails] = useState("");

  const [rows, setRows] = useState(initialProductsState);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [creditMemoNumber, setCreditMemoNumber] = useState("");

  const [submitted, setSubmitted] = useState(false);

  const [showCustomer, setShowCustomer] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);

  const [drugs, setDrugs] = useState([]);

  const [isBlocking, setIsBlocking] = useState(false);

  const [grandTotal,setGrandTotal] = useState(0)

  const [customerDetails, setCustomerDetails] = useState({
    credit_limit: 0,
    isCreditError: false,
  });

  const [loading, setLoading] = useState("idle");

  // console.log(customer);

  useEffect(() => {
   
    if (!selectedPreviousInvoiceNumber) {
      return;
    }
  
    setLoading("loading");
    const queryString = `?invoice_no=${selectedPreviousInvoiceNumber}`;
    axios.get(`${config.epharmUrl}/Ccredit_memo/get_invoice_details${queryString}`)
      .then((response) => {
        const data = response.data;
        const dt = !data.data ? [] : data.data;
        const total = data.grand_total_amount ?? 0
        console.log(total);

        const batchData = dt.map((item) => ({
          ...item,
          batch_id: item.batch_id,
          expiry_date: item.expiry_date,
          unit: item.unit,
          quantity: item.quantity,
          price: item.price,
          total_amount: item.total_amount
        }));
        setLoading("success");
        setRows(batchData);
        setGrandTotal(total)
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setLoading("error");
      });
  }, [selectedPreviousInvoiceNumber]);


  const handleRetrieveCustomerInfo = (event) => {
    const customer_value = event.target ? event.target.value : event;
    // console.log(customer_value);
    if (customer_value !== "") {
      axios
        .get(`${config.epharmUrl}/Cinvoice/customer_autocomplete`, {
          params: { customer_id: titleCase(customer_value) },
        })
        .then((res) => {
          const data = res.data;
          const dt = !data ? [] : data;
          const arr = dt.map((item) => ({
            value: `${item.customer_id}`,
            label: item.customer_name,
          }));

          // console.log(arr[0].value);
          setCustomer({
            ...customer,
            customer_name: customer_value,
            customer_id: +arr[0].value,
          });
          setCustomersList(arr);
        })
        .catch((error) => {
          errorMessages(error, null, actions);
        });
      setShowCustomer(true);
    } else {
      setShowCustomer(false);
    }
    // setIsBlocking(true)
  };

  const handleGetCustomer = (event) => {
    if (!event["innerText"]) {
      setCustomer({ customer_name: "", customer_id: "" });
      setShowCustomer(false);
      return;
    }
    const { innerText, value } = event;
    const id = value ? value.split("-")[0] : "";
    const limit = value ? value.split("-")[1] : 0;
    setCustomer({ customer_name: innerText, customer_id: id });
    setCustomerDetails({ ...customerDetails, credit_limit: parseInt(limit) });
    return axios.get(`${config.epharmUrl}/Cinvoice/outstanding_balance`, {
      params: { customer_id: id },
    });
  };

  const handleClickCustomer = async (event) => {
    if (!event["innerText"]) {
      return;
    }
    try {
      await fetchPreviousInvoiceNumbers(event.value);
      setSelectedInvoice(event.value);

      setShowCustomer(false);
    } catch (error) {
      errorMessages(error, null, actions);
    }

    //   // setIsBlocking(true)
  };

  const fetchPreviousInvoiceNumbers = async (customerId) => {
    try {
      const response = await axios.get(
        `${config.epharmUrl}/Ccredit_memo/retrieve_previous_invoice_id`,
        {
          params: { customer_id: titleCase(customerId) },
        },
      );
      // console.log(response.data);
      setPreviousInvoiceNumbers(response.data);
    } catch (error) {
      errorMessages(error, null, actions);
    }
  };

  const handleSaveCreditNote = async (e) => {
    e.preventDefault();
    const { customer_id } = customer;
    // console.log(customer_id)
    try {
      const payload = {
        previous_invoice: selectedPreviousInvoiceNumber,
        customer: customer.customer_id,
        credit_memo_date: creditNoteDate,
        credit_memo_details: creditNoteDetails,
        total_amount: totalAmount, // Implement the function to calculate total amount
        credit_memo_no: creditMemoNumber,
        product_details: rows,
      };


      // debugger;

      await axios.post(`${config.epharmUrl}/Ccredit_memo/credit_memo`, payload);

      history.push("/accounting/managecreditnotes");

      setSnackbarOpen(false);
    } catch (error) {
      errorMessages(error, null, actions);
    }
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [rows]);

  const handleRetrieveDrugs = (eventTxt, index) => {
    let tmp = eventTxt.trim();
    if (tmp.includes("(")) {
      tmp = tmp.substring(0, tmp.indexOf("("));
    }
    const arr = rows.map((item, idx) => {
      if (idx === index) {
        if (tmp !== "") {
          axios
            .get(`${config.epharmUrl}/Cinvoice/autocompleteproductsearch`, {
              params: { product_name: titleCase(tmp) },
            })
            .then((res) => {
              const data = res.data;
              const dt = !data ? [] : data;
              const arr = dt.map((item) => ({
                value: item.value,
                label: `${item.label}-${item.generic_name}`,
                generic_name: item.generic_name,
              }));
              setDrugs(arr);
            })
            .catch((error) => {
              errorMessages(error, null, actions);
            });
          return { ...item, product_name: tmp, showProduct: true };
        } else {
          return {
            ...item,
            product_name: tmp,
            showProduct: false,
            batch_id: "",
            batch_info: [],
            available_quantity: 0,
            product_rate: 0,
            product_quantity: "",
            expiry: "",
            unit: "",
            total_price: 0,
            discount: "",
          };
        }
      }
      return item;
    });
    setRows(arr);
    setIsBlocking(true);
  };

  const handleClickDrug = (event, index) => {
    const { value, innerText } = event;
    if (value === "" || innerText === "") {
      return;
    }
    let arr = rows.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          showProduct: false,
          product_name: innerText,
          product_id: value,
        };
      }
      return item;
    });
    axios
      .post(`${config.epharmUrl}/Cinvoice/retrieve_product_data_inv`, null, {
        params: { product_id: value },
      })
      .then((response) => {
        const data = response.data;
        const dt = !data ? {} : data;
        const batchArr = dt.batch ? dt.batch : [];
        if (batchArr.length > 0) {
          const batches =
            batchArr.length > 0
              ? batchArr.map(({ batch_id, purchase_detail_id }) => {
                  return { purchase_detail_id, batch_id };
                })
              : [];
          const batch = batches[0].purchase_detail_id
            ? batches[0].purchase_detail_id
            : "";
          arr = arr.map((item, idx) => {
            const batchObj = getBatchDetails(batch, batchArr);
            const exp_date = batchObj.expiry ? batchObj.expiry : "";
            const daysLeft = Math.floor(
              (Date.parse(exp_date) - Date.now()) / (24 * 60 * 60 * 1000),
            );
            if (idx === index) {
              if (daysLeft < 0) {
                return {
                  ...item,
                  available_quantity: "",
                  expiry: "",
                  expired: true,
                  unit: dt.unit ? dt.unit : "",
                  batch_id: "",
                  dosage: "",
                  total_stock: dt.total_product ? dt.total_product : 0,
                  batch_info: batchArr,
                  product_rate: dt.price,
                };
              } else {
                return {
                  ...item,
                  ...getBatchDetails(batch, batchArr),
                  expired: false,
                  batch_info: batchArr,
                  unit: dt.unit ? dt.unit : "",
                  total_stock: dt.total_product ? dt.total_product : 0,
                  batch_id: batches[0],
                  product_rate: dt.price,
                };
              }
            }
            return item;
          });
          const isExpired = arr.every((item) => item.expired);
          if (isExpired) {
            actions.snackbarActions.infoSnackbar(
              "Batch Expired, Please Choose another",
            );
          }
          setRows(arr);
        } else {
          actions.snackbarActions.infoSnackbar(
            "This product has no batches. Please choose another",
          );
        }
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
    // setIsBlocking(true);
  };

  const handleChangeBatch = (event, index) => {
    const batch_value = event.target ? event.target.value : event;
    const arr = rows.map((item, idx) => {
      const batchObj = getBatchDetails(batch_value, item.batch_info);
      const obj = batchObj ? batchObj : {};
      const exp_date = obj.expiry ? obj.expiry : "";
      const daysLeft = Math.floor(
        (Date.parse(exp_date) - Date.now()) / (24 * 60 * 60 * 1000),
      );
      if (idx === index) {
        if (daysLeft < 0) {
          return {
            ...item,
            available_quantity: "",
            expiry: "",
            expired: true,
            batch_id: "",
            dosage: "",
          };
        } else {
          return {
            ...item,
            ...getBatchDetails(batch_value, item.batch_info),
            batch_id: obj,
            expired: false,
          };
        }
      }
      return item;
    });
    const isExpired = arr.every((item) => item.expired);
    if (isExpired) {
      actions.snackbarActions.infoSnackbar(
        "Batch Expired, Please Choose another",
      );
    }
    setRows(arr);
    //  setIsBlocking(true);
  };

  const handleAddRow = () => {
    const item = {
      product_name: "",
      batch_id: "",
      batch_info: [],
      available_quantity: "",
      expiry: "",
      unit: "",
      product_quantity: "",
      product_rate: "",
      discount: 0,
      total_price: "",
      discount_type: 1,
      total_discount: 0,
      product_id: "",
      tax: 0,
      dosage: "",
      showProduct: false,
      uuid_id: uuidv4(),
    };
    setRows([...rows, item]);
    // setIsBlocking(true);
  };

  const getBatchDetails = (b_id, batch_data = []) => {
    const arr = batch_data
      .filter((item) => item.purchase_detail_id === b_id)
      .map((item) => ({
        ...item,
        expiry: item.expiry_date,
        available_quantity: item.stock,
      }));
    return arr[0];
  };

  const calculateTotalAmounts = (arr, p_amount, prev_amount) => {
    const sum = arr.reduce((a, row) => {
      return Number(a + row.total_price);
    }, 0);
    console.log(arr)
    const discount = arr.reduce((a, row) => {
      let total_apparent = +(row.product_rate * row.product_quantity);
      let dis = !row.discount ? 0 : +(row.discount * total_apparent);
      let disc = dis / 100;
      return a + disc;
    }, 0);
    const net_total = sum; //+(sum + prev_amount);
    const amount = +(net_total - p_amount);
    const final_amount = amount > 0 ? amount : 0;
    const change = +(p_amount - net_total);
    const final_change = change > 0 ? change : 0;
    setTotals({
      ...totals,
      grand_total_price: sum,
      n_total: +net_total,
      due_amount: final_amount,
      change: final_change,
      total_discount_amount: +discount,
      paid_amount: p_amount,
      previous_amount: prev_amount,
    });
  };

  const handleRemoveSpecificRow = (idx) => {
    const { paid_amount, previous_amount } = totals;
    const arr = rows.filter((_, index) => index !== idx);
    setRows(arr);
    calculateTotalAmounts(arr, paid_amount, previous_amount);
    setIsBlocking(true);
  };

  const handleChange = (event, index) => {
    const { value, name } = event.target;
    const arr = rows.map((item, idx) => {
      if (idx === index) {
        return { ...item, [name]: value };
      }
      return value;
    });
    setRows(arr);
    setIsBlocking(true);
  };

  const handleChangeQuantity = (event, index) => {
    const { value } = event.target;
    const { paid_amount, previous_amount } = totals;
    const arr = rows.map((item, idx) => {
      const total = +value * item.rate;
      const discount = total * (+item.discount / 100);
      const final_total = total - discount;
      console.log(total)
      if (index === idx) {
        return {
          ...item,
          quantity: value,
          total_price: total,
          isError: +value > item.available_quantity,
        };
      }
      return item;
    });
    setRows(arr);
    calculateTotalAmounts(arr, paid_amount, previous_amount);
    // setIsBlocking(true);
  };

  const handleCreditMemoNumber = (event) => {
    setCreditMemoNumber(event.target.value);
  };
  const handlePreviousInvoiceNumber = (event) => {
    setSelectedPreviousInvoiceNumber(event.target.value);
  };
  const handleCreditMemoDetailsChange = (event) => {
    setCreditNoteDetails(event.target.value);
  };

  const handleCreditNoteDateChange = (event) => {
    setCreditNoteDate(event.target.value);
  };
  // Function to calculate total amount based on product details
  const calculateTotalAmount = () => {
    const total_amount = rows.reduce(
      (total, product) => total + product.total_price,
      0,
    );
    setTotalAmount(total_amount);
    return totalAmount;
  };

  return {
    selectedInvoice,
    customer,
    setCustomer,
    customersList,
    creditNoteDate,
    setCreditNoteDate,
    selectedPreviousInvoiceNumber,
    setSelectedPreviousInvoiceNumber,
    creditNoteDetails,
    setCreditNoteDetails,
    rows,
    setRows,
    snackbarOpen,
    setSnackbarOpen,
    handleSaveCreditNote,
    previousInvoiceNumbers,
    setPreviousInvoiceNumbers,
    handleClickCustomer,
    handleCreditNoteDateChange,
    handleCreditMemoDetailsChange,
    handleCreditMemoNumber,
    creditMemoNumber,
    showCustomer,
    handleRetrieveCustomerInfo,
    handlePreviousInvoiceNumber,
    totalAmount,
    drugs,
    handleRetrieveDrugs,
    handleClickDrug,
    handleChangeBatch,
    handleAddRow,
    handleRemoveSpecificRow,
    handleChange,
    handleChangeQuantity,
    invoiceNumberDetails,
    grandTotal
  };
};

export default useCreditNote;
