import React from "react";
import Form from "react-bootstrap/Form";
import Label from "../../../Utils/FormInputs/Label";
import TextField from "../../../Utils/FormInputs/TextField";
import { CustomSelect } from "../../../Utils/FormInputs/SelectInput";
import AccountsPayableDialog from "./AccountPayableDialog";
import CustomDialog from "../../../Utils/Dialog/CustomDialog";
import { usePrint } from "../../../Utils/Template/usePrint";
import { PaymentVoucherReceipt } from "../../Report/PaymentVouchers";

function SingleSupplierPayment({
  state,
  actions,
  paid_through,
  accounts,
  handleCloseDialog,
  handleSubmit,
  handleCloseAccountsDialog,
  openAccountsPayableDialog,
  handleChangePaidThrough,
  handleChange,
  handleClose,
  voucher_id,
  submitted,
  handleCloseReceiptDialog,
  handleOpenReceiptDialog,
  receiptDialog,
  isPending,
}) {
  const { payment_date, payment_amount } = state;

  const { handlePrint, fontSize, componentRef } = usePrint("");
  return (
    <div>
      <CustomDialog
        isOverflow={false}
        maxWidth={"lg"}
        open={receiptDialog}
        title={"PAYMENT VOUCHER"}
        handleClose={handleCloseReceiptDialog}
        handlePrint={handlePrint}
      >
        <div ref={componentRef} style={{ padding: "16px 30px" }}>
          <style>{fontSize()}</style>
          <PaymentVoucherReceipt
            voucher_no={voucher_id}
            actions={actions}
            entity_type="Supplier Payment"
          />
        </div>
      </CustomDialog>

      <AccountsPayableDialog
        text="payment voucher"
        openAccountsPayableDialog={openAccountsPayableDialog}
        handleCloseAccountsDialog={handleCloseAccountsDialog}
        handleClose={handleClose}
        message="Payment Voucher"
        handleOpenReceiptDialog={handleOpenReceiptDialog}
      />

      <form onSubmit={handleSubmit} autoComplete="off">
        <Form.Group>
          <Label name="Payment Date" type />
          <TextField
            type="date"
            submitted={submitted}
            name="payment_date"
            value={payment_date}
            onChange={handleChange}
          />
          {submitted && !payment_date ? (
            <div className="help-block">Payment date is required</div>
          ) : null}
        </Form.Group>
        <Form.Group>
          <Label name="Payment Made(UGX)" type />
          <TextField
            data-testid="payment_amount"
            type="number"
            submitted={submitted}
            name="payment_amount"
            value={payment_amount}
            onChange={handleChange}
          />
          {submitted && !payment_amount ? (
            <div className="help-block">Payment made is required</div>
          ) : null}
        </Form.Group>
        <Form.Group data-testid="credit_to">
          <Label name="Credit To" type />
          <CustomSelect
            prefix="credit_to"
            submitted={submitted}
            onChange={handleChangePaidThrough}
            value={paid_through}
            options={accounts}
          />
          {submitted && !paid_through ? (
            <div className="help-block">Payment account is required</div>
          ) : null}
        </Form.Group>
        <div className="mt-3">
          <button
            data-testid="submit_payment"
            type="submit"
            className="btn btn-sm pharmacy-info-btn btn-sm mr-4"
          >
            {isPending ? "Submitting..." : "Submit"}
          </button>
          <button
            type="button"
            onClick={handleCloseDialog}
            className="btn btn-sm pharmacy-gray-btn btn-sm"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
export { SingleSupplierPayment };
