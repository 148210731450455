import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { formatAmount, formatDigits } from "../formatNumbers";
import { history } from '../../Helpers/history';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';


const WINDOW_HEIGHT = window.innerHeight
const height = WINDOW_HEIGHT - 250

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        // maxHeight: height,

    },
    listItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    sub: {
        paddingLeft: theme.spacing(4),
    },
    nested: {
        paddingLeft: theme.spacing(6),
    },
    nestedLi: {
        paddingLeft: theme.spacing(8),
    },
    nestedChild: {
        paddingLeft: theme.spacing(10),
    },
    parentText: {
        fontSize: '1.2rem',
        fontWeight: 600
    },
    childText: {
        fontSize: '1rem',
    },
    smallText: {
        fontSize: '0.9rem'
    },
    tableCell: {
        border: 0,
        padding: '10px',
        '': '1px solid rgb(181 177 177)'
    },
    coaCell: {
        borderBottom: '1px solid rgb(181 177 177)',
        padding: '10px',
    },
    table: {
        border: '1px solid rgb(223 219 219)'
    },
    container: {
        maxHeight: height,
    }
}));






export const CustomCell = ({ children, ...props }) => {
    const classes = useStyles();
    return (
        <TableCell className={classes.tableCell} {...props}>{children}</TableCell>
    )

}

export const CoaCustomCell = ({ children, ...props }) => {
    const classes = useStyles();
    return (
        <TableCell className={classes.coaCell} {...props}>{children}</TableCell>
    )

}





function BalanceSheetData({ children, start_date, end_date, data = [], isPrint = false, trialBalance = false, header = null }) {


    function nestedMenu(items) {
        const data = items ? items : []
        return data.map((nav) => {
            const head_level = nav.head_level ? nav.head_level : 0;
            const padding = head_level === "Parent" ? 0 : head_level === 0 ? 12 : head_level === 1 ? 24 : (head_level * 12) + 12;
            const category = nav.related_category ? nav.related_category : null;
            const isParent = !category || head_level === 0
            const isheadLevel = head_level !== "Parent"
            const balance = nav.balance ? nav.balance : 0;
            if (nav.children && nav.children.length === 0) {
                return (
                    <>
                        {nav.is_final_level ?
                            <TableRow key={nav.id}>
                                <CustomCell onClick={() => history.push(`/view_transactions/${nav.id}/${nav.head_code}-${nav.head_name}/${start_date}/${end_date}`)}
                                    style={{ paddingLeft: padding, borderLeft: 0, borderRight: 0, cursor: 'pointer' }}>
                                    <span style={{ color: !isPrint ? '#0065ff' : '', display: 'inline-block' }}>{nav.head_name}</span></CustomCell>
                                <CustomCell align='center'><strong>{balance !== null ? formatDigits(balance) : null}</strong></CustomCell>

                            </TableRow> : <TableRow key={nav.id}>
                                <CustomCell style={{ paddingLeft: padding }} >  <span style={{ fontWeight: isParent ? 'bold' : '' }}>{nav.head_name}</span></CustomCell>
                                <CustomCell align='center'><strong>{balance !== null ? formatDigits(balance) : null}</strong></CustomCell>
                            </TableRow>}
                    </>
                )
            }
            else if (!nav.children) {
                return (
                    <TableRow key={nav.head_name}>
                        <CustomCell style={{ paddingLeft: padding }} >
                            <span style={{ fontWeight: isParent ? 'bold' : '' }}>{nav.head_name}</span></CustomCell>
                        <CustomCell align='center'><strong>{balance !== null ? formatDigits(balance) : null}</strong></CustomCell>
                    </TableRow>
                )
            }
            return (
                <>
                    <TableRow key={nav.id}>
                        <CustomCell style={{ paddingLeft: padding, fontWeight: isParent ? 'bold' : '' }}>
                            <span style={{ fontWeight: isParent ? 'bold' : '' }}>{nav.head_name}</span>
                        </CustomCell>
                        {isheadLevel ? <CustomCell align='center'><strong>
                            {balance !== null ? formatDigits(balance) : null}</strong></CustomCell> : null}
                    </TableRow>
                    {nestedMenu(nav.children)}
                </>
            )
        })
    }
    return (
        <Table>
            {header}
            <TableBody>
                {nestedMenu(data)}
                {children}
            </TableBody>
        </Table>
    )
}




function CoaList({ children, start_date, end_date, data = [], isPrint = false, trialBalance = false, header = null }) {


    function nestedMenu(items) {
        const data = items ? items : []
        return data.map((nav) => {
            const head_level = nav.head_level ? nav.head_level : 0;
            const padding = nav.isParent ? 0 : head_level === 0 ? 12 : head_level === 1 ? 24 : (head_level * 12) + 12;
            const category = nav.related_category ? nav.related_category : null;
            const isParent = !category || head_level === 0
            const balance = nav.balance ? nav.balance : 0;
            if (nav.children && nav.children.length === 0) {
                return (
                    <>
                        {nav.is_final_level ?
                            <TableRow key={nav.id}>
                                <CustomCell
                                    onClick={() => history.push(`/view_transactions/${nav.id}/${nav.head_code}-${nav.head_name}/${start_date}/${end_date}`)}
                                    style={{ paddingLeft: padding, borderLeft: 0, borderRight: 0, cursor: 'pointer' }}
                                >
                                    <span
                                        style={{ color: !isPrint ? '#0065ff' : '', display: 'inline-block' }}
                                    >
                                        {nav.head_name}
                                    </span>
                                </CustomCell>

                                <CustomCell align='center'>
                                    <strong>
                                        {balance !== null ? formatDigits(balance) : null}
                                    </strong>
                                </CustomCell>

                            </TableRow>
                            :
                            <TableRow key={nav.id}>
                                <CustomCell style={{ paddingLeft: padding }}>
                                    <span
                                        style={{ fontWeight: isParent ? 'bold' : '' }}
                                    >
                                        {nav.head_name}
                                    </span>
                                </CustomCell>

                                <CustomCell align='center'>
                                    <strong>
                                        {balance !== null ? formatDigits(balance) : null}
                                    </strong>
                                </CustomCell>

                            </TableRow>
                        }
                    </>
                )
            }
            else if (!nav.children) {
                return (
                    <TableRow key={nav.head_name}>
                        <CustomCell style={{ paddingLeft: padding }} >  <span style={{ fontWeight: isParent ? 'bold' : '' }}>{nav.head_name}</span></CustomCell>
                        <CustomCell align='center'><strong>{balance !== null ? formatDigits(balance) : null}</strong></CustomCell>
                    </TableRow>
                )
            }
            return (
                <>
                    <TableRow key={nav.id}>
                        <CustomCell style={{ paddingLeft: padding, fontWeight: isParent ? 'bold' : '' }}>
                            <span style={{ fontWeight: isParent ? 'bold' : '' }}>{nav.head_name}</span>
                        </CustomCell>
                        <CustomCell align='center'>
                            <strong>{balance !== null ? formatDigits(balance) : null}</strong></CustomCell>
                    </TableRow>
                    {nestedMenu(nav.children)}
                </>
            )
        })
    }
    return (
        <Table>
            {header}
            <TableBody>
                {nestedMenu(data)}
                {children}
            </TableBody>
        </Table>
    )
}
export { CoaList, BalanceSheetData }
