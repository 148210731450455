import { history } from "./history"
import { errorSnackbar,infoSnackbar } from "../Redux/Actions/SnackbarActions/SnackbarActions";


export const errorMessages = (error, dispatch, actions) => {
    const response = !error.response ? {} : error.response;
    const errorStatus = !response.status ? "" : response.status;
    const message = !response.data ? "" : response.data;
    const message_response = !response.data ? {} : response.data;
    const error_message = message_response ? message_response.error || message_response.message : 'Operation unsuccessful. An error occurred on the server'
    switch (errorStatus) {
        case 403:
            actions ? actions.snackbarActions.errorSnackbar('You need to login first') :
                dispatch(errorSnackbar('You need to login first'));
            setTimeout(() => {
                history.push('/login');
            }, 3000);
            break;
        case 406:// for informative replies
            // let m = {error_message,duration:10000}
            actions  ? actions.snackbarActions.infoSnackbar(error_message):
            dispatch(infoSnackbar(error_message));
            break;
        case 401:
            actions ? actions.snackbarActions.errorSnackbar(message) :
                dispatch(errorSnackbar(message));
            setTimeout(() => {
                history.push('/subscriptionexpired')
            }, 1000);
            break;
        case 400:
            // changed from error to info so that bad requests are treated as informational errors
            actions ? actions.snackbarActions.infoSnackbar(`Bad Request. ${message}`) :
                dispatch(infoSnackbar(`Bad Request. ${message}`));
            break;
        case 404:
            actions ? actions.snackbarActions.errorSnackbar('The server cannot find the requested resource') :
                dispatch(errorSnackbar('The server cannot find the requested resource'));
            break;
        case 500:
            actions ? actions.snackbarActions.errorSnackbar(error_message) :
                dispatch(errorSnackbar(error_message));
            break;
        default:
            actions ? actions.snackbarActions.errorSnackbar('Operation unsuccessful. An error occurred on the server') :
                dispatch(errorSnackbar('Operation unsuccessful. An error occurred on the server'));

    }
};


export const infoMessages = (message, dispatch, actions) => {
    // const response = !error.response ? {} : error.response;
    // const errorStatus = !response.status ? "" : response.status;
    // const message = !response.data ? "" : response.data;
    // const message_response = !response.data ? {} : response.data;
    // const error_message = message_response ? message_response.error || message_response.message : 'Operation unsuccessful. An error occurred on the server'
    // switch (errorStatus) {
    //     case 403:
    //         actions ? actions.snackbarActions.errorSnackbar('You need to login first'):
    //             dispatch(errorSnackbar('You need to login first'));
    //         setTimeout(()=>{
    //             history.push('/login');
    //         },3000);
    //         break;
    //     case 406:
    //         let m = {message,duration:10000}
    //         actions  ? actions.snackbarActions.errorSnackbar(m):
    //         dispatch(errorSnackbar(m));
    //         break;
    //     case 401:
    //         actions ? actions.snackbarActions.errorSnackbar(message):
    //             dispatch(errorSnackbar(message));
    //         setTimeout(()=>{
    //             history.push('/subscriptionexpired')
    //         },1000);
    //         break;
    //     case 400:
    //         actions ? actions.snackbarActions.errorSnackbar('The server could not understand the request'):
    //         dispatch(errorSnackbar('The server could not understand the request'));
    //         break;
    //     case 404:
    //         actions  ? actions.snackbarActions.errorSnackbar('The server cannot find the requested resource') :
    //         dispatch(errorSnackbar('The server cannot find the requested resource'));
    //         break;
    //     case 500:
    //         actions  ? actions.snackbarActions.errorSnackbar(error_message) :
    //             dispatch(errorSnackbar(error_message));
    //         break;
    //     default:
    //         actions ? actions.snackbarActions.errorSnackbar('Operation unsuccessful. An error occurred on the server'):
    //         dispatch(errorSnackbar('Operation unsuccessful. An error occurred on the server'));
    //
    // }

    if (!actions) {
        // actions?.snackbarActions?.errorSnackbar("An error has occured. Actions missing!")
        dispatch(errorSnackbar("An error has occured. Actions missing!"));
    }

    dispatch(infoSnackbar(`${message}`));
};
