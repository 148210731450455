import React, { useState } from "react";
import StockSummary, { TotalHead } from "./StockSummary";


const headData = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Particulars",
    isBorder: true,
  },
  {
    id: "transaction_type",
    numeric: false,
    disablePadding: false,
    label: "Transaction type",
    isBorder:true
  },

  {
    id: "inwardquantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "inwardrate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "inwardamount",
    numeric: false,
    disablePadding: false,
    label: "Value",
    isBorder: true,
  },
  {
    id: "outwardquantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "outwardrate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "outwardamount",
    numeric: false,
    disablePadding: false,
    label: "Value",
    isBorder: true,
  },
  {
    id: "closingquantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "closingrate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "closingamount",
    numeric: false,
    disablePadding: false,
    label: "Value",
  },
];

const parentHeadData = [
  {
    id: "",
    numeric: false,
    disablePadding: false,
    label: "",
    // colSpan: 2,
  },
  {
    id: "quantity_in",
    numeric: false,
    disablePadding: false,
    label: "Inwards",
    colSpan: 3,
  },
  {
    id: "quantity_out",
    numeric: false,
    disablePadding: false,
    label: "Outwards",
    colSpan: 3,
  },
  {
    id: "closing_stock",
    numeric: false,
    disablePadding: false,
    label: "Closing Balnce",
    colSpan: 3,
  },
];


const StockSummaryMonthlyProduct = ({ match }) => {
  const [data, setData] = useState({
    loading: "idle",
    stockList: [],
    totals:{}
  });

  const { month, product_id,start_date, end_date } = match.params;

  const manipulate = (arr) => {
    return arr.map((item) => ({
      ...item,
      id: item.product_id,
      name: item.Date,
      isTransactionType:true
    }));
  };


const totalColumns = [
  {
    id: "Total Amount",
    numeric: false,
    disablePadding: false,
    label: "Total Amount",
    isBorder: true,
  },
  {
    // id: data?.totals?.total_opening_amount,
    numeric: false,
    disablePadding: false,
    // colSpan: 3,
    // align:"right",
    isBorder: true,
  },
  {
    id: data?.totals?.total_inward_amount,
    numeric: false,
    disablePadding: false,
    colSpan: 3,
    isBorder: true,
    align:"right"
  },
  {
    id: data?.totals?.total_outward_amount,
    numeric: false,
    disablePadding: false,
    colSpan: 3,
    isBorder: true,
    align:"right"
  },
  {
    id: data?.totals?.total_closing_amount,
    numeric: false,
    disablePadding: false,
    colSpan: 3,
    isBorder: true,
    align:"right"
  },
];

//   console.log(manipulate())
  return (
    <StockSummary
      data={data}
      setData={setData}
      heading="Monthly details"
      url="Creport/get_stock_summary"
      totalHeadData={<TotalHead totalColumns={totalColumns}/>}
      otherParams={{month,product_id, start_date, end_date }}
      manipulate={manipulate}
      {...{headData, parentHeadData}}
    />
  );
};

export default StockSummaryMonthlyProduct;
