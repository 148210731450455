/** @jsx jsx */ //please do not remove this implementation because the css will not be loaded without it
import { jsx, css } from "@emotion/react";
import { useState, useEffect } from "react";
import CompanyAddress from "../../Utils/CompanyAddress";
import { table, detailsTable, smallPadding } from "../../styles/tableStyles";
import { ItemsTable } from "../../Utils/Template/ReceiptTemplate";
import { formatDigits } from "../../Utils/formatAmount";
import axios from "axios";
import config from "../../Helpers/config.json";
import { errorMessages } from "../../Helpers/ErrorMessages";
import { formatDate } from "../../Utils/titleCaseFunction";

const headData = [{ value: "Expense" }, { value: "Amount" }, { value: "Memo" }];

const ExpenseVoucher = ({ voucher_id, actions}) => {
  const [voucherDetails, setVoucherDetails] = useState({});
  useEffect(() => {
    axios
      .get(
        `${config.epharmUrl}/accounting/retrive_expenses_voucher?voucher_id=${voucher_id}`
      )
      .then((response) => {
        const dt = response.data;
        const voucher_date = dt.Date ?? "";
        const paid_by = dt.vendor_name.vendor_name ?? "";
        const payment_method = dt.payment_method ?? "";
        const total_amount = dt.Total_Amount ?? 0;
        const expense_details = dt.expense_details ?? [];
        const voucher_details = dt.details ?? "";
        const params = {
          paid_by,
          payment_method,
          voucher_date,
          total_amount,
          expense_details,
          voucher_details,
        };
        setVoucherDetails(params);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  }, [voucher_id]);

  return (
    <table border="0" width="100%">
      <tbody>
        <tr>
          <td>
            {/* <table css={[table]}> */}
            <CompanyAddress />
            <table css={[table, detailsTable, smallPadding]}>
              <tr>
                <td data-testid="expense_date">
                  <span>
                    Date: <strong>{formatDate(voucherDetails?.voucher_date)}</strong>
                  </span>
                </td>
                <td data-testid="expense_date">
                  <span>
                    Voucher No.: <strong>{voucher_id}</strong>
                  </span>
                </td>
              </tr>
              <tr>
                {/* <td> */}
                <td data-testid="expense_date">
                  <span>
                    Order Of: <strong>{voucherDetails?.paid_by}</strong>
                  </span>
                </td>
                <td data-testid="expense_date">
                  <span>
                    Payment method: <strong>{voucherDetails?.payment_method}</strong>
                  </span>
                </td>
                {/* </td> */}
              </tr>
              <tr>
                {/* <td> */}
                <td data-testid="expense_date">
                  <span>
                    Memo: <strong>{voucherDetails?.voucher_details}</strong>
                  </span>
                </td>
                <td data-testid="expense_date">
                  <span>
                    Amount:
                    <strong>
                      {parseInt(voucherDetails?.total_amount).toLocaleString("en-US")}
                    </strong>
                  </span>
                </td>
                {/* </td> */}
              </tr>
            </table>
         
            <ItemsTable headData={headData}>
              <tbody>
                {voucherDetails?.expense_details?.length > 0 ? voucherDetails?.expense_details?.map((expense, index) => (
                  <tr key={index}>
                    <td data-testid={"account"}>
                      <span>{expense.expense}</span>
                    </td>
                    <td data-testid={"amount"}>
                      <span>{formatDigits(expense.amount)}</span>
                    </td>
                    <td data-testid={"memo"}>
                      <span>{expense.memo}</span>
                    </td>
                  </tr>
                )): <tr> No Available Data</tr>}
              </tbody>
            </ItemsTable>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export { ExpenseVoucher };
