import React, { useState } from "react";
import StockSummary, { TotalHead } from "./StockSummary";

const headData = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Particulars",
    isBorder: true,
  },
  {
    id: "openingquantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "openingrate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "openingamount",
    numeric: false,
    disablePadding: false,
    label: "Value",
    isBorder: true,
  },
  {
    id: "inwardquantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "inwardrate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "inwardamount",
    numeric: false,
    disablePadding: false,
    label: "Value",
    isBorder: true,
  },
  {
    id: "outwardquantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "outwardrate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "outwardamount",
    numeric: false,
    disablePadding: false,
    label: "Value",
    isBorder: true,
  },
  {
    id: "closingquantity",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "closingrate",
    numeric: false,
    disablePadding: false,
    label: "Rate",
  },
  {
    id: "closingamount",
    numeric: false,
    disablePadding: false,
    label: "Value",
  },
];

const parentHeadData = [
  {
    id: "opening_stock",
    numeric: false,
    disablePadding: false,
    label: "Opening",
    colSpan: 3,
  },
  {
    id: "quantity_in",
    numeric: false,
    disablePadding: false,
    label: "Inwards",
    colSpan: 3,
  },
  {
    id: "quantity_out",
    numeric: false,
    disablePadding: false,
    label: "Outwards",
    colSpan: 3,
  },
  {
    id: "closing_stock",
    numeric: false,
    disablePadding: false,
    label: "Closing Balnce",
    colSpan: 3,
  },
];


const StockSummaryCategoryDetails = ({ match }) => {
  const [data, setData] = useState({
    loading: "idle",
    stockList: [],
    totals:{}
  });
  const { category_id, category_name, start_date, end_date } = match.params;

  const manipulate = (arr) => {
    return arr.map((item) => ({
      ...item,
      id: item.product_id,
      name: item.productname,
      url: `/datailsbymonth/${item.product_id}/${item.start_date}/${item.end_date}`,
    }));
  };


  const totalColumns = [
    {
      id: "Total Amount",
      numeric: false,
      disablePadding: false,
      label: "Total Amount",
      isBorder: true,
    },
    {
      id: data?.totals?.total_opening_amount,
      numeric: false,
      disablePadding: false,
      colSpan: 3,
      align:"right",
      isBorder: true,
    },
    {
      id: data?.totals?.total_inward_amount,
      numeric: false,
      disablePadding: false,
      colSpan: 3,
      isBorder: true,
      align:"right"
    },
    {
      id: data?.totals?.total_outward_amount,
      numeric: false,
      disablePadding: false,
      colSpan: 3,
      isBorder: true,
      align:"right"
    },
    {
      id: data?.totals?.total_closing_amount,
      numeric: false,
      disablePadding: false,
      colSpan: 3,
      isBorder: true,
      align:"right"
    },
  ];

  // console.log(data.stockList);

  return (
    <StockSummary
      data={data}
      setData={setData}
      heading="Category details"
      url="Creport/get_stock_summary"
      otherParams={{ category_id, start_date, end_date }}
      manipulate={manipulate}
      totalHeadData={<TotalHead totalColumns={totalColumns}/>}
      {...{headData, parentHeadData}}
    />
  );
};

export default StockSummaryCategoryDetails;
