import React, { useState } from "react";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import { history } from "../../../Helpers/history";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useCompany } from "../../../Context/company-context";
import { titleCase } from "../../../Utils/titleCaseFunction";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPagination,
  MuiPaper,
} from "../../../Utils/Tables/CustomTable";
import { Form } from "react-bootstrap";
import { useSnackbar } from "../../../Context/snackbar-context";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {
  useExcelReports,
  usePdfReports,
} from "../../Invoice/CustomHooks/useExcelReports";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FilterResults from "react-filter-search";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { connect } from "react-redux";
import { useQuery } from "react-query";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { convertDate, formatDate } from "../../../Utils/ConvertDate";

const headData = [
  { id: "sl", numeric: false, disablePadding: false, label: "Sl" },

  {
    id: "medicineName",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },

  { id: "quantity", numeric: false, disablePadding: false, label: "Quantity" },

  { id: "batch", numeric: false, disablePadding: false, label: "Batch ID" },

  // { id: "expiry", numeric: false, disablePadding: false, label: "Expiry Date" },

  {
    id: "purchase",
    numeric: false,
    disablePadding: false,
    label: "Purchase Price",
  },

  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Total Amount",
  },
];

const CreditNoteInvoice = ({ match: { params }, snackbars, actions }) => {
  const { customer_id: credit_memo_id } = params;

  const [term, setTerm] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState("idle");

  const { state: snackbarState, handleClose, handleError } = useSnackbar();

  const { state } = useCompany();

  const { logo } = state;

  //   const { open, message, variant } = snackbars;

  const creditNoteInvoiceQuery = useQuery(
    ["creditNoteInvoiceQuery", credit_memo_id, term],
    async () => {
      try {
        const response = await axios.get(
          `${config.epharmUrl}/Ccredit_memo/credit_memo_data`,
          {
            params: {
              credit_memo_id,
              search: term,
            },
          },
        );

        return (
          response?.data ?? {
            details: {
              credit_memo_date: "",
              credit_memo_details: "",
              credit_memo_no: "",
              customer_name: "",
            },
            products: [],
          }
        );
      } catch (error) {
        errorMessages(error, null, actions);
      }
    },
    { retry: 1 },
  );

  console.log(creditNoteInvoiceQuery?.data);

  const { products, details } = creditNoteInvoiceQuery?.isSuccess
    ? creditNoteInvoiceQuery?.data
    : [];

  // useEffect(() => {
  //   setLoading("loading");
  //   axios
  //     .get(`${config.epharmUrl}/Cpurchase/purchase_details_data`, {
  //       params: { credit_memo_id, search: term },
  //     })
  //     .then((response) => {
  //       const data = response.data;
  //       const dt = data ? data : {};
  //       setLoading("success");
  //       //    setProductPurchase(dt);
  //     })
  //     .catch(() => {
  //       handleError("Operation unsuccessful");
  //       setLoading("error");
  //     });
  // }, [term]);

  const handleSearch = (event) => {
    setTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const excelData = {
    file_name: "CreditNotedExcel",
    url: "Ccredit_memo/credit_memos_excel",
    params: {
      credit_memo_id,
    },
  };

  const pdfData = {
    file_name: "CreditNotePDF",
    url: "Ccredit_memo/credit_detals_pdf2",
    params: {
      credit_memo_id,
      download: true,
    },
  };

  const { exportExcel } = useExcelReports(actions, excelData);

  const { exportPDF } = usePdfReports(actions, pdfData);

  return (
    <div>
      <ReusableDashboardHeader
        component="Credit Note Invoices"
        heading="Credit Note Invoices"
        subHeading="Credit Note Invoices"
        link={history.location.pathname}
      />

      <div className="mx-3 mt-5">
        <div className="pos-buttons">
          <Link
            to="/accounting/creditnote"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <button
              data-testid="add_credit_note"
              type="button"
              className="btn mr-2 pharmacy-btn"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Credit Note
            </button>
          </Link>

          <Link to="/managecreditnotes">
            <button
              data-testid="manage_credit_note"
              type="button"
              className="btn pharmacy-info-btn mr-2"
            >
              <FontAwesomeIcon icon={faList} /> Manage Credit Notes
            </button>
          </Link>
        </div>

        <div className="text-center">
          <img
            style={{ maxWidth: "100px" }}
            src={logo ? logo : "/images/mpeke3.png"}
            alt="mpeke"
          />

          <p data-testid="purchase_info" style={{ fontSize: "16px" }}>
            Customer: {titleCase(details?.customer_name ?? "N/A")}
          </p>

          <p>
            Date:&nbsp;
            {details?.credit_memo_date
              ? formatDate(details?.credit_memo_date)
              : "N/A"}
          </p>

          <p>Invoice No:&nbsp;{details?.credit_memo_no ?? "N/A"}</p>

          <p>Details:&nbsp;{details?.credit_memo_details ?? "N/A"}</p>
        </div>

        {/* <PharmacySnackbar
          open={open}
          variant={variant}
          message={message}
          handleCloseBar={handleClose}
        /> */}

        <MuiPaper>
          <EnhancedTableToolbar title="Credit Note Invoices">
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  onClick={exportExcel}
                  className="btn btn-sm pharmacy-btn mr-1"
                >
                  Download Excel
                </button>
              </span>

              <span>
                <button
                  className="btn pharmacy-btn mr-1 btn-sm"
                  onClick={exportPDF}
                >
                  Download PDF
                </button>
              </span>
            </span>

            <Form.Control
              type="text"
              value={term ? titleCase(term) : ""}
              className="form__control"
              onChange={handleSearch}
              placeholder="Search"
              style={{ float: "right" }}
            />
          </EnhancedTableToolbar>

          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead headCells={headData} />

            <FilterResults
              value={term}
              data={[{}]}
              renderResults={(results) => {
                return (
                  <TableBody>
                    {creditNoteInvoiceQuery?.isLoading && (
                      <TableRow>
                        <TableCell colSpan={headData.length} align="center">
                          <img
                            src="/images/listPreloader.gif"
                            alt="loader"
                            style={{ width: "5%" }}
                          />
                        </TableCell>
                      </TableRow>
                    )}

                    {creditNoteInvoiceQuery?.isError && (
                      <TableRow>
                        <TableCell colSpan={headData.length} align="center">
                          No Available Data
                        </TableCell>
                      </TableRow>
                    )}

                    {creditNoteInvoiceQuery?.isSuccess &&
                      products?.length > 0 &&
                      products?.map((product, idx) => (
                        <TableRow key={idx}>
                          <TableCell align="start">{idx}</TableCell>

                          <TableCell align="start">
                            {product?.product_name ?? "N/A"}
                          </TableCell>

                          <TableCell align="start">
                            {product?.quantity ?? "N/A"}
                          </TableCell>

                          <TableCell align="start">
                            {product?.batch ?? "N/A"}
                          </TableCell>

                          <TableCell align="start">
                            {product?.price ?? "N/A"}
                          </TableCell>

                          <TableCell align="start">
                            {product?.total ?? "N/A"}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                );
              }}
            />
          </Table>

          <MuiPagination
            data={products}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            rows={[10, 20, 30, 40, 50]}
            colSpan={headData.length}
          />
        </MuiPaper>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditNoteInvoice);
