import React from "react";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPaper,
} from "../../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../../Utils/FormInputs/Label";
import TextField from "../../../Utils/FormInputs/TextField";
import { history } from "../../../Helpers/history";
import { useAccountsPayable } from "./hooks/useAccountsPayable";
import { formatAmount } from "../../../Utils/formatNumbers";
import { formatDate } from "../../../Utils/ConvertDate";
import { BackButton } from "../../../Utils/FormInputs/BackButton";
import { CustomPagination } from "../../../Utils/Tables/CustomTable";
import { ErrorMessage } from "../../../Utils/styledComponents";
import { makeStyles } from "@material-ui/core/styles";
import {
  useExcelReports,
  usePdfReports,
} from "../../Invoice/CustomHooks/useExcelReports";

const useStyles = makeStyles(() => ({
  dueToday: {
    color: "#28a745",
  },
  notDue: {
    color: "orange",
  },
  overDue: {
    color: "red",
  },
}));

const headData = [
  {
    id: "supplier",
    numeric: false,
    disablePadding: false,
    label: "Supplier Name",
  },
  { id: "address", numeric: false, disablePadding: false, label: "Address" },
  { id: "mobile", numeric: false, disablePadding: false, label: "Mobile" },
  { id: "amount", numeric: false, disablePadding: false, label: "Amount" },
];

const AccountsPayable = ({ actions, snackbars, match: { params } }) => {
  const {
    data,
    from_date,
    to_date,
    handleChangeDate,
    isLoading,
    isSuccess,
    isError,
    handleCloseBar,
    handleFilter,
    totalPageCount,
    searchValue,
    handleChange,
    handlePageChange,
    activePage,
    submitted,
    status,
    handleChangeStatus,
  } = useAccountsPayable(actions);
  const { creditors } = data;
  const { component } = params;
  const isSupplier = component === "supplier";
  //   console.log(creditors);
  const emptyRows = 10 - Math.min(10, creditors.length - (activePage - 1) * 10);

  const { open, message, variant } = snackbars;
  const objExcel = {
    file_name: "Accounts Payables",
    url: "Cmanufacturer/supplier_payables_download",
    params: {
      download: true,
      start_date: from_date,
      end_date: to_date,
      file_type: 1,
    },
  };
  const objPDF = {
    file_name: "Accounts Payable",
    url: "Cmanufacturer/supplier_payables_download",
    params: {
      download: true,
      start_date: from_date,
      end_date: to_date,
      file_type: 2,
    },
  };
  const { exportExcel } = useExcelReports(actions, objExcel);
  const { exportPDF } = usePdfReports(actions, objPDF);

  const classes = useStyles();
  return (
    <div className="journals">
      <ReusableDashboardHeader
        component="Accounts Payable"
        heading="Accounts Payable"
        subHeading="Accounts Payable"
        link={history.location.pathname}
      />

      <div className="general-ledger-header mb-3">
        {!isSupplier ? (
          <div className="mb-3">
            <BackButton text="Reports" to="/allreports" />
          </div>
        ) : null}
        <form onSubmit={handleFilter}>
          <Form.Group as={Row} style={{ marginBottom: "-1%" }}>
            <Col sm={4}>
              <Form.Group as={Row}>
                <Label
                  name="From"
                  column
                  sm={2}
                  type
                  style={{ textAlign: "right" }}
                />
                <Col sm={10}>
                  <TextField
                    submitted={submitted}
                    type="date"
                    onChange={handleChangeDate}
                    value={from_date}
                    name="from_date"
                  />
                  {submitted && !from_date ? (
                    <ErrorMessage>From date is required</ErrorMessage>
                  ) : null}
                </Col>
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group as={Row}>
                <Label name="To" column sm={2} type />
                <Col sm={10}>
                  <TextField
                    submitted={submitted}
                    type="date"
                    value={to_date}
                    onChange={handleChangeDate}
                    name="to_date"
                  />
                  {submitted && !to_date ? (
                    <ErrorMessage>To date is required</ErrorMessage>
                  ) : null}
                </Col>
              </Form.Group>
            </Col>
            <Col sm={1}>
              <button type="submit" className="btn btn-sm pharmacy-btn-outline">
                Filter
              </button>
            </Col>
          </Form.Group>
        </form>
      </div>
      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={handleCloseBar}
      />
      <div className="mui-tables">
        {/* <div className='row'>
                    <div className='col-lg-5'>
                        <Form.Group as={Row}>
                            <Label name='Filter by:' column sm={2}/>
                            <Col sm={6}>
                                <SelectInput options={[{value:'all', text:'All'},{value:'due_today', text:'Due Today'},
                                    {value:'not_due', text:'Not Due'},{value:'over_due', text:'Over Due'}]} value={status} onChange={handleChangeStatus}/>
                            </Col>
                        </Form.Group>
                    </div>
                </div> */}
        <MuiPaper>
          <EnhancedTableToolbar title="">
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  onClick={exportExcel}
                  className="btn btn-sm pharmacy-btn mr-1"
                >
                  Download Excel
                </button>
              </span>
            </span>
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  onClick={exportPDF}
                  className="btn btn-sm pharmacy-btn mr-1"
                >
                  Download PDF
                </button>
              </span>
            </span>
            <TextField
              type="text"
              placeholder="Search..."
              value={searchValue}
              onChange={handleChange}
              data-testId="search_supplier"
            />
          </EnhancedTableToolbar>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead headCells={headData} />
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={headData.length} align="center">
                      <img
                        src="/images/listPreloader.gif"
                        alt="loader"
                        style={{ width: "5%" }}
                      />
                    </TableCell>
                  </TableRow>
                ) : null}
                {isSuccess ? (
                  creditors.length > 0 ? (
                    <>
                      {creditors.slice(0, 10).map((d, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={d.id}
                            className="mui-table-row"
                          >
                            <TableCell data-testid={"supplier_name-" + index}>
                              <Link
                                data-testid={`supplier_name_link-${index}`}
                                to={{
                                  pathname: `/creditdetails/${d.id}/${d.manufacturer_name}`,
                                }}
                              >
                                {d.manufacturer_name}
                              </Link>
                            </TableCell>
                            <TableCell data-testid={"supplier_address" + index}>
                              {d.address}
                            </TableCell>
                            <TableCell data-testid={"supplier_mobile" + index}>
                              {d.mobile}
                            </TableCell>
                            <TableCell data-testid={"total_amount-" + index}>
                              {d["Total Amount"]
                                ? formatAmount(d["Total Amount"])
                                : d["Total Amount"]}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={headData.length} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )
                ) : null}
                {isError ? (
                  <TableRow>
                    <TableCell colSpan={headData.length} align="center">
                      The server did not return a valid response
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
            {totalPageCount > 0 ? (
              <CustomPagination
                records={10}
                handleChangeNextPage={handlePageChange}
                data={creditors}
                total_count={totalPageCount}
                activePage={activePage}
              />
            ) : null}
          </TableContainer>
        </MuiPaper>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsPayable);
