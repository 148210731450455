import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as medicineActions from "../../Redux/Actions/MedicineActions/MedicineActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import DeleteDialog from "../../Utils/DeleteDialog";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPagination,
  MuiPaper,
} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { useMedicineType } from "./CustomHooks/useMedicineType";
import DetailsDialog from "../../Utils/Dialog/DetailsDialog";
import { TypeForm } from "./MedicineMetaComponents";
import {
  useExcelReports,
  usePdfReports,
} from "../Invoice/CustomHooks/useExcelReports";

const headData = [
  { id: "name", numeric: false, disablePadding: false, label: "Type Name" },
  { id: "action", numeric: false, disablePadding: false, label: "Action" },
];

const MedicineType = ({ actions, snackbars }) => {
  const {
    openDelete,
    types,
    typeProps,
    loading,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOpenDelete,
    handleCloseDelete,
    deleteMedicineType,
    handleCloseSnackbar,
  } = useMedicineType(actions);
  const {
    openDialog,
    openEdit,
    handleCloseEdit,
    handleOpenEdit,
    handleCloseDialog,
    handleOpenDialog,
  } = typeProps;
  const { open, message, variant } = snackbars;
  const obj = {
    file_name: "Product Types",
    url: "Cproduct/typeindex_excel_report",
  };
  const objPDF = {
    file_name: "Product Types PDF",
    url: "Cproduct/typeindex_pdf_report",
  };

  const { exportExcel } = useExcelReports(actions, obj);
  const { exportPDF } = usePdfReports(actions, objPDF);
  return (
    <div className="journals">
      <ReusableDashboardHeader
        component="Product Types"
        heading="Products"
        subHeading="Product Types"
        link="/medicinetype"
      />
      <div className="general-ledger-header">
        <div className="row">
          <div className="col-md-6">
            <h6 className="mt-2 mb-0">Product Types</h6>
          </div>
          <div className="col-md-6">
            <div
              className="text-right"
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <button
                data-testid="add_type"
                type="button"
                onClick={exportExcel}
                className="btn btn-sm pharmacy-info-btn"
              >
                Download Excel
              </button>
              <button
                data-testid="add_type"
                type="button"
                onClick={exportPDF}
                className="btn btn-sm pharmacy-info-btn"
              >
                Download PDF
              </button>
              <button
                data-testid="add_type"
                type="button"
                onClick={handleOpenDialog}
                className="btn btn-sm pharmacy-info-btn"
              >
                Add Product Type
              </button>
            </div>
          </div>
        </div>
      </div>
      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={handleCloseSnackbar}
      />
      <DeleteDialog
        handleClose={handleCloseDelete}
        openDialog={openDelete}
        message="Product type"
        handleDelete={deleteMedicineType}
      />
      <DetailsDialog
        title="New Product Type"
        openDialog={openDialog}
        handleClose={handleCloseDialog}
      >
        <TypeForm {...typeProps} />
      </DetailsDialog>
      <DetailsDialog
        title="Edit Product Type"
        openDialog={openEdit}
        handleClose={handleCloseEdit}
      >
        <TypeForm {...typeProps} />
      </DetailsDialog>
      <div className="mui-tables">
        <MuiPaper>
          <EnhancedTableToolbar title={""} />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead headCells={headData} />
              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="2" align="center">
                      <img
                        src="/images/listPreloader.gif"
                        alt="loader"
                        style={{ width: "5%" }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : types.length > 0 ? (
                <TableBody>
                  {types
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((type) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={type.type_id}
                        >
                          <TableCell>{type.type_name}</TableCell>
                          <TableCell>
                            <button
                              onClick={() => handleOpenEdit(type.type_id)}
                              className="btn btn-sm pharmacy-info-btn mr-1"
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </button>

                            <button
                              className="btn btn-sm pharmacy-btn-dismiss"
                              onClick={() => handleOpenDelete(type.type_id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="2" align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <MuiPagination
            data={types}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            rows={[10, 20, 30, 50, 100]}
            colSpan={2}
          />
        </MuiPaper>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    medicines: state.medicines,
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      medicineActions: bindActionCreators(medicineActions, dispatch),
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicineType);
