/** @jsx jsx */ //please do not remove this implementation because the css will not be loaded without it
import { jsx, css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import {
  ItemsTable,
  ReceiptTemplate,
} from "../../Utils/Template/ReceiptTemplate";
import { detailsTable, smallPadding, table } from "../../styles/tableStyles";
import { formatDate, formatDateTime } from "../../Utils/ConvertDate";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../Helpers/config.json";
import { errorMessages } from "../../Helpers/ErrorMessages";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Card from "@material-ui/core/Card";
import {
  MiniCenteredDiv,
  RightAlignedContainer,
} from "../../Utils/styledComponents";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import { history } from "../../Helpers/history";
import {
  useExcelReports,
  usePdfReports,
} from "../Invoice/CustomHooks/useExcelReports";
import { LoadingGif } from "../../Utils/Loader";

const headData = [
  { value: "Batch#" },
  { value: "Opening Qty" },
  { value: "Closing Qty" },
  { value: "Qty Adjusted" },
  { value: "Adj. Type" },
  { value: "Reason" },
];

const tableMargin = css`
  margin-top: 1rem;
`;

const ProductTable = ({ product_name, colSpan }) => (
  <table css={[table, detailsTable, smallPadding, tableMargin]}>
    <tbody>
      <tr>
        <td {...{ colSpan }}>
          <strong>{product_name}</strong>
        </td>
      </tr>
    </tbody>
  </table>
);

const LoadingTable = ({ children, colSpan }) => (
  <table css={[table, detailsTable, smallPadding, tableMargin]}>
    <tbody>
      <tr>
        <td align="center" {...{ colSpan }}>
          {children}
        </td>
      </tr>
    </tbody>
  </table>
);

const StockAdjustmentReport = ({ actions, snackbars, match: { params } }) => {
  const { adjustment_id } = params;
  const [adjustment, setAdjustment] = useState({
    products: [],
    adjustmentDetails: {},
    loading: "idle",
  });

  const groupByProductId = (arr = []) => {
    let result = [];
    let knowledge = {};
    arr.forEach((item) => {
      let id = item.product_id;
      if (id in knowledge) {
        result[knowledge[id]].batches.push(item);
      } else {
        knowledge[id] = result.length;
        result.push({
          ...item,
          batches: [item],
        });
      }
    });
    return result;
  };

  useEffect(() => {
    setAdjustment({ ...adjustment, loading: "loading" });
    axios
      .get(`${config.epharmUrl}/Creport/view_adjustment_details`, {
        params: { adjustment_id },
      })
      .then((res) => {
        const data = res.data;
        const arr = data ?? [];
        const obj = arr[0] ? arr[0] : {};
        const adjusted_by = `${
          obj.created_by_firstname ? obj.created_by_firstname : ""
        } ${obj.created_by_lastname ? obj.created_by_lastname : ""}`;
        const date = obj.date_created ? formatDate(obj.date_created) : "";
        const productArr = groupByProductId(arr);
        setAdjustment({
          ...adjustment,
          products: productArr,
          adjustmentDetails: { adjusted_by, date },
          loading: "success",
        });
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setAdjustment({ ...adjustment, loading: "error" });
      });
  }, []);

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackBar();
  };

  const { products, adjustmentDetails, loading } = adjustment;

  const { open, variant, message } = snackbars;

  const currentObj = {
    file_name: "Stock Adjustment Report",
    url: "Creport/adjustment_details_excel",
    params: { adjustment_id },
  };

  const pdfObj = {
    file_name: "Stock Adjustment Report",
    url: "Creport/adjustment_details_pdf",
    params: { adjustment_id, download: true },
  };

  const { exportExcel } = useExcelReports(actions, currentObj);

  const { exportPDF } = usePdfReports(actions, pdfObj);

  const isLoading = loading === "loading";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  const customerDetails = (
    <tbody>
      <tr>
        <td data-testid="adjustment_date">
          <span>
            Date: <strong>{adjustmentDetails.date}</strong>
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            Adjusted by:{" "}
            <strong data-testid="adjusted_by">
              {adjustmentDetails.adjusted_by}
            </strong>
          </span>
        </td>
      </tr>
    </tbody>
  );

  const itemDetails = (
    <>
      {isLoading ? (
        <LoadingTable colSpan={headData.length}>
          <LoadingGif />
        </LoadingTable>
      ) : null}
      {isSuccess ? (
        products.length > 0 ? (
          products.map((info) => {
            const batches = info.batches ? info.batches : [];
            return (
              <React.Fragment key={info.product_id}>
                <ProductTable
                  product_name={info.product_name}
                  colSpan={headData.length}
                />
                <ItemsTable headData={headData}>
                  <tbody>
                    {batches.map((item, index) => {
                      return (
                        <tr>
                          <td data-testid={"batch-" + index}>
                            <span>{item.batch_id}</span>
                          </td>
                          <td data-testid={"opening-quantity-" + index}>
                            <span>{item.opening_quantity}</span>
                          </td>
                          <td data-testid={"closing-quantity-" + index}>
                            <span>{item.closing_quantity}</span>
                          </td>
                          <td data-testid={"quantity-" + index}>
                            <span>{item.quantity}</span>
                          </td>
                          <td data-testid={"adjustment-type" + index}>
                            <span>
                              {item.adjustment_type === "I"
                                ? "Increment"
                                : "Decrement"}
                            </span>
                          </td>
                          <td data-testid={"reason" + index}>
                            <span>{item.reason}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </ItemsTable>
              </React.Fragment>
            );
          })
        ) : (
          <LoadingTable colSpan={headData.length}>
            {" "}
            No records found
          </LoadingTable>
        )
      ) : null}
      {isError ? (
        <LoadingTable colSpan={headData.length}>
          The server did not return a valid response
        </LoadingTable>
      ) : null}
    </>
  );

  return (
    <React.Fragment>
      <ReusableDashboardHeader
        component="Stock Adjustment Report"
        heading="Stock"
        subHeading="Stock adjustment report"
        link={history.location.pathname}
      />
      <PharmacySnackbar
        variant={variant}
        handleCloseBar={closeSnackbar}
        open={open}
        message={message}
      />
      <MiniCenteredDiv>
        <RightAlignedContainer>
          <button
            onClick={exportExcel}
            className="btn btn-sm pharmacy-btn mb-2 mr-3"
          >
            Excel
          </button>
          <button onClick={exportPDF} className="btn btn-sm pharmacy-btn mb-2">
            PDF
          </button>
        </RightAlignedContainer>
        <Card className="p-3">
          <ReceiptTemplate {...{ customerDetails, itemDetails }} />
        </Card>
      </MiniCenteredDiv>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  const { snackbars } = state;
  return { snackbars };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockAdjustmentReport);
