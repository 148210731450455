import React, { useCallback, useEffect, useState } from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import { connect } from "react-redux";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import { LoadingGif } from "../../Utils/Loader";
import { history } from "../../Helpers/history";
import {
  ExpenseContainer,
  RightAlignedContainer,
} from "../../Utils/styledComponents";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPagination,
  MuiPaper,
} from "../../Utils/Tables/CustomTable";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import axios from "axios";
import config from "../../Helpers/config.json";
import { errorMessages } from "../../Helpers/ErrorMessages";
import TextField from "../../Utils/FormInputs/TextField";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { dateStringTimeConvert } from "../../Utils/titleCaseFunction";
import {
  useExcelReports,
  usePdfReports,
} from "../Invoice/CustomHooks/useExcelReports";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";

const headData = [
  { id: "date", numeric: false, disablePadding: false, label: "Date" },
  {
    id: "product_name",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },
  { id: "batch_id", numeric: false, disablePadding: false, label: "Batch#" },
  {
    id: "adjustment_type",
    numeric: false,
    disablePadding: false,
    label: "Adj. Type",
  },
  { id: "reason", numeric: false, disablePadding: false, label: "Reason" },
  {
    id: "opening_qty",
    numeric: false,
    disablePadding: false,
    label: "Opening Qty",
  },
  {
    id: "closing_qty",
    numeric: false,
    disablePadding: false,
    label: "Closing Qty",
  },
  {
    id: "quantity_adjusted",
    numeric: false,
    disablePadding: false,
    label: "Qty Adjusted",
  },
  {
    id: "adjusted_by",
    numeric: false,
    disablePadding: false,
    label: "Adjusted by",
  },
];

const StockAdjustmentList = ({ actions, snackbars }) => {
  const [adjustmentData, setAdjustmentData] = useState({
    data: [],
    loading: "idle",
    total_count: 0,
  });
  const [activePage, setActivePage] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState({ start_date: "", end_date: "" });
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState("idle");
  const { start_date, end_date } = date;

  const { loading, data, total_count } = adjustmentData;

  const retrieveAdjustmentList = useCallback(
    (page, from_date = "", to_date = "", isSubmit = false) => {
      setAdjustmentData({ ...data, loading: "idle" });
      isSubmit && setIsSubmitted("pending");
      axios
        .get(`${config.epharmUrl}/Creport/adjustment_list`, {
          params: {
            page: page,
            search,
            start_date: from_date,
            end_date: to_date,
          },
        })
        .then((response) => {
          const res = response?.data;
          const dt = res ? res : {};
          const adjustments = dt.adjustments ? dt.adjustments : [];
          const count = dt.total_count ? dt.total_count : 0;
          const arr = adjustments.map((item) => ({
            ...item,
            date: item.date_created
              ? dateStringTimeConvert(item.date_created)
              : "",
            adjusted_by: `${
              item.created_by_firstname ? item.created_by_firstname : ""
            } ${item.created_by_lastname ? item.created_by_lastname : ""}`,
          }));
          setAdjustmentData({
            data: arr,
            loading: "success",
            total_count: count,
          });
          isSubmit && setIsSubmitted("resolved");
        })
        .catch((error) => {
          errorMessages(error, null, actions);
          setAdjustmentData({ ...data, loading: "error" });
          isSubmit && setIsSubmitted("rejected");
        });
    },
    [search, page]
  );

  useEffect(() => {
    retrieveAdjustmentList(activePage, start_date, end_date);
  }, [retrieveAdjustmentList]);

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
    setActivePage(1);
    setPage(0);
  };

  const handleChangeDate = (event) => {
    const { name, value } = event.target;
    setDate({ ...date, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (start_date && end_date) {
      retrieveAdjustmentList(1, start_date, end_date, true);
      setActivePage(1);
      setPage(0);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setActivePage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackBar();
  };

  const isLoading = loading === "pending";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  const isPending = isSubmitted === "pending";

  const { open, variant, message } = snackbars;

  const currentObj = {
    file_name: "Stock Adjustment Report",
    url: "Creport/adjustment_details_excel",
    params: { start_date, end_date },
  };

  const pdfObj = {
    file_name: "Stock Adjustment Report",
    url: "Creport/adjustment_details_pdf",
    params: { start_date, end_date, download: true },
  };

  const { exportExcel } = useExcelReports(actions, currentObj);

  const { exportPDF } = usePdfReports(actions, pdfObj);

  const emptyRows = 10 - Math.min(10, data?.length);

  const reusableTable = (
    <MuiPaper>
      <EnhancedTableToolbar title="">
        <TextField
          type="text"
          placeholder="Search..."
          value={search}
          data-testid="search"
          onChange={handleChangeSearch}
        />
      </EnhancedTableToolbar>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead headCells={headData} />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell align="center" colSpan={headData.length}>
                  <LoadingGif />
                </TableCell>
              </TableRow>
            ) : null}
            {isSuccess ? (
              data?.length > 0 ? (
                <>
                  {data.map((row, index) => {
                    return (
                      <TableRow tabIndex={-1} key={index}>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>{row.product_name}</TableCell>
                        <TableCell>{row.batch_id}</TableCell>
                        <TableCell>
                          {row.adjustment_type === "I"
                            ? "Increment"
                            : "Decrement"}
                        </TableCell>
                        <TableCell>{row.reason}</TableCell>
                        <TableCell>{row.opening_quantity}</TableCell>
                        <TableCell>{row.closing_quantity}</TableCell>
                        <TableCell>{row.quantity}</TableCell>
                        <TableCell>{row.adjusted_by}</TableCell>
                        {/*<TableCell>*/}
                        {/*    <Tooltip title="View adjustment details"*/}
                        {/*             placement="left-start">*/}
                        {/*        <Link*/}
                        {/*            to={{pathname: `/stock-adjustment-report/${row.stock_adjustment_id}`}}>*/}
                        {/*            <button*/}
                        {/*                data-testid={"view-adjustment-report-"+index}*/}
                        {/*                className="btn btn-sm pharmacy-gray-btn icon-button-holder mr-3 mb-sm-2">*/}
                        {/*                <FontAwesomeIcon icon={faEye}/>*/}
                        {/*            </button>*/}
                        {/*        </Link>*/}
                        {/*    </Tooltip>*/}
                        {/*</TableCell>*/}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 43 * emptyRows }}>
                      <TableCell colSpan={headData.length} />
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={headData.length}>
                    No records found
                  </TableCell>
                </TableRow>
              )
            ) : null}
            {isError ? (
              <TableRow>
                <TableCell align="center" colSpan={headData.length}>
                  The server did not return a valid response
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
        {total_count > 10 ? (
          <MuiPagination
            rowsPerPage={rowsPerPage}
            page={page}
            total_count={total_count}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            isTotalCount={true}
            handleChangePage={handleChangePage}
            rows={10}
            colSpan={headData.length}
          />
        ) : null}
        {/*{total_count > 0 ? <CustomPagination records={10} handleChangeNextPage={handleChangePage}*/}
        {/*                                        data={data} total_count={total_count} activePage={page} />: null}*/}
      </TableContainer>
    </MuiPaper>
  );

  return (
    <div className="journals">
      <ReusableDashboardHeader
        component="Stock Adjustment List"
        heading="Stock"
        subHeading="Stock adjustment list"
        link={history.location.pathname}
      />
      <ExpenseContainer>
        <div className="row">
          <div className="col-lg-8">
            <form onSubmit={handleSubmit}>
              <Form.Group as={Row} style={{ marginBottom: "-1%" }}>
                <Col sm={4}>
                  <Form.Group as={Row}>
                    <Label
                      name="From"
                      column
                      sm={2}
                      style={{ textAlign: "right" }}
                    />
                    <Col sm={10}>
                      <TextField
                        type="date"
                        value={start_date}
                        onChange={handleChangeDate}
                        name="start_date"
                        submitted={submitted}
                      />
                      {submitted && !start_date ? (
                        <div className="help-block">From date is required</div>
                      ) : null}
                    </Col>
                  </Form.Group>
                </Col>

                <Col sm={4}>
                  <Form.Group as={Row}>
                    <Label name="To" column sm={2} />
                    <Col sm={10}>
                      <TextField
                        type="date"
                        name="end_date"
                        value={end_date}
                        onChange={handleChangeDate}
                        submitted={submitted}
                      />
                      {submitted && !end_date ? (
                        <div className="help-block">To date is required</div>
                      ) : null}
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={1}>
                  <button
                    type="submit"
                    disabled={isPending}
                    className="btn btn-sm pharmacy-btn-outline"
                  >
                    {isPending ? "Filtering..." : "Filter"}
                  </button>
                </Col>
              </Form.Group>
            </form>
          </div>
          <div className="col-lg-4">
            <RightAlignedContainer>
              <button
                onClick={exportExcel}
                className="btn btn-sm pharmacy-btn mb-2 mr-3"
              >
                Excel
              </button>
              <button
                onClick={exportPDF}
                className="btn btn-sm pharmacy-btn mb-2"
              >
                PDF
              </button>
            </RightAlignedContainer>
          </div>
        </div>

        <PharmacySnackbar
          variant={variant}
          handleCloseBar={closeSnackbar}
          open={open}
          message={message}
        />
        {reusableTable}
      </ExpenseContainer>
    </div>
  );
};

function mapStateToProps(state) {
  const { snackbars } = state;
  return { snackbars };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockAdjustmentList);
