/** @jsx jsx */ //please do not remove this implementation because the css will not be loaded without it
import { jsx, css } from "@emotion/react";
import React from "react";
import {
  ItemsTable,
  ReceiptTemplate,
} from "../../../Utils/Template/ReceiptTemplate";
import { tableCell } from "../../../styles/tableStyles";
import { formatAmount, formatDigits } from "../../../Utils/formatNumbers";
import { dateStringTimeConvert } from "../../../Utils/titleCaseFunction";
// import { details } from "../../../Utils/RoleDetails";
import { useQuery } from "react-query";
import axios from "axios";
import config from "../../../Helpers/config.json";


const headData = [
  { value: "Account" },
  { value: "Description" },
  { value: "Debit" },
  { value: "Credt" },
];

const wordBreak = css`
  word-break: break-word;
`;

const SpecificJournalTemplate = ({ selectedJournalID }) => {

  const journalQuery = useQuery(
    ["journalQuery", selectedJournalID],
    async () => {
      try {
        const response = await axios.get(
          `${config.epharmUrl}/accounting/journal_id?journalid=${selectedJournalID}`
        );

        const data = response?.data;

        return data.data[0];
      } catch (error) {
        // log error
      }
    },
    { retry: 1 }
  );

  const journal = journalQuery.isSuccess ? journalQuery.data : {};

  const customerDetails = (
    <tbody>
      <tr>
        <td>
          <td data-testid="journal_id">
            <span>
              Journal#: <strong>{journal.journal_id}</strong>
            </span>
          </td>
        </td>
        <td>
          <td data-testid="journal_date">
            <span>
              Date:{" "}
              <strong>
                {journal.date ? dateStringTimeConvert(journal.date) : ""}
              </strong>
            </span>
          </td>
        </td>
      </tr>
    </tbody>
  );

  const itemDetails = (
    <ItemsTable headData={headData}>
      <tbody>
        {journal?.details && journal?.details.length > 0 ? (
          journal?.details.map((detail, idx) => (
            <tr>
              <td css={[wordBreak]} data-testid={"account-"}>
                <span>{detail.accountname}</span>
              </td>
              <td css={[wordBreak]} data-testid={"description-"}>
                <span>{detail.description}</span>
              </td>
              <td css={[wordBreak]} data-testid={"debit-"}>
                <span>{detail.debit}</span>
              </td>
              <td data-testid={"credit-"}>
                <span>{detail.credit}</span>
              </td>
            </tr>
          ))
        ) : (
          <></>
        )}

        <tr>
          <td css={[tableCell]} colSpan="5"></td>
        </tr>

        <tr>
          <td colSpan="4" align="right">
            <span>Total Credit</span>
          </td>
          <td data-testid="total_credit">
            <span>
              <strong>{formatDigits(journal.total_credit)}</strong>
            </span>
          </td>
        </tr>


        <tr>
          <td colSpan="4" align="right">
            <span>Total Debit</span>
          </td>
          <td data-testid="total_amount">
            <span>
              <strong>{formatDigits(journal.total_debit)}</strong>
            </span>
          </td>
        </tr>

      </tbody>
    </ItemsTable>
  );

  return <ReceiptTemplate {...{ customerDetails, itemDetails }} />;
};

export { SpecificJournalTemplate };
