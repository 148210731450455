import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TextField from "../FormInputs/TextField";
import Label from "../FormInputs/Label";
import { ErrorMessage } from "../styledComponents";
import * as mq from "../../styles/media-queries";
import styled from "@emotion/styled/macro";

const StyledForm = styled.form({
  [mq.extraLarge]: {
    width: "80%",
  },
});

function AccountingDateFilter({
  from_date,
  to_date,
  handleChange,
  submitted,
  handleSubmit,
  isPending,
}) {
  return (
    <StyledForm onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6">
          <Form.Group as={Row}>
            <Label name="From" sm={2} column type />
            <Col sm={7}>
              <TextField
                type="date"
                name="from_date"
                value={from_date}
                onChange={handleChange}
              />
              {submitted && !from_date ? (
                <ErrorMessage>This is a required field</ErrorMessage>
              ) : null}
            </Col>
          </Form.Group>
        </div>
        <div className="col-lg-6">
          <Form.Group as={Row}>
            <Label name="To" sm={2} column type />
            <Col sm={6}>
              <TextField
                type="date"
                name="to_date"
                value={to_date}
                onChange={handleChange}
              />
              {to_date < from_date ? (
                <ErrorMessage>
                  To date shouldn't be less than from date
                </ErrorMessage>
              ) : submitted && !to_date ? (
                <ErrorMessage>This is a required field</ErrorMessage>
              ) : null}
            </Col>
            <Col sm={2}>
              <button
                type="submit"
                className="btn btn-sm pharmacy-info-btn"
                disabled={isPending}
              >
                {isPending ? "Searching..." : "Search"}
              </button>
            </Col>
          </Form.Group>
        </div>
      </div>
    </StyledForm>
  );
}
export { AccountingDateFilter };
