import React from "react";
import Form from "react-bootstrap/Form";
import Label from "../../../Utils/FormInputs/Label";
import TextField from "../../../Utils/FormInputs/TextField";
import Textarea from "../../../Utils/FormInputs/Textarea";
import { CustomSelect } from "../../../Utils/FormInputs/SelectInput";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import { memo } from "react";

const useStyles = makeStyles(() => ({
  margin: {
    margin: 0,
  },
  inputBase: {
    fontSize: "0.9em",
    padding: "2px",
  },

  col: {
    maxWidth: 600,
  },
  td: {
    width: "20%",
  },
  btn: {
    width: "4%",
  },
  icon: {
    fontSize: ".8rem",
  },
  totalSection: {
    borderRadius: "10px",
    backgroundColor: "#fbfafa",
    padding: "8px",
  },
}));

const NewLedgerEntry = ({
  state,
  accounts,
  ledger,
  handleChange,
  handleChangeAccount,
  handleSubmit,
  isSubmitted,
  submitted,
  handleChangeItems,
  removeRow,
  addItem,
  total,
  handleBlurDebit,
  handleBlurCredit,
}) => {
  const classes = useStyles();
  const { credit_total, debit_total } = total;
  const { date, description } = state;
  return (
    <form
      data-testid="new-journal-entry-form"
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <div className={classes.col}>
        <Form.Group>
          <Label name="Date" type />
          <TextField
            type="date"
            onChange={handleChange}
            value={date}
            name="date"
            data-testid="journal-entry-date"
          />
          {submitted && !date && (
            <div className="help-block">Date is required</div>
          )}
        </Form.Group>
        <Form.Group>
          <Label name="Memo" type />
          <Textarea
            value={description}
            onChange={handleChange}
            submitted={submitted}
            name="description"
            data-testid="joirnal-entry-memo"
          />
          {submitted && !description && (
            <div className="help-block">Memo is required</div>
          )}
        </Form.Group>
      </div>
      <table className="table table-bordered table-sm">
        <tr>
          <td className={classes.td}>
            Account<span className="help-block">*</span>
          </td>
          <td className={classes.td}>Debit</td>
          <td className={classes.td}>Credit</td>
          <td className={classes.btn} />
        </tr>
        <tbody>
          {ledger.map((item, index) => (
            <tr key={item.id}>
              <td data-testid={`ledger_account_${index}`}>
                <CustomSelect
                  value={item.account_id}
                  options={accounts}
                  submitted={submitted}
                  dataTest="account"
                  onChange={(value) => handleChangeAccount(value, item.id)}
                />
                {submitted && !item.account_id && (
                  <div className="help-block">Select account</div>
                )}
              </td>
              <td>
                <TextField
                  type="number"
                  onChange={(e) => handleChangeItems(e, item.id)}
                  name="debit"
                  data-testid={`debit-amount-${index}`}
                  value={item.debit}
                  onBlur={() => handleBlurDebit(item.id)}
                />
              </td>
              <td>
                <TextField
                  type="number"
                  onChange={(e) => handleChangeItems(e, item.id)}
                  name="credit"
                  value={item.credit}
                  dataTestId="credit"
                  data-testid={`credit-amount-${index}`}
                  onBlur={() => handleBlurCredit(item.id)}
                />
              </td>
              <td>
                <button
                  type="button"
                  onClick={() => removeRow(item.id)}
                  className="btn btn-sm pharmacy-btn-dismiss"
                >
                  <ClearIcon classes={{ root: classes.icon }} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="row col-md-12">
        <div className="col-md-4">
          <button
            type="button"
            onClick={addItem}
            className="btn btn-sm pharmacy-grey-btn"
          >
            Add another Line
          </button>
        </div>
        <div className="col-md-8">
          <div className={classes.totalSection}>
            <div className="row">
              <div className="col-md-3">
                <p>Total</p>
              </div>
              <div className="col-md-3">
                <p className="text-center">{debit_total}</p>
              </div>
              <div className="col-md-3">
                <p className="text-center">{credit_total}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {debit_total === credit_total ? (
        // Display save button if totals are equal
        <button data-testid="journal-entry-submit-btn"
          type="submit"
          disabled={isSubmitted === "pending"}
          className="btn btn-sm pharmacy-info-btn mt-2"
        >
          {isSubmitted === "pending" ? "Submitting..." : "Submit"}
        </button>
      ) : (
        // Display a message or disable the save button if totals are not equal
        <p className="text-danger">
          Debit total and credit total must be equal before saving.
        </p>
      )}
    </form>
  );
};

export default NewLedgerEntry;
