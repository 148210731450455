import ManageInvoice from "./Components/Invoice/ManageInvoice"
import InvoiceDetails from "./Components/Invoice/InvoiceDetails";
import PosInvoiceDetails from "./Components/Invoice/PosInvoiceDetails";
import AddCustomer from "./Components/Customer/AddCustomer";
import ManageCustomer from "./Components/Customer/ManageCustomer";
import ManageProvider from "./Components/Customer/Providers";
import AddMedicine from "./Components/Medicine/AddMedicine";
import ManageMedicine from "./Components/Medicine/ManageMedicine";
import DashboardContent from "./Containers/Dasboard/DashboardContent";
import CreatePosInvoice from "./Components/Invoice/CreatePosInvoice";
import MedicineType from "./Components/Medicine/MedicineType";
import MedicineCategory from "./Components/Medicine/MedicineCategory";
import AddUnit from "./Components/Medicine/AddUnit";
import ManageManufacturer from "./Components/Manufacturer/ManageManufacturer";
import MedicineDetails from "./Components/Medicine/MedicineDetails";
import ManufacturerInvoice from "./Components/Manufacturer/ManufacturerInvoice";
import ManufacturerSalesDetails from "./Components/Manufacturer/ManufacturerSalesDetails";
import AddPurchase from "./Components/Purchase/AddPurchase";
import ManagePurchase from "./Components/Purchase/ManagePurchase";
import StockReport from "./Components/Stock/StockReport";
import StockBatchWise from "./Components/Stock/StockBatchWise";
import CustomerDetails from "./Components/Customer/CustomerDetails";
import ManufDetails from "./Components/Manufacturer/ManufDetails";
import Return from "./Components/Return/Return";
import CustomerReturnList from "./Components/Return/CustomerReturnList";
import ManufacturerReturnList from "./Components/Return/ManufacturerReturnList";
import CustomerReturnInvoice from "./Components/Return/CustomerReturnInvoice";
import CustomerReturnInvoiceDetails from "./Components/Return/CustomerReturnInvoiceDetails";
import ManufacturerReturn from "./Components/Return/ManufacturerReturn";
import ManufacturerReturnInvoice from "./Components/Return/ManufacturerReturnInvoice";
import ManufacturerReturnInvoiceDetails from "./Components/Return/ManufacturerReturnInvoiceDetails";
import AddSupplier from "./Components/Supplier/AddSupplier";
import ManageSupplier from "./Components/Supplier/ManageSupplier";
import BankTransaction from "./Components/Bank/BankTransaction";
import ManageBank from "./Components/Bank/ManageBank";
import AddService from "./Components/Service/AddService";
import ManageService from "./Components/Service/ManageService";
import ServiceInvoice from "./Components/Service/ServiceInvoice";
import ManageServiceInvoice from "./Components/Service/ManageServiceInvoice";
import ManageUsers from "./Components/Settings/ManageUsers";
import AddRole from "./Components/Settings/AddRole";
import RoleList from "./Components/Settings/RoleList";
import AssignUserRole from "./Components/Settings/AssignUserRole";
import ManufacturerLedgerReport from "./Components/Manufacturer/ManufacturerLedgerReport";
import ManufacturerLedgerInfo from "./Components/Manufacturer/ManufacturerLedgerInfo";
import TodayInvoiceReport from "./Components/Invoice/TodaysReport";
import InvoiceHtml from "./Components/Purchase/InvoiceHtml";
import EditSupplier from "./Components/Supplier/EditSupplier";
import PageNotFound from "./Components/PageNotFound";
import SupplierLedgerReport from "./Components/Supplier/SupplierLedgerReport";
import SupplierLedgerInfo from "./Components/Supplier/SupplierLedgerInfo";
import MyPagination from "./Utils/MyPagination";
import MainGuIComponent from "./Components/Invoice/MainGuIComponent";
import OutOfStock from "./Components/Stock/OutOfStock";
import ExpiryListReport from "./Components/Stock/ExpiryList";
import AddDesignation from "./Components/HumanResource/HRM/AddDesignation";
import ManageDesignation from "./Components/HumanResource/HRM/ManageDesignation";
import AddEmployee from "./Components/HumanResource/HRM/AddEmployee";
import EditUserProfile from "./Components/USer/EditUserProfile";
import ManageEmployee from "./Components/HumanResource/HRM/ManageEmployee";
import AddAttendance from "./Components/HumanResource/Attendance/AddAttendance";
import ManageAttendance from "./Components/HumanResource/Attendance/ManageAttendance";
import AttendanceReport from "./Components/HumanResource/Attendance/AttendanceReport";
import CustomerLedger from "./Components/Customer/CustomerLedger";
import AddBenefits from "./Components/HumanResource/Payroll/AddBenefits";
import ManageBenefits from "./Components/HumanResource/Payroll/ManageBenefits";
import EditBenefit from "./Components/HumanResource/Payroll/EditBenefit";
import AddSalarySetUp from "./Components/HumanResource/Payroll/AddSalarySetup";
import ManageSalarySetUp from "./Components/HumanResource/Payroll/ManageSalarySetup";
import EditSalarySetUp from "./Components/HumanResource/Payroll/EditSalarySetUp";
import SalaryGenerate from "./Components/HumanResource/Payroll/SalaryGenerate";
import ManageSalaryGenerate from "./Components/HumanResource/Payroll/ManageSalaryGenerate";
import SalaryPayment from "./Components/HumanResource/Payroll/SalaryPayment";
import AddExpenseItem from "./Components/HumanResource/Expense/AddExpenseItem";
import ManageExpenseItem from "./Components/HumanResource/Expense/ManageExpenseItem";
import AddExpense from "./Components/HumanResource/Expense/AddExpense";
import ManageExpense from "./Components/HumanResource/Expense/ManageExpense";
import ExpenseStatement from "./Components/HumanResource/Expense/ExpenseStatement";
import AddFixedAssets from "./Components/HumanResource/FixedAssets/AddFixedAssets";
import FixedAssetsList from "./Components/HumanResource/FixedAssets/FixedAssetsList";
import EditFixedAssets from "./Components/HumanResource/FixedAssets/EditFixedAssets";
import FixedAssetsPurchaseList from "./Components/HumanResource/FixedAssets/FixedAssetsPurchaseList";
import PurchaseFixedAssets from "./Components/HumanResource/FixedAssets/PurchaseFixedAssets";
import EditPurchaseFixedAsset from "./Components/HumanResource/FixedAssets/EditFixedPurchaseAsset";
import AddPerson from "./Components/HumanResource/PersonalLoan/AddPerson";
import AddLoan from "./Components/HumanResource/PersonalLoan/AddLoan";
import AddPayment from "./Components/HumanResource/PersonalLoan/AddPayment";
import ManagePerson from "./Components/HumanResource/PersonalLoan/ManagePerson";
import EditPerson from "./Components/HumanResource/PersonalLoan/EditPerson";
import PersonLoanDetails from "./Components/HumanResource/PersonalLoan/PersonLoanDetails";
import AddOfficePerson from "./Components/HumanResource/OfficeLoan/AddPerson";
import ManageOfficePerson from "./Components/HumanResource/OfficeLoan/ManageOfficePerson";
import PersonalLoanLedger from "./Components/HumanResource/OfficeLoan/PersonalLoanLedger";
import EditPersonLoan from "./Components/HumanResource/OfficeLoan/EditPersonLoan";
import EditAttendance from "./Components/HumanResource/Attendance/EditAttendance";
import DateWiseAttendanceReport from "./Components/HumanResource/Attendance/DateWiseReport";
import EmployeeWiseReport from "./Components/HumanResource/Attendance/EmployeeWiseReport";
import TimeWiseAttendanceReport from "./Components/HumanResource/Attendance/TimeWiseReport";
import EditDesignation from "./Components/HumanResource/HRM/EditDesignation";
import EditEmployee from "./Components/HumanResource/HRM/EditEmployee";
import EmployeeDetails from "./Components/HumanResource/HRM/EmployeeDetails";
import EditUser from "./Components/Settings/EditUser";
import SupportPage from "./Components/Settings/Support";
import TaxSettings from "./Components/Tax/TaxSettings";
import AddIncomeTax from "./Components/Tax/AddIncomeTax";
import ManageIncomeTax from "./Components/Tax/ManageIncomeTax";
import TaxReport from "./Components/Tax/TaxReport";
import InvoiceWiseTaxReport from "./Components/Tax/InvoiceWiseTaxReport";
import SubscriptionExpired from "./Components/SubscriptionExpired";
import ChangePassword from "./Components/USer/ChangePassword";
import AddCompany from "./Components/Settings/AddCompany";
import AddPurchaseOrder from "./Components/Purchase/AddPurchaseOrder";
import ManagePurchaseOrder from "./Components/Purchase/ManagePurchaseOrder";
import PurchaseOrderDetails from "./Components/Purchase/PurchaseOrderDetails";
import StockAdjustment from "./Components/Stock/StockAdjustment";
import CustomerPurchases from "./Components/Customer/CustomerPurchases";
import ProductTrends from "./Components/Report/ProductTrends";
import ChartOfAccounts from "./Components/Accounting/COA/ChartOfAccounts";
import Journals from "./Components/Accounting/COA/Journals";
import NewLedgerEntry from "./Components/Accounting/COA/NewLedgerEntry";
import InvoiceReportDetails from "./Components/Accounting/Reports/DebtorsReportDetails";
import AccountingPeriod from "./Components/Accounting/LedgerSetUp/AccountingPeriod";
import PurchaseOrderApproval from "./Components/Purchase/PurchaseOrderApproval";
import PurchaseAndSalesReports from "./Components/Report/PurchaseandSalesReport";
import Expenses from "./Components/Accounting/Expenses/Expenses";
import ExpenseItems from "./Components/Accounting/Expenses/ExpenseItems";
import EditBank from "./Components/Bank/EditBank";
import AccountReceivables from "./Components/Accounting/Reports/AccountReceivables";
import NewSalesReceipt from "./Components/Invoice/NewSalesReceipt";
import SalesReceipts from "./Components/Invoice/SalesReceipts";
import AddProvider from "./Components/Customer/AddProvider";
import ReceiptPrint from "./Components/Invoice/ReceiptPrint";
import SetupProducts from "./Components/Accounting/LedgerSetUp/SetupProducts";
import SetUpSuppliers from "./Components/Accounting/LedgerSetUp/SetUpSuppliers";
import SetupCustomers from "./Components/Accounting/LedgerSetUp/SetupCustomers";
import CustomerLedgers from "./Components/Accounting/LedgerSetUp/CustomerLedgers";
import SupplierLedgers from "./Components/Accounting/LedgerSetUp/SupplierLedgers";
import ProductLedgers from "./Components/Accounting/LedgerSetUp/ProductLedgers";
import ViewJournalTransactions from "./Components/Accounting/COA/ViewJournalTransactions";
import CompanyDetails from "./Components/Settings/CompanyDetails";
import { ProductLog } from "./Components/Stock/ProductLog";
import AdvancedPurchaseReport from "./Components/Report/AdvancedPurchaseReport";
import AdvancedSalesReport from "./Components/Report/AdvancedSalesReport";
import AdvancedSalesMedicineWiseReport from "./Components/Report/AdvancedSalesReportMedicineWIse";
import ProfitLossStatement from "./Components/Accounting/Reports/ProfitLossStatement";
import AgingReport from "./Components/Report/AgingReport";
import PaymentTypesList from "./Components/Settings/PaymentTypesList";
import BalanceSheet from "./Components/Accounting/Reports/BalanceSheet";
import TrialBalance from "./Components/Accounting/Reports/TrialBalance";
import AddManufacturer from "./Components/Manufacturer/AddManufacturer";
import AccountsPayable from "./Components/Accounting/Reports/AccountsPayable";
import AccountsPayablesDetails from "./Components/Accounting/Reports/AccountsPayablesDetails";
import StockSummaryCategories from "./Components/Accounting/Reports/StockSummaryCategories";
import StockSummaryCategoryDetails from "./Components/Accounting/Reports/StockSummaryCategoryDetails";
import PayablesSummary from "./Components/Accounting/Reports/PayablesSummary";
import ReceivablesSummary from "./Components/Accounting/Reports/ReceivablesSummary";
import PaymentVouchers from "./Components/Report/PaymentVouchers";
import CancelledSales from "./Components/Invoice/CancelledSales";
import ManualBackup from "./Components/Settings/ManualBackup";
import ExpenseReceipt from "./Components/Accounting/Expenses/ExpenseReceipt";
import StockAdjustmentList from "./Components/Stock/StockAdjustmentList";
import StockAdjustmentReport from "./Components/Stock/StockAdjustmentReport";
import ViewStockJournalTransactions from "./Components/Accounting/COA/ViewStockJournalTransactions";
import StockSummaryCategoryDetailsMonths from "./Components/Accounting/Reports/StockSummaryCategoryDetailsMonths";
import ProductHistoryReport from "./Components/Stock/ProductHistoryReport";
import { GeneralLedger } from "./Components/Accounting/COA/GeneralLedger.js";
import StockSummaryMonthlyProduct from "./Components/Accounting/Reports/StockSummaryMonthlyProduct";
import DebitNote from "./Components/Accounting/NoteScreens/DebitNote";
import ManageDebitNotes from "./Components/Accounting/NoteScreens/ManageDebitNotes";
import DebitNoteInvoice from "./Components/Accounting/NoteScreens/DebitNoteInvoice";
import CreditNote from "./Components/Accounting/CreditNote/CreditNote";
import ManageCreditNotes from "./Components/Accounting/CreditNote/ManageCreditNotes";
import CreditNoteInvoice from "./Components/Accounting/CreditNote/CreditNoteInvoice";
import DraftPurchase from "./Components/Purchase/DraftPurchase";
// import ManualJacob from "./Components/Settings/ManualJacob.js";
import InconsistentBatches from "./Components/Stock/InconsistentBatches";
import CorrectInconsistence from "./Components/Stock/CorrectInconsistence";

export const appRoutes = [
  { path: "/", exact: true, component: DashboardContent },
  {path: "/accounting/generalledger",
  component: GeneralLedger,
  },
  {path:"/product_history",component: ProductHistoryReport},
  {path: "/inconsistence-batches",
  component: InconsistentBatches,
  },
  {
    path: "/stock-adjustment-report/:adjustment_id",
    component: StockAdjustmentReport,
  },
  { path: "/correct-batch-inconsistence/:product_id/:batch_id", component: CorrectInconsistence },
  { path: "/stock-adjustment-list", component: StockAdjustmentList },
  { path: "/drafted_purchase", component: DraftPurchase },
  { path: "/expense-reciept/:voucher_id", component: ExpenseReceipt },
  { path: "/creditorssummary", component: PayablesSummary },
  { path: "/debtorssummary", component: ReceivablesSummary },

  {
    path: "/stocksummary/:category_id/:start_date/:end_date",
    component: StockSummaryCategoryDetails,
  },

  { path: "/stocksummary", component: StockSummaryCategories },
  {
    path: "/datailsbymonth/:product_id/:start_date/:end_date",
    component: StockSummaryCategoryDetailsMonths,
  },
  {
    path: "/:product_id/:month/:start_date/:end_date",
    component: StockSummaryMonthlyProduct,
  },
  { path: "/paymentmethods", component: PaymentTypesList },
  { path: "/aging_report", component: AgingReport },
  {
    path: "/receipt_print/:customer_id/:invoice_id/:is_cancelled",
    component: ReceiptPrint,
  },
  { path: "/receipt_print/:customer_id/:invoice_id", component: ReceiptPrint },
  {
    path: "/invoice_print/:customer_id/:invoice_id/:is_cancelled",
    component: PosInvoiceDetails,
  },
  {
    path: "/invoice_print/:customer_id/:invoice_id",
    component: PosInvoiceDetails,
  },
  {
    path: "/creditdetails/:supplier_id/:supplier",  
    component: AccountsPayablesDetails,
  },
  { path: "/creditors/:component", component: AccountsPayable },
  { path: "/new-receipt/:invoice_number", component: NewSalesReceipt },
  { path: "/new-receipt", component: NewSalesReceipt },
  { path: "/sales-receipts", component: SalesReceipts },
  { path: "/purchase_report", component: AdvancedPurchaseReport },
  { path: "/accounting/payment_vouchers", component: PaymentVouchers },
  { path: "/product_trends", component: ProductTrends },
  { path: "/sales_report", component: AdvancedSalesReport },
  {
    path: "/sales_report_medicine_wise",
    component: AdvancedSalesMedicineWiseReport,
  },
  { path: "/allreports", component: PurchaseAndSalesReports },
  {
    path: "/purchaseorderapproval/:local_purchase_order_id/:lpo_status",
    component: PurchaseOrderApproval,
  },
  { path: "/ledgers/setupsuppliers", component: SetUpSuppliers },
  { path: "/ledgers/setupproducts", component: SetupProducts },
  { path: "/ledgers/setupcustomers", component: SetupCustomers },
  { path: "/ledgers/ledgerlist/customer", component: CustomerLedgers },
  { path: "/ledgers/ledgerlist/supplier", component: SupplierLedgers },
  { path: "/ledgers/ledgerlist/product", component: ProductLedgers },
  { path: "/accounting/expenses/:component", component: Expenses },
  { path: "/accounting/expense-items", component: ExpenseItems },
  { path: "/accounting/setup", component: AccountingPeriod },
  { path: "/accounting/debtors/:component", component: AccountReceivables },
  { path: "/accounting/coa", component: ChartOfAccounts },
  { path: "/accounting/journals", component: Journals },
  { path: "/accounting/debitnote", component: DebitNote },
  { path: "/accounting/managedebitnotes", component: ManageDebitNotes },
  { path: "/accounting/creditnote", component: CreditNote },
  { path: "/accounting/managecreditnotes", component: ManageCreditNotes },
  { path: "/report/profit_loss_statement", component: ProfitLossStatement },
  { path: "/report/balancesheet", component: BalanceSheet },
  { path: "/report/trialbalance", component: TrialBalance },
  { path: "/debitnoteinvoice/:supplier_id", component: DebitNoteInvoice },
  {
    path: "/view_transactions/:transaction_id/:account/:start_date/:end_date",
    component: ViewJournalTransactions,
  },
  {
    path: "/view_stock_transactions/:transaction_id/:account/:is_stock",
    component: ViewStockJournalTransactions,
  },
  {
    path: "/view_transactions/:transaction_id/:account",
    component: ViewJournalTransactions,
  },
  { path: "/newledgerentry", component: NewLedgerEntry },
  {
    path: "/invoicereportdetails/:customer_id/:customer_name",
    component: InvoiceReportDetails,
  },
  {
    path: "/editpurchaseordertemplate/:local_purchase_order_id/:status/:template",
    component: AddPurchaseOrder,
  },
  {
    path: "/editpurchaseorder/:local_purchase_order_id/:status",
    component: AddPurchaseOrder,
  },
  { path: "/addpurchaseorder/:component", component: AddPurchaseOrder },
  { path: "/addpurchaseorder", component: AddPurchaseOrder },
  {
    path: "/purchaseorder/:local_purchase_order_id",
    component: PurchaseOrderDetails,
  },
  { path: "/managepurchaseorder", component: ManagePurchaseOrder },
  { path: "/creditnoteinvoice/:customer_id", component: CreditNoteInvoice },
  { path: "/customerpurchases/:customer_id", component: CustomerPurchases },
  { path: "/addcompany", component: AddCompany },
  {
    path: "/stockadjustment/:product_id/:product_name",
    component: StockAdjustment,
  },
  { path: "/stockadjustment", component: StockAdjustment },
  { path: "/stockadjustment/:product_id", component: StockAdjustment },
  {
    path: "/createinvoice/:component/:invoice_id",
    component: CreatePosInvoice,
  },
  { path: "/createinvoice/:component", component: CreatePosInvoice },
  { path: "/manageinvoice", component: ManageInvoice },
  { path: "/cancelledsales", component: CancelledSales },
  { path: "/invoicedetails/:invoice_id", component: InvoiceDetails },
  { path: "/todaysInvoice", component: TodayInvoiceReport },
  { path: "/guipos", component: MainGuIComponent },
  { path: "/editcustomer/:customer_id", component: AddCustomer },
  { path: "/addcustomer", component: AddCustomer },
  { path: "/managecustomer/:component", component: ManageCustomer },
  { path: "/manageprovider/:component", component: ManageProvider },
  { path: "/addprovider", component: AddProvider },
  { path: "/editmedicine/:product_id", component: AddMedicine },
  { path: "/addmedicine", component: AddMedicine },
  { path: "/managemedicine", component: ManageMedicine },
  { path: "/medicinetype", component: MedicineType },
  { path: "/managecategory", component: MedicineCategory },
  { path: "/addunit", component: AddUnit },
  { path: "/managemanufacturer", component: ManageManufacturer },
  { path: "/medicinedetails/:product_id", component: MedicineDetails },
  { path: "/manufacturerledger", component: ManufacturerLedgerReport },
  {
    path: "/manufacturerledgerinfo/:manufacturer_id",
    component: ManufacturerLedgerInfo,
  },
  { path: "/manufacturerinvoice/:purchase_id", component: ManufacturerInvoice },
  {
    path: "/manufacturersales/:manufacturer_id",
    component: ManufacturerSalesDetails,
  },
  { path: "/editpurchase/:purchase_id", component: AddPurchase },
  { path: "/retrievepurchase/:draft_id", component: AddPurchase },
  { path: "/addpurchase/:local_purchase_order_id", component: AddPurchase },
  { path: "/addpurchase", component: AddPurchase },
  { path: "/managepurchase", component: ManagePurchase },
  { path: "/stockreport", component: StockReport },
  { path: "/productlog/:product_id", component: ProductLog },
  { path: "/stockbatchwise", component: StockBatchWise },
  { path: "/customerdetails/:customer_id", component: CustomerDetails },
  { path: "/manufdetails/:manufacturer_id", component: ManufDetails },
  { path: "/return", component: Return },
  { path: "/stockreturnlist", component: CustomerReturnList },
  { path: "/manufreturnlist/:component", component: ManufacturerReturnList },
  { path: "/wastagereturnlist/:component", component: ManufacturerReturnList },
  { path: "/damages/:component", component: ManufacturerReturnList },
  { path: "/stockreturninvoice/:invoice_no", component: CustomerReturnInvoice },
  {
    path: "/stockreturndetails/:return_id",
    component: CustomerReturnInvoiceDetails,
  },
  {
    path: "/manufacturerreturn/:manufacturer_id",
    component: ManufacturerReturn,
  },
  {
    path: "/manufacturerreturninvoice/:product_id/:batch_id",
    component: ManufacturerReturnInvoice,
  },
  {
    path: "/manufacturerreturninvoice/:purchase_id",
    component: ManufacturerReturnInvoice,
  },
  {
    path: "/manufacturerinv/:return_id",
    component: ManufacturerReturnInvoiceDetails,
  },
  { path: "/addsupplier", component: AddSupplier },
  { path: "/managesupplier", component: ManageSupplier },
  { path: "/supplierledger", component: SupplierLedgerReport },
  { path: "/banktransactions", component: BankTransaction },
  { path: "/editbank/:bank_id", component: EditBank },
  { path: "/banks", component: ManageBank },
  { path: "/addservice", component: AddService },
  { path: "/manageservice", component: ManageService },
  { path: "/serviceinvoice", component: ServiceInvoice },
  { path: "/manageserviceinvoice", component: ManageServiceInvoice },
  { path: "/manageusers", component: ManageUsers },
  { path: "/editrole/:id/:role_name", component: AddRole },
  { path: "/addrole", component: AddRole },
  { path: "/rolelist", component: RoleList },
  { path: "/assignuserrole", component: AssignUserRole },
  { path: "/addmanufacturer", component: AddManufacturer },
  { path: "/invoicehtml/:purchase_id", component: InvoiceHtml },
  { path: "/editsupplier/:supplier_id", component: EditSupplier },
  { path: "/sledgerinfo/:supplier_id", component: SupplierLedgerInfo },
  { path: "/page", component: MyPagination },
  { path: "/out_of_stock", component: OutOfStock },
  { path: "/expired_drugs", component: ExpiryListReport },
  { path: "/adddesignation", component: AddDesignation },
  { path: "/managedesignation", component: ManageDesignation },
  { path: "/addemployee", component: AddEmployee },
  { path: "/edit_user_profile", component: EditUserProfile },
  { path: "/manageemployee", component: ManageEmployee },
  { path: "/addattendance", component: AddAttendance },
  { path: "/manageattendance", component: ManageAttendance },
  { path: "/attendancereport", component: AttendanceReport },
  { path: "/customer_ledger/:customer_id", component: CustomerLedger },
  { path: "/addbenefits", component: AddBenefits },
  { path: "/managebenefits", component: ManageBenefits },
  { path: "/editbenefit/:benefit_id", component: EditBenefit },
  { path: "/addsalarysetup", component: AddSalarySetUp },
  { path: "/managesalarysetup", component: ManageSalarySetUp },
  { path: "/editsetup/:salary_id", component: EditSalarySetUp },
  { path: "/salarygenerate", component: SalaryGenerate },
  { path: "/managesalarygenerate", component: ManageSalaryGenerate },
  { path: "/salarypayment", component: SalaryPayment },
  { path: "/addexpenseitem", component: AddExpenseItem },
  { path: "/manageexpenseitem", component: ManageExpenseItem },
  { path: "/addexpense", component: AddExpense },
  { path: "/manageexpense", component: ManageExpense },
  { path: "/expensestatement", component: ExpenseStatement },
  { path: "/addfixedassets", component: AddFixedAssets },
  { path: "/fixedassetslist", component: FixedAssetsList },
  { path: "/editfixedasset/:asset_id", component: EditFixedAssets },
  { path: "/fixedassetspurchaselist", component: FixedAssetsPurchaseList },
  { path: "/purchasefixedassets", component: PurchaseFixedAssets },
  {
    path: "/editpurchaseasset/:purchase_id",
    component: EditPurchaseFixedAsset,
  },
  { path: "/addperson", component: AddPerson },
  { path: "/addloan", component: AddLoan },
  { path: "/addpayment", component: AddPayment },
  { path: "/manageperson", component: ManagePerson },
  { path: "/editperson/:person_id", component: EditPerson },
  { path: "/personloandetails/:person_id", component: PersonLoanDetails },
  { path: "/addpersonoffice", component: AddOfficePerson },
  { path: "/managepersonoffice", component: ManageOfficePerson },
  { path: "/personledger/:person_id", component: PersonalLoanLedger },
  { path: "/editpersonloan/:person_id", component: EditPersonLoan },
  { path: "/editattendance", component: EditAttendance },
  { path: "/datewiseattendance", component: DateWiseAttendanceReport },
  { path: "/employeewiseattendance", component: EmployeeWiseReport },
  { path: "/timewisereport", component: TimeWiseAttendanceReport },
  { path: "/editdesignation/:designation_id", component: EditDesignation },
  { path: "/editemployee/:employee_id", component: EditEmployee },
  { path: "/employeedetails/:employee_id", component: EmployeeDetails },
  { path: "/edituser/:user_id", component: EditUser },
  { path: "/support", component: SupportPage },
  { path: "/taxsettings", component: TaxSettings },
  { path: "/addincometax", component: AddIncomeTax },
  { path: "/manageincometax", component: ManageIncomeTax },
  { path: "/taxreport", component: TaxReport },
  { path: "/invoicewisetaxreport", component: InvoiceWiseTaxReport },
  { path: "/subscriptionexpired", component: SubscriptionExpired },
  { path: "/changepassword", component: ChangePassword },
  { path: "/producttrends", component: ProductTrends },
  { path: "/expenses", component: Expenses },
  { path: "/companydetails", component: CompanyDetails },
  { path: "/manualbackup", component: ManualBackup },
  // { path: "/manualjacob", component: ManualJacob },
  { component: PageNotFound },
];
