import React, { useRef } from "react";
import { CustomPurchaseSalesHeader } from "./ReusableSalesAndPurchaseHeader";
import {
  CustomPagination,
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPagination,
  MuiPaper,
} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { titleCase, dateStringConvert } from "../../Utils/titleCaseFunction";
import { useMuiPagination } from "../../Containers/Dasboard/DashboardTables";
import { useMedicineWise } from "./CustomHooks/useMedicineWise";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import { history } from "../../Helpers/history";
import { BackButton } from "../../Utils/FormInputs/BackButton";
import { Container } from "../../Utils/styledComponents";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import { connect } from "react-redux";
import {
  useExcelReports,
  usePdfReports,
} from "../Invoice/CustomHooks/useExcelReports";

const headData = [
  {
    id: "salesdate",
    numeric: false,
    disablePadding: false,
    label: "Sales Date",
  },
  {
    id: "medicinename",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },
  {
    id: "medicinetype",
    numeric: false,
    disablePadding: false,
    label: "Product Type",
  },
  { id: "quantity", numeric: false, disablePadding: false, label: "Quantity" },
  {
    id: "salesprice",
    numeric: false,
    disablePadding: false,
    label: "Sale Price",
  },
  {
    id: "totalamount",
    numeric: false,
    disablePadding: false,
    label: "Total Amount",
  },
];

const AdvancedSalesMedicineWiseReport = (props) => {
  const {
    loading,
    sales,
    activePage,
    handleChangeActivePage,
    totalCount,
    handleFilter,
    from_date,
    to_date,
    handleChangeDate,
    submitted,
    isSubmitted,
  } = useMedicineWise();
  const { actions } = props;
  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useMuiPagination();
  const csvRef = useRef();
  const headers = [
    { key: "sales_date", label: "Date" },
    { key: "product_name", label: "Medicine" },
    { key: "product_model", label: "Medicine Type" },
    { key: "quantity", label: "Quantity" },
    { key: "rate", label: "Unit Price" },
    { key: "total_price", label: "Amount" },
  ];

  const currentObj = {
    file_name: "Sales Report Medicinewise",
    url: "admin/sales_batchwise_excel_report",
    params: { start_date: from_date, end_date: to_date },
  };
  const objPDF = {
    file_name: "Sales Report Medicinewise",
    url: "admin/sales_batchwise_pdf_report",
    params: { start_date: from_date, end_date: to_date },
  };

  const { exportExcel } = useExcelReports(actions, currentObj);
  const { exportPDF } = usePdfReports(actions, objPDF);

  return (
    <div className="journals">
      <ReusableDashboardHeader
        component="Sales Report(Medicine Wise)"
        heading="Reports"
        subHeading="Sales Report(Medicine Wise)"
        link={history.location.pathname}
      />

      <Container>
        <div className="mb-2">
          <BackButton text="Reports" to="/allreports" />
        </div>
        <CustomPurchaseSalesHeader
          to_date={to_date}
          from_date={from_date}
          submitted={submitted}
          isSubmitted={isSubmitted}
          handleChangeDate={handleChangeDate}
          handleFilter={handleFilter}
          exportExcel={exportExcel}
          exportPDF={exportPDF}
          csvRef={csvRef}
          csvData={sales}
          headers={headers}
          fetchCsvData={() => csvRef.current.link.click()}
          csvTitle="Sales Report(Medicine Wise)"
        />

        <MuiPaper>
          <EnhancedTableToolbar title="" />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead headCells={headData} />
              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="6" align="center">
                      <img
                        src="/images/listPreloader.gif"
                        alt="loader"
                        style={{ width: "5%" }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : sales.length > 0 ? (
                <TableBody>
                  {sales
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((d, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          className="mui-table-row"
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="default"
                            className="mui-table-cell"
                          >
                            <span>{dateStringConvert(d.date)}</span>
                          </TableCell>
                          <TableCell>
                            <span>{titleCase(d.product_name)}</span>
                          </TableCell>
                          <TableCell>
                            <span>{d.product_model}</span>
                          </TableCell>
                          <TableCell>
                            <span>{d.quantity}</span>
                          </TableCell>
                          <TableCell>
                            <span>{d.rate}</span>
                          </TableCell>
                          <TableCell>
                            <span>{d.total_price}</span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="6" align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {isSubmitted === "resolved" ? (
            <MuiPagination
              data={sales}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              rows={[10, 20, 30, 40, 50]}
              colSpan={6}
            />
          ) : (
            <CustomPagination
              data={sales}
              handleChangeNextPage={handleChangeActivePage}
              activePage={activePage}
              total_count={totalCount}
              records={10}
            />
          )}
        </MuiPaper>
      </Container>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSalesMedicineWiseReport);
