import React, {useEffect} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  StickyHeadTable,
  CustomTableCell,
} from "../../../Utils/Tables/StickyHeaderTable";
import { LoadingGif } from "../../../Utils/Loader";
import { formatAmount } from "../../../Utils/formatNumbers";
import { formatDate } from "../../../Utils/ConvertDate";
import { details } from "../../../Utils/RoleDetails";
import { MuiPagination } from "../../../Utils/Tables/CustomTable";
import { CustomPagination } from "../../../Utils/Tables/CustomTable";
import { useState } from "react";
import CustomDialog from "../../../Utils/Dialog/CustomDialog";
import { SpecificJournalTemplate } from "./SpecificJournal";
import { usePrint } from "../../../Utils/Template/usePrint";
import axios from "axios";
import config from "../../../Helpers/config.json";

const style = {
  border: {
    borderTop: "1px solid #606060",
    borderBottom: "1px solid #606060",
  },
  image: {
    width: 130,
    border: "1px solid #e4e4e4",
    borderRadius: "60px",
    background: "#e4e4e4",
  },
  p: {
    margin: 0,
  },
  parent: {
    textAlign: "center",
    marginTop: "5em",
  },
};

const headData = [
  { id: "date", label: "Date", minWidth: 200 },
  { id: "description", label: "Description", minWidth: 200 },
  { id: "credit", label: "Credit", minWidth: 170 },
  { id: "debit", label: "Debit", minWidth: 170 },
  { id: "balance", label: "Balance", minWidth: 170 },
  { id: "journal_no", label: "Journal ID.", minWidth: 200 },
];

export const ReusableJournalTable = ({
  isLoading,
  isSuccess,
  isError,
  isResolved,
  id,
  activePage,
  total_count,
  handleChangePage,
  data = [],
}) => {
  const emptyRows = 10 - Math.min(10, data.length);

  const [openDialog, setOpenDialog] = useState(false);

  const [loading, setLoading] = useState('idle');

  const [selectedJournal, setSelectedJournal] = useState({});
  const [selectedJournalID, setSelectedJournalID] = useState("");

  const { handlePrint, componentRef } = usePrint();

  useEffect(()=>{

    if (selectedJournalID){
        setLoading('pending');
        axios.get(`${config.epharmUrl}/accounting/journal_id?journalid=${selectedJournalID}`)
        .then(res => {
            const data = res.data ? res.data:{};
            const dt = data.data[0] ? data.data[0]:{}
            const bal = dt.openingbalance ? dt.openingbalance : ''
            const count = dt.total_count? dt.total_count:''
            const arr = dt.details ? dt.details : [];
            const list = arr.map(item=>({
                account:item.accountname,
                credit:item.credit,
                date:item.date_added,
                debit:item.debit,
                details:item.description,
                journalId:item.journalID,
            }))
            // console.log(dt)
            setSelectedJournal(dt)
            // setBalance(bal)
            // setTotalCount(count)
            setLoading('success')
        }).catch(err => {
            setLoading('error')
        })
    }
},[selectedJournalID])

  const handleOpen = (event, journalID) => {
    event.preventDefault();

    setSelectedJournalID(journalID);

    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <CustomDialog
        open={openDialog}
        handleClose={handleClose}
        title="Specific Journal Report"
        maxWidth="sm"
        isOverflow = {false}
      >
        <div className="col-lg-6">
          <div className="text-right" style={{ marginLeft: "30rem" }}>
            <button
              onClick={handlePrint}
              className="btn pharmacy-info-btn mb-2 mr-2"
            >
              Print
            </button>
          </div>
        </div>
        <div ref={componentRef}>
          <SpecificJournalTemplate selectedJournalID={selectedJournalID} />
        </div>
      </CustomDialog>

      <StickyHeadTable
        {...{
          data,
          columns: headData.filter((item) => Boolean(item)),
          width: "100%",
        }}
      >
        {isLoading ? (
          <TableRow>
            <TableCell align="center" colSpan={headData.length}>
              <LoadingGif />
            </TableCell>
          </TableRow>
        ) : null}
        {isSuccess ? (
          data.length > 0 ? (
            <>
              {data.map((row, index) => {
                return (
                  <TableRow tabIndex={-1} key={index}>
                    <CustomTableCell data-testid={"transaction_time-" + index}>
                      {formatDate(row.date)}
                    </CustomTableCell>
                    <CustomTableCell data-testid={"description-" + index}>
                      {row.details}
                    </CustomTableCell>
                    <CustomTableCell data-testid={"credit-" + index}>
                      {row.credit}
                    </CustomTableCell>
                    <CustomTableCell data-testid={"debit-" + index}>
                      {row.debit}
                    </CustomTableCell>
                    <CustomTableCell data-testid={"balance-" + index}>
                      {row.balance}
                    </CustomTableCell>
                    <CustomTableCell
                      data-testid={"journal_no-" + index}
                      // onClick={(event) => handleOpen(event, row.journalId)}
                      //  style={{ cursor: 'pointer' }}
                    >
                      {/* {row.journalId} */}
                      <a href="#" onClick={(event) => handleOpen(event, row.journalId)}>
                        {row.journalId}
                      </a>
                    </CustomTableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 43 * emptyRows }}>
                  <TableCell colSpan={headData.length} />
                </TableRow>
              )}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={headData.length}>
                No records found
              </TableCell>
            </TableRow>
          )
        ) : null}

        {isError ? (
          <TableRow>
            <TableCell align="center" colSpan={headData.length}>
              The server did not return a valid response
            </TableCell>
          </TableRow>
        ) : null}

        <CustomPagination
          data={data}
          records={10}
          activePage={activePage}
          total_count={total_count}
          handleChangeNextPage={handleChangePage}
        />
      </StickyHeadTable>
    </div>
  );
};

export const NoTransaction = ({ text }) => {
  return (
    <div style={style.parent}>
      <img
        src="/images/transaction.png"
        alt="transaction"
        style={style.image}
      />
      <p
        className="text-center"
        style={style.p}
      >{`There are no ${text} available`}</p>
    </div>
  );
};
