import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import { CreatableCustomSelect } from "../../Utils/FormInputs/SelectInput";
import { titleCase } from "../../Utils/titleCaseFunction";
import {
  AutoCompleteControlled,
  SelectItem,
} from "../../Utils/FormInputs/AutoCompleteInput";
import Textarea from "../../Utils/FormInputs/Textarea";
import React from "react";

function AddProductForm({
  state,
  unit,
  type_name,
  category_id,
  submitted,
  isSubmitted,
  children,
  isLoading,
  options,
  price,
  formRef,
  handleChangeFields,
  handleChangeCategory,
  handleChangeExpiryLimit,
  handleChangeType,
  handleChangeUnit,
  handleInputChange,
  handleChange,
  handleSaveMedicine,
  categoryList,
  unitList,
  typeList,
}) {
  const {
    product_name,
    strength,
    generic_name,
    box_size,
    expire_limit,
    max_stock,
    product_location,
    description,
    actual_price,
    order_level,
    price_type,
    expiry_days,
  } = state;


  const allList = !unitList ? [] : unitList;
  const allCategory = !categoryList ? [] : categoryList;
  const allType = !typeList ? [] : typeList;

 
  return (
    <form
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
      autoComplete="off"
      ref={formRef}
    >
      <div className="row">
        <div className="col-md-6">
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label type name="Product Name" htmlFor="medicineName" />
            </Col>
            <Col sm={8}>
              <TextField
                data-testid="product_name"
                submitted={submitted}
                type="text"
                placeholder="Product name"
                name="product_name"
                id="medicineName"
                value={product_name}
                onChange={handleChangeFields}
              />
              {submitted && !product_name && (
                <div className="invalid-text">Product name is required</div>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label name="Generic Name" htmlFor="genericName" />
            </Col>

            <Col sm={8}>
              <TextField
                data-testid="generic_name"
                type="text"
                placeholder="Generic name"
                name="generic_name"
                value={generic_name}
                id="genericName"
                onChange={handleChangeFields}
              />
              {/*{(submitted && !generic_name) &&*/}
              {/*    <div className="invalid-text">Generic name is required</div>}*/}
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label htmlFor="strength" name="Strength" />
            </Col>

            <Col sm={8}>
              <TextField
                data-testid="product_strength"
                type="text"
                placeholder="Strength"
                name="strength"
                id="strength"
                value={strength}
                onChange={handleChangeFields}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label htmlFor="category" name="Category" type />
            </Col>

            <Col sm={8}>
              <AutoCompleteControlled
                testId="product_category"
                options={allCategory}
                submitted={submitted}
                handleChange={handleChangeCategory}
                medicine_value={category_id}
                label_text={"category_name"}
                value_text={"category_id"}
                id={"category"}
                placeholder="Enter Category"
                errorMsg="Category is required"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label name="Details" htmlFor="details" />
            </Col>
            <Col sm={8}>
              <Textarea
                data-testid="product_details"
                name="description"
                value={description}
                id="details"
                onChange={handleChangeFields}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label name="Price Type" type htmlFor="priceType" />
            </Col>

            <Col sm={8}>
              <SelectItem
                testId="price_type"
                submitted={submitted}
                onChange={handleChangeFields}
                value={price_type}
                id="priceType"
                name="price_type"
                options={[
                  { value: "", text: "Select price type" },
                  { value: "1", text: "Mark-up price" },
                  { value: "0", text: "Actual price" },
                ]}
                errorMsg="Price type is required"
                placeholder="Select Price Type..."
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label htmlFor="expiry_days" name="Days To Expiry" />
            </Col>

            <Col sm={8}>
              <TextField
                data-testid="expiry_days"
                type="number"
                placeholder=""
                name="expiry_days"
                id="expiry_days"
                value={expiry_days}
                min="0"
                onChange={handleChangeFields}
                defaultValue={10}
              />
            </Col>
          </Form.Group>
          {price_type === "0" ? (
            <Form.Group as={Row}>
              <Col sm={3}>
                <Label name="Actual Price" htmlFor="actualPrice" type />
              </Col>
              <Col sm={8}>
                <TextField
                  data-testid="actual_price"
                  submitted={submitted}
                  type="number"
                  placeholder="Enter actual price"
                  id="actualPrice"
                  onChange={handleChangeFields}
                  value={actual_price}
                  name="actual_price"
                />
                {submitted && !actual_price && (
                  <div className="invalid-text">Actual price is required</div>
                )}
              </Col>
            </Form.Group>
          ) : price_type === "1" ? (
            <Form.Group as={Row}>
              <Col sm={3}>
                <Label name="Mark-up price(%)" type htmlFor="markupPrice" />
              </Col>

              <Col sm={8}>
                <CreatableCustomSelect
                  id="markupPrice"
                  isClearable
                  isLoading={isLoading}
                  onChange={handleChange}
                  onCreateOption={handleInputChange}
                  options={options}
                  value={price}
                  submitted={submitted}
                />
                {submitted && !price && (
                  <div className="invalid-text">Mark-up price is required</div>
                )}
              </Col>
            </Form.Group>
          ) : (
            <div />
          )}
        </div>
        <div className="col-md-6">
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label htmlFor="unit" name="Unit" />
            </Col>

            <Col sm={9}>
              <AutoCompleteControlled
                testId="product_unit"
                options={allList}
                handleChange={handleChangeUnit}
                medicine_value={unit}
                label_text={"unit_name"}
                value_text={"unit_name"}
                id={"unit"}
                placeholder="select unit"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label name="Product Type" htmlFor="medicineType" />
            </Col>

            <Col sm={9}>
              <AutoCompleteControlled
                testId="product_type"
                options={allType}
                handleChange={handleChangeType}
                medicine_value={type_name}
                label_text={"type_name"}
                value_text={"type_name"}
                id={"medicineType"}
                placeholder="select product type"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label htmlFor="boxSize" name="Box Size" />
            </Col>

            <Col sm={9}>
              <TextField
                data-testid="box_size"
                type="text"
                placeholder="Box size"
                name="box_size"
                id="boxSize"
                value={titleCase(box_size)}
                onChange={handleChangeFields}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label htmlFor="medicineShelf" name="Product Shelf" />
            </Col>
            <Col sm={9}>
              <TextField
                data-testid="product_location"
                type="text"
                placeholder="Product shelf"
                name="product_location"
                value={titleCase(product_location)}
                id="medicineShelf"
                onChange={handleChangeFields}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label htmlFor="reorderLevel" name="Reorder Level" />
            </Col>

            <Col sm={9}>
              <TextField
                data-testid="reorder_level"
                type="number"
                placeholder="Reorder level"
                name="order_level"
                id="reorderLevel"
                value={order_level}
                min="0"
                onChange={handleChangeFields}
                defaultValue={0}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label htmlFor="expiryLimit" name="Expiry Limit" />
            </Col>

            <Col sm={7}>
              <TextField
                data-testid="expiry_limit"
                type="number"
                name="expire_limit"
                value={expire_limit}
                min="0"
                id="expiryLimit"
                onChange={handleChangeFields}
              />
            </Col>
            <Col sm={2}>
              <Label column name="In Days" htmlFor="inDays" />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Label name="Maximum Stock" htmlForm="maximumStock" />
            </Col>
            <Col sm={9}>
              <TextField
                data-testid="max_stock"
                type="number"
                name="max_stock"
                id="maximumStock"
                value={max_stock}
                min="0"
                onChange={handleChangeFields}
                defaultValue={0}
              />
            </Col>
          </Form.Group>
        </div>
      </div>
      {children}
    </form>
  );
}
export { AddProductForm };
