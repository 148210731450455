import React from "react";
import Form from "react-bootstrap/Form";

const TextField = ({
  type,
  value,
  name,
  onChange,
  submitted = false,
  disabled,
  required,
  invalidInputMessage,
  ...rest
}) => {
  const isSubmitted = submitted && !value;

  return (
    <>
      <Form.Control
        style={{ border: isSubmitted ? "1px solid red" : "", fontSize: "13px" }}
        type={type}
        value={value}
        name={name}
        onChange={(e) => onChange(e)}
        className="form__control"
        disabled={disabled}
        required={required ?? false}
        {...rest}
      />
      {/*
        <Form.Control.Feedback>
        {validInputMessage}
        <Form.Control.Feedback>
        */}
      <Form.Control.Feedback type="invalid">
        {invalidInputMessage}
      </Form.Control.Feedback>
    </>
  );
};
export default TextField;
