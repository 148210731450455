/** @jsx jsx */ //please do not remove this implementation because the css will not be loaded without it
import { jsx } from "@emotion/react";
import React from "react";
import {
  ItemsTable,
  ReceiptTemplate,
} from "../../../Utils/Template/ReceiptTemplate";
import { formatAmount } from "../../../Utils/formatNumbers";
import { tableCell } from "../../../styles/tableStyles";

const headData = [
  { value: "Item" },
  { value: "Batch Number" },
  { value: "Quantity" },
  { value: "Rate" },
  { value: "Amount" },
];

const PayablesDetailsPrint = ({ products, purchaseInfo }) => {
  const {
    grand_total,
    paid_amount,
    balance,
    purchase_date,
    invoice_number,
    supplier,
  } = purchaseInfo;
  const customerDetails = (
    <tbody>
      <tr>
        <td>
          <span>
            Purchase Date: <strong>{purchase_date}</strong>
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            Invoice#: <strong>{invoice_number}</strong>
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            Supplier: <strong>{supplier}</strong>
          </span>
        </td>
      </tr>
    </tbody>
  );

  const itemDetails = (
    <ItemsTable headData={headData}>
      <tbody>
        {products.map((info, index) => {
          const qty = info.quantity ? info.quantity : 0;
          const rate = info.rate ? info.rate : 0;
          return (
            <tr key={index}>
              <td>
                <span>{info.product_name}</span>
              </td>
              <td>
                <span>{info.batch_id}</span>
              </td>
              <td>
                <span>{qty}</span>
              </td>
              <td>
                <span>{rate}</span>
              </td>
              <td>
                <span>{formatAmount(info.total_amount)}</span>
              </td>
            </tr>
          );
        })}

        <tr>
          <td css={[tableCell]} colSpan="4" align="right">
            <span>Total Amount</span>
          </td>
          <td css={[tableCell]}>
            <span>
              <strong>{formatAmount(grand_total)}</strong>
            </span>
          </td>
        </tr>
        <tr>
          <td colSpan="4" align="right">
            <span>Payment Made</span>
          </td>
          <td>
            <span>
              <strong>{formatAmount(paid_amount)}</strong>
            </span>
          </td>
        </tr>
        <tr>
          <td colSpan="4" align="right">
            <span>Amount Due</span>
          </td>
          <td>
            <span>
              <strong>{balance ? formatAmount(balance) : 0}</strong>
            </span>
          </td>
        </tr>
      </tbody>
    </ItemsTable>
  );

  return <ReceiptTemplate {...{ customerDetails, itemDetails }} />;
};

export { PayablesDetailsPrint };
