import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faPrescription } from "@fortawesome/free-solid-svg-icons/faPrescription";

class DashboardHeader extends Component {
  render() {
    const {
      dashboard,
      debitNote,
      invoice,
      newInvoice,
      manageInvoice,
      invoiceDetails,
      posDetails,
      insertedInv,
      editInvoice,
      posInvoice,
      guiPos,
      todaysReport,
      customer,
      addCustomer,
      manageCustomer,
      creditCustomer,
      paidCustomer,
      customers,
      customerDetails,
      customerLedger,
      medicine,
      addCategory,
      category,
      addMedicine,
      addUnit,
      editCategory,
      medicineEdit,
      editType,
      editUnit,
      importCsv,
      manageMedicine,
      manageCategory,
      medicineDetails,
      manageType,
      unitList,
      manufacturer,
      addManufacturer,
      editManufacturer,
      manageManufacturer,
      purchase,
      purchaseDetails,
      ledgerInformation,
      ledgerReport,
      manufacturerDetails,
      manufacturerSales,
      addPurchase,
      editPurchase,
      purchaseInvoice,
      managePurchase,
      stock,
      outOfdate,
      outOfstock,
      stockbatch,
      stockReport,
      supplier,
      addSupplier,
      editSupplier,
      manageSupplier,
      supplierLedger,
      reports,
      todaysReporting,
      salesReport,
      purchaseReport,
      salesMedicine,
      plManufacturer,
      plMedicine,
      invoiceReturn,
      returnForm,
      stockReturn,
      manufReturn,
      wastageReturn,
      customerInvoice,
      manufacturerReturn,
      manufactReturn,
      bank,
      newBank,
      bankTransaction,
      manageBank,
      service,
      addService,
      manageService,
      manageServiceInvoice,
      serviceInvoice,
      hrm,
      addDesignation,
      editDesignation,
      addEmployee,
      editEmployee,
      manageEmployee,
      manageDesignation,
      employeeDetails,
      attendance,
      dateWise,
      timeWise,
      employeeWise,
      addAttendance,
      manageAttendance,
      attendanceReport,
      editAttendance,
      expense,
      addExpense,
      addExpenseItem,
      expenseStatement,
      manageExpense,
      manageExpenseItem,
      payroll,
      addBenefit,
      addSetup,
      editBenefit,
      editSetup,
      manageBenefits,
      manageGenerate,
      manageSetup,
      salaryGenerate,
      salaryPayment,
      fixedAsset,
      addAsset,
      editAsset,
      addPurchaseAsset,
      editPurchaseAsset,
      assetList,
      purchaseAssetList,
      personalLoan,
      addPerson,
      editPerson,
      addPayment,
      addLoan,
      managePerson,
      officePerson,
      loanDetails,
      officeLoan,
      personLedger,
      stockAdjustment,
      manageOfficePerson,
      addOfficePerson,
      editPersonLoan,
      damage,
      accounting,
      chartofAccounts,
      manufacturerPayment,
      customerReceive,
      supplierPayment,
      cashAdjustment,
      debitVoucher,
      creditVoucher,
      cashBook,
      bankBook,
      generalLedger,
      inventoryLedger,
      cashFlow,
      cashflowDetails,
      generalLedgerDetails,
      profitLoss,
      profitLossDetails,
      trialBalance,
      trialBalanceDetails,
      manufacturerAccountingDetails,
      supplierAccountingDetails,
      settings,
      addRole,
      addSettingsUser,
      assignRole,
      backUp,
      currency,
      importSettings,
      langauge,
      manageCompany,
      manageUsers,
      roleList,
      smsConfigure,
      webSettings,
      user,
      editUser,
      editSettingsUser,
      support,
      editRole,
      tax,
      taxSettings,
      add,
      incomeTax,
      manage,
      taxIncome,
      ta,
      taxReport,
      wise,
      invoiceTax,
      subscription,
      changePassword,
      addCompany,
      company,
      editCompany,
      addPurchaseOrder,
      managePurchaseOrder,
      editPurchaseOrder,
      purchaseOrderDetails,
      customerPurchase,
      manufacturerInvoiceDetails,
      stockReturnDetails,
      creditNote,
    } = this.props;
    const {
      invoice_id,
      customer_id,
      product_id,
      purchase_id,
      invoice_no,
      asset_id,
      person_id,
      company_id,
      local_purchase_order_id,
      manufacturer_id,
      supplier_id,
      designation_id,
      employee_id,
      benefit_id,
      salary_id,
      user_id,
    } = this.props.match.params;
    return (
      <div>
        <div className="content-header">
          <div className="row" style={{ padding: "15px" }}>
            <div className="col-md-6">
              <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                <div className="header-icon">
                  <FontAwesomeIcon icon={faPrescription} />
                </div>
              </Link>
              <h5 style={{ marginBottom: 0, marginTop: 2 }}>
                {dashboard
                  ? "Dashboard"
                  : debitNote
                  ? "Debit Note"
                  : creditNote
                  ? "Credit Notes"
                  : newInvoice
                  ? "New Invoice"
                  : manageInvoice
                  ? "Manage Invoice"
                  : invoiceDetails
                  ? "Invoice Details"
                  : editInvoice
                  ? "Invoice Edit"
                  : paidCustomer
                  ? "Customer"
                  : posInvoice
                  ? "New POS Invoice"
                  : guiPos
                  ? "New GUI POS Invoice"
                  : customers
                  ? "Customer Details"
                  : todaysReport
                  ? "Today's Invoice Report"
                  : category
                  ? "Category"
                  : addMedicine
                  ? "New Medicine"
                  : addUnit
                  ? "Add Unit"
                  : medicineEdit
                  ? "Medicine Edit"
                  : editType
                  ? "Medicine Type Edit"
                  : editUnit
                  ? "Unit Edit"
                  : addCustomer
                  ? "Add Customer"
                  : manageCustomer
                  ? "Customer"
                  : creditCustomer
                  ? "Credit Customer"
                  : customerPurchase
                  ? "Customer Purchases"
                  : importCsv
                  ? "Import Medicine(CSV)"
                  : manageMedicine
                  ? "Manage Medicine"
                  : medicineDetails
                  ? "Medicine Details"
                  : manageType
                  ? "Add Medicine Type"
                  : unitList
                  ? "Unit List"
                  : addManufacturer
                  ? "New Supplier"
                  : editManufacturer
                  ? "Supplier Edit"
                  : manageManufacturer
                  ? "Manage Supplier"
                  : manufacturerDetails
                  ? "Supplier Details"
                  : manufacturerSales
                  ? "Supplier Sales Details"
                  : addPurchase
                  ? "Add Purchase"
                  : purchaseDetails
                  ? "Purchase Details"
                  : ledgerReport
                  ? "Supplier Ledger"
                  : editPurchase
                  ? "Purchase Edit"
                  : managePurchase
                  ? "Manage Purchase"
                  : outOfdate
                  ? "Out Of Date"
                  : outOfstock
                  ? "Out Of Stock"
                  : stockbatch
                  ? "Stock Report(Batch Wise)"
                  : stockReport
                  ? "Stock Report"
                  : addSupplier
                  ? "New Suplier"
                  : editSupplier
                  ? "Supplier Edit"
                  : supplierLedger
                  ? "Supplier Ledger"
                  : manageSupplier
                  ? "Manage Supplier"
                  : todaysReporting
                  ? "Today's Report"
                  : salesReport
                  ? "Sales Report"
                  : purchaseReport
                  ? "Purchase Report"
                  : salesMedicine
                  ? "Sales Report(Medicine Wise)"
                  : stockAdjustment
                  ? "Stock Adjustment"
                  : plManufacturer
                  ? "Profit / Loss(Supplier)"
                  : plMedicine
                  ? "Profit /Loss(Medicine)"
                  : returnForm
                  ? "Return Form"
                  : stockReturn
                  ? "Stock Return List"
                  : manufReturn
                  ? "Supplier Return List"
                  : wastageReturn
                  ? "Wastage Return List"
                  : manageBank
                  ? "Manage Bank"
                  : customerInvoice
                  ? "Stock Return Invoice"
                  : manufacturerReturn
                  ? "Supplier Return Invoice"
                  : manufactReturn
                  ? "Supplier Return"
                  : damage
                  ? "Damage"
                  : newBank
                  ? "New Bank"
                  : bankTransaction
                  ? "Bank Transaction"
                  : purchase
                  ? "Purchase"
                  : addService
                  ? "Add Service"
                  : manageService
                  ? "Manage Service"
                  : addCompany
                  ? "Add Company"
                  : editCompany
                  ? "Edit Company"
                  : manageServiceInvoice
                  ? "Manage Service Invoice"
                  : serviceInvoice
                  ? "New Service Invoice"
                  : hrm
                  ? "HRM"
                  : attendance
                  ? "Attendance"
                  : manufacturerInvoiceDetails
                  ? "Supplier Return Receipt"
                  : stockReturnDetails
                  ? "Customer Return Receipt"
                  : expense
                  ? "Expense"
                  : payroll
                  ? "Payroll"
                  : fixedAsset
                  ? "Fixed Assets"
                  : changePassword
                  ? "Change Password"
                  : personalLoan
                  ? "Personal Loan"
                  : officeLoan
                  ? "Office Loan"
                  : support
                  ? "User Support"
                  : subscription
                  ? "Subscription"
                  : accounting
                  ? "Accounting"
                  : settings
                  ? "Settings"
                  : invoiceTax
                  ? "Tax"
                  : taxReport
                  ? "Tax"
                  : taxIncome
                  ? "Tax"
                  : incomeTax
                  ? "Tax"
                  : taxSettings
                  ? "Tax"
                  : ledgerInformation
                  ? "Manufacturer Ledger"
                  : "User"}
              </h5>
              <small>
                {dashboard
                  ? "Home"
                  : debitNote
                  ? "Debit Note"
                  : creditNote
                  ? "Credit Notes"
                  : newInvoice
                  ? "Add New Invoice"
                  : manageInvoice
                  ? "Manage Your Invoice"
                  : invoiceDetails
                  ? "Invoice Details"
                  : editInvoice
                  ? "Invoice Edit"
                  : paidCustomer
                  ? "Paid Customer"
                  : posInvoice
                  ? "Add new pos invoice"
                  : guiPos
                  ? "GUI POS Invoice"
                  : customers
                  ? "Manage customer details"
                  : todaysReport
                  ? "Today's Invoice Report"
                  : manageCustomer
                  ? "Manage Customer"
                  : customerPurchase
                  ? "CustomerPurchases"
                  : addCategory
                  ? "New medicine category"
                  : addCustomer
                  ? "Add New Customer"
                  : creditCustomer
                  ? "Manage your credit customer"
                  : manageMedicine
                  ? "Manage your medicine"
                  : addMedicine
                  ? "Add new medicine"
                  : addUnit
                  ? "Add unit"
                  : editCategory
                  ? "Category edit"
                  : medicineEdit
                  ? "Edit medicine"
                  : editType
                  ? "Edit type"
                  : editUnit
                  ? "Edit unit"
                  : importCsv
                  ? "Import medicine"
                  : manageCategory
                  ? "Manage category"
                  : medicineDetails
                  ? "Medicine details"
                  : manageType
                  ? "Add new medicine type"
                  : unitList
                  ? "Unit list"
                  : addManufacturer
                  ? "Add new Supplier"
                  : editManufacturer
                  ? "Edit Supplier"
                  : editPurchase
                  ? "Edit purchase"
                  : manageManufacturer
                  ? "Manage your Supplier"
                  : purchaseDetails
                  ? "Purchase details"
                  : ledgerReport
                  ? "Supplier ledger"
                  : manufacturerDetails
                  ? "Supplier details"
                  : manufacturerSales
                  ? "Supplier sales details"
                  : addPurchase
                  ? "Add new purchase"
                  : managePurchase
                  ? "Manage your purchase"
                  : outOfdate
                  ? "Out of date"
                  : outOfstock
                  ? "Out of stock"
                  : stockbatch
                  ? "Stock report(batch wise)"
                  : stockReport
                  ? "Stock report"
                  : addSupplier
                  ? "Add new supplier"
                  : editSupplier
                  ? "Edit supplier"
                  : supplierLedger
                  ? "Supplier ledger"
                  : manageSupplier
                  ? "Manage your suppliers"
                  : todaysReporting
                  ? "Today's sales and purchase report"
                  : salesReport
                  ? "Total sales report"
                  : purchaseReport
                  ? "Total purchase report"
                  : salesMedicine
                  ? "Sales report(Medicine wise)"
                  : stockAdjustment
                  ? "Stock adjustment"
                  : plManufacturer
                  ? "Profit / Loss(Supplier)"
                  : plMedicine
                  ? "Profit / Loss(Medicine)"
                  : returnForm
                  ? "Return form"
                  : stockReturn
                  ? "Stock return list"
                  : manufReturn
                  ? "Supplier Return List"
                  : wastageReturn
                  ? "Wastage return list"
                  : damage
                  ? "Damages"
                  : customerInvoice
                  ? "Stock return invoice"
                  : manufacturerReturn
                  ? "Supplier return invoice"
                  : manufactReturn
                  ? "Supplier return"
                  : manufacturerInvoiceDetails
                  ? "Supplier return receipt"
                  : stockReturnDetails
                  ? "Customer return receipt"
                  : newBank
                  ? "Add new bank"
                  : bankTransaction
                  ? "Bank transaction"
                  : manageBank
                  ? "Manage bank"
                  : addService
                  ? "Add service"
                  : manageService
                  ? "Manage service"
                  : addCompany
                  ? "Add new company"
                  : manageServiceInvoice
                  ? "Manage service invoice"
                  : serviceInvoice
                  ? "New service invoice"
                  : addDesignation
                  ? "Add designation"
                  : editCompany
                  ? "Edit company"
                  : editDesignation
                  ? "Edit designation"
                  : manageDesignation
                  ? "Manage designation"
                  : addEmployee
                  ? "Add employee"
                  : editEmployee
                  ? "Edit employee"
                  : manageEmployee
                  ? "Manage employee"
                  : employeeDetails
                  ? "Employee details"
                  : addAttendance
                  ? "Add attendance"
                  : manageAttendance
                  ? "Manage attendance"
                  : attendanceReport
                  ? "Attendance report"
                  : editAttendance
                  ? "Edit attendance"
                  : dateWise
                  ? "Date wise attendance"
                  : employeeWise
                  ? "Employee wise attendance"
                  : timeWise
                  ? "Time wise attendance"
                  : addExpense
                  ? "Add expense"
                  : changePassword
                  ? "Update Password"
                  : addExpenseItem
                  ? "Add expense item"
                  : expenseStatement
                  ? "Expense statement"
                  : manageExpense
                  ? "Manage expense"
                  : manageExpenseItem
                  ? "Manage expense item"
                  : addBenefit
                  ? "Add Benefit"
                  : addSetup
                  ? "Add Salary SetUp"
                  : editBenefit
                  ? "Edit Benefit"
                  : editSetup
                  ? "Edit Salary SetUp"
                  : manageBenefits
                  ? "Manage Benefits"
                  : manageGenerate
                  ? "Manage Generate Salary"
                  : manageSetup
                  ? "Manage Salary SetUp"
                  : salaryGenerate
                  ? "Generate Salary"
                  : salaryPayment
                  ? "Salary Payment"
                  : addAsset
                  ? "Add Fixed Asset"
                  : editAsset
                  ? "Edit Fixed Asset"
                  : editPurchaseAsset
                  ? "Edit Purchase"
                  : addPurchaseAsset
                  ? "Purchase Fixed Asset"
                  : assetList
                  ? "Fixed Asset List"
                  : purchaseAssetList
                  ? "Purchase List"
                  : addLoan
                  ? "Add Loan"
                  : addPayment
                  ? "Add Payment"
                  : addPerson
                  ? "Add Person"
                  : editPerson
                  ? "Edit Person"
                  : managePerson
                  ? "Manage Person"
                  : loanDetails
                  ? "Loan Details"
                  : personLedger
                  ? "Loan Ledger"
                  : addOfficePerson
                  ? "Add person"
                  : editPersonLoan
                  ? "Edit Person"
                  : manageOfficePerson
                  ? "Manage Person"
                  : chartofAccounts
                  ? "Chart Of Accounts"
                  : manufacturerPayment
                  ? "Manufacturer Payment"
                  : supplierPayment
                  ? "Supplier Payment"
                  : customerReceive
                  ? "Customer Receivables"
                  : cashAdjustment
                  ? "Cash Adjustment"
                  : debitVoucher
                  ? "Debit Voucher"
                  : creditVoucher
                  ? "Credit Voucher"
                  : cashBook
                  ? "Cash Book"
                  : bankBook
                  ? "Bank Book"
                  : generalLedger
                  ? "General Ledger"
                  : inventoryLedger
                  ? "Inventory Ledger"
                  : cashFlow
                  ? "Cash Flow"
                  : cashflowDetails
                  ? "Cash Flow Details"
                  : generalLedgerDetails
                  ? "General Ledger Details"
                  : profitLoss
                  ? "Profit/Loss Statement"
                  : profitLossDetails
                  ? "Profit/Loss Details"
                  : trialBalance
                  ? "Trial Balance"
                  : trialBalanceDetails
                  ? "Trial Balance Details"
                  : editSettingsUser
                  ? "Edit User"
                  : manufacturerAccountingDetails
                  ? "Manufacturer Details"
                  : addPurchaseOrder
                  ? "Add Purchase Order"
                  : supplierAccountingDetails
                  ? "Supplier Details"
                  : addRole
                  ? "Add User Role"
                  : addSettingsUser
                  ? "Add user"
                  : assignRole
                  ? "Assign User Role"
                  : backUp
                  ? "Back Up "
                  : currency
                  ? "Currency"
                  : importSettings
                  ? "Import Settings"
                  : langauge
                  ? "Language"
                  : manageCompany
                  ? "Manage Company"
                  : manageUsers
                  ? "Manage Users"
                  : roleList
                  ? "Role List"
                  : smsConfigure
                  ? "SMS Configuration"
                  : webSettings
                  ? "Web Settings"
                  : support
                  ? "Support"
                  : editRole
                  ? " Edit Role"
                  : invoiceTax
                  ? "Invoice Wise Tax Report"
                  : taxReport
                  ? "Tax Report"
                  : taxIncome
                  ? "Manage Income Tax"
                  : incomeTax
                  ? "Add Income Tax"
                  : taxSettings
                  ? "Tax Settings"
                  : managePurchaseOrder
                  ? "Manage Purchase Order"
                  : editPurchaseOrder
                  ? "Edit Purchase Order"
                  : subscription
                  ? "Subscription Expired"
                  : ledgerInformation
                  ? "Manufacturer Ledger Information"
                  : purchaseOrderDetails
                  ? "Purchase Order Details"
                  : "Edit User"}
              </small>
            </div>
            <div className="col-md-6 mt-2">
              <div className="header-title">
                <ol className="breadcrumb">
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "inherit" }}
                    className="breadcrumb-item"
                  >
                    <li>
                      <FontAwesomeIcon icon={faHome} />
                      <span className="ml-1">Home</span>
                    </li>
                  </Link>
                  <li className="breadcrumb-item active">
                    <span>
                      {dashboard
                        ? "Dashboard"
                        : debitNote
                        ? "Debit Note"
                        : creditNote
                        ? "Credit Notes"
                        : invoice
                        ? "Invoice"
                        : customer
                        ? "Customer"
                        : medicine
                        ? "Medicine"
                        : manufacturer
                        ? "Supplier"
                        : purchase
                        ? "Purchase"
                        : stock
                        ? "Stock"
                        : supplier
                        ? "Supplier"
                        : reports
                        ? "Reports"
                        : invoiceReturn
                        ? "Return"
                        : bank
                        ? "Bank"
                        : service
                        ? "Service"
                        : hrm
                        ? "HRM"
                        : attendance
                        ? "Attendance"
                        : expense
                        ? "Expense"
                        : payroll
                        ? "Payroll"
                        : fixedAsset
                        ? "Fixed Assets"
                        : personalLoan
                        ? "Personal Loan"
                        : officeLoan
                        ? "Office Loan"
                        : accounting
                        ? "Accounting"
                        : settings
                        ? "Settings"
                        : company
                        ? "Company"
                        : support
                        ? "Suport"
                        : wise
                        ? "Tax"
                        : ta
                        ? "Tax"
                        : manage
                        ? "Tax"
                        : add
                        ? "Tax"
                        : tax
                        ? "Tax"
                        : subscription
                        ? "Subscription"
                        : changePassword
                        ? "Change Password"
                        : "User"}
                    </span>
                  </li>
                  {!dashboard && (
                    <li className="breadcrumb-item">
                      <NavLink
                        color="inherit"
                        className="dashboard-link"
                        activeClassName="active"
                        to={
                          newInvoice
                            ? "/createinvoice"
                            : debitNote
                            ? "/managedebitnote"
                            : creditNote
                            ? "/accounting/managecreditnote"
                            : manageInvoice
                            ? "/manageinvoice"
                            : stockAdjustment
                            ? "/stockadjustment"
                            : insertedInv
                            ? `/invoicedetails/${invoice_id}`
                            : posDetails
                            ? `/posdetails/${invoice_id}`
                            : editInvoice
                            ? `invoice_update_form/${invoice_id}`
                            : paidCustomer
                            ? "/paidcustomer"
                            : posInvoice
                            ? "/createposinvoice"
                            : guiPos
                            ? "/guipos"
                            : customerLedger
                            ? `/customer_ledger/${customer_id}`
                            : customerPurchase
                            ? `/customerpurchases/${customer_id}`
                            : todaysReport
                            ? "/todaysinvoice"
                            : addCustomer
                            ? "/addcustomer"
                            : customerDetails
                            ? `/customerdetails/${customer_id}`
                            : manageCustomer
                            ? "/managecustomer"
                            : creditCustomer
                            ? "/creditcustomer"
                            : addCategory
                            ? "/addcategory"
                            : medicineEdit
                            ? "/editmedicine"
                            : editType
                            ? "/editmedicinetype"
                            : editUnit
                            ? "/editunit"
                            : importCsv
                            ? "/medicinecsv"
                            : addMedicine
                            ? "/addmedicine"
                            : addUnit
                            ? "/addunit"
                            : editCategory
                            ? "/category_update"
                            : medicineDetails
                            ? `/medicinedetails/${product_id}`
                            : manageType
                            ? "/medicineType"
                            : unitList
                            ? "/unitlist"
                            : addManufacturer
                            ? "/addmanufacturer"
                            : manageMedicine
                            ? "/managemedicine"
                            : manageCategory
                            ? "/managecategory"
                            : editManufacturer
                            ? "/editmanufacturer"
                            : manageManufacturer
                            ? "/managemanufacturer"
                            : purchaseDetails
                            ? `/manufacturerinvoice/${purchase_id}`
                            : manufacturerDetails
                            ? `/manufdetails/${manufacturer_id}`
                            : ledgerReport
                            ? "/manufacturerledger"
                            : manufacturerSales
                            ? "/manufacturersales"
                            : addPurchase
                            ? "/addpurchase"
                            : editPurchase
                            ? "/editpurchase"
                            : outOfdate
                            ? "/expired_drugs"
                            : purchaseInvoice
                            ? `/invoicehtml/${purchase_id}`
                            : managePurchase
                            ? "/managepurchase"
                            : outOfstock
                            ? "/out_of_stock"
                            : stockbatch
                            ? "/stockbatchwise"
                            : stockReport
                            ? "/stockreport"
                            : addSupplier
                            ? "/addsupplier"
                            : editSupplier
                            ? `/editsupplier/${supplier_id}`
                            : supplierLedger
                            ? "/supplierledger"
                            : manageSupplier
                            ? "/managesupplier"
                            : todaysReporting
                            ? "/todaysreport"
                            : salesReport
                            ? "/salesreport"
                            : purchaseReport
                            ? "/purchasereport"
                            : salesMedicine
                            ? "/medicineWise"
                            : plManufacturer
                            ? "/manufwise"
                            : plMedicine
                            ? "/medwise"
                            : returnForm
                            ? "/return"
                            : stockReturn
                            ? "/stockreturnlist"
                            : manufReturn
                            ? "/manufreturnlist"
                            : wastageReturn
                            ? "/wastagereturnlist"
                            : damage
                            ? "/damages"
                            : customerInvoice
                            ? `/stockreturninvoice/${invoice_no}`
                            : manufacturerReturn
                            ? `/manufacturerreturninvoice/${purchase_id}`
                            : manufactReturn
                            ? `/manufacturerreturn/${manufacturer_id}`
                            : newBank
                            ? "/addnewbank"
                            : bankTransaction
                            ? "/banktransaction"
                            : manageBank
                            ? "/managebank"
                            : addService
                            ? "/addService"
                            : manageService
                            ? "/manageservice"
                            : manageServiceInvoice
                            ? "/manageserviceinvoice"
                            : serviceInvoice
                            ? "/serviceinvoice"
                            : addDesignation
                            ? "/adddesignation"
                            : editDesignation
                            ? `/editdesignation/${designation_id}`
                            : manageDesignation
                            ? "/managedesignation"
                            : addEmployee
                            ? "/addemployee"
                            : editEmployee
                            ? `/editemployee/${employee_id}`
                            : addAttendance
                            ? "/addattendance"
                            : manageAttendance
                            ? "/manageattendance"
                            : attendanceReport
                            ? "/attendancereport"
                            : editAttendance
                            ? "/editattendance"
                            : dateWise
                            ? "/datewiseattendance"
                            : employeeWise
                            ? "/employeewiseattendance"
                            : timeWise
                            ? "/timewisereport"
                            : addExpense
                            ? "/addexpense"
                            : addCompany
                            ? "/addcompany"
                            : editCompany
                            ? `/editcompany/${company_id}`
                            : addExpenseItem
                            ? "/addexpenseitem"
                            : expenseStatement
                            ? "/expensestatement"
                            : manageExpense
                            ? "/manageexpense"
                            : manageExpenseItem
                            ? "/manageexpenseitem"
                            : manageEmployee
                            ? "/manageemployee"
                            : employeeDetails
                            ? `/employeedetails/${employee_id}`
                            : addBenefit
                            ? "/addbenefits"
                            : addSetup
                            ? "/addsalarysetup"
                            : editBenefit
                            ? `/editbenefit/${benefit_id}`
                            : editSetup
                            ? `/editsetup/${salary_id}`
                            : manageBenefits
                            ? "/managebenefits"
                            : manageGenerate
                            ? "/managesalarygenerate"
                            : manageSetup
                            ? "/managesalarysetup"
                            : salaryGenerate
                            ? "/salarygenerate"
                            : salaryPayment
                            ? "/salarypayment"
                            : addAsset
                            ? "/addfixedassets"
                            : editAsset
                            ? `/editfixedasset/${asset_id}`
                            : editPurchaseAsset
                            ? `/editpurchaseasset/${purchase_id}`
                            : addPurchaseAsset
                            ? "/purchasefixedassets"
                            : assetList
                            ? "/fixedassetslist"
                            : purchaseAssetList
                            ? "/fixedassetspurchaselist"
                            : addLoan
                            ? "/addloan"
                            : addPayment
                            ? "/addpayment"
                            : addPerson
                            ? "/addperson"
                            : editPerson
                            ? `/editperson/${person_id}`
                            : managePerson
                            ? "/manageperson"
                            : loanDetails
                            ? `/personloandetails/${person_id}`
                            : addOfficePerson
                            ? "/addpersonoffice"
                            : editPersonLoan
                            ? `/editpersonloan/${person_id}`
                            : manageOfficePerson
                            ? "/managepersonoffice"
                            : personLedger
                            ? `/personledger/${person_id}`
                            : chartofAccounts
                            ? "/chartofaccounts"
                            : manufacturerPayment
                            ? "/manufacturerpayment"
                            : supplierPayment
                            ? "/supplierpayment"
                            : customerReceive
                            ? "/customerreceive"
                            : cashAdjustment
                            ? "/cashadjustment"
                            : debitVoucher
                            ? "/debitvoucher"
                            : creditVoucher
                            ? "/creditvoucher"
                            : cashBook
                            ? "/cashbook"
                            : bankBook
                            ? "/bankbook"
                            : generalLedger
                            ? "/generalledger"
                            : inventoryLedger
                            ? "/inventoryledger"
                            : cashFlow
                            ? "/cashflow"
                            : cashflowDetails
                            ? "/cashflowdetails"
                            : generalLedgerDetails
                            ? "/gledgerdetails"
                            : profitLoss
                            ? "/profitstatement"
                            : changePassword
                            ? "/changepassword"
                            : profitLossDetails
                            ? "/profitlossdetails"
                            : trialBalance
                            ? "/trialbalance"
                            : trialBalanceDetails
                            ? "/trialbalancedetails"
                            : manufacturerAccountingDetails
                            ? "/manufacturerdetails"
                            : supplierAccountingDetails
                            ? "/supplierdetails"
                            : editSettingsUser
                            ? `/edituser/${user_id}`
                            : addRole
                            ? "/addrole"
                            : addSettingsUser
                            ? "/adduser"
                            : assignRole
                            ? "/assignuserrole"
                            : backUp
                            ? "/backup"
                            : currency
                            ? "/currency"
                            : importSettings
                            ? "/import"
                            : langauge
                            ? "/language"
                            : manageCompany
                            ? "/managecompany"
                            : manageUsers
                            ? "/manageusers"
                            : roleList
                            ? "/rolelist"
                            : smsConfigure
                            ? "/smsconfigure"
                            : webSettings
                            ? "/websetting"
                            : support
                            ? "/support"
                            : invoiceTax
                            ? "/invoicewisetaxreport"
                            : taxReport
                            ? "/taxreport"
                            : taxIncome
                            ? "/manageincometax"
                            : incomeTax
                            ? "/addincometax"
                            : taxSettings
                            ? "/taxsettings"
                            : subscription
                            ? "/subscriptionexpired"
                            : addPurchaseOrder
                            ? "/addpurchaseorder"
                            : ledgerInformation
                            ? `/manufacturerledgerinfo/${manufacturer_id}`
                            : managePurchaseOrder
                            ? "/managepurchaseorder"
                            : editPurchaseOrder
                            ? "/editpurchaseorder"
                            : purchaseOrderDetails
                            ? `/purchaseorder/${local_purchase_order_id}`
                            : "/edit_user_profile"
                        }
                      >
                        {newInvoice
                          ? "New Invoice"
                          : debitNote
                          ? "Debit Note"
                          : creditNote
                          ? "Credit Note"
                          : manageInvoice
                          ? "Manage Invoice"
                          : addCategory
                          ? "Add Category"
                          : invoiceDetails
                          ? "Invoice Details"
                          : editInvoice
                          ? "Invoice Edit"
                          : importCsv
                          ? "Import Medicine(CSV)"
                          : posInvoice
                          ? "New POS Invoice"
                          : guiPos
                          ? "New GUI POS Invoice"
                          : manageMedicine
                          ? "Manage Medicine"
                          : todaysReport
                          ? "Today's Invoice Report"
                          : addCustomer
                          ? "Add Customer"
                          : manageCategory
                          ? "Manage Category"
                          : manageCustomer
                          ? "Manage Customer"
                          : creditCustomer
                          ? "Credit Customer"
                          : medicineDetails
                          ? "Medicine Details"
                          : paidCustomer
                          ? "Paid Customer"
                          : customers
                          ? "Customer Details"
                          : customerPurchase
                          ? "Customer Purchase"
                          : manageType
                          ? "Add Medicine Type"
                          : addMedicine
                          ? "New Medicine"
                          : addUnit
                          ? "Add Unit"
                          : editCategory
                          ? "Category Edit"
                          : medicineEdit
                          ? "Medicine Edit"
                          : editType
                          ? "Medicine Type Edit"
                          : editUnit
                          ? "Edit Unit"
                          : unitList
                          ? "Unit List"
                          : addManufacturer
                          ? "New Supplier"
                          : editManufacturer
                          ? "Supplier Edit"
                          : manageManufacturer
                          ? "Manage Suppliers"
                          : purchaseDetails
                          ? "Purchase Details"
                          : ledgerReport
                          ? "Supplier Ledger"
                          : manufacturerDetails
                          ? "Supplier Details"
                          : manufacturerSales
                          ? "Supplier sales Details"
                          : addPurchase
                          ? "Add Purchase"
                          : editPurchase
                          ? "Purchase Edit"
                          : managePurchase
                          ? "Manage Purchase"
                          : outOfdate
                          ? "Out Of Date"
                          : outOfstock
                          ? "Out Of Stock"
                          : stockbatch
                          ? "Stock Report (Batch Wise)"
                          : stockReport
                          ? "Stock Report"
                          : addSupplier
                          ? "New Supplier"
                          : editSupplier
                          ? "Supplier Edit"
                          : supplierLedger
                          ? "Supplier Ledger"
                          : manageSupplier
                          ? "Manage Supplier"
                          : todaysReporting
                          ? "Today's Report"
                          : salesReport
                          ? "Sales Report"
                          : purchaseReport
                          ? "Purchase Report"
                          : salesMedicine
                          ? "Sales Report(Medicine Wise)"
                          : plManufacturer
                          ? "Profit / Loss(Manufacturer)"
                          : plMedicine
                          ? "Profit /Loss(Medicine)"
                          : returnForm
                          ? "Return Form"
                          : stockReturn
                          ? "Stock Return List"
                          : manufReturn
                          ? "Manufacturer Return List"
                          : damage
                          ? "Damages"
                          : wastageReturn
                          ? "Wastage Return List"
                          : customerInvoice
                          ? "Stock Return Invoice"
                          : manufacturerReturn
                          ? "Supplier Return Invoice"
                          : stockAdjustment
                          ? "Stock adjustment"
                          : manufactReturn
                          ? "Supplier Return"
                          : newBank
                          ? "New Bank"
                          : addCompany
                          ? "Add Company"
                          : editCompany
                          ? "Edit Company"
                          : bankTransaction
                          ? "Bank Transaction"
                          : manageBank
                          ? "Manage Bank"
                          : addService
                          ? "Add Service"
                          : manageService
                          ? "Manage Service"
                          : manageServiceInvoice
                          ? "Manage Service Invoice"
                          : serviceInvoice
                          ? "New Service Invoice"
                          : addDesignation
                          ? "Add Designation"
                          : editDesignation
                          ? "Edit Designation"
                          : manageDesignation
                          ? "Manage Designation"
                          : addEmployee
                          ? "Add Employee"
                          : editEmployee
                          ? "Edit Employee"
                          : manageEmployee
                          ? "Manage Employee"
                          : employeeDetails
                          ? "Employee Details"
                          : addAttendance
                          ? "Add Attendance"
                          : manageAttendance
                          ? "Manage Attendance"
                          : attendanceReport
                          ? "Attendance Report"
                          : editAttendance
                          ? "Edit Attendance"
                          : dateWise
                          ? "Date Wise Attendance"
                          : employeeWise
                          ? "Employee Wise Attendance"
                          : timeWise
                          ? "Time Wise Attendance"
                          : addExpense
                          ? "Add Expense"
                          : addExpenseItem
                          ? "Add Expense Item"
                          : expenseStatement
                          ? "Expense Statement"
                          : manageExpense
                          ? "Manage Expense"
                          : manageExpenseItem
                          ? "Manage Expense Item"
                          : addBenefit
                          ? "Add Benefit"
                          : addSetup
                          ? "Add Salary SetUp"
                          : editBenefit
                          ? "Edit Benefit"
                          : editSetup
                          ? "Edit Salary SetUp"
                          : manageBenefits
                          ? "Manage Benefits"
                          : manageGenerate
                          ? "Manage Generate Salary"
                          : manageSetup
                          ? "Manage Salary SetUp"
                          : salaryGenerate
                          ? "Generate Salary"
                          : salaryPayment
                          ? "Salary Payment"
                          : addAsset
                          ? "Add Fixed Asset"
                          : editAsset
                          ? "Edit Fixed Asset"
                          : editPurchaseAsset
                          ? "Edit Purchase"
                          : addPurchaseAsset
                          ? "Purchase Fixed Asset"
                          : assetList
                          ? "Fixed Asset List"
                          : purchaseAssetList
                          ? "Purchase List"
                          : addLoan
                          ? "Add Loan"
                          : addPayment
                          ? "Add Payment"
                          : addPerson
                          ? "Add Person"
                          : editPerson
                          ? "Edit Person"
                          : managePerson
                          ? "Manage Person"
                          : loanDetails
                          ? "Loan Details"
                          : personLedger
                          ? "Loan Ledger"
                          : addOfficePerson
                          ? "Add person"
                          : editPersonLoan
                          ? "Edit Person"
                          : manageOfficePerson
                          ? "Manage Person"
                          : chartofAccounts
                          ? "Chart Of Accounts"
                          : manufacturerPayment
                          ? "Manufacturer Payment"
                          : supplierPayment
                          ? "Supplier Payment"
                          : customerReceive
                          ? "Customer Receivables"
                          : cashAdjustment
                          ? "Cash Adjustment"
                          : debitVoucher
                          ? "Debit Voucher"
                          : creditVoucher
                          ? "Credit Voucher"
                          : cashBook
                          ? "Cash Book"
                          : bankBook
                          ? "Bank Book"
                          : generalLedger
                          ? "General Ledger"
                          : inventoryLedger
                          ? "Inventory Ledger"
                          : cashFlow
                          ? "Cash Flow"
                          : cashflowDetails
                          ? "Cash Flow Details"
                          : generalLedgerDetails
                          ? "General Ledger Details"
                          : profitLoss
                          ? "Profit/Loss Statement"
                          : manufacturerInvoiceDetails
                          ? "Supplier return receipt"
                          : stockReturnDetails
                          ? "Customer return receipt"
                          : profitLossDetails
                          ? "Profit/Loss Details"
                          : trialBalance
                          ? "Trial Balance"
                          : trialBalanceDetails
                          ? "Trial Balance Details"
                          : changePassword
                          ? "Change Password"
                          : manufacturerAccountingDetails
                          ? "Manufacturer Details"
                          : supplierAccountingDetails
                          ? "Supplier Details"
                          : addPurchaseOrder
                          ? "Add Purchase Order"
                          : addRole
                          ? "Add User Role"
                          : addSettingsUser
                          ? "Add user"
                          : assignRole
                          ? "Assign User Role"
                          : backUp
                          ? "Back Up "
                          : currency
                          ? "Currency"
                          : importSettings
                          ? "Import Settings"
                          : langauge
                          ? "Language"
                          : manageCompany
                          ? "Manage Company"
                          : manageUsers
                          ? "Manage Users"
                          : roleList
                          ? "Role List"
                          : smsConfigure
                          ? "SMS Configuration"
                          : webSettings
                          ? "Web Settings"
                          : editSettingsUser
                          ? "Edit User"
                          : support
                          ? "Support"
                          : invoiceTax
                          ? "Invoice Wise Tax Report"
                          : taxReport
                          ? "Tax Report"
                          : taxIncome
                          ? "Manage Income Tax"
                          : incomeTax
                          ? "Add Income Tax"
                          : taxSettings
                          ? "Tax Settings"
                          : subscription
                          ? "Subscription Expired"
                          : ledgerInformation
                          ? "Manufacturer Ledger Information"
                          : purchaseOrderDetails
                          ? "Purchase Order Details"
                          : managePurchaseOrder
                          ? "Manage Purchase Order"
                          : editPurchaseOrder
                          ? "Edit Purchase Order"
                          : "Edit User"}
                      </NavLink>
                    </li>
                  )}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DashboardHeader);
