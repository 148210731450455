import { useState } from "react";
import axios from "axios";
import config from "../../../../Helpers/config.json";
import { errorMessages } from "../../../../Helpers/ErrorMessages";
import { useMutation, useQuery } from "react-query";

export const useManageCreditNote = (actions) => {
  // const storage = JSON.parse(sessionStorage.getItem("total_count"));

  // const item_count = storage ? storage : 0;
  // const count = () => +item_count || 0;

  const [creditNotes, setCreditNotes] = useState([]);

  // const [totalCount, setTotalCount] = useState(count);

  const [activePage, setActivePage] = useState(1);

  const [pageSize, setPageSize] = useState(10);

  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState("idle");

  const [totalAmount, setTotalAmount] = useState(0);

  const [recordCount, setRecordCount] = useState(0);

  const [openDialog, setOpenDialog] = useState(false);

  const [isSubmitted, setisSubmitted] = useState("idle");

  const [loadingCredit, setLoadingCredit] = useState("idle");

  const [draftDialog, setDraftDialog] = useState(false);

  const [date, setDate] = useState({
    // startDate: "2022-09-1",
    // endDate: "2023-12-06",
    startDate: "",
    endDate: "",
  });

  const [excelData] = useState({
    file_name: "CreditNoteListEXCEL",
    url: `Ccredit_memo/credit_memos_excel`,
    params: {
      start_date: date?.startDate,
      end_date: date?.endDate,
      download: true,
    },
  });

  const [pdfData] = useState({
    file_name: "CreditNoteListPDF",
    url:`Ccredit_memo/credit_memos_pdf`,
    params: {
      start_date: date?.startDate,
      end_date: date?.endDate,
      download: true,
    },
  });

  const isResolved = isSubmitted === "resolved";

  const creditNotesListQuery = useQuery(
    ["creditNotesListQuery", activePage, isResolved, date, search],
    async () => {
      try {
        const response = await axios.get(
          `${config.epharmUrl}/Ccredit_memo/credit_memos`,
          {
            params: {
              page_number: activePage,
              start_date: date?.startDate,
              end_date: date?.endDate,
              search,
            },
          },
        );

        const data = response?.data?.data ?? {
          credit_memo: {
            grand_total: {
              grand_total_amount: 0.0,
              memo_count: 4,
            },
            purchase_query: {
              credit_memo_list: [
                {
                  credit_memo_date: "Tue, 12 Dec 2023 00:00:00 GMT",
                  credit_memo_id: 4,
                  credit_memo_no: "CM00112",
                  customer_name: "Test Customer",
                  previous_invoice: "1",
                  total_amount: 0.0,
                },
              ],
              grand_total_amount: {
                grand_total_amount: 0.0,
                memo_count: 0,
              },
              memo_count: 0,
            },
          },
          next_page: 0,
          total_pages: 0,
          total_records: 0,
        };

        const creditMemo = data?.credit_memo ?? {};

        const grandTotalAmount =
          creditMemo?.grand_total?.grand_total_amount ?? 0;

        const creditNoteList =
          creditMemo?.purchase_query?.credit_memo_list ?? [];

        const totalRecords = data?.total_records ?? 0;

        setCreditNotes(creditNoteList);

        setTotalAmount(grandTotalAmount);

        setRecordCount(totalRecords);

        setLoading("success");

        setisSubmitted("idle");
      } catch (error) {
        errorMessages(error, null, actions);
      }
    },
    { retry: 1 },
  );

  const exportMutation = useMutation({
    mutationFn: async (type) => {

    },
    onSuccess: (response) => {},
    onError: (error) => {
      errorMessages(error, null, actions);
    },
  });

  // const retrieveCreditNotes = useCallback(
  //   (start_date, end_date) => {
  //     setLoading("idle");
  //     axios
  //       .get(`${config.epharmUrl}/Ccredit_memo/credit_memos`, {
  //         params: { page_number: activePage, start_date, end_date },
  //       })
  //       .then((response) => {
  //         const res = response.data;
  //         const dt = res.data ? res.data : {};
  //         const data = dt?.credit_memo ? dt?.credit_memo : {};
  //         const grand_totol_amount = data?.total_amount
  //         ? data.total_amount
  //         : null;
  //         const credit_note_list = data.purchase_query.credit_memo_list
  //           ? data.purchase_query.credit_memo_list
  //           : [];
  //         // console.log(data.purchase_query.credit_memo_list);
  //         const credit_note_count = dt.total_records ? dt.total_records : 0;
  //
  //         if (activePage === 1) {
  //           sessionStorage.setItem(
  //             "total_count",
  //             JSON.stringify(credit_note_count)
  //           );
  //           setTotalCount(credit_note_count);
  //         } else {
  //           setTotalCount(count);
  //         }
  //         setCreditNotes(credit_note_list);
  //         setTotalAmount(grand_totol_amount);
  //         setLoading("success");
  //         setisSubmitted("idle");
  //       })
  //       .catch((error) => {
  //         errorMessages(error, null, actions);
  //         setLoading("error");
  //       });
  //   },
  //   [activePage]
  // );

  // useEffect(() => {
  //   retrieveCreditNotes(startDate, endDate);
  // }, [retrieveCreditNotes, isResolved]);

  const handleChangeDate = (event) => {
    const { value, name } = event.target;
    setDate({ ...date, [name]: value });
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
    // if (event.target.value !== "") {
    //   axios
    //     .get(`${config.epharmUrl}/Ccredit_memo/credit_memos`, {
    //       params: { search: event.target.value },
    //     })
    //     .then((response) => {
    //       const res = response.data;
    //       const dt = res ? res : [];
    //       setCreditNotes(dt);
    //       // calculateTotalAmount(dt)
    //     })
    //     .catch((error) => {
    //       errorMessages(error, null, actions);
    //       setLoading("error");
    //     });
    // } else {
    //   retrieveCreditNotes(startDate, endDate);
    // }
  };

  // const handleOpenDialog = (id) => {
  //   setPurchaseId(id);
  //   setOpenDialog(true);
  //   setLoadingPurchase("loading");
  //   axios
  //     .get(`${config.epharmUrl}/Cpurchase/purchase_details_data`, {
  //       params: { purchase_id: id },
  //     })
  //     .then((response) => {
  //       const dataRes = response.data;
  //       const dt = dataRes ? dataRes : {};
  //       const products = !dt.purchase_all_data ? [] : dt.purchase_all_data;
  //       setProductPurchase({ supplierDetails: dt, products });
  //       setLoadingPurchase("success");
  //     })
  //     .catch((err) => {
  //       errorMessages(err, null, actions);
  //       setLoadingPurchase("error");
  //     });
  // };

  const handleOpenDraftDialog = () => {
    setDraftDialog(true);
  };

  const handleCancelDebitNote = () => {
    // handleDeletePurchase(true);
  };

  const handleCancelDraft = () => {
    // handleDeletePurchase();
    handleCloseDialog();
  };

  const handleCloseDraftDialog = () => {
    setDraftDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseBar = () => {
    actions.snackbarActions.hideSnackBar();
  };

  const handleSearch = (event) => {
    event.preventDefault();
    // if (startDate && endDate) {
    //   retrieveCreditNotes(startDate, endDate);
    //   // setSubmitted(!submitted)
    // }
  };

  const handleChangePage = (page) => {
    setActivePage(page);
  };

  const handleChangePageSize = (newSize) => {
    setPageSize(newSize);
  };

  const handleExport = (event, type) => {
    exportMutation.mutate(type);
  };

  const isLoading = creditNotesListQuery?.isLoading;

  const isSuccess = creditNotesListQuery?.isSuccess;

  const isError = creditNotesListQuery?.isError;

  return {
    creditNotes,
    totalAmount,
    handleChangeDate,
    date,
    handleChangeSearch,
    search,
    handleSearch,
    isLoading,
    isSuccess,
    isError,
    openDialog,
    handleCloseBar,
    activePage,
    recordCount,
    handleChangePage,
    pageSize,
    handleChangePageSize,
    handleExport,
    pdfData, excelData,
  };
};
