import React from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as purchaseActions from "../../Redux/Actions/PurchaseActions/PurchaseActions"
import * as manufacturerActions from "../../Redux/Actions/ManufacturerActions/ManufacturerActions";
import * as invoiceActions from "../../Redux/Actions/InvoiceActions/PosInvoiceActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {convDate} from "../../Utils/titleCaseFunction";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions, DialogContent, DialogTitle} from "../../Utils/Dialog/pharmacyDialog";
import {Link} from "react-router-dom";
import Card from "@material-ui/core/Card";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Grid from "@material-ui/core/Grid";
import {AutoCompleteControlled, AutoCompleteMedicine, AutoCompleteMultiple} from '../../Utils/FormInputs/AutoCompleteInput';
import DetailsDialog from "../../Utils/Dialog/DetailsDialog";
import {ManufacturerForm} from "../Manufacturer/ManufacturerForm";
import Alert from "@material-ui/lab/Alert";
import { useAddPurchaseOrder } from './CustomHooks/useAddPurchaseOrder';
import { RightAlignedContainer } from '../../Utils/styledComponents';
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../Helpers/history";


const AddPurchaseOrder = ({actions, snackbars, manufacturers, purchases, match}) => {
    const {local_purchase_order_id, status, template, component} = match.params;
    const {allProducts} = purchases;

    const {
        rows, manufacturer_name, grand_total, submitted, isSubmitted, openDialog,
        total_tax, sub_total, state,isPending,
        manufacturerProps, isError, isIdle, isSuccess, isLoading, 
        handleInput, handleChangeManufacturer, handleAddRow, handleRemoveSpecificRow, 
        handleChangeTax, handleSearchProduct,
        retrieveProductData, handleChangeQuantity, handleChangePrice,
        handleCloseBar, handleCloseDialog, handleSubmitPurchase, viewOrder,
        handleCloseChooseMedicine, openChooseMedicine, medicineToChoose,
        chosenMedicine, handleChosenMedicineChange, handleSetRows, chosen_submitted,
        isBlocking,isResolvedPurchase,isPendingDraft
    } = useAddPurchaseOrder(actions, match, allProducts);

    const {all_manufacturers} = manufacturers;
    const manufs = !all_manufacturers ? [] : all_manufacturers;
    const manufProducts = !allProducts ? [] : allProducts;
    const {open, message, variant} = snackbars;
    const {delivery_date,  deliver_to, comments, reason} = state

    const isLoaded = (component && isSuccess) || (!component && isIdle)

    const {openDialog:openSupplier, handleCloseDialog:handleCloseSupplier, handleOpenDialog:handleOpenSupplier} = manufacturerProps

    
    return (
        <div className="journals">
            <ReusableDashboardHeader
                component={`${local_purchase_order_id && !template ? 'Edit' : 'New'} Purchase Order`}
                heading={`${local_purchase_order_id && !template ? 'Edit' : 'New'} Purchase Order`}
                subHeading={`${local_purchase_order_id && !template ? 'Edit' : 'New'} Purchase Order`}
                link={{
                    pathname: local_purchase_order_id && !template ? `/editpurchaseorder/${local_purchase_order_id}/${status}` :
                        local_purchase_order_id && template ? `/editpurchaseordertemplate/${local_purchase_order_id}/${status}/${template}` : `/addpurchaseorder`
                }}/>

            <DetailsDialog openDialog={openChooseMedicine} title="Out of Stock Medicine to Purchase" handleClose={handleCloseChooseMedicine} isTop={true}>
                <Label name='Choose out of stock medicine to purchase'/>
                <div>
                    <AutoCompleteMultiple options={medicineToChoose} placeholder='Choose out of stock medicine to purchase' 
                    selectedOptions={chosenMedicine} handleChange={handleChosenMedicineChange} submitted={chosen_submitted}/>
                    {(chosen_submitted && (chosenMedicine.length === 0)) ?
                        <div className="invalid-text">Choose medicine to purchase</div> 
                    : null}
                </div>
                <RightAlignedContainer style={{marginTop:'10px'}}>
                    <button className='btn btn-sm pharmacy-btn' type="button" onClick={handleSetRows}>
                        Add Medicine
                    </button>
                </RightAlignedContainer>  
            </DetailsDialog>

            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
            />

            <DetailsDialog openDialog={openSupplier}  title='New Supplier' handleClose={handleCloseSupplier}>
                <ManufacturerForm {...manufacturerProps}/>
            </DetailsDialog>
            <div className="general-ledger-header">
                <Grid container spacing={0}>
                    <Grid item xs={6} md={5}>
                        <h6>{`${local_purchase_order_id && !template ? 'Edit' : 'New'} Purchase Order`}</h6>
                    </Grid>
                    <Grid item xs={6} md={7}>
                        <div className="text-right">
                            <button data-testid="quick_add_supplier" onClick={handleOpenSupplier} className='btn btn-sm pharmacy-info-btn mr-4'>Quick Add Supplier</button>
                            <Link to='/managepurchaseorder'>
                                <button data-testid="all_purchase_orders" className='btn btn-sm pharmacy-info-btn'>All Purchase Orders</button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>

            <Dialog open={openDialog}>
                <DialogTitle onClose={handleCloseDialog}>
                    <div className="text-center">
                        View Purchase Order
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="text-center">
                        <p style={{fontSize: "14px"}}>Would you like to view the purchase order?</p>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="text-right">
                        <button onClick={viewOrder} type="button" className="btn pharmacy-info-btn mr-1">View
                        </button>
                        <Link to="/managepurchaseorder">
                            <button type="submit" className="btn pharmacy-grey-btn mr-3">No</button>
                        </Link>

                    </div>
                </DialogActions>
            </Dialog>
            <div className="mui-tables">
                <Card className='p-3'>
                    <form autoComplete="off" onKeyDown={(e)=>{if (e.key === 'Enter'){e.preventDefault();}}}>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <Form.Group as={Row}>
                                    <Label name='Supplier' type sm={4} column htmlFor='supplier'/>
                                    <Col sm={6}>
                                        <AutoCompleteControlled options={manufs} submitted={submitted} handleChange={handleChangeManufacturer}
                                        errorMsg="Please select a supplier" medicine_value={manufacturer_name} placeholder="Select Supplier..."
                                            value_text='manufacturer_id' label_text='manufacturer_name'  id='supplier' testId="select_supplier"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Label name='Deliver To' type sm={4} column htmlFor='deliverTo'/>
                                    <Col sm={6}>
                                        <Textarea submitted={submitted} value={deliver_to} name='deliver_to' id='deliverTo'
                                                data-testid="deliver_to"
                                                onChange={handleInput}/>
                                        {(submitted && !deliver_to) &&
                                            <div className="invalid-text" data-testid="deliver_to_warning">Delivery address is required</div>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Label name='Expected Delivery Date' sm={4} column type/>
                                    <Col sm={6}>
                                        <TextField submitted={submitted} onChange={handleInput}
                                                   value={delivery_date ? convDate(delivery_date) : delivery_date} type='date' id='expectedDeliveryDate'
                                                   data-testid="expected_delivery_date"
                                                   name='delivery_date'/>
                                        {(submitted && !delivery_date) &&
                                            <div className="invalid-text" data-testid="expected_delivery_date_warning" >Delivery date is required</div>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Label name='Comments &amp; Special Instructions' htmlFor='specialInstructions' sm={4} column/>
                                    <Col sm={6}>
                                        <Textarea value={comments} name='comments' onChange={handleInput} id='specialInstructions'  data-testid="special_instructions"/>
                                    </Col>
                                </Form.Group>
                                {status === '2' ?
                                    <Form.Group as={Row}>
                                        <Label name='Reason' htmlFor='reason' sm={4} column/>
                                        <Col sm={6}>
                                            <Textarea value={reason} data-testid="reason" id='reason' name='comments' readOnly/>
                                        </Col>
                                    </Form.Group>
                                    : null}
                            </div>
                            <div className='col-lg-6'>
                                <Form.Group as={Row}>
                                    <Label name='Total Amount' htmlFor='totalAmount'  sm={3} column/>
                                    <Col sm={6}>
                                        <TextField type="number" disabled={true} id='totalAmount' name='sub_total'
                                                    data-testid="total_amount"
                                                   value={sub_total} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Label name='Tax(%)'  sm={3} column htmlFor='tax'/>
                                    <Col sm={6}>
                                        <TextField type="number" value={total_tax} name="total_tax" id='tax'
                                        data-testid="total_tax"
                                                   onChange={(event) => handleChangeTax(event, rows)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Label name='Grand Total' htmlFor='grandTotal'  sm={3} column/>
                                    <Col sm={6}>
                                        <TextField type="number" readOnly id='grandTotal' data-testid="grand_total" name='grand_total'
                                                   value={grand_total}/>
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                        {submitted && rows.length <=0 ? <div className='mb-2'>
                            <Alert severity="error">Please add a product before submitting</Alert>
                        </div> :  null}

                        <table className='table table-sm table-bordered add-purchase-order'>
                            <thead>
                            <tr>
                                <th>Item Details</th>
                                <th>Quantity<span className="text-danger asterics ml-1">*</span></th>
                                <th>Rate<span
                                    className="text-danger asterics ml-1">*</span></th>
                                <th>Amount</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {component && isLoading ? <tr>
                                <td colSpan={4}  align='center'>
                                    <img src="/images/listPreloader.gif"
                                         alt="loader"
                                         style={{width: '5%'}}/>
                                </td>
                            </tr>:null}
                            { isLoaded ? rows.length > 0 ? rows.map((item, idx) => (
                                <tr id="addr1" key={item.uuid_id}>
                                    <td>
                                        <AutoCompleteMedicine options={manufProducts} submitted={submitted}  idx={idx} handleChange={handleSearchProduct}
                                                              testId={"product-"+idx}
                                            handleProductData={retrieveProductData} value_text='value' label_text='label'  medicine_value={{label:item.product_name, value: item.product_id, generic_name: item.generic_name}} />
                                    </td>
                                    <td>
                                        <TextField
                                            type='number'
                                            name="quantity"
                                            min={'0'}
                                            data-testid={"quantity-"+idx}
                                            value={item.quantity}
                                            onChange={(e) => handleChangeQuantity(e, idx)}
                                            placeholder="0.00"
                                            submitted={submitted}
                                        />
                                        {(submitted && !item.quantity) &&
                                        <div className="invalid-text">Please enter the quantity</div>}
                                    </td>
                                    <td>
                                        <TextField type="number"
                                            name="rate"
                                            data-testid={"rate-"+idx}
                                            value={item.rate}
                                            onChange={(e) => handleChangePrice(e, idx)}
                                            placeholder="0.00"
                                            submitted={submitted}
                                            min={'0'}
                                        />
                                        {(submitted && !item.rate) &&
                                        <div className="invalid-text">Please enter the rate</div>}
                                    </td>
                                    <td data-testid={"total_cost-"+idx}>{rows[idx].total}</td>
                                    <td>
                                        <button type="button" data-testid={"delete-"+idx} className="btn pharmacy-btn-dismiss btn-sm"
                                                onClick={() => handleRemoveSpecificRow(item.uuid_id)}>X
                                        </button>
                                    </td>
                                </tr>
                            )): <tr>
                                <td colSpan={4} align='center'>No products loaded, please add products</td>
                            </tr>:null}
                            {isError ? <tr>
                                <td colSpan={4}  align='center'>
                                   The server did not return a valid response
                                </td>
                            </tr>:null}
                            </tbody>
                        </table>
                        <table className='table table-sm table-borderless'>
                            <tr>
                                <td rowSpan={4} colSpan={2}>
                                    <button type='button' data-testid="add_row" onClick={handleAddRow}
                                            className='btn btn-sm pharmacy-grey-btn'>Add another line
                                    </button>
                                </td>
                            </tr>
                            {/*<tr>*/}
                            {/*    <td/>*/}
                            {/*    <td/>*/}
                            {/*    <td align='right'>Sub Total</td>*/}
                            {/*    <td align='center'></td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td/>*/}
                            {/*    <td/>*/}
                            {/*    <td align='right'>Tax %</td>*/}
                            {/*    <td align='center'></td>*/}
                            {/*</tr>*/}
                            {/*<tr>*/}
                            {/*    <td/>*/}
                            {/*    <td/>*/}
                            {/*    <td align='right'>Grand Total</td>*/}
                            {/*    <td align='center'></td>*/}
                            {/*</tr>*/}
                        </table>

                        <button className="btn pharmacy-btn px-3"  data-testid="submit_purchase_order" disabled={isSubmitted === 'pending'}  onClick={handleSubmitPurchase}
                                type='submit'>{isSubmitted === 'pending' ? "Saving..." : "Save Final"}</button>

                    </form>
                </Card>

            </div>
        </div>
    );

}

function mapStateToProps(state) {

    return {
        manufacturers: state.manufacturers,
        invoices: state.invoices,
        purchases: state.purchases,
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            manufacturerActions: bindActionCreators(manufacturerActions, dispatch),
            invoiceActions: bindActionCreators(invoiceActions, dispatch),
            purchaseActions: bindActionCreators(purchaseActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPurchaseOrder);