import React, { useEffect, useState } from "react";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import { history } from "../../../Helpers/history";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useCompany } from "../../../Context/company-context";
import { titleCase } from "../../../Utils/titleCaseFunction";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPagination,
  MuiPaper,
} from "../../../Utils/Tables/CustomTable";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";

import { Form } from "react-bootstrap";
import { useSnackbar } from "../../../Context/snackbar-context";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {
  useExcelReports,
  usePdfReports,
} from "../../Invoice/CustomHooks/useExcelReports";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FilterResults from "react-filter-search";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useQuery } from "react-query";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { formatDate } from "../../../Utils/ConvertDate";

const headData = [
  { id: "sl", numeric: false, disablePadding: false, label: "Sl" },

  {
    id: "medicineName",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },

  { id: "quantity", numeric: false, disablePadding: false, label: "Quantity" },

  { id: "batch", numeric: false, disablePadding: false, label: "Batch ID" },

  { id: "expiry", numeric: false, disablePadding: false, label: "Expiry Date" },

  {
    id: "purchase",
    numeric: false,
    disablePadding: false,
    label: "Purchase Price",
  },

  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Total Amount",
  },
];

const DebitNoteInvoice = ({ match: { params }, snackbars, actions }) => {
  const { supplier_id: debitNoteID } = params;

  const [term, setTerm] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [page, setPage] = useState(0);

  //  const [productPurchase, setProductPurchase] = useState({});

  //  const [purchasesReceipts, setPurchasesReceipts] = useState([]);

  const [productDebit, setProductDebit] = useState({});

  const [debitReceipts, setDebitReceipts] = useState([]);

  const [loading, setLoading] = useState("idle");

  const { state: snackbarState, handleClose, handleError } = useSnackbar();

  const { state } = useCompany();

  const { logo } = state;

  const { open, message, variant } = snackbars;

  const debitNoteQuery = useQuery(
    ["debitNoteQuery", term],
    async () => {
      try {
        const response = await axios.get(
          `${config.epharmUrl}/Cdebitmemo/debit_single_memo_details`,
          {
            params: {
              related_debit_note: debitNoteID,
              search: term,
            },
          },
        );

        // console.log(response?.data);

        return response?.data;
        // ?? {
        //   batch: "479",
        //   company_info: false,
        //   currency: "UGX",
        //   debit_all_data: [
        //     {
        //       batch: "479",
        //       convert_date: "14 - DEC - 2023",
        //       expiry_date: "Sat, 14 Dec 2024 00:00:00 GMT",
        //       id: 1334,
        //       memo_date: "Thu, 14 Dec 2023 00:00:00 GMT",
        //       previous_invoice_no: "80",
        //       price: 24,
        //       product_name: "Product-198090-361785",
        //       quantity: 1,
        //       related_debit_note: 16,
        //       sl: 1,
        //       total: 24,
        //       transaction_no: 1728,
        //     },
        //   ],
        //   expiry_date: "Sat, 14 Dec 2024 00:00:00 GMT",
        //   memo_date: "Thu, 14 Dec 2023 00:00:00 GMT",
        //   position: "0",
        //   previous_invoice_no: "80",
        //   debit_note_no: "",
        //   details: "",
        //   price: 24,
        //   product_id: 1334,
        //   product_name: "Product-198090-361785",
        //   quantity: 1,
        //   related_debit_note: 16,
        //   title: "debit_ledger",
        //   total_amount: 24,
        //   transaction_no: 1728,
        // }
      } catch (error) {
        errorMessages(error, null, actions);
      }
    },
    { retry: 1 },
  );

  // useEffect(() => {
  //   setLoading("loading");
  //   axios
  //     .get(`${config.epharmUrl}/Cdebitmemo/debit_single_memo_details`, {
  //       params: { supplier_id },
  //     })
  //     .then((response) => {
  //       const data = response.data;
  //       const dt = data ? data : {};
  //       // console.log(dt)
  //       setLoading("success");
  //       setProductDebit(dt);
  //     })
  //     .catch(() => {
  //       handleError("Operation unsuccessful");
  //       setLoading("error");
  //     });
  // }, [term]);

  const handleSearch = (event) => {
    setTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const currentObj = {
    file_name: "Debit notes",
    url: "Cdebitmemo/debit_details_excel",
    params: {
      related_debit_note: debitNoteID,
    },
  };

  const pdfObj = {
    file_name: "Debit notes",
    url: "Cdebitmemo/debit_details_pdf",
    params: {
      related_debit_note: debitNoteID,
      download: true,
    },
  };

  const { exportExcel } = useExcelReports(actions, currentObj);

  const { exportPDF } = usePdfReports(actions, pdfObj);

  const isLoading = debitNoteQuery?.isLoading;

  const isSuccess = debitNoteQuery?.isSuccess;

  const isError = debitNoteQuery?.isError;

  const debitData = debitNoteQuery?.isSuccess ? debitNoteQuery?.data : {};

  // const debitData = !debits.debit_all_data ? [] : debits.debit_all_data;
  // console.log(debitData)

  return (
    <div>
      <ReusableDashboardHeader
        component="Debit Note Invoices"
        heading="Debit Note Invoices"
        subHeading="Debit Note Invoices"
        link={history.location.pathname}
      />

      <div className="mx-3 mt-5">
        <div className="pos-buttons">
          <Link
            to="/accounting/debitnote"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <button
              data-testid="add_debit_note"
              type="button"
              className="btn mr-2 pharmacy-btn"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Debit Note
            </button>
          </Link>

          <Link to="/managedebitnotes">
            <button
              data-testid="manage_debit_note"
              type="button"
              className="btn pharmacy-info-btn mr-2"
            >
              <FontAwesomeIcon icon={faList} /> Manage Debit Notes
            </button>
          </Link>
        </div>

        <div className="text-center">
          <img
              style={{maxWidth: "100px"}}
              src={logo ? logo : "/images/mpeke3.png"}
              alt="mpeke"
          />

          {/*<p data-testid="purchase_info" style={{fontSize: "16px"}}>*/}
          {/*  Supplier Name:&nbsp;{titleCase("Jim")}*/}
          {/*</p>*/}

          <p>Date:&nbsp;{formatDate(debitData?.memo_date ?? new Date())}</p>

          <p>Debit Memo Number:&nbsp;{debitData?.debit_note_no ?? "N/A"} </p>

          <p>Total Amount:&nbsp;UGX&nbsp;{debitData?.total_amount ?? "N/A"} </p>

          <p>Details: &nbsp;{debitData?.details ?? "N/A"}</p>
        </div>

        <PharmacySnackbar
            open={open}
            variant={variant}
            message={message}
          handleCloseBar={handleClose}
        />

        <MuiPaper>
          <EnhancedTableToolbar title="Debit Note Invoices">
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  onClick={exportExcel}
                  className="btn btn-sm pharmacy-btn mr-1"
                >
                  Download Excel
                </button>
              </span>

              <span>
                <button
                  className="btn pharmacy-btn mr-1 btn-sm"
                  onClick={exportPDF}
                >
                  Download PDF
                </button>
              </span>
            </span>

            <Form.Control
              type="text"
              value={term}
              className="form__control"
              onChange={handleSearch}
              placeholder="Search"
              style={{ float: "right" }}
            />
          </EnhancedTableToolbar>

          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead headCells={headData} />

            <FilterResults
              value={term}
              data={debitData?.debit_all_data ?? []}
              renderResults={(results) => {
                return (
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={headData.length} align="center">
                          <img
                            src="/images/listPreloader.gif"
                            alt="loader"
                            style={{ width: "5%" }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : null}

                    {isSuccess ? (
                      debitData?.debit_all_data?.length > 0 ? (
                        debitData?.debit_all_data?.map((product, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                              className="mui-table-row"
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="mui-table-cell"
                              >
                                <span>{product?.sl ?? index}</span>
                              </TableCell>

                              <TableCell>
                                <span data-testid={"product_name-" + index}>
                                  <Link
                                    to={{
                                      pathname: `/medicinedetails/${
                                        product?.id ?? "N/A"
                                      }`,
                                    }}
                                    style={{ color: "#09905c" }}
                                  >
                                    {product?.product_name
                                      ? titleCase(product?.product_name)
                                      : "N/A"}
                                  </Link>
                                </span>
                              </TableCell>

                              <TableCell>
                                <span data-testid={"quantity-" + index}>
                                  {product?.quantity ?? "N/A"}
                                </span>
                              </TableCell>

                              <TableCell>
                                <span data-testid={"batch_id-" + index}>
                                  {product?.batch ?? "N/A"}
                                </span>
                              </TableCell>

                              <TableCell>
                                <span data-testid={"expiry_date-" + index}>
                                  {product?.expiry_date ?? "N/A"}
                                </span>
                              </TableCell>

                              <TableCell>
                                <span data-testid={"purchase_price-" + index}>
                                  {product?.price ?? "N/A"}
                                </span>
                              </TableCell>

                              <TableCell>
                                <span data-testid={"total_amount-" + index}>
                                  {product?.total ?? "N/A"}
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell
                            data-testid="no_records_found"
                            colSpan={headData?.length}
                            align="center"
                          >
                            No records found
                          </TableCell>
                        </TableRow>
                      )
                    ) : null}

                    {isError ? (
                      <TableRow>
                        <TableCell colSpan={headData.length} align="center">
                          The server did not return a valid response
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                );
              }}
            />
          </Table>
          {/*<MuiPagination*/}
          {/*  data={debitData}*/}
          {/*  rowsPerPage={rowsPerPage}*/}
          {/*  page={page}*/}
          {/*  handleChangeRowsPerPage={handleChangeRowsPerPage}*/}
          {/*  handleChangePage={handleChangePage}*/}
          {/*  rows={[10, 20, 30, 40, 50]}*/}
          {/*  colSpan={headData.length}*/}
          {/*/>*/}
        </MuiPaper>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DebitNoteInvoice);
// export default DebitNoteInvoice;
