import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { useExpenses } from "./hooks/useExpenses";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { connect } from "react-redux";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import {
  CustomTableCell,
  StickyHeadTable,
} from "../../../Utils/Tables/StickyHeaderTable";
import { LoadingGif } from "../../../Utils/Loader";
import { formatDate } from "../../../Utils/ConvertDate";
import { history } from "../../../Helpers/history";
import { ReusableRouteTabs } from "../../../Utils/Dialog/ReusableTabs";
import {
  ExpenseContainer,
  RightAlignedContainer,
} from "../../../Utils/styledComponents";
import CustomDialog from "../../../Utils/Dialog/CustomDialog";
import { NewExpense } from "./NewExpense";
import { ReusableNewCOA } from "../COA/NewCOAccount";
import { NewExpenseItem } from "./NewExpenseItem";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import {
  EnhancedTableToolbar,
  MuiPagination,
  MuiPaper,
} from "../../../Utils/Tables/CustomTable";
import { makeStyles } from "@material-ui/core/styles";
import { CancelExpense } from "./CancelExpenseUI";
import TextField from "../../../Utils/FormInputs/TextField";
import { BlockNavigationComponent } from "../../../Utils/ReusableComponents/BlockNavigationComponent";
import { CustomPagination } from "../../../Utils/Tables/CustomTable";
import Table from "@material-ui/core/Table";
import { EnhancedTableHead } from "../../../Utils/Tables/CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "0.8em",
    height: "0.8em",
  },
}));

const headData = [
  { id: "date", numeric: false, disablePadding: false, label: "Date" },
  {
    id: "receiptNo",
    numeric: false,
    disablePadding: false,
    label: "Vendor Name",
  },
  { id: "expense", numeric: false, disablePadding: false, label: "Category" },
  {
    id: "details",
    numeric: false,
    disablePadding: false,
    label: "Details",
  },
  {
    id: "credit_ledger",
    numeric: false,
    disablePadding: false,
    label: "Payment",
  },
  { id: "amount", numeric: false, disablePadding: false, label: "Amount" },
  { id: "action", numeric: false, disablePadding: false, label: "Action" },
];

const components = [
  { label: "Expenses", path: "/accounting/expenses/active" },
  // { label: "Canceled Expenses", path: "/accounting/expenses/canceled" },
  // { label: "Expense Category", path: "/accounting/expense-items" },
];

const Expenses = ({ actions, snackbars, match: { params } }) => {
  const { component } = params;

  const {
    expenses,
    isLoading,
    isSuccess,
    isError,
    closeSnackbar,
    expenseProps,
    openCancel,
    canceledExpense,
    handleCancelExpense,
    handleOpenCancel,
    handleCloseCancel,
    activePage,
    page,
    rowsPerPage,
    searchValue,
    handleChangeSearch,
    handleChangeRowsPerPage,
    handleChangePage,
    total_count,
  } = useExpenses(actions, component);
  const {
    openExpense,
    handleCloseExpense,
    handleOpenExpense,
    itemProps,
    ledgerProps,
    isBlocking,
    isBlockDialog,
    setIsBlockDialog,
    handleResetForm,
  } = expenseProps;
  const {
    openLedger,
    handleOpenLedger,
    handleCloseLedger,
    handleSubmit: handleSubmitLedger,
    isPending: isPendingLedger,
  } = ledgerProps;

  const { openDialog, handleCloseDialog } = itemProps;

  const { open, variant, message } = snackbars;

  const emptyRows = 10 - Math.min(10, expenses.length);

  const isActive = component === "active";

  const reusableTable = (
    <MuiPaper>
      <EnhancedTableToolbar title="">
        <TextField
          type="text"
          placeholder="Search..."
          value={searchValue}
          onChange={handleChangeSearch}
        />
      </EnhancedTableToolbar>
      <Table
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <EnhancedTableHead headCells={headData} />
        {isLoading ? (
          <TableRow>
            <TableCell align="center" colSpan={headData.length}>
              <LoadingGif />
            </TableCell>
          </TableRow>
        ) : null}
        {isSuccess ? (
          expenses?.length > 0 ? (
            <>
              {expenses?.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <CustomTableCell>
                      {formatDate(row.expense_date)}
                    </CustomTableCell>
                    <CustomTableCell>{row.vendor_name}</CustomTableCell>
                    <CustomTableCell>{row.category}</CustomTableCell>
                    <CustomTableCell>{row.details}</CustomTableCell>
                    <CustomTableCell>{row.payment}</CustomTableCell>
                    <CustomTableCell>{row.total_amount}</CustomTableCell>
                    <CustomTableCell>
                      <Tooltip title="View Receipt" placement="left-start">
                        <Link
                          to={{
                            pathname: `/expense-reciept/${row?.voucher_id}`,
                          }}
                        >
                          <button
                            data-testid={"view_expense-" + index}
                            className="btn btn-sm pharmacy-btn icon-button-holder mr-3 mb-sm-2"
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                        </Link>
                      </Tooltip>
                    </CustomTableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 2 * emptyRows }}>
                  <TableCell colSpan={headData.length} />
                </TableRow>
              )}
            </>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={headData.length}>
                No records found
              </TableCell>
            </TableRow>
          )
        ) : null}
        {isError ? (
          <TableRow>
            <TableCell align="center" colSpan={headData.length}>
              The server did not return a valid response
            </TableCell>
          </TableRow>
        ) : null}
      </Table>

      <CustomPagination
        data={expenses}
        records={10}
        activePage={activePage}
        total_count={total_count}
        handleChangeNextPage={handleChangePage}
      />
    </MuiPaper>
  );

  const cancelButtons = (
    <>
      <button
        className="btn btn-sm pharmacy-info-btn"
        onClick={handleCancelExpense}
      >
        Continue
      </button>
      <button
        className="btn btn-sm pharmacy-gray-btn"
        onClick={handleCloseCancel}
      >
        NO
      </button>
    </>
  );

  const title = {
    active: " Expenses",
    canceled: "Canceled Expenses",
  };

  return (
    <div className="journals">
      <ReusableDashboardHeader
        component={title[component]}
        heading="Accounting"
        subHeading={title[component]}
        link={history.location.pathname}
      />
      <CustomDialog
        open={openCancel}
        handleClose={handleCloseCancel}
        title="Cancel Expense"
        maxWidth="lg"
        buttons={cancelButtons}
      >
        <CancelExpense expense={canceledExpense} />
      </CustomDialog>
      <BlockNavigationComponent
        when={isBlocking}
        shouldBlockNavigation={() => {
          return isBlocking;
        }}
        navigate={(path) => history.push(path)}
        dialogClose={isBlockDialog}
        setIsBlockDialog={setIsBlockDialog}
        clearForm={handleResetForm}
      />
      <ExpenseContainer>
        <RightAlignedContainer>
          <div className="mt-3 mx-3">
            <button
              data-testid="record-expense"
              className="btn btn-sm pharmacy-info-btn"
              style={{ visibility: isActive ? "visible" : "hidden" }}
              onClick={handleOpenExpense}
            >
              Record Expense
            </button>
          </div>
        </RightAlignedContainer>
        <CustomDialog
          open={openExpense}
          handleClose={handleCloseExpense}
          title="Record Expense"
          maxWidth="xl"
        >
          <RightAlignedContainer>
            <div className="mb-2 ml-2">
              <button
                className="btn btn-sm pharmacy-btn"
                onClick={handleOpenLedger}
              >
                Quick Add Expense Account
              </button>
            </div>
          </RightAlignedContainer>
          <NewExpense {...expenseProps} />
        </CustomDialog>
        <CustomDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          title="New Expense Category"
          maxWidth="sm"
        >
          <NewExpenseItem {...itemProps} />
        </CustomDialog>
        <CustomDialog
          open={openLedger}
          handleClose={handleCloseLedger}
          title="New Ledger Account"
          maxWidth="md"
        >
          <form
            onSubmit={handleSubmitLedger}
            autoComplete="off"
            className="general-card"
          >
            <ReusableNewCOA {...{ ...ledgerProps, isExpense: true }} />
            <button
              type="submit"
              className="btn btn-sm pharmacy-info-btn"
              disabled={isPendingLedger}
            >
              {isPendingLedger ? "Submitting..." : "Submit"}
            </button>
          </form>
        </CustomDialog>
        <ReusableRouteTabs
          value={history.location.pathname}
          tabHeading={components}
        />
        <PharmacySnackbar
          variant={variant}
          handleCloseBar={closeSnackbar}
          open={open}
          message={message}
        />
        {reusableTable}
      </ExpenseContainer>
    </div>
  );
};

function mapStateToProps(state) {
  const { snackbars } = state;
  return { snackbars };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);
