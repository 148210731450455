import React, { useEffect } from "react";
import { history } from "../../Helpers/history";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import { removeTime } from "../../Utils/titleCaseFunction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import { connect } from "react-redux";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import DetailsDialog from "../../Utils/Dialog/DetailsDialog";
import { RowCustomerForm } from "../Customer/CustomerForm";
import InvoicePrintDialog from "./InvoicePrintDialog";
import AddProvider from "../Customer/AddProvider";
import { useCreateInvoice } from "./CustomHooks/useCreateInvoice";
import {
  SelectItem,
  AutoCompleteUnControlled,
  AutoCompleteMedicine,
} from "../../Utils/FormInputs/AutoCompleteInput";
import { formatAmount } from "../../Utils/formatAmount";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import {
  ErrorMessage,
  RightAlignedContainer,
} from "../../Utils/styledComponents";
import { usePrint } from "../../Utils/Template/usePrint";
import { ReusablePosTemplate } from "./ReusablePosTemplate";
import { jsx } from "@emotion/react";
import { validateCurrentDate } from "../../Utils/ConvertDate";
import { BlockNavigationComponent } from "../../Utils/ReusableComponents/BlockNavigationComponent";
import CustomDialog from "../../Utils/Dialog/CustomDialog";
import Textarea from "../../Utils/FormInputs/Textarea";
import { useQuery } from "react-query";
import axios from "axios";

/**
 * Function to render the invoice form for creating and updating an invoice
 * @param {*} param0
 * @returns
 */

// function CreatePosInvoice({ actions, snackbars, match: { params } }) {
//   const { invoice_id: invoice_number } = params;
//
//   const {
//     submitted,
//     stateVariables,
//     openDialog,
//     providerProps,
//     isPending,
//     handleCloseDialog,
//     handleSaveInvoice,
//     handleCloseSnackbar,
//     provider,
//     isCardNumber,
//     handleChangeIsCard,
//     providerDetails,
//     handleChangeProviderDetails,
//     receiptData,
//     handlePreview,
//   } = useCreateInvoice(actions, invoice_number);
//   const {
//     customer,
//     rows,
//     state,
//     customerProps,
//     customerDetails,
//     isBlocking,
//     totals,
//     customers,
//     drugs,
//     handleChangeFormFields,
//     handleChange,
//     handleChangeBatch,
//     handleAddRow,
//     handleRemoveSpecificRow,
//     handleChangeQuantity,
//     handleRetrieveCustomerInfo,
//     handleClickCustomer,
//     handleRetrieveDrugs,
//     handleClickDrug,
//   } = stateVariables;
//   const { previous_amount, grand_total_price, n_total } = totals;
//   const {
//     openCustomerDialog,
//     handleOpenCustomerDialog,
//     handleCloseCustomerDialog,
//   } = customerProps;
//   const { openDialog: openProvider, handleCloseDialog: handleClose } =
//     providerProps;
//   const { credit_limit } = customerDetails;
//   const { invoice_date, inva_details } = state;
//   const allCustomers = !customers ? [] : customers;
//   const allDrugs = !drugs ? [] : drugs;
//   const { open, variant, message } = snackbars;
//
//   const { handlePrint, componentRef } = usePrint("");
//
//   useEffect(() => {
//     if (!openDialog) {
//       return;
//     }
//     const listener = (event) => {
//       if (event.key === "Enter" || event.key === "NumpadEnter") {
//         // console.log("Enter key was pressed. Run your function.");
//         event.preventDefault();
//         handlePrint();
//       }
//     };
//     document.addEventListener("keydown", listener);
//     return () => {
//       document.removeEventListener("keydown", listener);
//     };
//   }, [openDialog]);
//
//   const { customer: invoiceCustomer } = receiptData;
//   const { first_name, last_name } = invoiceCustomer;
//   const user_name = `${!first_name ? "" : first_name} ${
//     !last_name ? "" : last_name
//   }`;
//
//   const provider_type = provider.split("-")[1];
//   const isInsurance = provider_type === "INSURANCE";
//   const { account_name, card_number } = providerDetails;
//
//   const requiredLimit = credit_limit > 0 && credit_limit < n_total;
//
//   const validate_invoice_date = validateCurrentDate(invoice_date);
//
//   return (
//     <div>
//       <ReusableDashboardHeader
//         component="New Invoice"
//         heading="Sales"
//         subHeading="New Invoice"
//         link={history.location.pathname}
//       />
//       <PharmacySnackbar
//         open={open}
//         variant={variant}
//         message={message}
//         handleCloseBar={handleCloseSnackbar}
//       />
//       <DetailsDialog
//         title="New Customer"
//         openDialog={openCustomerDialog}
//         handleClose={handleCloseCustomerDialog}
//       >
//         <RowCustomerForm {...customerProps} />
//       </DetailsDialog>
//       <DetailsDialog
//         title="New Provider"
//         openDialog={openProvider}
//         handleClose={handleClose}
//       >
//         <AddProvider {...providerProps} />
//       </DetailsDialog>
//       <div style={{ display: "none" }}>
//         <div ref={componentRef} className="card styled-pos-card">
//           <ReusablePosTemplate {...{ ...receiptData, user_name }} />
//         </div>
//       </div>
//
//       <BlockNavigationComponent
//         when={isBlocking}
//         shouldBlockNavigation={() => {
//           return isBlocking;
//         }}
//         navigate={(path) => history.push(path)}
//       />
//
//       <InvoicePrintDialog
//         openDialog={openDialog}
//         handleCloseDialog={handleCloseDialog}
//         message="Invoice"
//       >
//         <RightAlignedContainer>
//           <button
//             onClick={handlePrint}
//             type="button"
//             className="btn pharmacy-amber-btn mr-3"
//           >
//             Print
//           </button>
//           <button
//             onClick={handlePreview}
//             type="button"
//             className="btn pharmacy-info-btn mr-3"
//           >
//             Preview
//           </button>
//           <Link to="/manageinvoice">
//             <button type="button" className="btn pharmacy-grey-btn ">
//               No
//             </button>
//           </Link>
//         </RightAlignedContainer>
//       </InvoicePrintDialog>
//
//       <CustomDialog
//         // open={isOpen}
//         // handleClose={handleCloseExpiredDialog}
//         title="Drug about to Expire!"
//         // buttons={buttons}
//       >
//         Do you want to proceed with this action? fo
//       </CustomDialog>
//
//       <div className="receipt-container">
//         <div className="text-md-right my-4">
//           <button
//             data-testid="quick_add_customer"
//             type="button"
//             className="btn btn-sm  pharmacy-info-btn mr-4"
//             onClick={() => handleOpenCustomerDialog("")}
//           >
//             Quick Add Customer
//           </button>
//           {/*<button type='button' className='btn btn-sm  pharmacy-info-btn' onClick={()=>handleOpen('')}>Quick Add Provider</button>*/}
//         </div>
//         {requiredLimit ? (
//           <div className="mb-4">
//             <Alert severity="error">
//               The customer has exceeded their credit limit. They can only
//               purchase items within the credit limit{" "}
//             </Alert>
//           </div>
//         ) : null}
//         <form
//           onSubmit={handleSaveInvoice}
//           autoComplete="off"
//           data-testid="create-pos-invoice-form"
//         >
//           <div className="row">
//             <div className="col-lg-6">
//               <Form.Group as={Row}>
//                 <Col sm="3">
//                   <Label name="Bill To" type />
//                 </Col>
//                 <Col sm="8">
//                   <AutoCompleteUnControlled
//                     options={allCustomers}
//                     submitted={submitted}
//                     handleChange={handleRetrieveCustomerInfo}
//                     medicine_value={customer}
//                     label_text={"customer_name"}
//                     value_text={"customer_id"}
//                     handleProductData={handleClickCustomer}
//                     testId="customer"
//                     id="customer"
//                     placeholder="Type Customer Name"
//                     errorMsg="Enter Customer Name"
//                   />
//                 </Col>
//               </Form.Group>
//               <Form.Group as={Row}>
//                 <Col sm="3">
//                   <Label name="Credit Limit" />
//                 </Col>
//                 <Col sm="8">
//                   <TextField
//                     data-testid="creditLimit"
//                     type="text"
//                     value={
//                       credit_limit ? formatAmount(credit_limit) : credit_limit
//                     }
//                     readOnly
//                   />
//                 </Col>
//               </Form.Group>
//               <Form.Group as={Row}>
//                 <Col sm="3">
//                   <Label name="Outstanding" />
//                 </Col>
//                 <Col sm="8">
//                   <TextField
//                     data-testid="outstanding_balance"
//                     type="text"
//                     value={
//                       previous_amount
//                         ? formatAmount(previous_amount)
//                         : previous_amount
//                     }
//                     readOnly
//                   />
//                 </Col>
//               </Form.Group>
//               {/*<Form.Group as={Row}>*/}
//               {/*    <Col sm='3'>*/}
//               {/*        <Label name='Invoice For' type/>*/}
//               {/*    </Col>*/}
//               {/*    <Col sm='8'>*/}
//               {/*        <SelectInput defaultOption='Select provider...'   value={provider} onChange={handleChangeProvider}*/}
//               {/*                       options={providers} submitted={submitted}/>*/}
//               {/*        {(submitted && !provider) &&*/}
//               {/*            <div className="invalid-text">Please select the provider</div>}*/}
//               {/*    </Col>*/}
//
//               {/*</Form.Group>*/}
//               <Form.Group as={Row} className="mb-0">
//                 <Col sm="3">
//                   <Label name="Account Name" type />
//                 </Col>
//                 <Col sm="8">
//                   <TextField
//                     type="text"
//                     data-testid="account_name"
//                     value={account_name}
//                     submitted={submitted}
//                     name="account_name"
//                     onChange={handleChangeProviderDetails}
//                   />
//                   {submitted && !account_name && (
//                     <div className="invalid-text">
//                       Please enter the{" "}
//                       {isInsurance ? "policy holder" : "account Name"}
//                     </div>
//                   )}
//                 </Col>
//               </Form.Group>
//               <Form.Group as={Row}>
//                 <Col sm="3" />
//                 <Col sm="8">
//                   <Form.Check
//                     type="checkbox"
//                     label="Add card number"
//                     name="isCardNumber"
//                     data-testid="add_card_number"
//                     value={isCardNumber}
//                     checked={isCardNumber}
//                     onChange={handleChangeIsCard}
//                   />
//                 </Col>
//               </Form.Group>
//               {isCardNumber ? (
//                 <Form.Group as={Row}>
//                   <Col sm="3">
//                     <Label name="Card Number" type />
//                   </Col>
//                   <Col sm="8">
//                     <TextField
//                       data-testid="card_number"
//                       type="text"
//                       value={card_number}
//                       submitted={submitted}
//                       name="card_number"
//                       onChange={handleChangeProviderDetails}
//                     />
//                     {submitted && !card_number && (
//                       <div className="invalid-text">
//                         Please enter the card number
//                       </div>
//                     )}
//                   </Col>
//                 </Form.Group>
//               ) : null}
//             </div>
//             <div className="col-lg-6">
//               <Form.Group as={Row}>
//                 <Col sm="3">
//                   <Label name="Invoice Date" />
//                 </Col>
//                 <Col sm="9">
//                   <TextField
//                     data-testid="invoice_date"
//                     type="date"
//                     value={invoice_date}
//                     name="invoice_date"
//                     onChange={handleChangeFormFields}
//                   />
//                   {submitted && !invoice_date ? (
//                     <ErrorMessage>Invoice date is required</ErrorMessage>
//                   ) : null}
//                   {validate_invoice_date === false ? (
//                     <ErrorMessage>
//                       Please select a valid date, you selected a date in the
//                       future
//                     </ErrorMessage>
//                   ) : null}
//                 </Col>
//               </Form.Group>
//               <Form.Group as={Row}>
//                 <Col sm="3">
//                   <Label name="Grand Total" />
//                 </Col>
//                 <Col sm="9">
//                   <TextField
//                     type="number"
//                     data-testid="grand_total_price"
//                     name="grand_total_price"
//                     value={grand_total_price}
//                     placeholder="0.00"
//                     readOnly
//                   />
//                 </Col>
//               </Form.Group>
//
//               {/*</Form.Group>*/}
//               {/*<Form.Group as={Row}>*/}
//               {/*    <Col sm='3'>*/}
//               {/*        <Label name='Total Discount'/>*/}
//               {/*    </Col>*/}
//               {/*    <Col sm='8'>*/}
//               {/*        <TextField type="number" name="total_discount_amount" placeholder="0.00"*/}
//               {/*                   value={total_discount_amount} readOnly/>*/}
//               {/*    </Col>*/}
//
//               {/*</Form.Group>*/}
//               <Form.Group as={Row}>
//                 <Col sm="3">
//                   <Label name="Net Total" />
//                 </Col>
//                 <Col sm="9">
//                   <TextField
//                     type="number"
//                     data-testid="net_total"
//                     name="n_total"
//                     placeholder="0.00"
//                     value={n_total}
//                     readOnly
//                   />
//                 </Col>
//               </Form.Group>
//               <Form.Group as={Row}>
//                 <Col sm="3">
//                   <Label name="Memo" />
//                 </Col>
//                 <Col sm="9">
//                   <TextField
//                     as="textarea"
//                     name="inva_details"
//                     data-testid="invoice_memo"
//                     value={inva_details}
//                     onChange={handleChangeFormFields}
//                   />
//                 </Col>
//               </Form.Group>
//               {/*<Form.Group as={Row}>*/}
//               {/*    <Col sm='3'>*/}
//               {/*        <Label name='Paid Amount'/>*/}
//               {/*    </Col>*/}
//               {/*    <Col sm='8'>*/}
//               {/*        <TextField type="number" value={paid_amount}*/}
//               {/*                   onChange={handleChangeBalance}*/}
//               {/*                   name="paid_amount" placeholder="0.00"/>*/}
//               {/*        {(submitted && !paid_amount) &&*/}
//               {/*            <div className="invalid-text">Please enter the paid amount</div>}*/}
//               {/*    </Col>*/}
//
//               {/*</Form.Group>*/}
//               {/*<Form.Group as={Row}>*/}
//               {/*    <Col sm='3'>*/}
//               {/*        <Label name='Due Amount'/>*/}
//               {/*    </Col>*/}
//               {/*    <Col sm='8'>*/}
//               {/*        <TextField type="number" name="due_amount" placeholder="0.00"*/}
//               {/*                   value={due_amount} readOnly/>*/}
//               {/*    </Col>*/}
//
//               {/*</Form.Group>*/}
//             </div>
//           </div>
//           {submitted && rows.length <= 0 ? (
//             <div className="mb-2">
//               <Alert severity="error">
//                 Please add a product before submitting
//               </Alert>
//             </div>
//           ) : null}
//           <div className="table-responsive">
//             <table className="table table-bordered table-sm">
//               <thead>
//                 <tr>
//                   <td width="400" style={{ verticalAlign: "middle" }}>
//                     Item Information
//                     <span className="text-danger asterics ml-1">*</span>
//                   </td>
//                   <td width="120" style={{ verticalAlign: "middle" }}>
//                     Total Stock
//                   </td>
//                   <td width="200" style={{ verticalAlign: "middle" }}>
//                     Batch<span className="text-danger asterics ml-1">*</span>
//                   </td>
//                   <td width="150" style={{ verticalAlign: "middle" }}>
//                     Ava.Qty
//                   </td>
//                   <td width="200" style={{ verticalAlign: "middle" }}>
//                     Expiry
//                   </td>
//                   <td width="180" style={{ verticalAlign: "middle" }}>
//                     Unit
//                   </td>
//                   <td width="200" style={{ verticalAlign: "middle" }}>
//                     Qty<span className="text-danger asterics ml-1">*</span>
//                   </td>
//                   <td width="200" style={{ verticalAlign: "middle" }}>
//                     Price
//                   </td>
//                   {/*<td width='80' style={{verticalAlign:'middle'}}>Discount%</td>*/}
//                   <td width="400" style={{ verticalAlign: "middle" }}>
//                     Total
//                   </td>
//                   <td style={{ verticalAlign: "middle" }}> Action</td>
//                 </tr>
//               </thead>
//               <tbody>
//                 {rows.map((item, idx) => {
//                   return (
//                     <tr id="addr0" key={idx}>
//                       <td width="15%">
//                         <AutoCompleteMedicine
//                           options={allDrugs}
//                           submitted={submitted}
//                           idx={idx}
//                           handleChange={handleRetrieveDrugs}
//                           label_text="label"
//                           medicine_value={{
//                             label: rows[idx].product_name,
//                             value: rows[idx].product_id,
//                             generic_name: rows[idx].generic_name,
//                           }}
//                           id="product_name"
//                           testId={"product-" + idx}
//                           value_text="value"
//                           handleProductData={handleClickDrug}
//                           placeholder="Type Product Name"
//                           errorMsg="Enter Product Name"
//                           data-testid={"product-" + idx}
//                         />
//                       </td>
//                       <td>
//                         {item.product_name === "" ? "" : item.total_stock}
//                       </td>
//                       <td data-testid={"product_batch-" + idx}>
//                         <SelectItem
//                           options={item?.batch_info?.map(
//                             ({ batch_id, purchase_detail_id }) => {
//                               return { batch_id, purchase_detail_id };
//                             },
//                           )}
//                           submitted={submitted}
//                           idx={idx}
//                           onChange={handleChangeBatch}
//                           value={
//                             item.product_name === ""
//                               ? ""
//                               : item.batch_id
//                                 ? item.batch_id
//                                 : item.batch_info.length > 0
//                                   ? item.batch_info[0]
//                                   : ""
//                           }
//                           errorMsg="Enter Batch ID"
//                           label_text="batch_id"
//                           value_text="purchase_detail_id"
//                         />
//                       </td>
//                       <td>
//                         <TextField
//                           type="text"
//                           data-testid={"available_quantity-" + idx}
//                           name="available_quantity"
//                           value={rows[idx].available_quantity}
//                           disabled={true}
//                           placeholder="0.00"
//                         />
//                       </td>
//                       <td width="10%">
//                         <TextField
//                           type="text"
//                           data-testid={"expiry_date-" + idx}
//                           value={
//                             rows[idx].expiry ? removeTime(rows[idx].expiry) : ""
//                           }
//                           disabled={true}
//                         />
//                       </td>
//                       <td>
//                         <TextField
//                           type="text"
//                           data-testid={"product_unit-" + idx}
//                           name="unit"
//                           value={!rows[idx].unit ? "" : rows[idx].unit}
//                           onChange={(e) => handleChange(e, idx)}
//                           disabled={true}
//                         />
//                       </td>
//                       <td>
//                         <TextField
//                           type="number"
//                           data-testid={"product_quantity-" + idx}
//                           name="product_quantity"
//                           value={rows[idx].product_quantity}
//                           onChange={(e) => handleChangeQuantity(e, idx)}
//                           submitted={submitted}
//                           placeholder="0.00"
//                         />
//                         {submitted && !rows[idx].product_quantity ? (
//                           <div className="invalid-text">Enter the quantity</div>
//                         ) : item.isError ? (
//                           <div className="invalid-text">
//                             Quantity should be less than stock
//                           </div>
//                         ) : null}
//                       </td>
//                       <td>
//                         <TextField
//                           type="text"
//                           data-testid={"product_rate-" + idx}
//                           name="product_rate"
//                           value={item.product_rate}
//                           disabled={true}
//                           placeholder="0.00"
//                         />
//                       </td>
//                       {/*<td>*/}
//                       {/*    <TextField*/}
//                       {/*        type="number"*/}
//                       {/*        data-testid={"discount-"+idx}*/}
//                       {/*        name="discount"*/}
//                       {/*        min="0"*/}
//                       {/*        value={rows[idx].discount}*/}
//                       {/*        onChange={(e) => handleChangeDiscount(e, idx)}*/}
//                       {/*        placeholder="0.00"*/}
//                       {/*    />*/}
//                       {/*    <Form.Control type="hidden" value={rows[idx].tax}/>*/}
//                       {/*</td>*/}
//                       <td>
//                         <TextField
//                           type="text"
//                           data-testid={"total_price-" + idx}
//                           name="total_price"
//                           value={rows[idx].total_price}
//                           onChange={(e) => handleChange(e, idx)}
//                           placeholder="0.00"
//                           readOnly
//                         />
//                       </td>
//                       <td>
//                         <button
//                           type="button"
//                           data-testid={"delete-" + idx}
//                           className="btn btn-outline-danger btn-sm"
//                           onClick={() => handleRemoveSpecificRow(idx)}
//                         >
//                           <FontAwesomeIcon icon={faTrash} />
//                         </button>
//                         <input
//                           type="hidden"
//                           value={rows[idx].total_discount}
//                           className="total_discount dppr"
//                           name="total_discount"
//                         />
//                       </td>
//                     </tr>
//                   );
//                 })}
//                 <tr>
//                   <td colSpan={10}>
//                     <button
//                       type="button"
//                       data-testid="add_row"
//                       onClick={handleAddRow}
//                       className="btn  pharmacy-gray-btn btn-sm "
//                     >
//                       Add another line
//                     </button>
//                   </td>
//
//                   {/*<td>*/}
//                   {/*   Total Amount*/}
//                   {/*</td>*/}
//                   {/*<td colSpan={2}>*/}
//                   {/*    <strong>{formatAmount(grand_total_price)}</strong>*/}
//                   {/*</td>*/}
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//           <div className="text-right">
//             <button
//               type="submit"
//               data-testid="save_invoice"
//               disabled={isPending || requiredLimit}
//               className="btn pharmacy-btn btn-sm"
//             >
//               {isPending ? "Submitting..." : "Submit"}
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// }

function CreatePosInvoice({ actions, snackbars, match: { params } }) {
  const { invoice_id: invoice_number } = params;

  const {
    submitted,
    stateVariables,
    openDialog,
    providerProps,
    isPending,
    handleCloseDialog,
    handleSaveInvoice,
    handleCloseSnackbar,
    provider,
    isCardNumber,
    handleChangeIsCard,
    providerDetails,
    handleChangeProviderDetails,
    receiptData,
    handlePreview,
  } = useCreateInvoice(actions, invoice_number);

  const {
    customer,
    rows,
    state,
    customerProps,
    customerDetails,
    isBlocking,
    totals,
    customers,
    drugs,
    handleChangeFormFields,
    handleChange,
    handleChangeBatch,
    handleAddRow,
    handleRemoveSpecificRow,
    handleChangeQuantity,
    handleRetrieveCustomerInfo,
    handleClickCustomer,
    handleRetrieveDrugs,
    handleClickDrug,
    isOpen,
    expiredDrugReason,
    showExpiredDrugReasonInput,
    showExpiredDrugDialog,
    openExpiredDrugReasonInput,
    closeExpiredDrugReasonInput,
    openExpiredDrugDialog,
    closeExpiredDrugDialog,
    handleChangeExpiredDrugReason,
    handleChangeDiscount
  } = stateVariables;

  const { previous_amount, grand_total_price, n_total } = totals;

  const {
    openCustomerDialog,
    handleOpenCustomerDialog,
    handleCloseCustomerDialog,
  } = customerProps;

  const { openDialog: openProvider, handleCloseDialog: handleClose } =
    providerProps;

  const { credit_limit } = customerDetails;

  const { invoice_date, inva_details } = state;

  const allCustomers = !customers ? [] : customers;

  const allDrugs = !drugs ? [] : drugs;

  const { open, variant, message } = snackbars;

  const { handlePrint, componentRef } = usePrint("");

  useEffect(() => {
    if (!openDialog) {
      return;
    }
    const listener = (event) => {
      if (event.key === "Enter" || event.key === "NumpadEnter") {
        // console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
        handlePrint();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [openDialog]);

  const { customer: invoiceCustomer } = receiptData;

  const { first_name, last_name } = invoiceCustomer;

  const user_name = `${!first_name ? "" : first_name} ${!last_name ? "" : last_name
    }`;

  const provider_type = provider.split("-")[1];

  const isInsurance = provider_type === "INSURANCE";

  const { account_name, card_number } = providerDetails;
  // we are going to get credit left = the creditlimit - outstanding debt
  const remaining_credit = credit_limit- previous_amount;
  const requiredLimit = remaining_credit > 0 && remaining_credit < n_total;

  const validate_invoice_date = validateCurrentDate(invoice_date);

  const buttons = (
    <>
      <button
        onClick={
          showExpiredDrugReasonInput
            ? closeExpiredDrugDialog
            : openExpiredDrugReasonInput
        }
        type="button"
        className="btn btn-danger btn-sm"
      >
        {showExpiredDrugReasonInput ? "Submit" : "Yes"}
      </button>

      {/* <button
        onClick={closeExpiredDrugDialog}
        type="button"
        className="btn btn-sm pharmacy-gray-btn"
      >
        No
      </button> */}
    </>
  );

  return (
    <>
      <ReusableDashboardHeader
        component="New Invoice"
        heading="Sales"
        subHeading="New Invoice"
        link={history.location.pathname}
      />
      <PharmacySnackbar
        open={open}
        variant={variant}
        message={message}
        handleCloseBar={handleCloseSnackbar}
      />
      <DetailsDialog
        title="New Customer"
        openDialog={openCustomerDialog}
        handleClose={handleCloseCustomerDialog}
      >
        <RowCustomerForm {...customerProps} />
      </DetailsDialog>
      <DetailsDialog
        title="New Provider"
        openDialog={openProvider}
        handleClose={handleClose}
      >
        <AddProvider {...providerProps} />
      </DetailsDialog>

      <CustomDialog
        open={showExpiredDrugDialog}
        handleClose={closeExpiredDrugDialog}
        title="Drug about to Expire!"
        buttons={buttons}
      >
        {showExpiredDrugReasonInput ? (
          <>
            <Label name={"Reason for proceeding:"} />
            <Textarea
              data-testid="expired_drug_reason"
              name="reason for expired drug"
              value={expiredDrugReason}
              placeholder="Enter a valid reason"
              id="details"
              onChange={handleChangeExpiredDrugReason}
            />
          </>
        ) : (
          <Label name={"Are you sure you want to proceed with this action?"} />
        )}
      </CustomDialog>

      <div style={{ display: "none" }}>
        <div ref={componentRef} className="card styled-pos-card">
          <ReusablePosTemplate {...{ ...receiptData, user_name }} />
        </div>
      </div>

      <BlockNavigationComponent
        when={isBlocking}
        shouldBlockNavigation={() => {
          return isBlocking;
        }}
        navigate={(path) => history.push(path)}
      />

      <InvoicePrintDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        message="Invoice"
      >
        <RightAlignedContainer>
          <button
            onClick={handlePrint}
            type="button"
            className="btn pharmacy-amber-btn mr-3"
          >
            Print
          </button>
          <button
            onClick={handlePreview}
            type="button"
            className="btn pharmacy-info-btn mr-3"
          >
            Preview
          </button>
          <Link to="/manageinvoice">
            <button type="button" className="btn pharmacy-grey-btn ">
              No
            </button>
          </Link>
        </RightAlignedContainer>
      </InvoicePrintDialog>

      <CustomDialog
        // open={isOpen}
        // handleClose={handleCloseExpiredDialog}
        title="Drug about to Expire!"
      // buttons={buttons}
      >
        Do you want to proceed with this action? fo
      </CustomDialog>

      <div className="receipt-container">
        <div className="text-md-right my-4">
          <button
            data-testid="quick_add_customer"
            type="button"
            className="btn btn-sm  pharmacy-info-btn mr-4"
            onClick={() => handleOpenCustomerDialog("")}
          >
            Quick Add Customer
          </button>
          {/*<button type='button' className='btn btn-sm  pharmacy-info-btn' onClick={()=>handleOpen('')}>Quick Add Provider</button>*/}
        </div>
        {requiredLimit ? (
          <div className="mb-4">
            <Alert severity="error">
              The customer has exceeded their credit limit. They can only
              purchase items within the credit limit{" "}
            </Alert>
          </div>
        ) : null}
        <form
          onSubmit={handleSaveInvoice}
          autoComplete="off"
          data-testid="create-pos-invoice-form"
        >
          <div className="row">
            <div className="col-lg-6">
              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Bill To" type />
                </Col>
                <Col sm="8">
                  <AutoCompleteUnControlled
                    options={allCustomers}
                    submitted={submitted}
                    handleChange={handleRetrieveCustomerInfo}
                    medicine_value={customer}
                    label_text={"customer_name"}
                    value_text={"customer_id"}
                    handleProductData={handleClickCustomer}
                    testId="customer"
                    id="customer"
                    placeholder="Type Customer Name"
                    errorMsg="Enter Customer Name"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Credit Limit" />
                </Col>
                <Col sm="8">
                  <TextField
                    data-testid="creditLimit"
                    type="text"
                    value={
                      credit_limit ? formatAmount(credit_limit) : credit_limit
                    }
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Outstanding" />
                </Col>
                <Col sm="8">
                  <TextField
                    data-testid="outstanding_balance"
                    type="text"
                    value={
                      previous_amount
                        ? formatAmount(previous_amount)
                        : previous_amount
                    }
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-0">
                <Col sm="3">
                  <Label name="Account Name" type />
                </Col>
                <Col sm="8">
                  <TextField
                    type="text"
                    data-testid="account_name"
                    value={account_name}
                    submitted={submitted}
                    name="account_name"
                    onChange={handleChangeProviderDetails}
                  />
                  {submitted && !account_name && (
                    <div className="invalid-text">
                      Please enter the{" "}
                      {isInsurance ? "policy holder" : "account Name"}
                    </div>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="3" />
                <Col sm="8">
                  <Form.Check
                    type="checkbox"
                    label="Add card number"
                    name="isCardNumber"
                    data-testid="add_card_number"
                    value={isCardNumber}
                    checked={isCardNumber}
                    onChange={handleChangeIsCard}
                  />
                </Col>
              </Form.Group>
              {isCardNumber ? (
                <Form.Group as={Row}>
                  <Col sm="3">
                    <Label name="Card Number" type />
                  </Col>
                  <Col sm="8">
                    <TextField
                      data-testid="card_number"
                      type="text"
                      value={card_number}
                      submitted={submitted}
                      name="card_number"
                      onChange={handleChangeProviderDetails}
                    />
                    {submitted && !card_number && (
                      <div className="invalid-text">
                        Please enter the card number
                      </div>
                    )}
                  </Col>
                </Form.Group>
              ) : null}
            </div>
            <div className="col-lg-6">
              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Invoice Date" />
                </Col>
                <Col sm="9">
                  <TextField
                    data-testid="invoice_date"
                    type="date"
                    value={invoice_date}
                    name="invoice_date"
                    onChange={handleChangeFormFields}
                  />
                  {submitted && !invoice_date ? (
                    <ErrorMessage>Invoice date is required</ErrorMessage>
                  ) : null}
                  {validate_invoice_date === false ? (
                    <ErrorMessage>
                      Please select a valid date, you selected a date in the
                      future
                    </ErrorMessage>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Grand Total" />
                </Col>
                <Col sm="9">
                  <TextField
                    type="number"
                    data-testid="grand_total_price"
                    name="grand_total_price"
                    value={grand_total_price}
                    placeholder="0.00"
                    readOnly
                  />
                </Col>
              </Form.Group>
              
              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Net Total" />
                </Col>
                <Col sm="9">
                  <TextField
                    type="number"
                    data-testid="net_total"
                    name="n_total"
                    placeholder="0.00"
                    value={n_total}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Memo" />
                </Col>
                <Col sm="9">
                  <TextField
                    as="textarea"
                    name="inva_details"
                    data-testid="invoice_memo"
                    value={inva_details}
                    onChange={handleChangeFormFields}
                  />
                </Col>
              </Form.Group>
            </div>
          </div>

          {submitted && rows.length <= 0 ? (
            <div className="mb-2">
              <Alert severity="error">
                Please add a product before submitting
              </Alert>
            </div>
          ) : null}
          <div className="table-responsive">
            <table className="table table-bordered table-sm">
              <thead>
                <tr>
                  <td width="400" style={{ verticalAlign: "middle" }}>
                    Item Information
                    <span className="text-danger asterics ml-1">*</span>
                  </td>

                  <td width="120" style={{ verticalAlign: "middle" }}>
                    Total Stock
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Batch<span className="text-danger asterics ml-1">*</span>
                  </td>

                  <td width="150" style={{ verticalAlign: "middle" }}>
                    Ava.Qty
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Expiry
                  </td>

                  <td width="180" style={{ verticalAlign: "middle" }}>
                    Unit
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Qty<span className="text-danger asterics ml-1">*</span>
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Price
                  </td>

                  <td width='80' style={{verticalAlign:'middle'}}>Discount%</td>

                  <td width="400" style={{ verticalAlign: "middle" }}>
                    Total
                  </td>

                  <td style={{ verticalAlign: "middle" }}> Action</td>
                </tr>
              </thead>

              <tbody>
                {rows.map((item, idx) => {
                  return (
                    <tr id="addr0" key={idx}>
                      <td width="15%">
                        <AutoCompleteMedicine
                          options={allDrugs}
                          submitted={submitted}
                          idx={idx}
                          handleChange={handleRetrieveDrugs}
                          label_text="label"
                          medicine_value={{
                            label: rows[idx].product_name,
                            value: rows[idx].product_id,
                            generic_name: rows[idx].generic_name,
                          }}
                          id="product_name"
                          testId={"product-" + idx}
                          value_text="value"
                          handleProductData={handleClickDrug}
                          placeholder="Type Product Name"
                          errorMsg="Enter Product Name"
                          data-testid={"product-" + idx}
                        />
                      </td>

                      <td>
                        {item.product_name === "" ? "" : item.total_stock}
                      </td>

                      <td data-testid={"product_batch-" + idx}>
                        <SelectItem
                          options={item?.batch_info?.map(
                            ({ batch_id, purchase_detail_id }) => {
                              return ({ batch_id, purchase_detail_id });
                            },
                          )}
                          submitted={submitted}
                          idx={idx}
                          onChange={handleChangeBatch}
                          value={
                            item.product_name === ""
                              ? ""
                              : item.batch_id
                                ? item.batch_id
                                : item.batch_info.length > 0
                                  ? item.batch_info[0]
                                  : ""
                          }
                          errorMsg="Enter Batch ID"
                          label_text="batch_id"
                          value_text="batch_id"
                        />
                      </td>

                      <td>
                        <TextField
                          type="text"
                          data-testid={"available_quantity-" + idx}
                          name="available_quantity"
                          value={rows[idx].available_quantity}
                          disabled={true}
                          placeholder="0.00"
                        />
                      </td>
                      <td width="10%">
                        <TextField
                          type="text"
                          data-testid={"expiry_date-" + idx}
                          value={
                            rows[idx].expiry ? removeTime(rows[idx].expiry) : ""
                          }
                          disabled={true}
                        />
                      </td>
                      <td>
                        <TextField
                          type="text"
                          data-testid={"product_unit-" + idx}
                          name="unit"
                          value={!rows[idx].unit ? "" : rows[idx].unit}
                          onChange={(e) => handleChange(e, idx)}
                          disabled={true}
                        />
                      </td>
                      <td>
                        <TextField
                          type="number"
                          data-testid={"product_quantity-" + idx}
                          name="product_quantity"
                          value={rows[idx].product_quantity}
                          onChange={(e) => handleChangeQuantity(e, idx)}
                          submitted={submitted}
                          placeholder="0.00"
                        />
                        {submitted && !rows[idx].product_quantity ? (
                          <div className="invalid-text">Enter the quantity</div>
                        ) : item.isError ? (
                          <div className="invalid-text">
                            Quantity should be less than stock
                          </div>
                        ) : null}
                      </td>
                      <td>
                        <TextField
                          type="text"
                          data-testid={"product_rate-" + idx}
                          name="product_rate"
                          value={item.product_rate}
                          disabled={true}
                          placeholder="0.00"
                        />
                      </td>
                      <td>
                          <TextField
                              type="number"
                            data-testid={"discount-"+idx}
                            name="discount"
                              min="0"
                            value={rows[idx].discount}
                            onChange={(e) => handleChangeDiscount(e, idx)}
                            placeholder="0.00"
                          />
                      <Form.Control type="hidden" value={rows[idx].tax}/>
                      </td>
                      <td>
                        <TextField
                          type="text"
                          data-testid={"total_price-" + idx}
                          name="total_price"
                          value={rows[idx].total_price}
                          onChange={(e) => handleChange(e, idx)}
                          placeholder="0.00"
                          readOnly
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          data-testid={"delete-" + idx}
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => handleRemoveSpecificRow(idx)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                        <input
                          type="hidden"
                          value={rows[idx].total_discount}
                          className="total_discount dppr"
                          name="total_discount"
                        />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={10}>
                    <button
                      type="button"
                      data-testid="add_row"
                      onClick={handleAddRow}
                      className="btn  pharmacy-gray-btn btn-sm "
                    >
                      Add another line
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-right">
            <button
              type="submit"
              data-testid="save_invoice"
              disabled={isPending || requiredLimit}
              className="btn pharmacy-btn btn-sm"
            >
              {isPending ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}
// export default connect(mapStateToProps, mapDispatchToProps)(CreatePosInvoice);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePosInvoice);
