import React from "react";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import { history } from "../../../Helpers/history";
import { Col, Form, Row } from "react-bootstrap";
import Label from "../../../Utils/FormInputs/Label";
import TextField from "../../../Utils/FormInputs/TextField";
import {
  AutoCompleteUnControlled,
  SelectItem,
} from "../../../Utils/FormInputs/AutoCompleteInput";
import { removeTime } from "../../../Utils/titleCaseFunction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import useCreditNote from "../Reports/hooks/useCreditNote";
import SelectInput from "../../../Utils/FormInputs/SelectInput";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { connect } from "react-redux";

const CreditNote = ({ actions, snackbars }) => {
  const {
    selectedInvoice,
    customer,
    setCustomer,
    customersList,
    creditNoteDate,
    invoiceNumberDetails,
    creditNoteDetails,
    productDetails,
    snackbarOpen,
    // setSnackbarOpen,
    handleSaveCreditNote,
    previousInvoiceNumbers,
    handleRetrieveCustomerInfo,
    handleClickCustomer,
    handleCreditNoteDateChange,
    handleCreditMemoDetailsChange,
    handleCreditMemoNumber,
    creditMemoNumber,
    handlePreviousInvoiceNumber,
    totalAmount,
    drugs,
    handleRetrieveDrugs,
    handleClickDrug,
    handleChangeBatch,
    handleChange,
    handleChangeQuantity,
    handleRemoveSpecificRow,
    handleAddRow,
    rows,
    selectedPreviousInvoiceNumber,
    grandTotal
  } = useCreditNote(actions);

  // console.log(customer)
  // console.log(selectedInvoice, selectedPreviousInvoiceNumber);

  // const invoiceNos =
    // previousInvoiceNumbers?.map((item) => item.invoice_no) ?? [];

  const invoiceOptions =
    previousInvoiceNumbers?.map((invoiceNo) => ({
      value: invoiceNo?.id,
      text: invoiceNo?.invoice_no,
    })) ?? [];

  console.log(rows)

  return (
    <div data-testid="credit-note-screen">
      <ReusableDashboardHeader
        component="Credit Note"
        heading="Credit"
        subHeading="Credit Note"
        link={history.location.pathname}
      />

      <div className="receipt-container">
        <form
          onSubmit={handleSaveCreditNote}
          autoComplete="off"
          data-testid="debit-note-form"
        >
          <div className="row">
            <div className="col-lg-6">
              <Form.Group>
                <Col sm="3">
                  <Label name="Customer:" />
                </Col>

                <Col sm="8">
                  <AutoCompleteUnControlled
                    options={customersList}
                    // submitted={submitted}
                    handleChange={handleRetrieveCustomerInfo}
                    label_text={"customer_name"}
                    value_text={"customer_id"}
                    medicine_value={customer}
                    handleProductData={handleClickCustomer}
                    testId="customer"
                    id="customer"
                    placeholder="Type Customer Name"
                    errorMsg="Enter Customer Name"
                  />

                  {/* {submitted && !invoice_date ? (
                    <ErrorMessage>Invoice date is required</ErrorMessage>
                  ) : null}

                  {validate_invoice_date === false ? (
                    <ErrorMessage>
                      Please select a valid date, you selected a date in the
                      future
                    </ErrorMessage>
                  ) : null} */}
                </Col>
              </Form.Group>

              <Form.Group>
                <Col sm="4">
                  <Label name="Credit Memo No:" />
                </Col>

                <Col sm="8">
                  <TextField
                    type="text"
                    value={creditMemoNumber}
                    name="credit-memo-no"
                    data-testid="credit-memo-no"
                    onChange={handleCreditMemoNumber}
                  />
                </Col>
              </Form.Group>

              <Form.Group>
                <Col sm="4">
                  <Label name="Previous Invoice Nos:" />
                </Col>

                <Col sm="8">
                  <SelectInput
                    data-testid="previous_invoice"
                    value={selectedPreviousInvoiceNumber}
                    onChange={handlePreviousInvoiceNumber}
                    options={invoiceOptions}
                    defaultOption="Select invoice number..."
                    // submitted={submitted}
                  />
                </Col>
              </Form.Group>
            </div>

            <div className="col-lg-6">
              <Form.Group>
                <Col sm="4">
                  <Label name="Credit Memo Date:" />
                </Col>

                <Col sm="8">
                  <TextField
                    type="date"
                    name="credit-memo-date"
                    data-testid="credit-memo-date"
                    value={creditNoteDate}
                    onChange={handleCreditNoteDateChange}
                    required={true}
                  />
                </Col>
              </Form.Group>

              <Form.Group>
                <Col sm="4">
                  <Label name="Credit Memo Details:" />
                </Col>

                <Col sm="8">
                  <TextField
                    as="textarea"
                    value={creditNoteDetails}
                    data-testid="credit_note_details"
                    name="credit_note_details"
                    onChange={handleCreditMemoDetailsChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group>
                <Col sm="3">
                  <Label name="Total Amount" />
                </Col>

                <Col sm="8">
                  <TextField
                    type="number"
                    data-testid="total_amount"
                    name="n_total"
                    placeholder="0.00"
                    value={grandTotal}
                    readOnly
                  />
                </Col>
              </Form.Group>
            </div>
          </div>

          <div className="tabel-responsive">
            <table className="table table-bordered table-sm">
              <thead>
                <tr>
                  <td width="400" style={{ verticalAlign: "middle" }}>
                    Item Information
                    <span className="text-danger asterics ml-1">*</span>
                  </td>

                  <td width="120" style={{ verticalAlign: "middle" }}>
                    Total Stock
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Batch<span className="text-danger asterics ml-1">*</span>
                  </td>

                  <td width="150" style={{ verticalAlign: "middle" }}>
                    Ava.Qty
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Expiry
                  </td>

                  <td width="180" style={{ verticalAlign: "middle" }}>
                    Unit
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Qty<span className="text-danger asterics ml-1">*</span>
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Price
                  </td>

                  {/*<td width='80' style={{verticalAlign:'middle'}}>Discount%</td>*/}

                  <td width="400" style={{ verticalAlign: "middle" }}>
                    Total
                  </td>

                  <td style={{ verticalAlign: "middle" }}> Action</td>
                </tr>
              </thead>

              <tbody>
                {rows?.map((item, idx) => {
                  return (
                    <tr id="addr0" key={item.uuid_id}>
                      <td width="15%">
                        <AutoCompleteUnControlled
                          options={drugs}
                          // submitted={submitted}
                          idx={idx}
                          handleChange={handleRetrieveDrugs}
                          label_text="label"
                          medicine_value={{
                            label: rows[idx].product_name,
                            value: rows[idx].product_id,
                          }}
                          data-testid={"product-" + idx}
                          id="product_name"
                          value_text="value"
                          handleProductData={handleClickDrug}
                          placeholder="Type Product Name"
                          errorMsg="Enter Product Name"
                        />
                      </td>

                      <td>
                        {item.product_name === "" ? "" : item.total_stock}
                      </td>

                      <td>
                        <TextField
                          // options={item?.batch_info?.map(
                          //   ({ batch_id, purchase_detail_id }) => {
                          //     return { batch_id, purchase_detail_id };
                          //   },
                          // )}
                          // submitted={submitted}
                          idx={idx}
                          // onChange={handleChangeBatch}
                          value={ item.batch_id
                                ? item.batch_id: ""
                          }
                          data-testid={"product_batch-" + idx}
                          errorMsg="Enter Batch ID"
                          label_text="batch_id"
                          value_text="purchase_detail_id"
                          disabled={true}
                        />
                      </td>

                      <td>
                        <TextField
                          type="text"
                          name="available_quantity"
                          data-testid={"available_quantity-" + idx}
                          value={rows[idx].aval_stock}
                          disabled={true}
                          placeholder="0"
                        />
                      </td>

                      <td width="10%">
                        <TextField
                          type="text"
                          data-testid={"expiry_date-" + idx}
                          value={
                            rows[idx].expiry_date ? rows[idx].expiry_date: ""
                          }
                          disabled={true}
                        />
                      </td>

                      <td>
                        <TextField
                          type="text"
                          name="unit"
                          data-testid={"product_unit-" + idx}
                          value={!rows[idx].unit ? "" : rows[idx].unit}
                          onChange={(e) => handleChange(e, idx)}
                          disabled={true}
                        />
                      </td>

                      <td>
                        <TextField
                          type="number"
                          name="quantity"
                          data-testid={"quantity-" + idx}
                          value={rows[idx]?.quantity}
                          min={0}
                          onChange={(e) => handleChangeQuantity(e, idx)}
                          // submitted={submitted}
                          placeholder="0.00"
                        />

                        {!rows[idx]?.quantity ? (
                          <div className="invalid-text">Enter the quantity</div>
                        ) : null}

                        {/*{*/}
                        {/*  // submitted &&*/}
                        {/*  !rows[idx].product_quantity ? (*/}
                        {/*    <div className="invalid-text">*/}
                        {/*      Enter the quantity*/}
                        {/*    </div>*/}
                        {/*  ) : item.isError ? (*/}
                        {/*    <div className="invalid-text">*/}
                        {/*      Quantity should be less than stock*/}
                        {/*    </div>*/}
                        {/*  ) : null*/}
                        {/*}*/}
                      </td>

                      <td>
                        <TextField
                          type="text"
                          data-testid={"product_rate-" + idx}
                          name="product_rate"
                          value={item.rate}
                          //   disabled={true}
                          placeholder="0.00"
                        />
                      </td>

                      <td>
                        <TextField
                          type="text"
                          data-testid={"total_price-" + idx}
                          name="total_price"
                          value={rows[idx].total_price}
                          onChange={(e) => handleChange(e, idx)}
                          placeholder="0.00"
                          readOnly
                        />
                      </td>

                      <td>
                        <button
                          type="button"
                          data-testid={"delete-" + idx}
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => handleRemoveSpecificRow(idx)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>

                        <input
                          type="hidden"
                          value={rows[idx].total_discount}
                          className="total_discount dppr"
                          name="total_discount"
                        />
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <td colSpan={10}>
                    <button
                      type="button"
                      onClick={handleAddRow}
                      data-testid="add-row"
                      className="btn  pharmacy-gray-btn btn-sm "
                    >
                      Add another line
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="text-md-right">
            <button
              data-testid="save_sale"
              type="submit"
              // disabled={isPending}
              className="btn pharmacy-btn"
            >
              {/* {isPending ? "Submitting..." : "Submit"} */}
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditNote);
