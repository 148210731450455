import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FilterResults from "react-filter-search";
import { titleCase } from "../../Utils/titleCaseFunction";
import { useCompany } from "../../Context/company-context";
import config from "../../Helpers/config.json";
import axios from "axios";
import { useSnackbar } from "../../Context/snackbar-context";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPagination,
  MuiPaper,
} from "../../Utils/Tables/CustomTable";
import Form from "react-bootstrap/Form";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import { history } from "../../Helpers/history";
import {
  useExcelReports,
  usePdfReports,
} from "../Invoice/CustomHooks/useExcelReports";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import { bindActionCreators } from "redux";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import { connect } from "react-redux";

const headData = [
  { id: "sl", numeric: false, disablePadding: false, label: "Sl" },
  {
    id: "medicineName",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },
  { id: "quantity", numeric: false, disablePadding: false, label: "Quantity" },
  { id: "batch", numeric: false, disablePadding: false, label: "Batch ID" },
  { id: "expiry", numeric: false, disablePadding: false, label: "Expiry Date" },
  {
    id: "purchase",
    numeric: false,
    disablePadding: false,
    label: "Purchase Price",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Total Amount",
  },
];

function ManufacturerInvoice({ match: { params }, snackbars, actions }) {
  const { purchase_id } = params;
  const [term, setTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [productPurchase, setProductPurchase] = useState({});
  const [purchasesReceipts, setPurchasesReceipts] = useState([]);
  const [loading, setLoading] = useState("idle");
  const { state: snackbarState, handleClose, handleError } = useSnackbar();

  const currentObj = {
    file_name: "Purchases",
    url: "Cpurchase/purchase_excel_export",
    params: {
      purchase_id,
    },
  };
  const { open, message, variant } = snackbars;

  useEffect(() => {
    setLoading("loading");
    axios
      .get(`${config.epharmUrl}/Cpurchase/purchase_details_data`, {
        params: { purchase_id, search: term },
      })
      .then((response) => {
        const data = response.data;
        const dt = data ? data : {};
        setLoading("success");
        setProductPurchase(dt);
      })
      .catch(() => {
        handleError("Operation unsuccessful");
        setLoading("error");
      });
  }, [term]);

  const handleSearch = (event) => {
    setTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const { exportExcel } = useExcelReports(actions, currentObj);

  const pdfObj = {
    file_name: "Purchases",
    url: "Cpurchase/purchase_pdf",
    params: {
      purchase_id,
      download: true,
    },
  };

  const { exportPDF } = usePdfReports(actions, pdfObj);

  const isLoading = loading === "loading";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  const purchase = !productPurchase ? {} : productPurchase;
  const purchaseData = !purchase.purchase_all_data
    ? []
    : purchase.purchase_all_data;

  const { state } = useCompany();
  const { logo } = state;

  // const downloadPDF = () => {
  //   const allReceipts = !purchasesReceipts ? [] : purchasesReceipts;
  //   exportPDF(
  //     "Purchase Report",
  //     [
  //       [
  //         "sl",
  //         "Product_name",
  //         "Quantity",
  //         "Batch_id",
  //         "Expiry_date",
  //         "Purchase_price",
  //         "Total Amount",
  //       ],
  //     ],
  //     purchaseData.map((purchase, index) => [
  //       (index += 1),
  //       purchase.product_name,
  //       purchase.quantity,
  //       purchase.batch_id,
  //       purchase.expeire_date,
  //       purchase.manufacturer_price,
  //       purchase.total_amount,
  //     ]),
  //     "Purchases.pdf"
  //   );
  // };

  return (
    <div>
      <ReusableDashboardHeader
        component="Purchases"
        heading="Purchases"
        subHeading="Purchases"
        link={history.location.pathname}
      />
      <div className="mx-3 mt-5">
        <div className="pos-buttons">
          <Link
            to="/addpurchase"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <button
              data-testid="add_purchase"
              type="button"
              className="btn mr-2 pharmacy-btn"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Purchase
            </button>
          </Link>
          <Link to="/managepurchase">
            <button
              data-testid="manage_purchase"
              type="button"
              className="btn pharmacy-info-btn mr-2"
            >
              <FontAwesomeIcon icon={faList} /> Manage Purchase
            </button>
          </Link>
        </div>
        <div className="text-center">
          <img
            style={{ maxWidth: "100px" }}
            src={logo ? logo : "/images/mpeke3.png"}
            alt="mpeke"
          />
          <p data-testid="purchase_info" style={{ fontSize: "16px" }}>
            {titleCase(purchase.manufacturer_name)}
            <br />
            Date :&nbsp;{purchase.final_date} <br />
            Invoice No: &nbsp; {purchase.chalan_no}
            <br /> Details: &nbsp;{titleCase(purchase.purchase_details)}
          </p>
        </div>
        <PharmacySnackbar
          open={open}
          variant={variant}
          message={message}
          handleCloseBar={handleClose}
        />
        <MuiPaper>
          <EnhancedTableToolbar title="Purchases">
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  onClick={exportExcel}
                  className="btn btn-sm pharmacy-btn mr-1"
                >
                  Excel
                </button>
              </span>
              <span>
                <button
                  className="btn pharmacy-btn mr-1 btn-sm"
                  onClick={exportPDF}
                >
                  Pdf
                </button>
              </span>
            </span>
            <Form.Control
              type="text"
              value={term ? titleCase(term) : ""}
              className="form__control"
              onChange={handleSearch}
              placeholder="Search"
              style={{ float: "right" }}
            />
          </EnhancedTableToolbar>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead headCells={headData} />
            <FilterResults
              value={term}
              data={purchaseData}
              renderResults={(results) => (
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={headData.length} align="center">
                        <img
                          src="/images/listPreloader.gif"
                          alt="loader"
                          style={{ width: "5%" }}
                        />
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {isSuccess ? (
                    purchaseData.length > 0 ? (
                      results
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((d, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                              className="mui-table-row"
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="mui-table-cell"
                              >
                                <span>{d.sl}</span>
                              </TableCell>
                              <TableCell>
                                <span data-testid={"product_name-" + index}>
                                  <Link
                                    to={{
                                      pathname: `/medicinedetails/${d.id}`,
                                    }}
                                    style={{ color: "#09905c" }}
                                  >
                                    {titleCase(d.product_name)}
                                  </Link>
                                </span>
                              </TableCell>
                              <TableCell>
                                <span data-testid={"quantity-" + index}>
                                  {d.quantity}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span data-testid={"batch_id-" + index}>
                                  {d.batch_id}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span data-testid={"expiry_date-" + index}>
                                  {d.expeire_date}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span data-testid={"purchase_price-" + index}>
                                  {d.manufacturer_price}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span data-testid={"total_amount-" + index}>
                                  {d.total_amount}
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell
                          data-testid="no_records_found"
                          colSpan={headData.length}
                          align="center"
                        >
                          No records found
                        </TableCell>
                      </TableRow>
                    )
                  ) : null}
                  {isError ? (
                    <TableRow>
                      <TableCell colSpan={headData.length} align="center">
                        The server did not return a valid response
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              )}
            />
          </Table>
          <MuiPagination
            data={purchaseData}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            rows={[10, 20, 30, 40, 50]}
            colSpan={headData.length}
          />
        </MuiPaper>
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManufacturerInvoice);
