import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
// import {useCloseLpo} from "./useCloseLpo";
import { useCloseLpo } from "../../Purchase/CustomHooks/useCloseLpo";
import { titleCase } from "../../../Utils/titleCaseFunction";
import { errorMessages } from "../../../Helpers/ErrorMessages";

export const useInconsistentBatches = (actions, params = {}) =>{
  const { product_id: prdt_id, product_name: prdt_name, batch_id: bat_id } = params;

    const [batches, setBatches] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('')
    const [product, setProduct] = useState({
      product_name: "",
      product_id: prdt_id,
      selected_product: prdt_name,
      batch_id: bat_id
    });
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] =useState(0)

    useEffect(() => {
      setLoading("loading");
        axios.get(
            `${config.epharmUrl}/Cinvoice/batch_inconsistencies?search=${search}&per_page=${perPage}&page=${page}`).then(response => {
            const res = response.data;
            const dt = !res.data ? {} : res.data;
            const arr = dt.data ? dt.data : [];
            setLoading("success");
            setBatches(arr);
            setCount(arr.length)
        }).catch(error => {
            errorMessages(error, null, actions);
            setLoading(false)
        })
    }, [page,search])

    const handleChangePage=(newPage) =>{
          setPage(newPage)
    }

    const handleChangeSearchValue = (e) =>{
        setSearch(e.target.value)

    }
    return {batches,product, search, handleChangeSearchValue,handleChangePage,page,count,perPage}
}