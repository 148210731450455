import {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {exportPDF} from "../../../Utils/exportPDF";
import {copyToClipboard} from "../../../Utils/copyToClipboard";
import { titleCase } from "../../../Utils/titleCaseFunction";

export const useManageMedicine = (actions) =>{
    const storage = JSON.parse(sessionStorage.getItem('total_count'))
    const item_count = storage ? storage : 0
    const count = () => +item_count || 0
    const [openDialog, setOpenDialog] = useState(false);
    const [product_id, setProductId] = useState('');
    const [product_count, setProductCount] = useState(count);
    const [activePage, setActivePage] = useState(1);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [searchValue, setSearchValue] = useState('');
    const csvLink = useRef();
    const componentRef = useRef();
    const [product_categories, setProductCategories] = useState(null);
    const [categoryList, setCategories] = useState([]);



    const retrieveProducts = useCallback(()=>{
        setLoading('loading')
        const cat_id = product_categories ? product_categories.category_id ?? '' : '';
        axios.get(`${config.epharmUrl}/Cproduct/products`, {params:{page_number:activePage,  category_id: cat_id}}).then(response => {
            const res = response.data;
            const dt = res ? res : {};
            const data = dt.data ? dt.data : {};
            const arr = data.products ? data.products : []
            const p_count = data.total_records ? data.total_records : 0;
            if(activePage === 1){
                sessionStorage.setItem('total_count', JSON.stringify(p_count))
                setProductCount(p_count);
            }else {
                setProductCount(count);
            }
            setProducts(arr);
            setLoading('success');
        }).catch(error => {
            errorMessages(error, null, actions);
            setLoading('error')
        })
    }, [activePage, product_categories])

    useEffect(() => {
        retrieveProducts()
    }, [retrieveProducts])

    useEffect(()=>{
        axios.get(`${config.epharmUrl}/Ccategory/manage_category`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const categories = dt.category_list ? dt.category_list : [];
            const arr = categories.map(category => ({
                value: category.category_id,
                label: titleCase(category.category_name)
            }));
           setCategories(categories);
        }).catch(error => {
            errorMessages(error, null,actions)
        })
    },[])

    const handleChange = (event) => {
        setSearchValue(event.target.value)
        if(event.target.value !== ''){
            axios.get(`${config.epharmUrl}/Cproduct/product_list_search`, {params:{search:event.target.value}}).then(response => {
                const res = response.data;
                const dt = res ? res : [];
                const arr = dt.map(item=>({...item, price:item.price}))
                setProducts(arr);
            }).catch(error => {
                errorMessages(error, null, actions)
                setLoading('error')
            })
        }else{
            retrieveProducts()
        }
    };

    const handleOpenDialog = (_id) => {
        setProductId(_id)
        setOpenDialog(true)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleDelete = () => {
        axios.post(`${config.epharmUrl}/Cproduct/product_delete/${product_id}`).then(() => {
            const arr = products.filter(medicine => medicine.product_id !== product_id);
            setProducts(arr)
            actions.snackbarActions.successSnackbar('Product Successfully deleted');
            setOpenDialog(false)
        }).catch(error => {
            errorMessages(error, null, actions)
            setOpenDialog(false)
        });
    };

    const downloadPDF = () => {
        exportPDF("Product Report", [["Sl", "Product Name", "Generic Name", "Product Type", "Unit",
                "Shelf", "Strength"]],
            products.map((med, index) => [index += 1, med.product_name, med.generic_name,
                med.product_model, med.unit, med.manufacturer_name, med.product_location, med.price,
                med.purchase_p, med.strength]), "Products.pdf");
    };

    const copyTable = () => {
        copyToClipboard(actions, 10, componentRef)
    };

    const fetchData = () => {
       csvLink.current.link.click()
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };
    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const handleClickProductCategories = (value) => {
        setProductCategories(value)
    }

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return {openDialog, product_id, product_count, activePage, products, loading, searchValue,
         csvLink, handleChange, handleOpenDialog, handleCloseDialog, downloadPDF,
        copyTable, handleCloseBar, fetchData, handlePageChange,handleDelete, isLoading,isSuccess,
        isError, product_categories, handleClickProductCategories, categoryList
    }
}