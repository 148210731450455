import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as purchaseActions from "../../Redux/Actions/PurchaseActions/PurchaseActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {
  dateStringTimeConvert,
  titleCase,
} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import { useMuiPagination } from "../../Containers/Dasboard/DashboardTables";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPagination,
  MuiPaper,
} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import SelectInput from "../../Utils/FormInputs/SelectInput";
// import { usePurchaseOrders } from "./CustomHooks/usePurchaseOrders";
import { usePurchaseOrders } from "../Purchase/CustomHooks/usePurchaseOrders";
import CustomDialog from "../../Utils/Dialog/CustomDialog";
import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import Textarea from "../../Utils/FormInputs/Textarea";
import { RightAlignedContainer } from "../../Utils/styledComponents";
import { useInconsistentBatches } from "./hooks/useInconsistentBatches";
import { AutoCompleteUnControlled } from "../../Utils/FormInputs/AutoCompleteInput";
import { CustomPagination } from "../../Utils/Tables/CustomTable";
import {
  useExcelReports,
  usePdfReports,
} from "../Invoice/CustomHooks/useExcelReports";

export const useStyles = makeStyles((theme) => ({
  icon: {
    width: ".95rem",
  },
  approved: {
    color: "green",
  },
  canceled: {
    color: "#D9512C",
  },
  pending: {
    color: "#F29339",
  },
  purchased: {
    color: "#1d5dd3",
  },
  closed: {
    color: "#a30c0c",
  },
}));

const headData = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },
  { id: "batch", numeric: false, disablePadding: false, label: "Batch Number" },
  {
    id: "stock",
    numeric: false,
    disablePadding: false,
    label: "Stock",
  },
  { id: "action", numeric: false, disablePadding: false, label: "Action" },
];

const InconsistentBatches = ({ actions, snackbars }) => {
  // const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
  // useMuiPagination();
  const { handleCloseBar } = usePurchaseOrders(actions);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const singleUser = !user ? {} : user;

  const { open, message, variant } = snackbars;

  const {
    drugs,
    search,
    handleChangeSearchValue,
    loading,
    batches,
    page,
    perPage,
    handleChangePage,
    count,
  } = useInconsistentBatches();
  const allDrugs = !drugs ? [] : drugs;
  const objPDF = {
    file_name: "Purchase Orders",
    url: "Cpurchaseorder/purchase_orders_pdf",
  };
  const objExcel = {
    file_name: "Purchase Orders",
    url: "Cpurchaseorder/purchase_orders_excel",
  };

  const { exportPDF } = usePdfReports(actions, objPDF);
  const { exportExcel } = useExcelReports(actions, objExcel);

  // console.log(batches)

  return (
    <div className="journals">
      <ReusableDashboardHeader
        component="Inconsistent Batches"
        heading="Inconsistent Batches"
        subHeading="Inconsistent Batches"
        link="/managepurchaseorder"
      />
      <div className="general-ledger-header mb-3">
        <Grid container spacing={0}>
          <Grid item xs={6} md={8}></Grid>
          <Grid item xs={6} md={4}>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="text-right"
            >
              <div style={{ marginLeft: "10px" }}>
                {/* <button
                  type="button"
                  className="btn btn-sm  pharmacy-info-btn mr-4"
                  onClick={exportExcel}
                >
                  Download Excel
                </button>
                <button
                  type="button"
                  className="btn btn-sm  pharmacy-info-btn mr-4"
                  onClick={exportPDF}
                >
                  Download PDF
                </button> */}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={handleCloseBar}
      />
      <div className="mui-tables">
        <MuiPaper>
          <EnhancedTableToolbar title="">
            <Form.Control
              type="text"
              value={search ? titleCase(search) : ""}
              data-testid="product_search"
              onChange={handleChangeSearchValue}
              className="form__control"
              placeholder="Search"
              style={{ float: "right" }}
            />
          </EnhancedTableToolbar>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead headCells={headData} />
              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="7" align="center">
                      <img
                        src="/images/listPreloader.gif"
                        alt="loader"
                        style={{ width: "5%" }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : batches.length > 0 ? (
                <TableBody>
                  {batches.slice(0, 10).map((d) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={d.purchase_order_number}
                      className="mui-table-row"
                    >
                      <TableCell data-testid="product_name">
                        {d.product_name}
                      </TableCell>
                      <TableCell data-testid="batch_number">
                        {" "}
                        {d.batch_number}
                      </TableCell>
                      <TableCell data-testid="stock">
                        {d.Actual_stock}
                      </TableCell>
                      <TableCell id="mainbuttons" data-testid="adjust_button">
                        <Link
                          to={{
                            pathname: `/correct-batch-inconsistence/${d.product_id}/${d.batch_number}`,
                          }}
                        >
                          <button className="btn pharmacy-info-btn btn-sm mr-3">
                            Adjust stock
                          </button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="7" align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            <CustomPagination
              data={batches}
              records={10}
              activePage={page}
              total_count={count}
              handleChangeNextPage={handleChangePage}
            />
          </TableContainer>
        </MuiPaper>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    purchases: state.purchases,
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      purchaseActions: bindActionCreators(purchaseActions, dispatch),
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InconsistentBatches);
