import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ReusableNewCOA } from "./NewCOAccount";
import { useChartOfAccounts } from "./hooks/useChartOfAccounts";
import {
  Container,
  RightAlignedContainer,
} from "../../../Utils/styledComponents";
import { AccountingDrawer } from "../../../Utils/AccountingUtils/AccountingDrawer";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import COATable from "./COATable";
import { LoadingGif } from "../../../Utils/Loader";
import { NoTransaction } from "./ReusableJournalTable";
import { useStyles } from "../../../Utils/AccountingUtils/TreeViewComponents";
import {
  CustomPagination,
  MuiPagination,
} from "../../../Utils/Tables/CustomTable";
import axios from "axios";
import { Box, Grid, Switch, TableCell, TableRow } from "@material-ui/core";
import async from "react-select/async";
import TextField from "../../../Utils/FormInputs/TextField";
import { useQueries, useQuery } from "react-query";
import config from "../../../Helpers/config.json"
import { Accordion, Button, Card, Table } from "react-bootstrap";

const ChartOfAccounts = ({ actions, snackbars }) => {
  const { open, variant, message } = snackbars;
  const {
    closeSnackbar,
    openDialog,
    handleOpenDialog,
    handleCloseDialog,
    coaProps,
    retrieveCategoryAccounts,
    accounts,
    isLoadingData,
    isSuccessData,
    isErrorData,
  } = useChartOfAccounts(actions);
  const { isPending, handleSubmit } = coaProps;
  const classes = useStyles();
  const [accountDetails, setAccountDetails] = useState([]);
  // const [data, setData] = useState({
  //   accountDetails: [],
  //   total_count: 0,
  // });
  const [activePage, setActivePage] = useState(1);

  const [checkedView, setCheckedView] = useState(false);

  const tableHeader = ["Account Type", "Account No.", "Account Name", "Amount"];

  const summaryChartOfAccountsQuery = useQuery(
    ["summaryChartOfAccountsQuery"],
    async () => {
      try {

        const response = await axios.get(`${config.epharmUrl}/accounting/new_chart_of_accounts`)

        return response?.data[0]?.data;

      } catch (error) {
        // 
      }
    },
    { retry: 1 });

  const accountDetailsQuery = useQuery(
    ["accountDetailsQuery", activePage],
    async () => {
      try {
        const response = await axios.get(
          `${config.epharmUrl}/accounting/get_chart_of_accounts`,
          { params: { page_number: activePage } });

        const data = response?.data[0]?.data;

        setAccountDetails(Object.entries(data).map(([category, items], idx) => ({ category, items })));

        // return response
        return Object.entries(data).map(([category, items], idx) => ({ category, items }));

      } catch (error) {
        // 
      }
    },
    { retry: 1 });


  // useEffect(() => {
  //   const fetchAccountDetails = async () => {
  //     const res = await fetch(
  //       `http://localhost:3500/accounting/get_chart_of_accounts?page_number=${activePage}`
  //     );
  //
  //     const accountData = await res.json();
  //     // console.log(accountData[0].data);
  //     // setData({
  //     //   ...data,
  //     //   accountDetails: accountData[0].data,
  //     //   total_count: accountData.total_count,
  //     // });
  //     const accountDetails = Object.entries(accountData[0].data).map(
  //       ([category, items]) => ({
  //         category,
  //         items,
  //       })
  //     );
  //     setAccountDetails(accountDetails);
  //   };
  //   fetchAccountDetails();
  // }, [activePage]);
  //

  const nextPage = () => {
    setActivePage(activePage + 1);
  };

  // const prevPage = () => {
  //   if (activePage > 1) {
  //     setActivePage(activePage - 1);
  //   }
  // };

  const downloadAllAccounts = async () => {
    return await fetch(
      "http://localhost:3500/accounting/chart_of_accounts_download"
    );
  };

  const handleViewChange = (event) => {

    setCheckedView(event.target.checked);

  }

  const summaryChartOfAccountsData = summaryChartOfAccountsQuery.isSuccess ? summaryChartOfAccountsQuery.data : {};

  return (
    <div className="journals">
      <ReusableDashboardHeader
        component="Chart of Accounts"
        heading="Accounting"
        subHeading="Chart of accounts"
        link="/accounting/coa"
      />

      <PharmacySnackbar
        message={message}
        open={open}
        handleCloseBar={closeSnackbar}
        variant={variant}
      />

      <AccountingDrawer
        open={openDialog}
        title="Create Account"
        handleClose={handleCloseDialog}
      >
        <form
          data-testid="create-new-account-form"
          onSubmit={handleSubmit}
          autoComplete="off"
          className="general-card"
        >
          <ReusableNewCOA {...coaProps} />

          <div style={{ marginLeft: "15px" }}>
            <button
              data-testid="submit-button"
              type="submit"
              className="btn btn-sm pharmacy-info-btn"
              disabled={isPending}
            >
              {isPending ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </AccountingDrawer>

      <Container>
        <RightAlignedContainer>
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              width: "700px",
              marginBottom: "10px",
            }}
            className="text-right"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              SummaryView
            </Box>

            <Switch
              checked={checkedView}
              onChange={handleViewChange}
              color="default"
              name="checkedA"
              inputProps={{ "aria-label": "view checkbox" }}
            />

            <Box display="flex" justifyContent="center" alignItems="center">
              TabularView
            </Box>

            <button
              type="button"
              className="btn btn-sm pharmacy-info-btn mb-2"
              onClick={downloadAllAccounts}
              style={{ flex: "none" }}
            >
              Download All Accounts
            </button>

            <button
              data-testid="create-new-account-button"
              type="button"
              className="btn btn-sm pharmacy-info-btn mb-2"
              style={{ flex: "none" }}
              onClick={handleOpenDialog}
            >
              New Account
            </button>

            {/* <Grid item xs={12} md={6}> */}

            <TextField
              data-testid="search_account"
              type="text"
              value=''
              name="search"
              onChange={()=>{}}
              placeholder="Search Account"
            />

            {/* </Grid> */}
          </div>
        </RightAlignedContainer>

        {checkedView ? (
          <>
            {/* TABULAR VIEW */}

            {isLoadingData ? (
              <div className="text-center mt-5">
                <LoadingGif />
              </div>
            ) : null}

            {isSuccessData ? (
              <table style={{ width: "100%" }}>
                <thead
                  style={{
                    borderBottom: "2px solid black",
                    borderTop: "2px solid black",
                  }}
                >
                  <tr>
                    {tableHeader.map((headerItem) => (
                      <th
                        style={{
                          padding: "10px 0",
                          textTransform: "uppercase",
                          fontWeight: "bolder",
                        }}
                        key={headerItem}
                      >
                        {headerItem}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* {Object.values(accountDetails).map((item) => {
                console.log("item", item);
                return (
                  <tr
                    style={{
                      borderBottom: "1px solid rgb(223 219 219)",
                      backgroundColor:
                        item.reporting === "TopLevel" ? "#17a2b8" : "",
                      fontWeight:
                        item.reporting === "SecondLevel" ? "bold" : "",
                    }}
                    key={item.id}
                  >
                    <td
                      style={{
                        padding: "10px 0",
                      }}
                    >
                      {item.accountno}
                    </td>
                    <td>{item.accountname}</td>
                    <td>{item.accounttype}</td>
                    <td>{item.account_category}</td>
                  </tr>
                );
              })} */}

                  {accountDetails.map((categoryData) => (
                    <React.Fragment key={categoryData.category}>
                      <tr style={{ background: "#17a2b8" }}>
                        <td
                          colSpan="4"
                          style={{ fontWeight: "bold", padding: "10px 0" }}
                        >
                          {categoryData.category}
                        </td>
                      </tr>
                      {categoryData.items.map((item) => (
                        <tr
                          key={item.id}
                          style={{ borderBottom: "1px solid rgb(223 219 219)" }}
                        >
                          <td style={{ padding: "10px 0" }}>
                            {item.account_category}
                          </td>
                          <td style={{ padding: "10px 0" }}>
                            {item.accountno}
                          </td>
                          <td>{item.accountname}</td>
                          <td>{item.balance}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            ) : (
              <NoTransaction text="accounts" />
            )}

            {isSuccessData && accountDetails?.length === 0 ? (
              <p align="center">No records found</p>
            ) : null}

            {isErrorData ? (
              <div className="mt-3 text-center">
                The server did not return a valid response
              </div>
            ) : null}

            {isSuccessData ? (
              <CustomPagination
                data={accountDetails}
                records={10}
                activePage={activePage}
                // total_count={total_count}
                handleChangeNextPage={nextPage}
              />
            ) : null}
          </>
        ) : (
          <>
            {/* SUMMARY VIEW */}
            {/* <p>Summary View</p> */}
            {/* <COATable children={accountDetails} /> */}
            {summaryChartOfAccountsData &&
            Object.entries(summaryChartOfAccountsData).length > 0 ? (
              Object.entries(summaryChartOfAccountsData).map(
                ([key, values], idx) => {
                  console.log(key)
                  return (
                    <>
                      <Accordion /* defaultActiveKey="0" */ className="my-3">
                        <Card>
                          <Card.Header data-testid={key==='expenditure'?'expenditure':null}>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="0"
                            >
                              {key}
                            </Accordion.Toggle>
                          </Card.Header>

                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              {Object.entries(values) &&
                              Object.entries(values).length > 0 ? (
                                Object.entries(values).map(
                                  ([category, valueArray], categoryIndex) => (
                                    <Accordion /* defaultActiveKey="0" */>
                                      <Card>
                                        <Card.Header>
                                          <Accordion.Toggle
                                            as={Button}
                                            variant="link"
                                            eventKey="0"
                                          >
                                            {category}
                                          </Accordion.Toggle>
                                        </Card.Header>

                                        <Accordion.Collapse eventKey="0">
                                          <Card.Body>
                                            <Table striped bordered hover>
                                              <thead>
                                                <th>Account Name</th>
                                                <th>Account Number</th>
                                                <th>Balance</th>
                                                {/* <th>Category Code</th>
                                      <th>Reporting</th> */}
                                              </thead>

                                              <tbody>
                                                {valueArray &&
                                                valueArray.length > 0 ? (
                                                  valueArray.map(
                                                    (
                                                      valueObject,
                                                      valueIndex
                                                    ) => (
                                                      <tr>
                                                        <td>
                                                          {
                                                            valueObject.accountname
                                                          }
                                                        </td>
                                                        <td>
                                                          {
                                                            valueObject.accountno
                                                          }
                                                        </td>
                                                        <td>
                                                          {valueObject.balance}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )
                                                ) : (
                                                  <tr>
                                                    <td colSpan={3}>
                                                      No Data Available
                                                    </td>
                                                  </tr>
                                                )}
                                              </tbody>
                                            </Table>
                                          </Card.Body>
                                        </Accordion.Collapse>
                                      </Card>
                                    </Accordion>
                                  )
                                )
                              ) : (
                                <>No Data Available</>
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </>
                  );
                }
              )
            ) : (
              <>No Data Available</>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

function mapStateToProps(state) {
  const { snackbars } = state;
  return { snackbars };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: { snackbarActions: bindActionCreators(snackbarActions, dispatch) },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartOfAccounts);
