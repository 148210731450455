import React from "react";
import "./App.css";
import { Switch, Route, Router } from "react-router-dom";
import UserLoginPage from "../Components/USer/UserLoginPage";
import ForgotPassword from "../Components/USer/ForgotPassword";
import MainPage from "./MainPage";
import axios from "axios";
import ResetEmailText from "../Components/USer/ResetPage";
import PasswordReset from "../Components/USer/PasswordReset";
import version from "../softwareVersion.json";
import { AppProviders } from "../Context";
import { history } from "../Helpers/history";

function App() {
  axios.interceptors.request.use(function (config) {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const singleUser = !user ? {} : user;
    const user_id = !singleUser.user_id ? 1 : singleUser.user_id;
    const token = JSON.parse(sessionStorage.getItem("token"));
    const tokenObj = !token ? {} : token;
    config.headers.sid_web = tokenObj.sid_web;
    config.headers.token = tokenObj.sid_web;

    config.headers.user_id = user_id;
    return config;
  });
  const current_year = new Date().getFullYear();
  return (
    <div>
      <div className="mpeke-wrapper">
        <Switch>
          <Route path="/login">
            <UserLoginPage />
          </Route>
          <Route path="/forgotpassword">
            <ForgotPassword />
          </Route>
          <Route path="/resetemail">
            <ResetEmailText />
          </Route>
          <Route path="/reset/:reset_token" component={PasswordReset} />
          <AppProviders>
            <MainPage />
          </AppProviders>
        </Switch>
      </div>
      <footer className="py-4">
        <div className="row">
          <div className="col-md-8">
            <div className="text-right footer-text">
              MaraScientific &copy; {current_year}. All rights reserved.
            </div>
          </div>
          <div className="col-md-4">
            <div className="text-right footer-text">
              Version {version.version}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
