import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import { titleCase } from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import DeleteDialog from "../../Utils/DeleteDialog";
import Form from "react-bootstrap/Form";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPagination,
  MuiPaper,
} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import { useMedicineCategory } from "./CustomHooks/useMedicineCategory";
import DetailsDialog from "../../Utils/Dialog/DetailsDialog";
import { CategoryForm } from "./MedicineMetaComponents";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import CsvDialog from "../../Utils/CsvDialog";
import { Alert } from "@material-ui/lab";
import { CSVLink } from "react-csv";
import {
  useExcelReports,
  usePdfReports,
} from "../Invoice/CustomHooks/useExcelReports";

const headData = [
  { id: "sl", numeric: false, disablePadding: false, label: "Sl" },
  { id: "name", numeric: false, disablePadding: false, label: "Category Name" },
  { id: "action", numeric: false, disablePadding: false, label: "Action" },
];

const MedicineCategory = ({ actions, snackbars }) => {
  const {
    openDelete,
    categories,
    loading,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOpenDelete,
    handleCloseDelete,
    deleteCategory,
    categoryProps,
    csvProps,
    handleCloseSnackbar,
    downloadPDF,
    term,
    handleSearch,
  } = useMedicineCategory(actions);
  const { open, message, variant } = snackbars;
  const {
    openDialog,
    openEdit,
    handleCloseEdit,
    handleOpenEdit,
    handleCloseDialog,
    handleOpenDialog,
  } = categoryProps;
  const {
    handleChangeCategoryCsv,
    handleCloseCSVDialog,
    handleOpenCSVDialog,
    fetchData,
    handleUploadCsv,
    error_message,
    allCategories,
    csvdialog,
    csvLink,
  } = csvProps;

  const obj = {
    file_name: "Product Categories",
    url: "Ccategory/category_list_excel",
  };
  const objPDF = {
    file_name: "Product Categories",
    url: "Ccategory/category_list_pdf",
  };
  const { exportExcel } = useExcelReports(actions, obj);
  const { exportPDF } = usePdfReports(actions, objPDF);

  return (
    <div className="journals">
      <CsvDialog
        openDialog={csvdialog}
        handleCloseDialog={handleCloseCSVDialog}
        handleUploadCsv={handleUploadCsv}
        handleChangeUpload={handleChangeCategoryCsv}
        message="Category"
      >
        <span>
          <button
            onClick={fetchData}
            className="btn pharmacy-primary-btn btn-sm"
          >
            Download Sample File
          </button>
          {error_message !== "" ? (
            <div style={{ marginTop: "10px" }}>
              <Alert severity="error" style={{ borderRadius: "10px" }}>
                {error_message}
              </Alert>
            </div>
          ) : null}
          <CSVLink
            data={allCategories}
            ref={csvLink}
            filename="Categories.csv"
            className="hidden"
          />
        </span>
      </CsvDialog>
      <ReusableDashboardHeader
        component="Product Categories"
        heading="Products"
        subHeading="Product Categories"
        link="/managecategory"
      />
      <div className="general-ledger-header">
        <div className="row-mr-4">
          <div className="col-md-6">
            <h6 className="mt-2 mb-0">Product Categories</h6>
          </div>
          <div className="row">
            <div className="text-right">
              <button
                type="button"
                onClick={handleOpenDialog}
                className="btn btn-sm pharmacy-info-btn"
              >
                Add Category
              </button>
            </div>
            <div
              className="text-right"
              style={{ marginBottom: "4px", paddingLeft: "20px" }}
            >
              <button
                type="button"
                onClick={handleOpenCSVDialog}
                className="btn pharmacy-info-btn btn-sm mr-4"
                style={{ marginBottom: "4px" }}
              >
                <FontAwesomeIcon icon={faFileUpload} /> Upload Category
              </button>
            </div>
          </div>
        </div>
      </div>
      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={handleCloseSnackbar}
      />
      <DeleteDialog
        handleClose={handleCloseDelete}
        openDialog={openDelete}
        message="category"
        handleDelete={deleteCategory}
      />
      <DetailsDialog
        title="New Category"
        openDialog={openDialog}
        handleClose={handleCloseDialog}
      >
        <CategoryForm {...categoryProps} />
      </DetailsDialog>
      <DetailsDialog
        title="Edit Category"
        openDialog={openEdit}
        handleClose={handleCloseEdit}
      >
        <CategoryForm {...categoryProps} />
      </DetailsDialog>
      <div className="mui-tables">
        <MuiPaper>
          <EnhancedTableToolbar title={""}>
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  className="btn pharmacy-btn mr-1 btn-sm"
                  onClick={exportPDF}
                >
                  Download PDF
                </button>
              </span>
            </span>
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  className="btn pharmacy-btn mr-1 btn-sm"
                  onClick={exportExcel}
                >
                  Download Excel
                </button>
              </span>
            </span>
            <Form.Control
              type="text"
              onChange={(event) => handleSearch(event.target.value)}
              className="form__control"
              placeholder="Search"
              style={{ float: "right" }}
            />
          </EnhancedTableToolbar>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead headCells={headData} />
              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="3" align="center">
                      <img
                        src="/images/listPreloader.gif"
                        alt="loader"
                        style={{ width: "5%" }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : categories.length > 0 ? (
                <FilterResults
                  value={term}
                  data={categories}
                  renderResults={(results) => (
                    <TableBody>
                      {results
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((info, index) => {
                          const count = (index += 1);
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={info.category_id}
                              className="mui-table-row"
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                padding="default"
                                className="mui-table-cell"
                              >
                                <span>{count}</span>
                              </TableCell>
                              <TableCell>
                                {titleCase(info.category_name)}
                              </TableCell>
                              <TableCell id="mainbuttons">
                                <Tooltip title="Update" placement="left-start">
                                  <button
                                    onClick={() =>
                                      handleOpenEdit(info.category_id)
                                    }
                                    className="btn btn-sm pharmacy-info-btn mr-3"
                                  >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                  </button>
                                </Tooltip>
                                <Tooltip title="Delete" placement="left-start">
                                  <button
                                    className="btn btn-sm pharmacy-btn-dismiss"
                                    onClick={() =>
                                      handleOpenDelete(info.category_id)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  )}
                />
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="3" align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <MuiPagination
            data={categories}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            rows={[10, 20, 30, 50, 100]}
            colSpan={3}
          />
        </MuiPaper>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicineCategory);
