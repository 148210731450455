import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";
import { Col, Form, Row } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Label from "../../../Utils/FormInputs/Label";
import TextField from "../../../Utils/FormInputs/TextField";
import { Link } from "react-router-dom";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import {
  CustomPagination,
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPaper,
} from "../../../Utils/Tables/CustomTable";
import { LoadingGif } from "../../../Utils/Loader";
import {
  dateStringTimeConvert,
  titleCase,
} from "../../../Utils/titleCaseFunction";
import { formatAmount } from "../../../Utils/formatNumbers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowRestore } from "@fortawesome/free-solid-svg-icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import { PurchaseDetailsPrint } from "../../Purchase/PurchaseDetailsPrint";
import { useManageCreditNote } from "../Reports/hooks/useManageCreditNotes";
import {useExcelReports, usePdfReports} from "../../Invoice/CustomHooks/useExcelReports";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "0.8em",
    height: "0.8em",
  },
  approved: {
    color: "green",
  },
  canceled: {
    color: "#D9512C",
  },
  pending: {
    color: "#F29339",
  },
}));

const headData = [
  {
    id: "date_created",
    numeric: false,
    disablePadding: false,
    label: "Date Created",
  },

  {
    id: "creditMemoNo",
    numeric: false,
    disablePadding: false,
    label: "Credit Memo No",
  },

  // {id: 'purchaseID', numeric: false, disablePadding: false, label: 'Purchase ID'},

  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "Customer Name",
  },

  {
    id: "previousInvoiceNo",
    numeric: false,
    disablePadding: false,
    label: "Previous Invoice No",
  },

  {
    id: "tAmount",
    numeric: false,
    disablePadding: false,
    label: "Total Amount",
  },

  //   manage_purchase.create || manage_purchase.update || manage_purchase.delete

  // ?

  { id: "action", numeric: false, disablePadding: false, label: "Action" },

  //     : { id: "nonaction", numeric: false, disablePadding: false, label: "" },
];

//  const buttons = (
//    <>
//      <button
//        className="btn pharmacy-info-btn  btn-sm mr-3"
//        onClick={handleOpenDraftDialog}
//      >
//        Continue
//      </button>
//      <button
//        className="btn pharmacy-grey-btn btn-sm"
//        onClick={handleCloseDialog}
//      >
//        No
//      </button>
//    </>
//  );

const ManageCreditNotes = ({ actions, snackbars }) => {
  const {
    creditNotes = [],
    date,
    handleChangeDate,
    isLoading,
    isSuccess,
    isError,
    handleSearch,
    search,
    handleChangeSearch,
    handleCloseBar,
    totalAmount,
    activePage,
    recordCount,
    handleChangePage,
    pageSize,
    handleChangePageSize,
    handleExport,
      pdfData, excelData
  } = useManageCreditNote(actions);

  let printCreditNote = true;

  const {exportExcel} = useExcelReports(actions, excelData);

  const {exportPDF} = usePdfReports(actions, pdfData )

  const classes = useStyles();

  const { open, message, variant } = snackbars;

  return (
    <div>
      <DashboardHeader credit creditNote />

      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={handleCloseBar}
      />

      {/* <CustomDialog
        maxWidth="md"
        title="Cancel Purchase"
        open={openDialog}
        handleClose={handleCloseDialog}
        buttons={buttons}
        isOverflow={false}
      >
        <PurchaseDetailsPrint
          {...{ ...productPurchase, loading: loadingPurchase }}
        />
      </CustomDialog> */}

      <div className="general-ledger-header mb-3">
        <Grid container spacing={0}>
          <Grid item xs={6} md={8}>
            <form onSubmit={handleSearch}>
              <Form.Group as={Row} style={{ marginBottom: "-1%" }}>
                <Col sm={4}>
                  <Form.Group as={Row}>
                    <Label
                      name="From"
                      column
                      sm={2}
                      style={{ textAlign: "right" }}
                    />

                    <Col sm={10}>
                      <TextField
                        data-testid="startDate"
                        type="date"
                        onChange={handleChangeDate}
                        value={date?.startDate}
                        name="startDate"
                      />
                    </Col>
                  </Form.Group>
                </Col>

                <Col sm={4}>
                  <Form.Group as={Row}>
                    <Label name="To" column sm={2} />
                    <Col sm={10}>
                      <TextField
                        data-testid="endDate"
                        type="date"
                        value={date?.endDate}
                        onChange={handleChangeDate}
                        name="endDate"
                      />
                    </Col>
                  </Form.Group>
                </Col>

                {/* <Col sm={1}>
                  <button
                    data-testid="submit_date_filter"
                    type="submit"
                    className="btn btn-sm pharmacy-btn-outline"
                  >
                    Filter
                  </button>
                </Col> */}
              </Form.Group>
            </form>
          </Grid>

          <Grid item xs={6} md={4}>
            <div className="text-right">
              <Link to="/accounting/creditnote">
                <button
                  data-testid="add_credit_note"
                  className="btn btn-sm pharmacy-info-btn py-1"
                >
                  <AddIcon classes={{ root: classes.icon }} />
                  Add Credit Note
                </button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="mui-table">
        <MuiPaper>
          <EnhancedTableToolbar title="Credit Notes">
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  type={"button"}
                  onClick={exportExcel}
                  className="btn btn-sm mr-1 pharmacy-btn"
                >
                  Download Excel
                </button>
              </span>
            </span>

            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  type={"button"}
                  onClick={exportPDF}
                  className="btn btn-sm mr-1 pharmacy-btn"
                >
                  Download PDF
                </button>
              </span>
            </span>

            <Form.Control
              type="text"
              value={search ? titleCase(search) : ""}
              onChange={handleChangeSearch}
              className="form__control"
              placeholder="Search"
              style={{ float: "right" }}
            />
          </EnhancedTableToolbar>

          <div className="sub-journal-bar" style={{ marginLeft: "0" }}>
            <h6 data-testid="running_balance">
              Total Credit Amount :<strong>{formatAmount(totalAmount)}</strong>
            </h6>
          </div>

          <div>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead headCells={headData} />

              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={headData.length} align="center">
                      <LoadingGif />
                    </TableCell>
                  </TableRow>
                )
                    : null
                }

                {isSuccess ? (
                  creditNotes?.length > 0 ? (
                    <>
                      {creditNotes?.map((d, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            className="mui-table-row"
                          >
                            <TableCell>
                              {d?.credit_memo_date ? dateStringTimeConvert(d.credit_memo_date) : "N/A"}
                            </TableCell>

                            <TableCell>
                              <span data-testid={"credit_memo_no-" + index}>
                                {d?.credit_memo_no ?? "N/A"}
                              </span>
                            </TableCell>

                            <TableCell>
                              <span data-testid={"customer_name-" + index}>
                                {d?.customer_name ? titleCase(d.customer_name) : "N/A"}
                              </span>
                            </TableCell>

                            <TableCell>
                              <span data-testid={"previous_memo_no-" + index}>
                                {d?.previous_invoice?.invoice_number ?? "N/A"}
                              </span>
                            </TableCell>

                            <TableCell>
                              <span data-testid={"grand_total_amount-" + index}>
                                {d.total_amount
                                  ? formatAmount(d.total_amount)
                                  : d.total_amount}
                              </span>
                            </TableCell>

                            <TableCell id="mainbuttons">
                              {printCreditNote ? (
                                <span>
                                  <Link
                                    to={{
                                      pathname: `/creditnoteinvoice/${d.credit_memo_id}`,
                                    }}
                                  >
                                    <button
                                      data-testid={"view_purchase-" + index}
                                      className="btn pharmacy-btn btn-sm mr-3 "
                                    >
                                      <FontAwesomeIcon icon={faWindowRestore} />
                                    </button>
                                  </Link>

                                  {/*<Tooltip title="Update" placement="left-start">*/}
                                  {/*manage_purchase.update ?
                                                                <Link
                                                                    to={{pathname: `/editpurchase/${d.purchase_id}`}}>
                                                                    <button
                                                                        data-testid={"edit_purchase-"+index}
                                                                        className="btn pharmacy-info-btn btn-sm mr-1 ">
                                                                        <FontAwesomeIcon
                                                                            icon={faPencilAlt}/>
                                                                    </button>
                                                                </Link> : null */}
                                  {/*</Tooltip>*/}
                                </span>
                              ) : (
                                <span />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        data-testid="no_records_found"
                        colSpan={headData.length}
                        align="center"
                      >
                        No records found
                      </TableCell>
                    </TableRow>
                  )
                ) : null}

                {isError ? (
                  <TableRow>
                    <TableCell colSpan={headData.length} align="center">
                      The server did not return a valid response
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </div>

          <CustomPagination
            data={creditNotes}
            records={pageSize}
            activePage={activePage}
            total_count={recordCount}
            handleChangeNextPage={handleChangePage}
          />
        </MuiPaper>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCreditNotes);
