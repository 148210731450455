import React, { useState } from "react";
import StockSummary, { TotalHead } from "./StockSummary";

const StockSummaryCategoryDetailsMonths = ({ match }) => {
  const [data, setData] = useState({
    loading: "idle",
    stockList: [],
    totals:{}
  });

  const { product_id } = match.params;

  const manipulate = (arr) => {
    return arr.map((item) => ({
      ...item,
      id: item.product_id,
      name: item.month,
      url: `/${item.product_id}/${item.period}/${item.start_date}/${item.end_date}`,
    }));
  };

  const totalColumns = [
    {
      id: "Total Amount",
      numeric: false,
      disablePadding: false,
      label: "Total Amount",
      isBorder: true,
    },
    {
      id: data?.totals?.total_opening_amount,
      numeric: false,
      disablePadding: false,
      colSpan: 2,
      align:"right",
      isBorder: true,
    },
    {
      id: data?.totals?.total_inward_amount,
      numeric: false,
      disablePadding: false,
      colSpan: 2,
      isBorder: true,
      align:"right"
    },
    {
      id: data?.totals?.total_outward_amount,
      numeric: false,
      disablePadding: false,
      colSpan: 2,
      isBorder: true,
      align:"right"
    },
    {
      id: data?.totals?.total_closing_amount,
      numeric: false,
      disablePadding: false,
      colSpan: 2,
      isBorder: true,
      align:"right"
    },
  ];


  return (
    <StockSummary
      data={data}
      setData={setData}
      heading="Product details by month"
      url="Creport/get_stock_summary"
      otherParams={{ product_id}}
      manipulate={manipulate}
      totalHeadData={<TotalHead totalColumns={totalColumns}/>}
    />
  );
};

export default StockSummaryCategoryDetailsMonths;
