import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import * as userActions from "../../Redux/Actions/UserActions/UserActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as alertActions from "../../Redux/Actions/AlertActions";
import {history} from "../../Helpers/history";
import version from "../../softwareVersion";


export class UserLoginPage extends Component {
    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
            revealPassword: false,
        };
        history.listen((location, action)=>{
            const {actions} = this.props;
            actions.alertActions.clearAlert();
        });
        sessionStorage.clear();
        
    }

    togglePassword = () => {
        const {revealPassword} = this.state;
        this.setState({revealPassword: !revealPassword})
    };

    handleChange = event => {
        const {name, value} = event.target;
        this.setState({[name]: value})
    };
    handleLogin = event => {
        event.preventDefault();
        const {actions} = this.props;
        const {username, password} = this.state;
        const formData = new FormData();
        formData.append('username',username);
        formData.append('password', password);
        if(username && password){
            actions.userActions.userLogin(formData);
        }

    };

    render() {
        const {username, password} = this.state;
        const {type, message} = this.props.alert;
        const {loading} = this.props.users;
        return (
            <div className="styled-login">
                {message && <div className={`text-center alert ${type}`}>{message}</div>}
                <div className="card styled-login-card">
                    <div className="row">
                        <div className="col-md-7 text-center responsive-side-div">
                            <div className="border-right"
                                 // style={{backgroundColor: "rgba(10,66,191,0.04)"}}
                            >
                                <div className="py-5">
                                    <img
                                        src="/images/mpekeimage.svg"
                                        style={{width: "100%", marginTop: "20"}}
                                        alt="logo"
                                    />
                                    <p className="text-center mt-3" style={{fontSize: ".8em", color: "#585858"}}>
                                        &copy; Mara Scientific | MpekeRx
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="py-5 pr-4 pl-3">
                                <div className="text-center styled-content mb-2">
                                    <h4 style={{
                                        marginTop: 20,
                                        fontWeight: 500,
                                        fontFamily: "Roboto"
                                    }}>LOGIN</h4>
                                </div>
                                <Form onSubmit={this.handleLogin} autoComplete="off">
                                    <Form.Group>
                                        <Form.Label htmlFor='useremail'>Email</Form.Label>
                                        <Form.Control id="useremail" type="email" value={username} name="username"
                                                      onChange={this.handleChange}/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor='userpassword'>Password</Form.Label>
                                        <Form.Control id="userpassword" type="password" value={password} name="password"
                                                      onChange={this.handleChange}/>
                                        {/*<div className="password-icon">*/}
                                        {/*    <FontAwesomeIcon icon={faEye} onClick={this.togglePassword}/>*/}
                                        {/*</div>*/}
                                    </Form.Group>
                                    <div className="text-center">
                                        <button id="login_btn" disabled={!!loading} className="btn pharmacy-btn px-5 mt-3"
                                                style={{width: "100%"}}>{loading ? "Logging in...":"Login"}</button>
                                    </div>
                                    <Link to="/forgotpassword"><p className="mt-2">Forgot Password?</p></Link>
                                </Form>

                            </div>
                            <div className="text-right mt-5 mr-3">
                                Version {version.version}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        users:state.users
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            userActions: bindActionCreators(userActions, dispatch),
            alertActions:bindActionCreators(alertActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(UserLoginPage);
