import React from 'react';
import {ExportButton, ReusableSalesAndPurchaseHeader} from "../Report/ReusableSalesAndPurchaseHeader";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import {EnhancedTableHead, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {convDate, dateStringConvert, titleCase} from "../../Utils/titleCaseFunction";
import {useMuiPagination} from "../../Containers/Dasboard/DashboardTables";
import {usePurchaseReport} from "../Report/CustomHooks/usePurchaseReport";
import {ReusableSearchForm} from "../Report/ReusableSearchForm";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import {Container, RightAlignedContainer} from "../../Utils/styledComponents"
import {BackButton} from "../../Utils/FormInputs/BackButton";
import { AutoCompleteUnControlled } from '../../Utils/FormInputs/AutoCompleteInput';
import {useExcelReports} from "../Invoice/CustomHooks/useExcelReports";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {connect} from "react-redux";
import {formatAmount} from "../../Utils/formatAmount";
import { useProductHistoryReport } from './hooks/useProductHistory';
import { Grid } from '@material-ui/core';
import TextField from '../../Utils/FormInputs/TextField';

const headData = [
    {id: 'pdate', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'purchaseId', numeric: false, disablePadding: false, label: 'Invoice#'},
    {id: 'batch_number', numeric: false, disablePadding: false, label: 'Batch#'},
    {id: 'name', numeric: false, disablePadding: false, label: 'Supplier Name'},
    {id: 'product', numeric: false, disablePadding: false, label: 'Transaction Type'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'price', numeric: false, disablePadding: false, label: 'Unit Cost'},
    {id: 'total', numeric: false, disablePadding: false, label: 'Total Amount'},
];


const ProductHistoryReport = (props) => {
    const {actions} = props;
    const {
       loading
    } = usePurchaseReport()
   
    const{product, 
        submitted,
        drugs,
        products,
        from_date,
        to_date,
        today,
        page,
        period, 
        rowsPerPage,
        handleChangeRowsPerPage, 
        handleChangePage,
        handleChangeDate,
        handleClickDrug,
        // loading,
        isSubmitted, 
        downloadPDF,
        handleSubmit,
        handleRetrieveDrugs} = useProductHistoryReport(actions)


    const allDrugs = !drugs ? [] : drugs;

    const currentObj = {file_name:'Purchase Report', url:'admin/todays_purchase_excel_report',
     params:{start_date:period.value === "today" ? today:from_date, end_date:period.value === "today" ? today:to_date, manufacturer_id:product.value ? product.value : ''}}

    const {exportExcel} = useExcelReports(actions, currentObj)
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Product History Report" heading="Reports"
                                     subHeading="Product History Report" link={history.location.pathname}/>
            <Container>
                <div className='mb-5'>
                    <BackButton text='Reports' to='/allreports'/>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='row mb-1'>
                        <div className='col-lg-5'>
                            <Form.Group as={Row}>
                                <Col sm={2}>
                                    <Label name='Product:' type/>
                                </Col>
                                <Col sm={8}>
                                    <AutoCompleteUnControlled
                                        options={allDrugs}
                                        handleChange={handleRetrieveDrugs}
                                        label_text="label"
                                        medicine_value={{
                                            label: product.product_name,
                                            value: product.product_id,
                                        }}
                                        data-testid="product"
                                        value_text="value"
                                        handleProductData={handleClickDrug}
                                        placeholder="Type Product Name"
                                        errorMsg="Enter Product Name"
                                    />
                                </Col>
                            </Form.Group>
                        </div>
                        <div className='col-lg-5'>
                            <RightAlignedContainer>
                                <ExportButton exportExcel={exportExcel} exportPdf={downloadPDF}/>
                            </RightAlignedContainer>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <Form.Group as={Row}>
                                <Col sm={2}>
                                    <Label name="From"/>
                                </Col>
                                <Col sm={10}>
                                    <TextField
                                        type="date"
                                        onChange={handleChangeDate}
                                        value={from_date}
                                        name="from_date"
                                    />
                                    {submitted && !from_date ? (
                                        <div className="invalid-text">From date is required</div>
                                    ) : null}
                                </Col>
                            </Form.Group>
                        </div>
                        <div className='col-lg-4'>
                            <Form.Group as={Row}>
                                <Col sm={2}>
                                    <Label name="To"/>
                                </Col>
                                <Col sm={10}>
                                    <TextField
                                        type="date"
                                        value={to_date}
                                        onChange={handleChangeDate}
                                        name="to_date"
                                    />
                                    {submitted && !to_date ? (
                                        <div className="invalid-text">To date is required</div>
                                    ) : null}
                                </Col>
                            </Form.Group>
                        </div>
                        <div className='col-lg-2'>
                            <button
                                type="submit"
                                disabled={isSubmitted === "pending"}
                                className="btn btn-sm pharmacy-btn-outline"
                            >
                                {isSubmitted === "pending" ? "Filtering" : "Filter"}
                            </button>
                        </div>
                    </div>
                </form>
                <MuiPaper>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            size='medium'
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead headCells={headData}/>
                            {loading ? <TableBody><TableRow>
                                <TableCell colSpan="7" align="center"><img src="/images/listPreloader.gif"
                                                                           alt="loader"
                                                                           style={{width: '5%'}}/></TableCell>
                            </TableRow></TableBody> : products.length > 0 ?
                                <TableBody>
                                    {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((d, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell>
                                                        <span>{d.date}</span>
                                                    </TableCell>
                                                    <TableCell>

                                                        <span>{d.invoice}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.batch_number}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.supplier_name}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.transactiontype}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.quantity}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.unit_cost}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span>{d.total_amount}</span>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                    {/* <TableRow>
                                        <TableCell colSpan={6} align="right">Total Purchase Amount:</TableCell>
                                        <TableCell>{purchase_amount}</TableCell>
                                    </TableRow> */}
                                </TableBody>
                                : <TableBody><TableRow>
                                    <TableCell colSpan="7" align="center">No records found</TableCell>
                                </TableRow></TableBody>}
                        </Table>
                    </TableContainer>
                    <MuiPagination data={products} rowsPerPage={rowsPerPage} page={page}
                                   handleChangeRowsPerPage={handleChangeRowsPerPage}
                                   handleChangePage={handleChangePage} rows={[10, 20, 30, 40, 50]} colSpan={6}/>
                </MuiPaper>
            </Container>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductHistoryReport);