import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../Helpers/config.json";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { useQuery } from "react-query";

export const useManageDebitNote = (actions) => {
  const storage = JSON.parse(sessionStorage.getItem("total_count"));
  const item_count = storage ? storage : 0;
  const count = () => +item_count || 0;

  const [debitNotes, setDebitNotes] = useState([]);

  const [totalCount, setTotalCount] = useState(count);

  const [activePage, setActivePage] = useState(1);

  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState("idle");

  const [totalAmount, setTotalAmount] = useState(0);

  const [openDialog, setOpenDialog] = useState(false);

  const [isSubmitted, setisSubmitted] = useState("idle");

  const [loadingDebit, setLoadingDebit] = useState("idle");

  const [draftDialog, setDraftDialog] = useState(false);

  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const isResolved = isSubmitted === "resolved";

  const debitNoteListQuery = useQuery(
    ["debitNoteListQuery", date, activePage, search],
    async () => {
      try {
        const response = await axios.get(
          `${config.epharmUrl}/Cdebitmemo/debit_memo_list`,
          {
            params: {
              page_number: activePage,
              start_date: date?.startDate,
              end_date: date?.endDate,
              search,
            },
          },
        );

        // console.log(response);

        setDebitNotes(response?.data?.data?.debit_memo?.debit_memo_list ?? []);

        setTotalAmount(response?.data?.data?.debit_memo?.grand_total_amount ?? 0);

        setLoading("success");

        setisSubmitted("idle");

        return (
          response?.data?.data ?? {
            debit_memo: {
              debit_count: 0,
              debit_memo_list: [],
              grand_total_amount: 0,
            },
            next_page: 0,
            total_pages: 0,
            total_records: 0,
          }
        );
      } catch (error) {
        errorMessages(error, null, actions);
      }
    },
    { retry: 1 },
  );

  const {debit_memo, next_page, total_pages, total_records} = debitNoteListQuery?.isSuccess ? debitNoteListQuery?.data : {}

  // console.log(debitNoteListQuery?.data)

  // const retrieveDebitNotes = useCallback(
  //   (start_date, end_date) => {
  //     setLoading("idle");
  //     axios
  //       .get(`${config.epharmUrl}/Cdebitmemo/debit_memo_list`, {
  //         params: { page_number: activePage, start_date, end_date },
  //       })
  //       .then((response) => {
  //         const res = response.data;
  //         const dt = res.data ? res.data : {};
  //         const data = dt?.debit_memo ? dt?.debit_memo : {};
  //         const grand_totol_amount = data?.grand_total_amount
  //           ? data.grand_total_amount
  //           : null;
  //         const debit_note_list = data.debit_memo_list
  //           ? data.debit_memo_list
  //           : [];
  //         const debit_note_count = dt.total_records ? dt.total_records : 0;
  //
  //         if (activePage === 1) {
  //           sessionStorage.setItem(
  //             "total_count",
  //             JSON.stringify(debit_note_count)
  //           );
  //           setTotalCount(debit_note_count);
  //         } else {
  //           setTotalCount(count);
  //         }
  //         setDebitNotes(debit_note_list);
  //         setTotalAmount(grand_totol_amount);
  //         setLoading("success");
  //         setisSubmitted("idle");
  //       })
  //       .catch((error) => {
  //         errorMessages(error, null, actions);
  //         setLoading("error");
  //       });
  //   },
  //   [activePage]
  // );
  //
  // useEffect(() => {
  //   retrieveDebitNotes(startDate, endDate);
  // }, [retrieveDebitNotes, isResolved]);

  const handleChangeDate = (event) => {
    const { value, name } = event.target;
    setDate({ ...date, [name]: value });
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
    // if (event.target.value !== "") {
    //   axios
    //     .get(`${config.epharmUrl}/Cdebitmemo/debit_list_search`, {
    //       params: { search: event.target.value },
    //     })
    //     .then((response) => {
    //       const res = response.data;
    //       const dt = res ? res : [];
    //       setDebitNotes(dt);
    //       // calculateTotalAmount(dt)
    //     })
    //     .catch((error) => {
    //       errorMessages(error, null, actions);
    //       setLoading("error");
    //     });
    // } else {
    //   retrieveDebitNotes(startDate, endDate);
    // }
  };

  // const handleOpenDialog = (id) => {
  //   setPurchaseId(id);
  //   setOpenDialog(true);
  //   setLoadingPurchase("loading");
  //   axios
  //     .get(`${config.epharmUrl}/Cpurchase/purchase_details_data`, {
  //       params: { purchase_id: id },
  //     })
  //     .then((response) => {
  //       const dataRes = response.data;
  //       const dt = dataRes ? dataRes : {};
  //       const products = !dt.purchase_all_data ? [] : dt.purchase_all_data;
  //       setProductPurchase({ supplierDetails: dt, products });
  //       setLoadingPurchase("success");
  //     })
  //     .catch((err) => {
  //       errorMessages(err, null, actions);
  //       setLoadingPurchase("error");
  //     });
  // };

  const handleOpenDraftDialog = () => {
    setDraftDialog(true);
  };

  //   const handleDeletePurchase = (is_draft = false) => {
  //     axios
  //       .post(`${config.epharmUrl}/Cpurchase/delete_purchase`, {
  //         purchase_id,
  //         user_roles_id: user_id,
  //       })
  //       .then(() => {
  //         actions.snackbarActions.successSnackbar(
  //           "Purchase canceled successfully"
  //         );
  //         setisSubmitted("resolved");
  //         setDraftDialog(false);
  //         if (is_draft) {
  //           sessionStorage.setItem(purchase_id, JSON.stringify(productPurchase));
  //           history.push(`/editpurchase/${purchase_id}`);
  //         }
  //       })
  //       .catch((error) => {
  //         errorMessages(error, null, actions);
  //       });
  //   };

  const handleCancelDebitNote = () => {
    // handleDeletePurchase(true);
  };

  const handleCancelDraft = () => {
    // handleDeletePurchase();
    handleCloseDialog();
  };

  const handleCloseDraftDialog = () => {
    setDraftDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseBar = () => {
    actions.snackbarActions.hideSnackBar();
  };

  const handleSearch = (event) => {
    event.preventDefault();
    //if (startDate && endDate) {
    //  retrieveDebitNotes(startDate, endDate);
    // setSubmitted(!submitted)
    //}
  };

  const handleChangePage = (page) => {
    setActivePage(page);
  };

  const isLoading = debitNoteListQuery?.isLoading;

  const isSuccess = debitNoteListQuery?.isSuccess;

  const isError = debitNoteListQuery?.isError;

  return {
    debitNotes,
    totalAmount,
    handleChangeDate,
    date,
    handleChangeSearch,
    search,
    handleSearch,
    isLoading,
    isSuccess,
    isError,
    openDialog,
    handleChangePage,
    handleCloseBar,
    activePage,
  };
};
