import React,  { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowRestore } from "@fortawesome/free-solid-svg-icons/faWindowRestore";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {
  convert,
  dateStringConvert,
  dateStringTimeConvert,
  titleCase,
} from "../../Utils/titleCaseFunction";
import { CustomPagination } from "../../Utils/Tables/CustomTable";
import DashboardHeader from "../../Containers/Dasboard/DashboardHeader";
import { CancelDialog } from "../../Utils/DeleteDialog";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPaper,
} from "../../Utils/Tables/CustomTable";
import { useManagePurchase } from "./CustomHooks/useManagePurchase";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import { formatAmount } from "../../Utils/formatAmount";
import { LoadingGif } from "../../Utils/Loader";
import {
  useExcelReports,
  usePdfReports,
} from "../Invoice/CustomHooks/useExcelReports";
import Tooltip from "@material-ui/core/Tooltip";
import { history } from "../../Helpers/history";
import { ReusableRouteTabs } from "../../Utils/Tabs/ReusableTabs";
import axios from "axios";
import config from "../../Helpers/config.json";
import { errorMessages } from "../../Helpers/ErrorMessages";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "0.8em",
    height: "0.8em",
  },
  approved: {
    color: "green",
  },
  canceled: {
    color: "#D9512C",
  },
  pending: {
    color: "#F29339",
  },
}));

const DraftPurchase = ({ actions, snackbars }) => {
  const [loading, setLoading] = useState("idle");
  const [draft, setDraft] = useState({});
  const [search, setSearch] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [date, setDate] = useState({ from_date: "", to_date: "" });
  const [isSubmitted, setisSubmitted] = useState("idle");
  const [totalCount, setTotalCount] = useState(0);


  const { from_date, to_date } = date;
  const isResolved = isSubmitted === "resolved";

  const retrieveDraftList = useCallback(
    (start_date, end_date) => {
    setLoading(true);
    axios.get(`${config.epharmUrl}/Cpurchase/search_purchase_drafts`,{params:{search:search,page:activePage,per_page:"",start_date,end_date}}).then(res => {
        const data = res.data;
        const dt = !data ? {} : data;
        const draft_data = dt.details ?? []
        const total = dt.num_pages ? dt.num_pages : 0
        setTotalCount(total)
        setDraft(draft_data)
        setLoading("success");
        setisSubmitted("idle");
    }).catch((err) => {
      errorMessages(err, null, actions);
        setLoading(false);
    })
  },[activePage, search])


  useEffect(()=>{
   retrieveDraftList(from_date, to_date)
}, [retrieveDraftList, isResolved])

const handleSearchDraft = (event) => {
  setSearch(event.target.value);
  if (event.target.value !== "") {
    axios
      .get(`${config.epharmUrl}/Cpurchase/search_purchase_drafts`,
      {params:{search: event.target.value,page:activePage,start_date:from_date,end_date:to_date}}).then(res => {
        const data = res.data;
        const dt = !data ? {} : data;
        const draft_data = dt.details ?? []
        setDraft(draft_data);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setLoading("error");
      });
  } 
};


const handleSearchList = (event) => {
  event.preventDefault();
  if (from_date && to_date) {
    retrieveDraftList(from_date, to_date)
  }
};

const handleChangePage = (page) => {
  setActivePage(page);
};

const handleChangeDate = (event) => {
  const { value, name } = event.target;
  setDate({ ...date, [name]: value });
};

const handleCloseBar = () => {
  actions.snackbarActions.hideSnackBar();
};

const handleRetrieveDraft = (id) => {
  // setDraftId(id)
  // axios
  // .get(`${config.epharmUrl}/Cpurchase/get_all_drafts`, {
  //   params: { draft_id: id }
  // })
  // .then((response) => {
  //     const dataRes = response.data;
  //     const details = dataRes.purchase_details_drafts ?? []
  //     const purchase = dataRes.purchase_drafts ?? []
  //     setDraftPurchase({ supplierDetails:purchase[0] , products: details });
  //     sessionStorage.setItem(draft_id, JSON.stringify(draftPurchase));
      history.push(`/retrievepurchase/${id}`);
    
  // })
  // .catch((error) => {
  //   errorMessages(error, null, actions);
  // });
 }

  const currentObj = {
    file_name: "Purchase List",
    url: "Cpurchase/purchase_list_report",
    params: { start_date: from_date, end_date: to_date },
  };

  const objPDF = {
    file_name: "Purchase List PDF",
    url: "Cpurchase/purchase_list_report_pdf",
    params: { start_date: from_date, end_date: to_date },
  };

  const { exportExcel } = useExcelReports(actions, currentObj);
  const { exportPDF } = usePdfReports(actions, objPDF);

  const { open, message, variant } = snackbars;
  const user = JSON.parse(sessionStorage.getItem("user"));
  const singleUser = !user ? {} : user;
  const permissions = !singleUser.permission ? {} : singleUser.permission;
  const manage_purchase = !permissions.manage_purchase
    ? {}
    : permissions.manage_purchase;
  const headData = [
    {
      id: "date_created",
      numeric: false,
      disablePadding: false,
      label: "Date Created",
    },
    {
      id: "invoiceNo",
      numeric: false,
      disablePadding: false,
      label: "Invoice No",
    },
    // {id: 'purchaseID', numeric: false, disablePadding: false, label: 'Purchase ID'},
    {
      id: "manufName",
      numeric: false,
      disablePadding: false,
      label: "Supplier Name",
    },
    {
      id: "purchaseDate",
      numeric: false,
      disablePadding: false,
      label: "Purchase Date",
    },
    {
      id: "tAmount",
      numeric: false,
      disablePadding: false,
      label: "Total Amount",
    },
    manage_purchase.create || manage_purchase.update || manage_purchase.delete
      ? { id: "action", numeric: false, disablePadding: false, label: "Action" }
      : { id: "nonaction", numeric: false, disablePadding: false, label: "" },
  ];
  const classes = useStyles();


  const components = [{label: 'All Purchases', path: '/managepurchase'},
        // {label: 'Canceled Purchases', path: '/canceled-purchases'},
        {label: 'Drafted Purchases', path: '/drafted_purchase'}
    ]

    const isLoading = loading === "loading";
    const isSuccess = loading === "success";
    const isError = loading === "error";
  

  return (
    <div className="journals">
      <DashboardHeader purchase managePurchase />
      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={handleCloseBar}
      />
      <ReusableRouteTabs
        value={history.location.pathname}
        tabHeading={components}
      />
      <div className="general-ledger-header mb-3">
        <Grid container spacing={0}>
          <Grid item xs={6} md={8}>
            <form onSubmit={handleSearchList}>
              <Form.Group as={Row} style={{ marginBottom: "-1%" }}>
                <Col sm={4}>
                  <Form.Group as={Row}>
                    <Label
                      name="From"
                      column
                      sm={2}
                      style={{ textAlign: "right" }}
                    />
                    <Col sm={10}>
                      <TextField
                        data-testid="from_date"
                        type="date"
                        onChange={handleChangeDate}
                        value={from_date}
                        name="from_date"
                      />
                    </Col>
                  </Form.Group>
                </Col>

                <Col sm={4}>
                  <Form.Group as={Row}>
                    <Label name="To" column sm={2} />
                    <Col sm={10}>
                      <TextField
                        data-testid="to_date"
                        type="date"
                        value={to_date}
                        onChange={handleChangeDate}
                        name="to_date"
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={1}>
                  <button
                    data-testid="submit_date_filter"
                    type="submit"
                    className="btn btn-sm pharmacy-btn-outline"
                  >
                    Filter
                  </button>
                </Col>
              </Form.Group>
            </form>
          </Grid>

          <Grid item xs={6} md={4}>
            <div className="text-right">
              <Link to="/addpurchase">
                <button
                  data-testid="add_purchase"
                  className="btn btn-sm pharmacy-info-btn py-1"
                >
                  <AddIcon classes={{ root: classes.icon }} />
                  Add New Purchase
                </button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="mui-table">
        <MuiPaper>
          <EnhancedTableToolbar title="Purchases">
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  onClick={exportExcel}
                  className="btn btn-sm mr-1 pharmacy-btn"
                >
                  Download Excel
                </button>
              </span>
            </span>
            <span className="btn-group" style={{ flex: "1 1 100%" }}>
              <span>
                <button
                  onClick={exportPDF}
                  className="btn btn-sm mr-1 pharmacy-btn"
                >
                  Download PDF
                </button>
              </span>
            </span>
            <Form.Control
              type="text"
              value={search ? titleCase(search) : ""}
              onChange={handleSearchDraft}
              className="form__control"
              placeholder="Search by supplier name..."
              style={{ float: "right" }}
            />
          </EnhancedTableToolbar>
          <div>
            <Table
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead headCells={headData} />
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={headData.length} align="center">
                      <LoadingGif />
                    </TableCell>
                  </TableRow>
                ) : null}
                {isSuccess ? (
                  draft.length > 0 ? (
                    <>
                      {draft.slice(0, 10).map((d, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            className="mui-table-row"
                          >
                            <TableCell>
                              {dateStringTimeConvert(d.purchase_date)}
                            </TableCell>
                            <TableCell>
                              <span data-testid={"invoice_no-" + index}>
                                {d.chalan_no}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span data-testid={"manufacturer_name-" + index}>
                                {titleCase(d.manufacturer_name)}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span data-testid={"purchase_date-" + index}>
                                {dateStringConvert(d.purchase_date)}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span data-testid={"grand_total_amount-" + index}>
                                {d.grand_total_amount
                                  ? formatAmount(d.grand_total_amount)
                                  : d.grand_total_amount}
                              </span>
                            </TableCell>
                            <TableCell id="mainbuttons">
                              {/* {manage_purchase.update ||
                              manage_purchase.delete ? ( */}
                                <span>
                                  
                                  <Tooltip
                                    title="View Draft Purchase"
                                    placement="left-start"
                                  >
                                          <button 
                                           onClick={() =>
                                            handleRetrieveDraft(d.draft_id)
                                          }
                                          className="btn pharmacy-info-btn btn-sm mr-1 mb-1" id="view_invoice">
                                              View Draft</button>
                                      {/* </Link> */}
                                  </Tooltip>
                                
                                </span>
                                <span />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        data-testid="no_records_found"
                        colSpan={headData.length}
                        align="center"
                      >
                        No records found
                      </TableCell>
                    </TableRow>
                  )
                ) : null}
                {isError ? (
                  <TableRow>
                    <TableCell colSpan={headData.length} align="center">
                      The server did not return a valid response
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </div>
          <CustomPagination
            data={draft}
            records={10}
            activePage={activePage}
            total_count={totalCount}
            handleChangeNextPage={handleChangePage}
          />
        </MuiPaper>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DraftPurchase);
