import React, {useEffect, useState} from 'react';
import config from "../../../Helpers/config";
import axios from "axios"
import {convDate, convertDate, titleCase} from "../../../Utils/titleCaseFunction";
import {history} from "../../../Helpers/history"
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {useAddManufacturer} from "../../Manufacturer/CustomHooks/useAddManufacturer";
import { v4 as uuidv4 } from 'uuid';

export const useAddPurchaseOrder = (actions, match) => {
    const {local_purchase_order_id, status, template, component} = match.params
    const [rows, setRows] = useState([{
        product_name: '', product_id: '',local_purchase_order_details_id:'', uuid_id: uuidv4(),
        quantity: 0, rate: 0, total: 0, showProduct: false
    }]);
    const [manufacturer_name, setManufacturerName] = useState('');
    const [state, setState] = useState({shipping_terms:'', fob:'',
        deliver_to: '', delivery_date: '', order_date: convertDate(),
        comments: '', reason: '',purchase_order_number:''
    });
    const [grand_total, setGrandTotal] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openDialog, setOpenDialog] = useState(false);
    const [id, setId] = useState('');
    const [total_tax, setTotalTax] = useState(0);
    const [sub_total, setSubTotal] = useState(0);
    const [purchaseOrder, setPurchaseOrder] = useState({})
    const [loading, setLoading] = useState('idle')
    const {manufacturerProps, isResolved} = useAddManufacturer(actions)

    const [openChooseMedicine, setChooseMedicineDialog] = useState(false);
    const [chosen_submitted, setChosenSubmitted] = useState(false);
    const [medicineToChoose, setMedicineToChoose] = useState([]);
    const [chosenMedicine, setChosenMedicine] = useState([]);
    const [isBlocking, setIsBlocking] = useState(false)

    useEffect(() => {
        actions.manufacturerActions.getAllManufacturers();
    }, [isResolved])

    useEffect(() => {
        if (!component) {
            return
        }
        setLoading('loading')
        axios.get(`${config.epharmUrl}/Creport/retrieve_all_out_of_stock_drugs`, {params: {local_purchase_order_id}})
            .then(response => {
                const data = response.data;
                const dt = data ? data : []
                setLoading('success');
                const arr = dt.map((item) => ({
                    ...item, uuid_id: uuidv4(), label:item.product_name, value:item.generic_name,
                    quantity: 0, rate: 0, total: 0, showProduct: false
                }));
                if (arr.length === 0) {
                    setChooseMedicineDialog(false);
                    setRows(arr);
                } else {
                    setChooseMedicineDialog(true);
                    setMedicineToChoose(arr);
                    //setRows(arr);
                } 
            }).catch(error => {
            errorMessages(error, null, actions);
            setLoading('error')
        })
    }, [component])

    useEffect(() => {
        if (!local_purchase_order_id) {
            return
        }
        axios.get(`${config.epharmUrl}/Cpurchaseorder/purchaseoder_update_form`, {params: {local_purchase_order_id}})
            .then(response => {
                const data = response.data;
                const dt = data ? data : {}
                const products = dt.product_list ? dt.product_list : [];
                setPurchaseOrder(dt)
                calculateGrandTotal(products)
            }).catch(error => {
            errorMessages(error, null, actions);
        })

    }, [local_purchase_order_id])

    useEffect(() => {
        if (!local_purchase_order_id) {
            return
        }
        const products = !purchaseOrder.product_list ? [] : purchaseOrder.product_list;
        const arr = products.map((item) => ({...item, uuid_id: uuidv4()}));
        setRows(arr);
        setManufacturerName({manufacturer_id: purchaseOrder.manufacturer_id, manufacturer_name: purchaseOrder.manufacturer_name})
        setState({
            deliver_to: purchaseOrder.ship_to_address,
            delivery_date: purchaseOrder.delivery_date ? purchaseOrder.delivery_date : '',
            comments: purchaseOrder.details,
            order_date: purchaseOrder.order_date,
            reason: purchaseOrder.reason ? purchaseOrder.reason : '',
            purchase_order_number: purchaseOrder.purchase_order_number ?? ''
        })
        setGrandTotal(purchaseOrder.grand_total);
        setTotalTax(purchaseOrder.tax)

    }, [local_purchase_order_id, purchaseOrder])

    const handleInput = event => {
        const {name, value} = event.target;
        setState({...state,[name]: value});
        setIsBlocking(true)
    };

    const handleChangeManufacturer = value => {
        const manu_name = value === null ? '' : value;
        setManufacturerName(manu_name);
        if(component && (manu_name !== '')){
            const arr = rows.map(({product_id})=>product_id)
            const get_supplier_id = value.manufacturer_id ? value.manufacturer_id : value.value;
            const formData = new FormData()
            formData.append('supplier_id', get_supplier_id)
            console.log(rows)
            arr.forEach(item=>{
                formData.append('product_id', item.product_id)
            })
            axios.post(`${config.epharmUrl}/Cpurchaseorder/retrieve_last_purchase_price`, {supplier_id:get_supplier_id, product:arr})
                .then(response => {
                    const data = response.data;
                    const dt = data ? data : {}
                    const arr = dt.detail ? dt.detail :[]
                    let arr1 = []
                    for (let row in rows){
                        let obj = {}
                        for (let item in arr){
                            if (parseInt(rows[row].product_id) === parseInt(arr[item].product_id)){
                                obj = {
                                    product_name: rows[row].product_name, 
                                    product_id: arr[item].product_id,
                                    quantity: '', 
                                    rate: arr[item].manufacturer_price, 
                                    total: 0, 
                                    showProduct: false,
                                    uuid_id: uuidv4()
                                }
                            }
                        }
                        arr1.push(obj)
                    }
                    setRows(arr1)
                }).catch(error => {
                errorMessages(error, null, actions);
            })
        }
        setIsBlocking(true)
    };

    const handleAddRow = () => {
        const item = {product_name: '', product_id: '', quantity: 0, unit_price: 0, total: 0, rate: 0, uuid_id: uuidv4()};
        setRows([...rows, item])
        setIsBlocking(true)
    };

    const handleRemoveSpecificRow = (uuid_id_to_remove) => {
        const arr = rows.filter((item) => item.uuid_id !== uuid_id_to_remove);
        calculateGrandTotal(arr);
        setRows(arr)
        setIsBlocking(true)
    };

    const handleChangeTax = (event, arr) => {
        setTotalTax(event.target.value);
        calculateGrandTotal(arr)
        setIsBlocking(true)
    };

    const calculateTax = () => {
        const tax = !total_tax ? 0 : total_tax;
        return tax / 100;
    };

    const calculateGrandTotal = (arr) => {
        const sum = arr.reduce((a, row) => {
            return +(a + row.total);
        }, 0);
        const tax_amount = calculateTax() * sum;
        const total_amount = sum + tax_amount;
        setSubTotal(sum);
        setGrandTotal(total_amount)
    };

    const handleSearchProduct = (eventTxt, index) => {
        var items = [...rows];
        if (items[index]) {
            items[index]['product_name'] = eventTxt;
            let temp = eventTxt.trim();
            if (temp.includes('(')) {
                temp = eventTxt.substring(0, eventTxt.indexOf('('));
            }
            if (temp !== ' ') {
                if (component && (![index]['product_id'])) {
                    axios.get(config.epharmUrl + '/Cpurchase/product_search_by_manufacturer',
                    {params: {product_name: titleCase(temp)}})
                    .then((response) => {
                        const data = response.data;
                        const dt = !data ? [] : data;
                        if (response.data === "No Medicine Found") {
                            items[index]['product_id'] = '';
                        } else {
                            items[index]['product_id'] = dt.length > 0 ? dt[0]['value'] : '';
                        }
                    })
                    .catch(error => {
                        errorMessages(error, '', null)
                    })
                } else {
                    actions.purchaseActions.searchDrugbyManufacturer(titleCase(temp));
                }
                items[index]['showProduct'] = true;
            } else {
                actions.purchaseActions.searchDrugbyManufacturer('');
                items[index]['showProduct'] = false;
                items[index]['product_id'] = '';
            }
        }
        setRows(items)
        setIsBlocking(true)
    };

    const retrieveProductData = (event, index) => {
        let arr = rows.map((item,idx)=>{
            if (idx === index) {
                return {...item, product_name: event.innerText, product_id: event.value, showProduct: false}
            }
            return item
        })
        if (event.innerText !== '') {
            axios.get(`${config.epharmUrl}/Cinvoice/retrieve_product_data`,
                {params: {product_id: event.value}}).then(response => {
                const data = response.data;
                const dt = data ? data : {};
                arr = arr.map((item, idx) =>{
                    if (idx === index) {
                        return {...item, rate:dt.manufacturer_price ? dt.manufacturer_price : 0}
                    }
                    return item
                })
                setRows(arr)
            }).catch(error => {
                errorMessages(error, null, actions)
            });
        }
        setIsBlocking(true)
    };

    const getTotalApparent = (index) => {
        const items = [...rows]
        items[index]['total'] = +(items[index]['rate'] * items[index]['quantity']);
        calculateGrandTotal(items)
    };

    const handleChangeQuantity = (event, index) => {
        const {value} = event.target;
        const items = [...rows]
        if (items[index]) {
            items[index]["quantity"] = value;
            getTotalApparent(index)
        }
        setRows(items)
        calculateGrandTotal(items)
        setIsBlocking(true)
    };

    const handleChangePrice = (event, index) => {
        const {value} = event.target;
        const items = [...rows]
        if (items[index]) {
            items[index]["rate"] = value;
            getTotalApparent(index)

        }
        setRows(items)
        calculateGrandTotal(items)
        setIsBlocking(true)

    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const handleOpenDialog = (local_order_id) => {
        setOpenDialog(true)
        setId(local_order_id)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleSubmitPurchase = event => {
        event.preventDefault();
        const {delivery_date,  deliver_to, comments,purchase_order_number,shipping_terms, fob, reason} = state
        const user = JSON.parse(sessionStorage.getItem('user'));
        const singleUser = !user ? {} : user;
        const user_id = !singleUser.user_id ? '' : singleUser.user_id
        setSubmitted(true)

        const isTemplate = status === '0' || status === '2'

        const addProducts = rows.map(({product_id,quantity,rate,total})=>({product_id, quantity: +quantity, rate: +rate, total: +total}))
        const editProducts = rows.map(({local_purchase_order_details_id,product_id,quantity,rate,total})=>({product_id, quantity: +quantity, rate: +rate,
            total: +total, local_purchase_order_details_id}))

        const dataObj = {manufacturer_id:manufacturer_name.manufacturer_id,ship_to_address:deliver_to ? deliver_to : '',
            delivery_date: delivery_date ? convDate(delivery_date) : delivery_date, tax: total_tax ? total_tax : 0, comments: comments ? comments : '',
            shipping_termes:shipping_terms ? shipping_terms : '', fob:fob ? fob : '',  grand_total, status: isTemplate ? +status : null,
            user_id}

        const editData = {local_purchase_order_id, purchase_order_number, ...dataObj,reason,
           new_products:editProducts.filter(item=>!item.local_purchase_order_details_id),
            products:editProducts.filter(item=>item.local_purchase_order_details_id)}

        const jsonData = local_purchase_order_id ? editData : {...dataObj, products: addProducts}

        const arr = rows.every(item => item.rate && item.product_name && item.quantity)
        if (arr && manufacturer_name && delivery_date && deliver_to) {
            setIsSubmitted('pending')
            setIsBlocking(false)
            const url = local_purchase_order_id && !template ? 'purchaseoder_update' : 'creat_purchaseorder'
            axios.post(`${config.epharmUrl}/Cpurchaseorder/${url}`, jsonData)
                .then(response => {
                    const data = response.data;
                    const dt = data ?? {};
                    const id = dt.local_purchase_order_id ?? '';
                    actions.snackbarActions.successSnackbar(`Purchase Order ${local_purchase_order_id && !template ? 'Edited' : 'Saved'} Successfully`);
                    handleOpenDialog(id)
                    setIsSubmitted('resolved')
                }).catch(error => {
                errorMessages(error, null, actions);
                setIsSubmitted('rejected')
            })
        }
    };

    const viewOrder = () => {
        history.push(`/purchaseorderapproval/${local_purchase_order_id ? local_purchase_order_id : id}/${status ? status : 0}`)
    };

    const handleCloseChooseMedicine = () => {
        setChooseMedicineDialog(false);
        setRows([{product_name: '', product_id: '', quantity: 0, unit_price: 0, total: 0, rate: 0, uuid_id: uuidv4()}]);
    };

    const handleChosenMedicineChange = (event, value) => { 
        setChosenMedicine(value);
    }

    const handleSetRows = () => {
        setChosenSubmitted(true);
        if (chosenMedicine.length > 0) {
            setChooseMedicineDialog(false);
            setRows(chosenMedicine);
            setChosenSubmitted(false);
        }   
    }

    const isIdle = loading === 'idle'
    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return  {
        rows, manufacturer_name, grand_total, submitted, isSubmitted, openDialog,
        id, total_tax, sub_total, purchaseOrder, loading, isResolved,
        manufacturerProps, isError, isIdle, isSuccess, isLoading, state,
        handleInput, handleChangeManufacturer, handleAddRow, handleRemoveSpecificRow, 
        handleChangeTax, calculateTax, calculateGrandTotal, handleSearchProduct,
        retrieveProductData, getTotalApparent, handleChangeQuantity, handleChangePrice,
        handleCloseBar, handleOpenDialog, handleCloseDialog, handleSubmitPurchase, viewOrder,
        handleCloseChooseMedicine, openChooseMedicine, medicineToChoose, chosenMedicine, handleChosenMedicineChange,
        handleSetRows, chosen_submitted, isBlocking
    }
}