import React, { useState, useEffect } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  StickyHeadTable,
  CustomTableCell,
} from "../../../Utils/Tables/StickyHeaderTable";
import { LoadingGif } from "../../../Utils/Loader";
import { formatAmount } from "../../../Utils/formatNumbers";
import { formatDateTime } from "../../../Utils/ConvertDate";
import { RightAlignedContainer } from "../../../Utils/styledComponents";
import { AccountingDateFilter } from "../../../Utils/AccountingUtils/AccountingDateFilter";
import { dateConvert } from "../../../Utils/ConvertDate";
import axios from "axios";
import config from "../../../Helpers/config.json";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { connect } from "react-redux";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import {
  useExcelReports,
  usePdfReports,
} from "../../Invoice/CustomHooks/useExcelReports";
import { formatDigits } from "../../../Utils/formatAmount";
import { MuiPagination } from "../../../Utils/Tables/CustomTable";
import { usePrint } from "../../../Utils/Template/usePrint";
import CustomDialog from "../../../Utils/Dialog/CustomDialog";
import { SpecificJournalTemplate } from "./SpecificJournal";

const style = {
  border: {
    borderTop: "1px solid #606060",
    borderBottom: "1px solid #606060",
  },
  image: {
    width: 130,
    border: "1px solid #e4e4e4",
    borderRadius: "60px",
    background: "#e4e4e4",
  },
  p: {
    margin: 0,
  },
  parent: {
    textAlign: "center",
    marginTop: "5em",
  },
};

const headData = [
  { id: "date", label: "Date", minWidth: 200 },
  { id: "account_name", label: "Account name", minWidth: 200 },
  { id: "details", label: "Details", minWidth: 200 },
  { id: "credit", label: "Credit", minWidth: 170 },
  { id: "debit", label: "Debit", minWidth: 170 },
  { id: "balance", label: "Balance", minWidth: 170 },
  { id: "journal", label: "Journal", minWidth: 170 },
];

export const GeneralLedger = ({ actions, snackbars, isResolved }) => {
  const [ledger, setLedger] = useState([]);
  const [loading, setLoading] = useState("idle");
  const [opening_balance, setBalance] = useState(0);
  const [state, setState] = React.useState({
    from_date: dateConvert(),
    to_date: dateConvert(),
  });
  const [submitted, setSubmitted] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState("idle");
  const [activePage, setActivePage] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total_count, setTotalCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedJournalID, setSelectedJournalID] = useState("");
  const [specificItem, setItem] = useState({
    id: "",
    head_name: "",
    head_code: "",
  });
  const { from_date, to_date } = state;

  const { handlePrint, componentRef } = usePrint();

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackBar();
  };
  const start_date = from_date;
  const end_date = to_date;

  const currentObj = {
    file_name: "General Ledger",
    url: "accounting/general_ledger_excel?",
    params: { start_date, end_date },
  };

  const { exportExcel, isLoading: isLoadingExcel } = useExcelReports(
    actions,
    currentObj
  );

  const objPDF = {
    file_name: "General Ledger",
    url: "accounting/general_ledger_pdf?",
    params: { start_date, end_date },
  };

  const { exportPDF, isLoading: isLoadingPDF } = usePdfReports(actions, objPDF);

  useEffect(() => {
    setLoading("pending");
    axios
      .get(
        `${config.epharmUrl}/accounting/general_ledger_details?start_date=${from_date}&end_date=${to_date}&page=${activePage}&per_page=${rowsPerPage}`
      )
      .then((res) => {
        const data = res.data;
        const dt = data ? data : {};
        const arr = dt.data ? dt.data : [];
        const obj = arr[0] ? arr[0] : {};
        const ledgers = obj.details ? obj.details : [];
        const bal = obj.openingbalance ?? 0;
        const total = obj.total_count ?? 0;
        const ledgersArr = ledgers.map(
          ({
            Account,
            Credit,
            Date,
            Debit,
            details,
            balance,
            journal,
            ...rest
          }) => ({
            ...rest,
            Account,
            Credit,
            Date,
            Debit,
            details,
            balance,
            journal,
          })
        );
        setBalance(bal);
        setLedger(ledgersArr);
        setTotalCount(total);
        setLoading("success");
      })
      .catch((err) => {
        setLoading("error");
      });
  }, [isResolved, activePage, rowsPerPage]);

  const handleChange = (event) => {
    const { value, name } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleChangePage = (event,newPage) => {
    setPage(newPage);
    setActivePage(activePage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setActivePage(1);
  };

  const handleOpen = (event, journalID) => {
    event.preventDefault();

    setSelectedJournalID(journalID);

    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { from_date, to_date } = state;
    setSubmitted(true);
    if (from_date && to_date) {
      setIsSubmitted("pending");
      axios
        .get(
          `${config.epharmUrl}/accounting/general_ledger_details?start_date=${from_date}&end_date=${to_date}&page=${activePage}&per_page=${rowsPerPage}`
        )
        .then((res) => {
          const data = res.data;
          const dt = data ? data : {};
          const arr = dt.data ? dt.data : [];
          const obj = arr[0] ? arr[0] : {};
          const ledgers = obj.details ? obj.details : [];
          const bal = obj.openingbalance ?? 0;
          console.log(obj)
          const total = obj.total_count ?? 0;
          const ledgersArr = ledgers.map(
            ({
              Account,
              Credit,
              Date,
              Debit,
              details,
              balance,
              journal,
              ...rest
            }) => ({
              ...rest,
              Account,
              Credit,
              Date,
              Debit,
              details,
              balance,
              journal,
            })
          );
          setActivePage(1);
          setPage(0);
          setTotalCount(total)
          setBalance(bal);
          setLedger(ledgersArr);
          setIsSubmitted("resolved");
        })
        .catch((err) => {
          errorMessages(err, null, actions);
          setIsSubmitted("rejected");
        });
    }
  };

  const isLoading = loading === "pending";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  const isPending = isSubmitted === "pending";

  const emptyRows = 10 - Math.min(10, ledger.length);
  return (
    <div>
      <CustomDialog
        open={openDialog}
        handleClose={handleClose}
        title="Specific Journal Report"
        maxWidth="sm"
        isOverflow={false}
      >
        <div className="col-lg-6">
          <div className="text-right" style={{ marginLeft: "30rem" }}>
            <button
              onClick={handlePrint}
              className="btn pharmacy-info-btn mb-2 mr-2"
            >
              Print
            </button>
          </div>
        </div>
        <div ref={componentRef}>
          <SpecificJournalTemplate selectedJournalID={selectedJournalID} />
        </div>
      </CustomDialog>
      <ReusableDashboardHeader
        component="General Ledger"
        heading="Accounting"
        subHeading="General Ledger"
        link="/accounting/generalledger"
      />
      {/* <PharmacySnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={variant}/> */}
      <div className="sub-journal-bar">
        <RightAlignedContainer>
          <h5 data-testid="running_balance">
            Opening Balance : <strong>{formatAmount(opening_balance)}</strong>
          </h5>
        </RightAlignedContainer>
      </div>
      <div className="pt-3">
        <div className="mx-3">
          <AccountingDateFilter
            {...{
              from_date,
              to_date,
              handleChange,
              submitted,
              handleSubmit,
              isPending,
            }}
          />
          {ledger.length > 0 ? (
            <RightAlignedContainer>
              <button
                onClick={exportPDF}
                disabled={isLoadingPDF}
                className="btn btn-sm pharmacy-info-btn mb-2 mr-2"
              >
                Download PDF
              </button>
              <button
                type="button"
                onClick={exportExcel}
                disabled={isLoadingExcel}
                className="btn btn-sm pharmacy-info-btn mb-2"
              >
                {isLoadingExcel ? "Exporting...." : "Export to Excel"}
              </button>
            </RightAlignedContainer>
          ) : null}
        </div>

        <StickyHeadTable
          {...{
            ledger,
            columns: headData.filter((item) => Boolean(item)),
            width: "100%",
          }}
        >
          {isLoading ? (
            <TableRow>
              <TableCell align="center" colSpan={headData.length}>
                <LoadingGif />
              </TableCell>
            </TableRow>
          ) : null}
          {isSuccess ? (
            ledger.length > 0 ? (
              <>
                {ledger.slice(0,rowsPerPage).map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      <CustomTableCell
                        data-testid={"transaction_time-" + index}
                      >
                        {formatDateTime(row.Date)}
                      </CustomTableCell>
                      <CustomTableCell data-testid={"account_name-" + index}>
                        {row.Account}
                      </CustomTableCell>
                      <CustomTableCell data-testid={"details-" + index}>
                        {row.details}
                      </CustomTableCell>
                      <CustomTableCell data-testid={"credit-" + index}>
                        {formatDigits(row.Credit)}
                      </CustomTableCell>
                      <CustomTableCell data-testid={"dedit-" + index}>
                        {formatDigits(row.Debit)}
                      </CustomTableCell>
                      <CustomTableCell data-testid={"balance-" + index}>
                        {row.balance}
                      </CustomTableCell>
                      <CustomTableCell
                        data-testid={"journal-" + index}
                        // onClick={(event) => handleOpen(event, row.journalId)}
                        //  style={{ cursor: 'pointer' }}
                      >
                        {/* {row.journalId} */}
                        <a
                          href="#"
                          onClick={(event) => handleOpen(event, row.journal)}
                        >
                          {row.journal}
                        </a>
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 43 * emptyRows }}>
                    <TableCell colSpan={headData.length} />
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={headData.length}>
                  No records found
                </TableCell>
              </TableRow>
            )
          ) : null}
          {isError ? (
            <TableRow>
              <TableCell align="center" colSpan={headData.length}>
                The server did not return a valid response
              </TableCell>
            </TableRow>
          ) : null}
          <MuiPagination
            rowsPerPage={rowsPerPage}
            page={page}
            isTotalCount={true}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            total_count={total_count}
            handleChangePage={handleChangePage}
            rows={[10, 50, 100, 200, 300, 500]}
            colSpan={headData.length}
          />
        </StickyHeadTable>
      </div>
    </div>
  );
};

export const NoTransaction = ({ text }) => {
  return (
    <div style={style.parent}>
      <img
        src="/images/transaction.png"
        alt="transaction"
        style={style.image}
      />
      <p
        className="text-center"
        style={style.p}
      >{`There are no ${text} available`}</p>
    </div>
  );
};

function mapStateToProps(state) {
  const { snackbars } = state;
  return { snackbars };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: { snackbarActions: bindActionCreators(snackbarActions, dispatch) },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralLedger);
