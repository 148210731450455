import React, { useEffect, useState } from "react";
import { history } from "../../Helpers/history";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../Utils/FormInputs/TextField";
import { removeTime } from "../../Utils/titleCaseFunction";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { useNewReceipt } from "./CustomHooks/useNewReceipt";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import { connect } from "react-redux";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import DetailsDialog from "../../Utils/Dialog/DetailsDialog";
import { RowCustomerForm } from "../Customer/CustomerForm";
import InvoicePrintDialog from "./InvoicePrintDialog";
import {
  ErrorMessage,
  RightAlignedContainer,
} from "../../Utils/styledComponents";
import {
  AutoCompleteUnControlled,
  SelectItem,
} from "../../Utils/FormInputs/AutoCompleteInput";
import Alert from "@material-ui/lab/Alert";
import { usePrint } from "../../Utils/Template/usePrint";
import { SalesReceiptTemplate } from "./SalesReceiptTemplate";
import { Link } from "react-router-dom";
import { validateCurrentDate } from "../../Utils/ConvertDate";
import { BlockNavigationComponent } from "../../Utils/ReusableComponents/BlockNavigationComponent";
import ExpiryPopup from "./ExpiryPopup";
import CustomDialog from "../../Utils/Dialog/CustomDialog";
import Textarea from "../../Utils/FormInputs/Textarea";

/**
 * Component to return the UI for the New Sales Receipt
 * @param {*} param0
 * @returns
 */
function NewSalesReceipt({ actions, snackbars, match: { params } }) {
  const { invoice_number } = params;

  const {
    submitted,
    stateVariables,
    openDialog,
    isPending,
    handleCloseDialog,
    handleSaveReceipt,
    handleCloseSnackbar,
    receiptData,
    handleViewReceipt,
  } = useNewReceipt(actions, invoice_number);

  const {
    customer,
    rows,
    state,
    customerProps,
    handleClickCustomer,
    paytype,
    totals,
    customers,
    drugs,
    handleChangeBatch,
    handleAddRow,
    handleRetrieveCustomerInfo,
    handleChangePaymentMethod,
    handleChange,
    handleChangeBalance,
    handleChangeFormFields,
    handleRemoveSpecificRow,
    handleChangeQuantity,
    handleRetrieveDrugs,
    handleClickDrug,
    payTypes,
    isBlocking,
    isOpen,
    expiredDrugReason,
    showExpiredDrugReasonInput,
    showExpiredDrugDialog,
    openExpiredDrugReasonInput,
    closeExpiredDrugReasonInput,
    openExpiredDrugDialog,
    closeExpiredDrugDialog,
    handleChangeExpiredDrugReason,
    handleChangeDiscount
  } = stateVariables;

  const { paid_amount, change, due_amount, n_total } = totals;

  const {
    openCustomerDialog,
    handleOpenCustomerDialog,
    handleCloseCustomerDialog,
  } = customerProps;

  const { invoice_date } = state;

  const allCustomers = !customers ? [] : customers;

  const allDrugs = !drugs ? [] : drugs;

  const { open, variant, message } = snackbars;

  const { handlePrint, componentRef } = usePrint("");


  useEffect(() => {
    if (!openDialog) {
      return;
    }
    const listener = (event) => {
      if (event.key === "Enter" || event.key === "NumpadEnter") {
        // console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
        handlePrint();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [openDialog]);

  const validate_invoice_date = validateCurrentDate(invoice_date);

  const buttons = (
    <>
      <button
        onClick={
          showExpiredDrugReasonInput
            ? closeExpiredDrugDialog
            : openExpiredDrugReasonInput
        }
        type="button"
        className="btn btn-danger btn-sm"
      >
        {showExpiredDrugReasonInput ? "Submit" : "Yes"}
      </button>

      {/* <button
        onClick={closeExpiredDrugDialog}
        type="button"
        className="btn btn-sm pharmacy-gray-btn"
      >
        No
      </button> */}
    </>
  );

  return (
    <div data-testid="new-sales-receipt">
      <ReusableDashboardHeader
        component="New Receipt"
        heading="Sales"
        subHeading="New receipt"
        link={history.location.pathname}
      />

      <PharmacySnackbar
        open={open}
        variant={variant}
        message={message}
        handleCloseBar={handleCloseSnackbar}
      />

      <DetailsDialog
        title="New Customer"
        openDialog={openCustomerDialog}
        handleClose={handleCloseCustomerDialog}
      >
        <RowCustomerForm {...customerProps} />
      </DetailsDialog>

      <div style={{ display: "none" }}>
        <div ref={componentRef} className="card styled-pos-card">
          <SalesReceiptTemplate {...receiptData} />
        </div>
      </div>

      <BlockNavigationComponent
        when={isBlocking}
        shouldBlockNavigation={() => {
          return isBlocking;
        }}
        navigate={(path) => history.push(path)}
      />

      <InvoicePrintDialog
        text="receipt"
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        message="Receipt"
      >
        <RightAlignedContainer>
          <button
            onClick={handlePrint}
            type="button"
            className="btn pharmacy-amber-btn mr-3"
          >
            Print
          </button>

          <button
            onClick={handleViewReceipt}
            type="button"
            className="btn pharmacy-info-btn mr-3"
          >
            Preview
          </button>

          <Link to="/sales-receipts">
            <button type="button" className="btn pharmacy-grey-btn ">
              No
            </button>
          </Link>
        </RightAlignedContainer>
      </InvoicePrintDialog>

      <div className="receipt-container">
        <div className="text-md-right mt-4 mb-5">
          <button
            data-testid="quick_add_customer"
            type="button"
            className="btn btn-sm  pharmacy-info-btn"
            onClick={() => handleOpenCustomerDialog("")}
          >
            Quick Add Customer
          </button>
        </div>

        <form
          onSubmit={handleSaveReceipt}
          autoComplete="off"
          data-testid="new-sales-receipt-form"
        >
          <div className="row">
            <div className="col-lg-6">
              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Date" />
                </Col>

                <Col sm="8">
                  <TextField
                    type="date"
                    value={invoice_date}
                    name="invoice_date"
                    onChange={handleChangeFormFields}
                  />

                  {submitted && !invoice_date ? (
                    <ErrorMessage>Invoice date is required</ErrorMessage>
                  ) : null}

                  {validate_invoice_date === false ? (
                    <ErrorMessage>
                      Please select a valid date, you selected a date in the
                      future
                    </ErrorMessage>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Customer" />
                </Col>

                <Col sm="8" style={{ margin: "0px" }}>
                  <AutoCompleteUnControlled
                    options={allCustomers}
                    submitted={submitted}
                    handleChange={handleRetrieveCustomerInfo}
                    medicine_value={customer}
                    label_text={"customer_name"}
                    value_text={"customer_id"}
                    handleProductData={handleClickCustomer}
                    testId="customer"
                    id="customer"
                    placeholder="Type Customer Name"
                    errorMsg="Enter Customer Name"
                  />
                </Col>
              </Form.Group>

              {/*<Form.Group as={Row}>*/}
              {/*    <Col sm='3'>*/}
              {/*        <Label name='Credit limit'/>*/}
              {/*    </Col>*/}
              {/*   <Col sm='8'>*/}
              {/*       <TextField data-testid="credit_limit" type='number' value={credit_limit} readOnly/>*/}
              {/*   </Col>*/}

              {/*</Form.Group>*/}
              {/*<Form.Group as={Row}>*/}
              {/*    <Col sm='3'>*/}
              {/*        <Label name='Outstanding'/>*/}
              {/*    </Col>*/}
              {/*   <Col sm='8'>*/}
              {/*       <TextField   type='number' value={previous_amount} readOnly/>*/}
              {/*   </Col>*/}

              {/*</Form.Group>*/}

              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Payment Method" type />
                </Col>

                <Col sm="8">
                  <SelectInput
                    data-testid="payment_method"
                    value={paytype}
                    onChange={handleChangePaymentMethod}
                    options={payTypes}
                    defaultOption="Select payment method..."
                    submitted={submitted}
                  />

                  {submitted && !paytype ? (
                    <ErrorMessage>Payment method is required</ErrorMessage>
                  ) : null}
                </Col>
              </Form.Group>
            </div>

            <div className="col-lg-6">
              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Total Amount" />
                </Col>

                <Col sm="9">
                  <TextField
                    type="number"
                    name="n_total"
                    placeholder="0.00"
                    data-testid="total_amount"
                    value={n_total}
                    readOnly
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Paid Amount" />
                </Col>

                <Col sm="9">
                  <TextField
                    type="number"
                    value={paid_amount}
                    onChange={handleChangeBalance}
                    submitted={submitted}
                    data-testid="paid_amount"
                    name="paid_amount"
                    placeholder="0.00"
                  />

                  {submitted && !paid_amount ? (
                    <div className="invalid-text">
                      Please enter the paid amount
                    </div>
                  ) : null}

                  {submitted && paid_amount && paid_amount < n_total ? (
                    <div className="invalid-text">
                      Paid amount should not be less than the total amount
                    </div>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Balance" />
                </Col>

                <Col sm="9">
                  <TextField
                    type="number"
                    name="change"
                    value={change}
                    placeholder="0.00"
                    readOnly
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm="3">
                  <Label name="Due Amount" />
                </Col>

                <Col sm="9">
                  <TextField
                    type="number"
                    name="due_amount"
                    placeholder="0.00"
                    data-testid="due_amount"
                    value={due_amount}
                    readOnly
                  />
                </Col>
              </Form.Group>
            </div>
          </div>

          {submitted && rows.length <= 0 ? (
            <div className="mb-4">
              <Alert severity="error">
                Please add a product before submitting
              </Alert>
            </div>
          ) : null}

          <CustomDialog
            open={showExpiredDrugDialog}
            handleClose={closeExpiredDrugDialog}
            title="Drug about to Expire!"
            buttons={buttons}
          >
            {showExpiredDrugReasonInput ? (
              <>
                <Label name={"Reason for proceeding:"} />
                <Textarea
                  data-testid="expired_drug_reason"
                  name="reason for expired drug"
                  value={expiredDrugReason}
                  placeholder="Enter a valid reason"
                  id="details"
                  onChange={handleChangeExpiredDrugReason}
                />
              </>
            ) : (
              <Label name={"Are you sure you want to proceed with this action?"} />
            )}
          </CustomDialog>

          <div className="table-responsive">
            <table className="table table-bordered table-sm">
              <thead>
                <tr>

                  <td width="400" style={{ verticalAlign: "middle" }}>
                    Item Information
                    <span className="text-danger asterics ml-1">*</span>
                  </td>

                  <td width="120" style={{ verticalAlign: "middle" }}>
                    Total Stock
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Batch<span className="text-danger asterics ml-1">*</span>
                  </td>

                  <td width="150" style={{ verticalAlign: "middle" }}>
                    Ava.Qty
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Expiry
                  </td>

                  <td width="180" style={{ verticalAlign: "middle" }}>
                    Unit
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Qty<span className="text-danger asterics ml-1">*</span>
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Price
                  </td>

                  <td width='80' style={{verticalAlign:'middle'}}>Discount%</td>

                  <td width="400" style={{ verticalAlign: "middle" }}>
                    Total
                  </td>

                  <td style={{ verticalAlign: "middle" }}> Action</td>

                </tr>
              </thead>

              <tbody>
                {rows.map((item, idx) => {
                  // console.log("item", item);

                  return (
                    <>
                      <tr id="addr0" key={item.uuid_id}>
                        <td width="15%">
                          <AutoCompleteUnControlled
                            options={allDrugs}
                            submitted={submitted}
                            idx={idx}
                            handleChange={handleRetrieveDrugs}
                            label_text="label"
                            medicine_value={{
                              label: rows[idx].product_name,
                              value: rows[idx].product_id,
                            }}
                            testId={"product-" + idx}
                            id="product_name"
                            value_text="value"
                            handleProductData={handleClickDrug}
                            placeholder="Type Product Name"
                            errorMsg="Enter Product Name"
                          />
                        </td>

                        <td>
                          {item.product_name === "" ? "" : item.total_stock}
                        </td>

                        <td data-testid={"product_batch-" + idx}>
                          <SelectItem
                            options={item?.batch_info?.map(
                              ({ batch_id, purchase_detail_id }) => {
                                return { batch_id, purchase_detail_id };
                              }
                            )}
                            submitted={submitted}
                            idx={idx}
                            onChange={handleChangeBatch}
                            value={
                              item.product_name === ""
                                ? ""
                                : item.batch_id
                                  ? item.batch_id
                                  : item.batch_info.length > 0
                                    ? item.batch_info[0]
                                    : ""
                            }
                            errorMsg="Enter Batch ID"
                            label_text="batch_id"
                            value_text="batch_id"
                          />
                        </td>

                        <td>
                          <TextField
                            type="text"
                            name="available_quantity"
                            data-testid={"available_quantity-" + idx}
                            value={rows[idx]?.available_quantity}
                            disabled={true}
                            placeholder="0.00"
                          />
                        </td>

                        <td width="10%">
                          <TextField
                            type="text"
                            data-testid={"expiry_date-" + idx}
                            value={
                              rows[idx].expiry
                                ? removeTime(rows[idx].expiry)
                                : ""
                            }
                            disabled={true}
                          />
                        </td>

                        <td>
                          <TextField
                            type="text"
                            name="unit"
                            data-testid={"product_unit-" + idx}
                            value={!rows[idx].unit ? "" : rows[idx].unit}
                            onChange={(e) => handleChange(e, idx)}
                            disabled={true}
                          />
                        </td>

                        <td>
                          <TextField
                            type="number"
                            name="product_quantity"
                            data-testid={"product_quantity-" + idx}
                            value={rows[idx].product_quantity}
                            onChange={(e) => handleChangeQuantity(e, idx)}
                            submitted={submitted}
                            placeholder="0.00"
                          />

                          {submitted && !rows[idx].product_quantity ? (
                            <div className="invalid-text">
                              Enter the quantity
                            </div>
                          ) : item.isError ? (
                            <div className="invalid-text">
                              Quantity should be less than stock
                            </div>
                          ) : null}

                        </td>

                        <td>
                          <TextField
                            type="text"
                            data-testid={"product_rate-" + idx}
                            name="product_rate"
                            value={item.product_rate}
                            disabled={true}
                            placeholder="0.00"
                          />
                        </td>

                        <td>
                          <TextField
                               type="number"
                               data-testid={"discount-"+idx}
                               name="discount"
                               min="0"
                               value={rows[idx].discount}
                               onChange={(e) => handleChangeDiscount(e, idx)}
                               placeholder="0.00"
                            />
                           <Form.Control type="hidden" value={rows[idx].tax}/>
                        </td>

                        <td>
                          <TextField
                            type="text"
                            data-testid={"total_price-" + idx}
                            name="total_price"
                            value={rows[idx].total_price}
                            onChange={(e) => handleChange(e, idx)}
                            placeholder="0.00"
                            readOnly
                          />
                        </td>

                        <td>
                          <button
                            type="button"
                            data-testid={"delete-" + idx}
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => handleRemoveSpecificRow(idx)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>

                          <input
                            type="hidden"
                            value={rows[idx].total_discount}
                            className="total_discount dppr"
                            name="total_discount"
                          />
                        </td>
                      </tr>

                    </>
                  );
                })}

                <tr>
                  <td colSpan={10}>
                    <button
                      type="button"
                      onClick={handleAddRow}
                      data-testid="add-row"
                      className="btn  pharmacy-gray-btn btn-sm "
                    >
                      Add another line
                    </button>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

          <div className="text-md-right">
            <button
              data-testid="save_sale"
              type="submit"
              disabled={isPending}
              className="btn pharmacy-btn"
            >
              {isPending ? "Submitting..." : "Submit"}
            </button>
          </div>

        </form>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewSalesReceipt);
