import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import { PLContainer } from "../../../Utils/styledComponents";
import { CoaList, CustomCell } from "../../../Utils/AccountingUtils/CoaList";
import { usePrint } from "../../../Utils/Templates/usePrint";
import { BackButton } from "../../../Utils/Buttons/DataExportationButton";
import { PrintPdfButtons } from "./PrintPdfButtons";
import companyAddress from "../../../Helpers/companyAddress.json";
import Card from "@material-ui/core/Card";
import {
  reverseDate,
  formatDate,
  convertDate,
} from "../../../Utils/ConvertDate";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import { AccountingDateFilter } from "../../../Utils/AccountingUtils/AccountingDateFilter";
import { useProfitLossStatement } from "./hooks/useProfitLossStatement";
import { NoTransaction } from "../COA/ReusableJournalTable";
import { LoadingGif } from "../../../Utils/Loader";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import { history } from "../../../Helpers/history";
import { useQuery } from "react-query";
import axios from "axios";
import config from "../../../Helpers/config.json";
import { useCompany } from "../../../Context/company-context";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
  icon: {
    fill: "#605e5e",
  },
  settingsIcon: {
    width: "1.3em",
    fontSize: "2rem",
  },
  totalCell: {
    padding: "8px 8px 8px 0",
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    textTransform: "uppercase",
    fontWeight: "bold",
    borderBottom: "3px double black",
  },
  amountCell: {
    borderTop: 0,
    borderRight: 0,
    borderBottom: "3px double black",
    borderLeft: 0,
    width: "100px",
    paddingBottom: 0,
  },
});

const headData = [
  { id: "account", label: "" },
  { id: "balance", label: "DEBIT" },
  { id: "action", label: "CREDIT" },
];

const header = (
  <TableHead>
    {headData.map((item) => (
      <TableCell align="right">{item.label}</TableCell>
    ))}
  </TableHead>
);

const TrialBalance = ({ actions, snackbars, match: { params } }) => {
  const classes = useStyles();

  const { state } = useCompany();

  const { logo } = state;

  // const {
  //     date,
  //     data,
  //     closeSnackbar,
  //     handleChangeDate,
  //     isLoading,
  //     isSuccess,
  //     isError,
  //     isPending,
  //     submitted,
  //     handleSubmit } = useProfitLossStatement(actions, 'try1');

  const [dates, setDates] = useState({
    startDate: reverseDate(convertDate()),
    endDate: reverseDate(convertDate()),
  });

  const { isLoading, isError, isSuccess, data } = useQuery(
    ["trialBalanceQuery", dates],
    async () => {
      try {
        const response = await axios.get(
          `${config.epharmUrl}/accounting/new_trail_balance_report`,
          {
            headers: {},
            params: {
              start_date: dates.startDate,
              end_date: dates.endDate,
            },
          }
        );

        const data = response?.data;

        return {
          categories: data?.categories ?? {},
          totalCredits: data?.total_credits ?? 0,
          totalDebits: data?.total_debits ?? 0,
        };
      } catch (error) {
        //
      }
    },
    { retry: 1 }
  );

  const { open, variant, message } = snackbars;

  const { componentRef, handlePrint } = usePrint("TrailBalance");

  const { img_url: image } = companyAddress;

  // const current_date = new Date();

  // const { from_date, to_date } = date;

  // const dt = data ? data : {};

  // const incomeStatement = dt.data ? dt.data : [];

  const handleChangeDate = (event) => {
    setDates({ ...dates, [event.target.name]: event.target.value });
  };

  return (
    <div className="journals">
      <ReusableDashboardHeader
        component="Trial Balance"
        heading="Accounting"
        subHeading="Trial Balance"
        link={history.location.pathname}
      />

      <PharmacySnackbar
        message={message}
        open={open}
        // handleCloseBar={closeSnackbar}
        variant={variant}
      />

      <div className="d-flex justify-content-start align-items-center ml-4 mt-3">
        <BackButton to="/allreports" text="Reports" />
      </div>

      <PLContainer>
        {/* <AccountingDateFilter
                    {...{
                        start_date: from_date,
                        end_date: to_date,
                        handleChange: handleChangeDate,
                        submitted,
                        handleSubmit,
                        isLoading
                    }}
                /> */}

        {isLoading ? (
          <div className="text-center mt-5">
            <LoadingGif />
          </div>
        ) : null}

        {isSuccess ? (
          <Card className="mx-2 my-3">
            <div
              style={{
                padding: "8px 16px",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
              className="px-8 py-16 d-flex justify-content-between align-items-center"
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="form-group d-flex justify-content-center align-items-center">
                  <label className="" htmlFor="startDate">
                    From&nbsp;
                  </label>
                  <input
                    type="date"
                    name="startDate"
                    value={dates.startDate}
                    onChange={handleChangeDate}
                    className="form-control"
                    required
                    data-testid="start-date"
                    // defaultValue={reverseDate())}
                  />
                </div>

                <div className="form-group d-flex justify-content-center align-items-center">
                  <label className="ml-4" htmlFor="endDate">
                    To&nbsp;
                  </label>
                  <input
                    type="date"
                    name="endDate"
                    value={dates.endDate}
                    onChange={handleChangeDate}
                    className="form-control"
                    required
                    data-testid="end-date"
                    // defaultValue={reverseDate())}
                  />
                </div>
              </div>

              <div className="">
                <PrintPdfButtons
                  downloadPdf={() => {}}
                  handlePrint={handlePrint}
                />
              </div>
            </div>

            <div className="p-3">
              <div ref={componentRef}>
                <table className="table table-sm table-borderless prescription-table">
                  <tr>
                    <td align="center">
                      <img
                        className="img-fluid img-thumbnail border-0 w-25 h-25"
                        src={logo ?? image}
                        alt="smartmedlogo"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <h5>
                        <strong>Trial Balance</strong>
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <h6>
                        <strong>{formatDate(new Date(Date.now()))}</strong>
                      </h6>
                    </td>
                  </tr>
                </table>

                <Table>
                  <TableBody>
                    <TableRow className="mt-5 d-flex">
                      <TableCell className="w-50 d-flex justify-content-start align-items-center"></TableCell>
                      <TableCell className="w-25 d-flex justify-content-end align-items-center">
                        <strong>CREDITS</strong>
                      </TableCell>
                      <TableCell className="w-25 d-flex justify-content-end align-items-center">
                        <strong>DEBITS</strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Table className="">
                  <TableBody className="">
                    {data?.categories &&
                    Object.entries(data?.categories).length > 0 ? (
                      Object.entries(data?.categories).map(
                        ([key, values], idx) => (
                          <>
                            <Table className="" key={idx}>
                              <TableHead
                                className="d-flex justify-content-start ml-2 mt-3"
                                data-toggle={`category-${key}`}
                              >
                                <TableRow>
                                  <strong>{key}</strong>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                <Table id={`category-${key}`}>
                                  {values?.account_details &&
                                  values?.account_details.length > 0 ? (
                                    values?.account_details.map(
                                      (accountDetail, index) => (
                                        <TableRow
                                          className="d-flex"
                                          key={index}
                                        >
                                          <TableCell
                                            className="w-50 d-flex justify-content-start"
                                            // data-testid={`account_name`}
                                            data-testid={accountDetail.account}
                                          >
                                            {accountDetail?.account}
                                          </TableCell>
                                          <TableCell
                                            className="w-25 d-flex justify-content-end align-items-center"
                                            data-testid={`${accountDetail.account}_credit`}
                                          >
                                            {accountDetail?.credit}
                                          </TableCell>
                                          <TableCell
                                            className="w-25 d-flex justify-content-end align-items-center"
                                            data-testid={`${accountDetail.account}_debit`}
                                          >
                                            {accountDetail?.debit}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )
                                  ) : (
                                    <TableRow className="d-flex">
                                      <TableCell className="w-100 d-flex justify-content-end align-items-center">
                                        No Data Available
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Table>
                              </TableBody>
                            </Table>
                          </>
                        )
                      )
                    ) : (
                      <TableRow>
                        <CustomCell align="center">
                          <strong>No Data available</strong>
                        </CustomCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                <Table>
                  <TableBody>
                    <TableRow className="mt-5 d-flex">
                      <TableCell className="w-50 d-flex justify-content-start align-items-center">
                        <strong>Total</strong>
                      </TableCell>
                      <TableCell className="w-25 d-flex justify-content-end align-items-center">
                        {data?.totalCredits ?? 0}
                      </TableCell>
                      <TableCell className="w-25 d-flex justify-content-end align-items-center">
                        {data?.totalDebits ?? 0}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {/* <CoaList  {...{ data: [], reports: true, trialBalance: true, header }}>
                                    <TableRow>
                                        <TableCell className={classes.totalCell}><strong>Total</strong></TableCell>
                                        <TableCell className={classes.amountCell} align='right'>{data?.totalCredits ?? 0}</TableCell>
                                        <TableCell className={classes.amountCell} align='right'>{data?.totalDebits ?? 0}</TableCell>
                                    </TableRow>
                                </CoaList> */}
              </div>
            </div>
          </Card>
        ) : (
          <NoTransaction text="transactions" />
        )}

        {isError ? <div>The server did not return a valid response</div> : null}
      </PLContainer>

      {/* </Container> */}
    </div>
  );
};

function mapStateToProps(state) {
  const { snackbars } = state;
  return { snackbars };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: { snackbarActions: bindActionCreators(snackbarActions, dispatch) },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialBalance);
