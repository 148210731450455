import { useEffect, useState } from "react";
import { useDates } from "../../../Components/Accounting/Expenses/hooks/useDates";
import { convDate, dateConvert } from "../../../Utils/ConvertDate";
import axios from "axios";
import moment from "moment";
import config from "../../../Helpers/config.json";
import { history } from "../../../Helpers/history";
import { errorMessages } from "../../../Helpers/ErrorMessages";

const getCurrentYears = () => {
  const N = new Date().getFullYear() - 2020 + 6;
  const arr = Array.from(Array(N).keys());
  const year_arr = arr.map((x) => {
    return { value: 2020 + x, text: 2020 + x };
  });
  return year_arr;
};

export const useDashboardContent = (actions) => {
  const years = getCurrentYears();
  const [start, setStart] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [statistics, setStatistics] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [remainingDays, setRemainingDays] = useState("");
  const [expired_drugs, setExpiredDrugs] = useState([]);
  const [out_of_stock, setOutOfStock] = useState([]);
  const [date, handleChangeDate, setDate] = useDates();
  const [sales_analytics, setSalesAnalytics] = useState([]);
  const [period, setPeriod] = useState("yearly");
  const [year, setYear] = useState(new Date().getFullYear());
  const { from_date, to_date } = date;
  const [loading, setLoading] = useState([]);
  const [progressReport, setProgressReport] = useState([]);
  const [debtors, setDebtors] = useState([]);
  const [creditors, setCreditors] = useState([]);
  const [cashPayments, setCashPayments] = useState([]);
  const [totals, setTotals] = useState({ total_purchases: 0, total_sales: 0 });
  const [filter_type, setFilterType] = useState("today");
  const [custom_filter, setCustomFilter] = useState("");
  const [invoices_data, setInvoicesData] = useState({
    total_receipts: 0,
    total_invoices: 0,
    total_cancelled: 0,
  });
  const [reportProps, setReportProps] = useState({
    salesAmount: 0,
    purchaseAmount: 0,
    total_cash_sales: 0,
    total_credit_sales: 0,
    total_paid_purchases: 0,
    total_credit_purchase: 0,
  });

  const inputType = {
    weekly: "week",
    monthly: "month",
    custom: "date",
  };
  const isVisited = JSON.parse(sessionStorage.getItem("isVisited"));

  // Top Selling Products
  const [topSellingProducts, setSellState] = useState([]);

  const calculateTotal = (arr) => {
    const total_sales = arr.reduce((a, sale) => {
      return Number(a + sale.total_sales);
    }, 0);
    const total_purchases = arr.reduce((a, purchase) => {
      return Number(a + purchase.total_purchases);
    }, 0);
    setTotals({ total_purchases, total_sales });
  };

  // for the filtering of the progress report
  useEffect(() => {
    if (period === "monthly" && from_date === "" && to_date === "") {
      return;
    }

    var s_date = "";
    if (period === "yearly") {
      s_date = year;
    } else if (period === "monthly") {
      let today = new Date(from_date);
      s_date =
        today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2);
    } else {
      s_date = from_date;
    }
    const pd =
      period === "yearly"
        ? "2"
        : period === "monthly"
          ? "1"
          : period === "custom"
            ? "3"
            : 0;
    const pars = {
      start_date: s_date,
      end_date: pd === "2" ? "" : to_date,
      option: pd,
    };
    if (
      (period === "custom" && from_date && to_date) ||
      (period !== "custom" && (from_date || year))
    ) {
      axios
        .post(`${config.epharmUrl}/admin/progress_report`, null, {
          params: pars,
        })
        .then((response) => {
          const data = response.data;
          const dt = data ?? [];
          calculateTotal(dt);
          setProgressReport(dt);
        })
        .catch((error) => {
          errorMessages(error, null, actions);
        });
    }
  }, [from_date, to_date, year]);

  // for the table for cash payments
  const retrieveCashPayments = (
    date_filter,
    overall_start_date,
    overall_end_date
  ) => {
    const isCustom =
      date_filter === "custom"
        ? { start_date: overall_start_date, end_date: overall_end_date }
        : {};

    setLoading(true);
    /*const formData = new FormData();
        formData.append('search',''); */

    axios
      .post(`${config.epharmUrl}/admin/cash_payments/1`, null, {
        params: { search: "", time_filter: date_filter, ...isCustom },
      })
      .then((response) => {
        const data = response.data;
        const dt = data ?? {};
        const cash = dt.cash_list ?? [];
        const cashArr = cash.map((item, index) => ({
          id: index,
          date_paid: item.date_paid,
          receipt_number: item.receipt_number,
          customer_name: item.customer_name,
          amount_paid: item.amount_paid,
        }));
        setCashPayments(cashArr);
        setLoading(false);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setLoading(false);
      });
  };

  // for debt customers i.e. debtors?
  const retrieveDebtors = (
    date_filter,
    overall_start_date,
    overall_end_date
  ) => {
    const isCustom =
      date_filter === "custom"
        ? { start_date: overall_start_date, end_date: overall_end_date }
        : {};

    sessionStorage.setItem("isVisited", JSON.stringify("visited"));

    setLoading(true);
    axios
      .get(`${config.epharmUrl}/Cinvoice/debtors`, {
        params: {
          page_number: 1,
          search: "",
          time_filter: date_filter,
          ...isCustom,
        },
      })
      .then((response) => {
        const data = response.data;
        const dt = data.data ?? {};
        const debtData = dt.debtors ?? [];
        const debtArr = debtData.map((item) => ({
          id: item.customer_id,
          date: item.date,
          invoice_no: item.invoice_no,
          name: item["Customer Name"],
          amount: item["Total Debt"],
        }));
        setDebtors(debtArr);
        setLoading(false);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setLoading(false);
      });
  };

  // for creditors
  const retrieveCreditors = (
    date_filter,
    overall_start_date,
    overall_end_date
  ) => {
    const isCustom =
      date_filter === "custom"
        ? { start_date: overall_start_date, end_date: overall_end_date }
        : {};
    axios
      .get(`${config.epharmUrl}/Cmanufacturer/supplier_payables`, {
        params: {
          page_number: 1,
          search: "",
          time_filter: date_filter,
          ...isCustom,
        },
      })
      .then((response) => {
        const dat = response.data;
        const dt = dat.data ?? {};
        setCreditors(dt?.filtered_count);
        setLoading(false);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setLoading(false);
      });
  };

  // get expired and out of stock medicine
  const retrieveExpiredOutofStock = () => {
    (async () => {
      try {
        const getExpiredDrugs = axios.get(
          `${config.epharmUrl}/User/expired_products`
        );
        const getOutOfStock = axios.get(
          `${config.epharmUrl}/User/out_of_stock`
        );
        const [expiredResponse, outOfStockResponse] = await Promise.all([
          getExpiredDrugs,
          getOutOfStock,
        ]);
        const expired = expiredResponse.data;
        const dt = !expired ? {} : expired;
        const exp = dt.expired_products ? dt.expired_products : [];
        const stock = outOfStockResponse.data ? outOfStockResponse.data : [];
        setExpiredDrugs(exp);
        setOutOfStock(stock);
        if (isVisited !== "visited") {
          setOpenDialog(true);
        }
      } catch (error) {
        errorMessages(error, null, actions);
      }
    })();
  };

  // api call to get the total customers, medicine, invoice and suppliers
  const retrieveAdminCall = () => {
    axios
      .get(config.epharmUrl + "/admin/")
      .then((response) => {
        const data = response.data;
        const stat = data ?? {};
        setStatistics(stat);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  };

  // api call to get the total customers, medicine, invoice and suppliers
  const retrieveReportCall = (
    date_filter,
    overall_start_date,
    overall_end_date
  ) => {
    const isCustom =
      date_filter === "custom"
        ? { start_date: overall_start_date, end_date: overall_end_date }
        : {};
    axios
      .get(config.epharmUrl + "/admin/todays_sales_report", {
        params: { time_filter: date_filter, ...isCustom },
      })
      .then((response) => {
        const data = response.data;
        const stat = data ?? {};
        const total_purchase_amount = stat.total_purchase_amount
          ? stat.total_purchase_amount
          : 0;
        const total_paid_purchases = stat.total_paid_purchases
          ? stat.total_paid_purchases
          : 0;
        const report = {
          salesAmount: stat.total_sales ? stat.total_sales : 0,
          purchaseAmount: total_purchase_amount,
          total_cash_sales: stat.cash_sales ? stat.cash_sales : 0,
          total_credit_sales: stat.credit_sales ? stat.credit_sales : 0,
          total_paid_purchases: stat.total_paid_purchases
            ? stat.total_paid_purchases
            : 0,
          total_credit_purchase: total_purchase_amount - total_paid_purchases,
        };
        setReportProps(report);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  };

  // get the remaining days of the license for RX
  const retrieveRemainingDays = () => {
    axios
      .get(`${config.epharmUrl}/admin/remaining_days`)
      .then((response) => {
        const data = response.data;
        const dt = data ?? {};
        const days = dt.days ?? 0;
        if (days <= 30) {
          setRemainingDays(days);
          setOpenAlert(history.length <= 4 && true);
        }
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  };

  // api call to get the sales analytics data
  const retrieveSalesAnalytics = () => {
    axios
      .get(config.epharmUrl + "/admin/facility_performance")
      .then((response) => {
        const data = response.data;
        const stat = data ?? {};
        setSalesAnalytics(stat);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  };

  const retrieveTopSellingProducts = (
    date_filter,
    overall_start_date,
    overall_end_date
  ) => {
    const paramsObj = {
      date_filter: date_filter,
      start_date: overall_start_date,
      end_date: overall_end_date,
    };

    axios
      .get(`${config.epharmUrl}/Creport/top_selling_products`, {
        params: paramsObj,
      })
      .then((response) => {
        const data = response.data;
        const dt = data ?? {};
        const prdts = dt.map((item, index) => ({
          id: index,
          product_name: item.product_name,
          total_quantity_sold: item.total_quantity,
          total_revenue_generated: item.total_revenue,
        }));
        setSellState(prdts);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  };

  // api call to get the receipts data
  const retrieveInvoiceCalls = (
    date_filter,
    overall_start_date,
    overall_end_date
  ) => {
    const paramsObj = {
      time_filter: date_filter,
      start_date: overall_start_date,
      end_date: overall_end_date,
    };

    axios
      .get(config.epharmUrl + "/Cinvoice/range_invoice_totals", {
        params: paramsObj,
      })
      .then((response) => {
        const data = response.data;
        const stat = data.data ?? {};
        const inv_dt = {
          total_cancelled: stat.cancelled ?? 0,
          total_invoices: stat.invoices ?? 0,
          total_receipts: stat.receipts ?? 0,
        };
        setInvoicesData(inv_dt);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  };

  useEffect(() => {
    retrieveCashPayments("today", date.from_date, date.to_date);
    retrieveDebtors("today", date.from_date, date.to_date);
    retrieveCreditors("today", date.from_date, date.to_date);
    retrieveExpiredOutofStock();
    retrieveAdminCall();
    retrieveReportCall("today", date.from_date, date.to_date);
    retrieveRemainingDays();
    retrieveSalesAnalytics("today", date.from_date, date.to_date);
    retrieveTopSellingProducts("today", date.from_date, date.to_date);
    retrieveInvoiceCalls("today", date.from_date, date.to_date);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFilterType("today");
      retrieveCashPayments("today", date.from_date, date.to_date);
      retrieveDebtors("today", date.from_date, date.to_date);
      retrieveCreditors("today", date.from_date, date.to_date);
      retrieveExpiredOutofStock();
      retrieveAdminCall();
      retrieveReportCall("today", date.from_date, date.to_date);
      retrieveRemainingDays();
      retrieveSalesAnalytics("today", date.from_date, date.to_date);
      retrieveTopSellingProducts("today", date.from_date, date.to_date);
      retrieveInvoiceCalls("today", date.from_date, date.to_date);
    }, 800000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setInterval(() => {
      if (start < statistics.total_customer) {
        setStart(start + 1);
      }
    }, 1000);
  }, [start, statistics]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePeriod = (event) => {
    const { value } = event.target;
    setPeriod(value);
    setDate({ from_date: "", to_date: "" });
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const handleCloseBar = () => {
    actions.snackbarActions.hideSnackBar();
  };

  const stat = Object.keys(statistics).length;

  // First Row
  // Small Cards
  const customers = !statistics.total_customer ? 0 : statistics.total_customer;
  const manufacturers = !statistics.total_manufacturers
    ? 0
    : statistics.total_manufacturers;
  const products = !statistics.total_product ? 0 : statistics.total_product;

  // Second Row
  // Small Cards
  const expiredDrugs = !expired_drugs ? [] : expired_drugs;
  const outOfStockDrugs = !out_of_stock ? [] : out_of_stock;

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
    setCustomFilter("");
    const filter_change = event.target.value;

    // set dates
    let overall_start_date = "";
    let overall_end_date = "";
    if (filter_change === "today") {
      overall_start_date = overall_end_date = dateConvert();
    }
    if (filter_change === "this_week") {
      var startOfWeek = moment().startOf("isoWeek").toDate();
      var endOfWeek = moment().endOf("isoWeek").toDate();

      overall_start_date = convDate(startOfWeek);
      overall_end_date = convDate(endOfWeek);
    }
    if (filter_change === "this_month") {
      var date = new Date();
      var monthStartDay = convDate(
        new Date(date.getFullYear(), date.getMonth(), 1)
      );
      var monthEndDay = convDate(
        new Date(date.getFullYear(), date.getMonth() + 1, 0)
      );

      overall_start_date = convDate(monthStartDay);
      overall_end_date = convDate(monthEndDay);
    }

    if (filter_change !== "custom_day" && filter_change !== "custom_month") {
      retrieveTopSellingProducts(
        filter_change,
        overall_start_date,
        overall_end_date
      );
      retrieveDebtors(filter_change, overall_start_date, overall_end_date);
      retrieveCreditors(filter_change, overall_start_date, overall_end_date);
      retrieveReportCall(filter_change, overall_start_date, overall_end_date);
      retrieveCashPayments(filter_change, overall_start_date, overall_end_date);
      retrieveInvoiceCalls(filter_change, overall_start_date, overall_end_date);
    }
  };

  const handleCustomDateChange = (event) => {
    var overall_end_date = "";
    var overall_start_date = "";
    var val = "";

    if (filter_type === "custom_month") {
      let today = new Date(event.value);
      val =
        today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2);
      setCustomFilter(val);
    }

    if (filter_type === "custom_day") {
      val = event.target.value;
      setCustomFilter(val);
    }

    if (val) {
      // then filter
      if (filter_type === "custom_month") {
        var arr = val.split("-");
        overall_start_date = convDate(new Date(+arr[0], arr[1] - 1, 1));
        overall_end_date = convDate(new Date(+arr[0], +arr[1], 0));
      }

      if (filter_type === "custom_day") {
        overall_start_date = val;
        overall_end_date = val;
      }

      retrieveTopSellingProducts(
        "custom",
        overall_start_date,
        overall_end_date
      );
      retrieveDebtors("custom", overall_start_date, overall_end_date);
      retrieveCreditors("custom", overall_start_date, overall_end_date);
      retrieveReportCall("custom", overall_start_date, overall_end_date);
      retrieveCashPayments("custom", overall_start_date, overall_end_date);
      retrieveInvoiceCalls("custom", overall_start_date, overall_end_date);
    }
  };

  return {
    openDialog,
    openAlert,
    handleChangeDate,
    period,
    year,
    from_date,
    topSellingProducts,
    remainingDays,
    handleCustomDateChange,
    to_date,
    inputType,
    handleOpenDialog,
    handleCloseDialog,
    handleChangePeriod,
    debtors,
    loading,
    sales_analytics,
    custom_filter,
    handleChangeYear,
    handleCloseBar,
    expiredDrugs,
    outOfStockDrugs,
    customers,
    manufacturers,
    handleFilterChange,
    products,
    invoices_data,
    reportProps,
    stat,
    progressReport,
    cashPayments,
    totals,
    years,
    filter_type,
    creditors,
  };
};
