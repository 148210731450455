import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../Redux/Actions/UserActions/UserActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import TextField from "../../Utils/FormInputs/TextField";
import Card from "@material-ui/core/Card";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import { history } from "../../Helpers/history";
import { useAddRole } from "./CustomHooks/useAddRole";
import {
  CollapsibleList,
  DataCell,
  ModuleTable,
  HeadCell,
} from "../../Utils/List/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import { BlockNavigationComponent } from "../../Utils/ReusableComponents/BlockNavigationComponent";

const headData = [
  { item: "Module Name", align: "left" },
  { item: "Create", align: "left" },
  { item: "Read", align: "left" },
  { item: "Update", align: "left" },
  { item: "Delete", align: "left" },
];

const AddRole = ({ actions, snackbars, match: { params } }) => {
  const { id, role_name } = params;
  const { open, message, variant } = snackbars;
  const {
    role_id,
    roles,
    submitted,
    isSubmitted,
    handleCheckRow,
    handleOpen,
    handleChange,
    handleChangeCheckAll,
    handleChangeCheck,
    submitRole,
    handleCloseBar,
    isBlocking,
  } = useAddRole(actions, { id, role_name });
  return (
    <div className="journals">
      <ReusableDashboardHeader
        component="Add Role"
        dashboard="Add Role"
        heading="Settings"
        link={history.location.pathname}
        subHeading="Add Role"
      />
      <div className="general-ledger-header">
        <div className="row">
          <div className="col-lg-6">
            <h6 style={{ marginTop: "0.5rem" }}>Add Role</h6>
          </div>
          <div className="col-lg-6">
            <div className="text-right">
              <Link
                to="/rolelist"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <button
                  type="button"
                  className="btn mr-2 pharmacy-info-btn btn-sm"
                >
                  <FontAwesomeIcon icon={faList} /> Roles
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <BlockNavigationComponent
        when={isBlocking}
        shouldBlockNavigation={() => {
          return isBlocking;
        }}
        navigate={(path) => history.push(path)}
      />
      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={handleCloseBar}
      />
      <form onSubmit={submitRole} autoComplete="off">
        <Card className="p-3 m-4">
          <div className="mb-4">
            <Form.Group as={Row}>
              <Form.Label column sm="1" htmlFor="roleName">
                Role Name<span className="ml-1 asterics text-danger">*</span>
              </Form.Label>
              <Col sm="3">
                <TextField
                  submitted={submitted}
                  type="text"
                  placeholder="Role Name"
                  name="role_id"
                  id="roleName"
                  value={role_id}
                  onChange={handleChange}
                />
                {submitted && !role_id && (
                  <div className="invalid-text">Please enter the role name</div>
                )}
              </Col>
            </Form.Group>
          </div>
          {roles.map((item, index) => {
            console.log('roles',item)
            const contents = item.content ? item.content : [];
            return (
              <div key={index}>
                <CollapsibleList
                  key={index}
                  open={item.open}
                  name={item.title}
                  handleOpen={() => handleOpen(index)}
                >
                  <ModuleTable
                    headData={headData}
                    extraCell={
                      <HeadCell align="center">
                        <Form.Check
                          type="checkbox"
                          checked={item.check}
                          name="check"
                          onChange={(e) => handleChangeCheckAll(e, index)}
                          style={{ transform: "scale(1.2)" }}
                          id={`select-${item.module_name}`}
                        />
                      </HeadCell>
                    }
                  >
                    {contents.map((det, ind) => {
                      return (
                        <TableRow key={ind}>
                          <DataCell align="center">
                            <Form.Check
                              type="checkbox"
                              checked={det.checkedRow}
                              name="check"
                              onChange={(e) => handleCheckRow(e, index, ind)}
                              style={{ transform: "scale(1.2)" }}
                              id={`select-${det.sub_module_name}`}
                            />
                          </DataCell>
                          <DataCell>{det.sub_module_name}</DataCell>
                          <DataCell>
                            <input
                              type="checkbox"
                              name="create"
                              style={{ transform: "scale(1.3)" }}
                              checked={+det.create}
                              onChange={(e) => handleChangeCheck(e, index, ind)}
                            />
                          </DataCell>
                          <DataCell>
                            <input
                              type="checkbox"
                              style={{ transform: "scale(1.3)" }}
                              name="read"
                              checked={+det.read}
                              onChange={(e) => handleChangeCheck(e, index, ind)}
                            />
                          </DataCell>
                          <DataCell>
                            <input
                              type="checkbox"
                              style={{ transform: "scale(1.3)" }}
                              checked={+det.update}
                              name="update"
                              onChange={(e) => handleChangeCheck(e, index, ind)}
                            />
                          </DataCell>
                          <DataCell>
                            <input
                              type="checkbox"
                              style={{ transform: "scale(1.3)" }}
                              checked={+det.delete}
                              name="delete"
                              onChange={(e) => handleChangeCheck(e, index, ind)}
                            />
                          </DataCell>
                        </TableRow>
                      );
                    })}
                  </ModuleTable>
                </CollapsibleList>
              </div>
            );
          })}
        </Card>

        <div className="text-center">
          <button
            type="submit"
            disabled={isSubmitted === "pending"}
            className="btn pharmacy-btn px-5"
          >
            {isSubmitted === "pending" ? "Saving...." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
    users: state.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      userActions: bindActionCreators(userActions, dispatch),
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRole);
