import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import ReusableList from "./ReusableList";
import { NoTransaction } from "./ReusableJournalTable";
import Typography from "@material-ui/core/Typography";
import TextField from "../../../Utils/FormInputs/TextField";
import { titleCase } from "../../../Utils/titleCaseFunction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../../Utils/Snackbars/SnackbarUtil";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import { LoadingGif } from "../../../Utils/Loader";
import LedgerEntry from "./LedgerEntry";
import axios from "axios";
import config from "../../../Helpers/config.json";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { JournalList } from "./JournalList";

const useStyles = makeStyles(() => ({
  item: {
    borderRight: "1px solid #c7c6c7",
    borderLeft: "1px solid #c7c6c7",
    height: "100vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c7c6c7",
      "-webkit-border-radius": "10px",
      borderRadius: "10px",
    },
  },
  rounded: {
    borderRadius: 0,
  },
  body1: {
    fontSize: "0.98em",
  },
  body2: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.85em",
  },
}));
const Journals = ({ actions, snackbars, match: { params } }) => {
  const current_date = new Date();
  const classes = useStyles();

  const [allJournals, setAllJournals] = useState([]);
  const [search, setSearch] = useState("");
  const [loadingJournals, setLoadingJournals] = useState("idle");
  const [specificItem, setItem] = useState({
    id: "",
    head_name: "",
    head_code: "",
  });
  const [isSubmitLedger, setIsSubmitLedger] = React.useState("idle");

  const { id, head_name, head_code } = specificItem;

  const isResolved = isSubmitLedger === "resolved";

  useEffect(() => {
    setLoadingJournals("loading");
    axios
      .get(`${config.epharmUrl}/accounting/chart/journals`)
      .then((res) => {
        const data = res.data;
        const dt = data ? data : {};
        const arr = dt.data ? dt.data : [];
        const dat = arr.map((item, index) => {
          if (index === 0) {
            return { ...item, isActive: "active" };
          }
          return item;
        });
        const item = arr[0] ? arr[0] : {};
        setAllJournals(dat);
        setItem(item);
        setLoadingJournals("success");
      })
      .catch((err) => {
        errorMessages(err, null, actions);
        setLoadingJournals("error");
      });
  }, [isResolved]);

  const handleClickJournal = (item) => {
    const { id, head_name, head_code } = item;
    const dat = allJournals.map((item) => {
      if (item.id === id) {
        return { ...item, isActive: "active" };
      }
      return { ...item, isActive: "inactive" };
    });
    setAllJournals(dat);
    setItem(item);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const filteredList = (arr) => {
    return arr.filter((data) => {
      if (search === "") return data;
      else if (titleCase(data.head_name).includes(titleCase(search))) {
        return data;
      }
    });
  };

  const isLoadingJournals = loadingJournals === "loading";
  const isSuccessJournals = loadingJournals === "success";
  const isErrorJournals = loadingJournals === "error";

  const closeSnackbar = () => {
    actions.snackbarActions.hideSnackBar();
  };

  const { open: openBar, variant, message } = snackbars;

  return (
    <div className="journals">
      <ReusableDashboardHeader
        component="Journals"
        heading="Accounting"
        subHeading="Journals"
        link="/accounting/journals"
      />
      <PharmacySnackbar
        message={message}
        open={openBar}
        handleCloseBar={closeSnackbar}
        variant={variant}
      />

      <Card elevation={0} classes={{ root: classes.rounded }}>
        <Grid container spacing={0}>
          <Grid item xs={6} md={4} classes={{ item: classes.item }}>
            <div className="top-bar all-journals">
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    data-testid="search-journal-button"
                    type="text"
                    value={titleCase(search)}
                    name="search"
                    onChange={handleSearch}
                    placeholder="Search journal"
                  />
                </Grid>
                <Grid item xs={12} md={6}></Grid>
              </Grid>
            </div>
            {isLoadingJournals ? (
              <div className="text-center mt-5">
                <LoadingGif />
              </div>
            ) : null}
            {isSuccessJournals ? (
              allJournals.length > 0 ? (
                filteredList(allJournals).map((item, idx) => {
                  const isActive = item.isActive === "active";
                  return (
                    <ReusableList
                      key={item.id}
                      primaryText={item.head_name}
                      data-testid={"journal-" + idx}
                      id={"journal-" + idx}
                      isActive={isActive}
                      secondaryText={item.head_code}
                      onClick={() => handleClickJournal(item)}
                      // journals={allJournals}
                    />
                  );
                })
              ) : (
                <NoTransaction text="journals" />
              )
            ) : null}
            {isErrorJournals ? (
              <div className="text-center mt-5">
                The server did not return a valid response
              </div>
            ) : null}
          </Grid>
          <Grid item xs={6} md={8}>
            <div className="top-bar">
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography
                    data-testid="journal-title"
                    variant="body1"
                    classes={{ body1: classes.body1 }}
                  >
                    {head_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    classes={{ body2: classes.body2 }}
                  >
                    {head_code}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                  <LedgerEntry
                    {...{ actions, isSubmitLedger, setIsSubmitLedger }}
                  />
                </Grid>
              </Grid>
            </div>
            <JournalList
              isResolved={isResolved}
              item={specificItem}
              actions={actions}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
function mapStateToProps(state) {
  const { snackbars } = state;
  return { snackbars };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: { snackbarActions: bindActionCreators(snackbarActions, dispatch) },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Journals);
