import React, { useEffect, useState } from "react";
import ReusableDashboardHeader from "../../../Containers/Dasboard/ReusableDashboardHeader";
import { history } from "../../../Helpers/history";
import { Col, Form, Row } from "react-bootstrap";
import Label from "../../../Utils/FormInputs/Label";
import TextField from "../../../Utils/FormInputs/TextField";
import {
  AutoCompleteControlled,
  AutoCompleteUnControlled,
  SelectItem,
} from "../../../Utils/FormInputs/AutoCompleteInput";
import { removeTime } from "../../../Utils/titleCaseFunction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import SelectInput from "../../../Utils/FormInputs/SelectInput";
import useDebitNote from "../Reports/hooks/useDebitNote";
import { bindActionCreators } from "redux";
import * as snackbarActions from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { connect } from "react-redux";

const DebitNote = ({ actions, snackbars }) => {
  const {
    supplier,
    suppliers,
    debitNoteDate,
    selectedPreviousInvoiceNumber,
    debitNoteDetails,
    rows,
    snackbarOpen,
    setSnackbarOpen,
    handleSaveDebitNote,
    previousInvoiceNumbers,
    handleClickSupplier,
    handleDebitNoteDateChange,
    handleDebitMemoDetailsChange,
    handleDebitMemoNumber,
    debitMemoNumber,
    showSupplier,
    handleChangeSupplier,
    handlePreviousInvoiceNumber,
    totalAmount,
    drugs,
    handleRetrieveDrugs,
    handleClickDrug,
    handleChangeBatch,
    handleAddRow,
    handleRemoveSpecificRow,
    handleChange,
    handleChangeQuantity,
    invoiceNumberDetails,
    grandTotal
  } = useDebitNote(actions);

  console.log(rows)

  const options =
    suppliers?.map((manufacturer) => ({
      supplier_name: manufacturer.manufacturer_name,
      supplier_id: manufacturer.manufacturer_id,
    })) ?? [];

  // const invoiceNos =
  //   previousInvoiceNumbers?.map((item) => item.invoice_no) ?? [];

  const invoiceOptions =
    previousInvoiceNumbers?.map((invoiceNo) => ({
      value: invoiceNo?.id,
      text: invoiceNo?.invoice_no,
    })) ?? [];

  return (
    <div data-testid="debit-note-screen">
      <ReusableDashboardHeader
        component="Debit Note"
        heading="Debit"
        subHeading="Debit Note"
        link={history.location.pathname}
      />

      <div className="receipt-container">
        <form
          onSubmit={handleSaveDebitNote}
          autoComplete="off"
          data-testid="debit-note-form"
        >
          <div className="row">
            <div className="col-lg-6">
              <Form.Group>
                <Col sm="4">
                  <Label name="Supplier:" />
                </Col>

                <Col sm="8">
                  <AutoCompleteControlled
                    options={options}
                    // submitted={submitted}
                    data-testid="supplier"
                    handleChange={handleChangeSupplier}
                    errorMsg="Please select a supplier"
                    medicine_value={supplier}
                    handleProductData={handleClickSupplier}
                    value_text="supplier_id"
                    label_text="supplier_name"
                    required={true}
                  />

                  {/* {submitted && !invoice_date ? (
                    <ErrorMessage>Invoice date is required</ErrorMessage>
                  ) : null}

                  {validate_invoice_date === false ? (
                    <ErrorMessage>
                      Please select a valid date, you selected a date in the
                      future
                    </ErrorMessage>
                  ) : null} */}
                </Col>
              </Form.Group>

              <Form.Group>
                <Col>
                  <Label name="Debit Memo No:" />
                </Col>

                <Col sm="8">
                  <TextField
                    type="text"
                    value={debitMemoNumber}
                    data-testid='debitMemoNo'
                    name="debit-memo-no"
                    onChange={handleDebitMemoNumber}
                    required={true}
                    invalidInputMessage={"Invalid or missing debit memo number"}
                  />
                </Col>
              </Form.Group>

              <Form.Group>
                <Col sm="4">
                  <Label name="Previous Invoice Nos:" />
                </Col>

                <Col sm="8">
                  <SelectInput
                    data-testid="previous_invoice"
                    value={selectedPreviousInvoiceNumber}
                    onChange={handlePreviousInvoiceNumber}
                    options={invoiceOptions}
                    defaultOption="Select invoice number..."
                    // submitted={submitted}
                    required={true}
                  />
                </Col>
              </Form.Group>
            </div>

            <div className="col-lg-6">
              <Form.Group>
                <Col sm="4">
                  <Label name="Debit Memo Date:" />
                </Col>

                <Col sm="8">
                  <TextField
                    type="date"
                    name="debit-memo-date"
                    data-testid = 'debit-memo-date'
                    value={debitNoteDate}
                    onChange={handleDebitNoteDateChange}
                    required={true}
                    invalidInputMessage={"Invalid or missing date"}
                  />
                </Col>
              </Form.Group>

              <Form.Group>
                <Col sm="4">
                  <Label name="Debit Memo Details:" />
                </Col>

                <Col sm="8">
                  <TextField
                    as="textarea"
                    value={debitNoteDetails}
                    name="debit_note_details"
                    data-testid ='debit_note_details'
                    onChange={handleDebitMemoDetailsChange}
                    required={true}
                    invalidInputMessage={"Invalid or missing details"}
                  />
                </Col>
              </Form.Group>

              <Form.Group>
                <Col sm="4">
                  <Label name="Total Amount" />
                </Col>

                <Col sm="8">
                  <TextField
                    type="number"
                    data-testid="total_amount"
                    name="n_total"
                    placeholder="0.00"
                    value={grandTotal}
                    readOnly
                  />
                </Col>
              </Form.Group>
            </div>
          </div>

          <div className="tabel-responsive">
            <table className="table table-bordered table-sm">
              <thead>
                <tr>
                  <td width="400" style={{ verticalAlign: "middle" }}>
                    Item Information
                    <span className="text-danger asterics ml-1">*</span>
                  </td>

                  <td width="120" style={{ verticalAlign: "middle" }}>
                    Total Stock
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Batch<span className="text-danger asterics ml-1">*</span>
                  </td>

                  <td width="150" style={{ verticalAlign: "middle" }}>
                    Ava.Qty
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Expiry
                  </td>

                  <td width="180" style={{ verticalAlign: "middle" }}>
                    Unit
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Qty<span className="text-danger asterics ml-1">*</span>
                  </td>

                  <td width="200" style={{ verticalAlign: "middle" }}>
                    Price
                  </td>

                  {/*<td width='80' style={{verticalAlign:'middle'}}>Discount%</td>*/}

                  <td width="400" style={{ verticalAlign: "middle" }}>
                    Total
                  </td>

                  <td style={{ verticalAlign: "middle" }}> Action</td>
                </tr>
              </thead>

              <tbody>
                {
                rows?.map((item, idx) => {
                  return (
                    <tr key={item?.uuid_id}>
                      <td width="15%">
                        <AutoCompleteUnControlled
                          options={drugs}
                          // submitted={submitted}
                          idx={idx}
                          handleChange={handleRetrieveDrugs}
                          label_text="label"
                          medicine_value={{
                            label:rows[idx]?.product_name,
                            value:rows[idx]?.product_id,
                          }}
                          data-testid={"product-" + idx}
                          id="product_name"
                          value_text="value"
                          handleProductData={handleClickDrug}
                          placeholder="Type Product Name"
                          errorMsg="Enter Product Name"
                        />
                      </td>

                      <td>
                        
                        <TextField
                          type="text"
                          name="total_stock"
                          data-testid={"total_stock-" + idx}
                          value={item.product_name === "" ? "" : item?.total_stock}
                          disabled={true}
                          placeholder="0"
                        />
                      </td>

                      <td >
                      <TextField
                          // options={item?.batch_info?.map(
                          //   ({ batch_id, purchase_detail_id }) => {
                          //     return { batch_id, purchase_detail_id };
                          //   },
                          // )}
                          // submitted={submitted}
                          idx={idx}
                          data-testid={"product_batch-" + idx}
                          // onChange={handleChangeBatch}
                          value={item.batch_id ? item?.batch_id:""}
                          errorMsg="Enter Batch ID"
                          label_text="batch_id"
                          value_text="purchase_detail_id"
                          disabled={true}
                        />
                      </td>

                      <td>
                        <TextField
                          type="text"
                          name="available_quantity"
                          data-testid={"available_quantity-" + idx}
                          value={rows[idx]?.aval_stock}
                          disabled={true}
                          placeholder="0"
                        />
                      </td>

                      <td width="10%">
                        <TextField
                          type="text"
                          data-testid={"expiry_date-" + idx}
                          value={rows[idx]?.expiry_date
                              ? rows[idx]?.expiry_date
                              : ""
                          }
                          disabled={true}
                        />
                      </td>

                      <td>
                        <TextField
                          type="text"
                          name="unit"
                          data-testid={"product_unit-" + idx}
                          value={!rows[idx]?.unit ? "" : rows[idx]?.unit}
                          onChange={(e) => handleChange(e, idx)}
                          disabled={true}
                        />
                      </td>

                      <td>
                        <TextField
                          type="number"
                          name="quantity"
                          data-testid={"quantity-" + idx}
                          value={ rows[idx]?.quantity}
                          min={0}
                          onChange={(e) => handleChangeQuantity(e, idx)}
                          // submitted={submitted}
                          placeholder="0.00"
                        />

                        {
                          // submitted &&
                          !
                           rows[idx]?.quantity ? (
                            <div className="invalid-text">
                              Enter the quantity
                            </div>
                          ) : item?.isError ? (
                            <div className="invalid-text">
                              Quantity should be less than stock
                            </div>
                          ) : null
                        }

                      </td>

                      <td>
                        <TextField
                          type="text"
                          data-testid={"product_rate-" + idx}
                          name="product_rate"
                          value={item?.manufacturer_price}
                          //   disabled={true}
                          placeholder="0.00"
                        />
                      </td>

                      <td>
                        <TextField
                          type="text"
                          data-testid={"total_price-" + idx}
                          name="total_price"
                          value={ rows[idx]?.total_price}
                          onChange={(e) => handleChange(e, idx)}
                          placeholder="0.00"
                          readOnly
                        />
                      </td>

                      <td>
                        <button
                          type="button"
                          data-testid={"delete-" + idx}
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => handleRemoveSpecificRow(idx)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>

                        <input
                          type="hidden"
                          value={rows[idx]?.total_discount}
                          className="total_discount dppr"
                          name="total_discount"
                        />
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <td colSpan={10}>
                    <button
                      type="button"
                      onClick={handleAddRow}
                      data-testid="add-row"
                      className="btn  pharmacy-gray-btn btn-sm "
                    >
                      Add another line
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="text-md-right">
            <button
              data-testid="save_sale"
              type="submit"
              // disabled={isPending}
              className="btn pharmacy-btn"
            >
              {/* {isPending ? "Submitting..." : "Submit"} */}
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DebitNote);
