import { memo, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../Helpers/config.json";
import { errorMessages } from "../../../../Helpers/ErrorMessages";
import { useNewExpenseItem } from "./useNewExpenseItem";
import { history } from "../../../../Helpers/history";
import { useNewCOAccount } from "../../COA/hooks/useNewCOAccount";
import { dateConvert } from "../../../../Utils/ConvertDate";
import { useQuery } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { data } from "jquery";

const paymentMethods = [
  { label: "MTN" },
  { label: "Airtel" },
  { label: "Cash" },
  { label: "VISA" },
  { label: "Bank" },
];

export const useRecordExpenses = (actions) => {
  const [openExpense, setOpenExpense] = useState(false);
  const [expenseItems, setExpenseItems] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState("idle");
  const [openExpenseDialog, setOpenExpenseDialog] = useState(false);
  const [receiptDialog, setReceiptDialog] = useState(false);
  const [rows, setRows] = useState([
    { account_name: "", amount: "", memo: "", uuid_id: uuidv4() },
  ]);
  const [voucher_id, setVoucherId] = useState("");
  const { itemProps } = useNewExpenseItem(actions);
  const { isResolved: isItemResolved } = itemProps;
  const balance_date = dateConvert();
  const [payTypes, setPayTypes] = useState([]);
  const [paytype, setPayType] = useState("");
  const [isBlocking, setIsBlocking] = useState(false);
  const [isBlockDialog, setIsBlockDialog] = useState(false);
  const [category, setCategory] = useState({ supplier: "", customer: "" });
  const [state, setState] = useState({
    amount: "",
    description: "",
    expense_date: "",
    vendor_name: "",
  });
  const [expenseState, setExpenseState] = useState({
    vendor: null,
    category: null,
    expense: null,
  });

  const [isPendingSaveNew, setIsPendingSaveNew] = useState(false);
  const [isPendingSaveExit, setIsPendingSaveExit] = useState(false);
  const [grand_total, setGrandTotal] = useState(0);

  const {
    coaProps,
    openDialog: openLedger,
    handleOpenDialog: handleOpenLedger,
    handleCloseDialog: handleCloseLedger,
  } = useNewCOAccount(actions, balance_date);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${config.epharmUrl}/accounting/expense_types`
        );
        let _data = res.data;
        let dt = _data.data ?? [];
        let exp_types = dt.map((i) => ({
          label: i.expense_type_name,
          value: i.id,
        }));
        setExpenseItems(exp_types);
      } catch (e) {
        // errorMessages(e, null, actions)
      }
    })();
  }, [isItemResolved]);

  useEffect(() => {
    try {
      const arr = paymentMethods
        .map((item, index) => ({
          value: index + 1,
          text: item.label,
        }))
        .filter((item) => item.text !== "CREDIT");
      setPayTypes(arr);
    } catch (error) {
      errorMessages(error, null, actions);
    }
  }, []);

  const handleChangePaymentMethod = (event) => {
    const { value } = event.target;
    setPayType(value);
    setIsBlocking(true);
  };

  const handleChangeState = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleChangeExpenseState = (obj) => {
    const { value, id } = obj;
    console.log(value);
    setExpenseState({ ...expenseState, [id]: value });
  };

  const handleChangeAccount = (obj) => {
    const { value, uuid_id } = obj;
    const arr = rows.map(item=>{
      if(item.uuid_id === uuid_id){
        return {...item,account_name:value}
      }
      return item
    })
    console.log(value);
    setRows(arr)
  
    // setExpenseState({ ...expenseState, [id]: value });
  };

  async function fetchJournals() {
    const res = await axios.get(
      `${config.epharmUrl}/accounting/get_expenditure_accounts`
    );
    let _data = res.data;
    let dt = _data ?? [];
    return dt.map((i) => ({ label: i.head_name, value: i.id }));
  }

  const { data: ledgers } = useQuery("fetch-journals", fetchJournals, {
    onError: (err) => {
      errorMessages(err, null, actions);
    },
  });

  async function fetchVendorUnderCategory() {
    const res = await axios.get(`${config.epharmUrl}/accounting/vendor_list`);
    let _data = res.data;
    let dt = _data.data ?? [];
    return dt.map((i) => ({
      label: `${i.Name}-${i.category}`,
      category: i.category,
      value: i.id,
    }));
  }

  const { data: vendorList } = useQuery(
    "vendor-list",
    fetchVendorUnderCategory,
    {
      onError: (err) => {
        errorMessages(err, null, actions);
      },
    }
  );

  const calculateGrandTotal = (arr) => {
    const sum = arr.reduce((a, row) => {
      return +(+a + +row.amount);
    }, 0);
    setGrandTotal(sum);
  };

  const handleChangeMemo = (event, index) => {
    const { value } = event.target;
    const arr = rows.map((item, idx) => {
      if (idx === index) {
        return { ...item, memo: value };
      }
      return item;
    });
    setRows(arr);
    setIsBlocking(true);
  };

  const handleAddRow = () => {
    const item = { account_name: "", amount: "", memo: "", uuid_id: uuidv4() };
    setRows([...rows, item]);
    setIsBlocking(true);
  };

  const handleRemoveSpecificRow = (uuid_id_to_remove) => () => {
    const arr = rows.filter((item) => item.uuid_id !== uuid_id_to_remove);
    calculateGrandTotal(arr);
    setRows(arr);
    setIsBlocking(true);
  };

  const handleChangeAmount = (event, index) => {
    const { value } = event.target;
    const arr = rows.map((item, idx) => {
      if (idx === index) {
        return { ...item, amount: value };
      }
      return item;
    });
    setRows(arr);
    calculateGrandTotal(arr);
    setIsBlocking(true);
  };

  const handleOpenExpense = () => {
    setOpenExpense(true);
  };

  const handleCloseExpense = () => {
    // if (isBlocking) {
    //   setIsBlockDialog(true);
    // } else {
      setOpenExpense(false);
      setIsBlockDialog(false);
      setIsBlocking(false);
    // }
  };

  const handleCloseExpenseDialog = () => {
    setOpenExpenseDialog(false);
  };

  const handleClose = () => {
    setOpenExpense(false);
    setOpenExpenseDialog(false);
    setState({
      amount: "",
      description: "",
      expense_date: "",
      vendor_name: "",
    });
    setExpenseState({ vendor: null, category: null, expense: null });
  };

  const handleOpenReceiptDialog = () => {
    setReceiptDialog(true);
    setOpenExpenseDialog(false);
  };

  const handleCloseReceiptDialog = () => {
    setReceiptDialog(false);
    setOpenExpense(false);
  };

  const handleSubmitExpense = (e, isExit = false) => {
    e.preventDefault();
    setSubmitted(true);
    const { vendor } = expenseState;
    const { description, expense_date } = state;
    const [{ account_name, amount }] = rows;

    if (isExit) {
      setIsPendingSaveExit(true);
    } else {
      setIsPendingSaveNew(true);
    }

    const expenseInfo = rows.map(item=>({
      ...item,expense_account:item?.account_name?.value
    }))

    const arr = rows.every(item=>item.account_name && item.amount)

    const params = {
      expense_date: expense_date,
      total_amount: +grand_total,
      bank_account: paytype,
      expense_details: description,
      category: vendor.category,
      vendor: +vendor?.value,
      expense_info: expenseInfo
    };

    // debugger;
    if (
      expense_date &&
      paytype &&
      arr &&
      vendor
    ) {
      setIsSubmitted("pending");
      axios
        .post(`${config.epharmUrl}/accounting/insert_expenses`, params)
        .then((response) => {
          const data = response.data
          const dt = data.data
          const id = dt.voucher_id

          console.log(data)
          console.log(id)
          actions.snackbarActions.successSnackbar(
            "Expense recorded successfully"
          );
          setIsSubmitted("resolved");
          setVoucherId(id)
          setState({
            amount: "",
            description: "",
            expense_date: "",
            vendor_name: "",
          });
          setExpenseState({ vendor: null});
          setRows([{amount:"",memo:"",account_name:""}])
          setPayType("")
          setGrandTotal(0)
          if (isExit) {
            setOpenExpenseDialog(true);
          }
          setSubmitted(false);
          setIsPendingSaveExit(false);
          setIsPendingSaveNew(false);
          setIsBlockDialog(false)
          setIsBlocking(false)
        })
        .catch((err) => {
          errorMessages(err, null, actions);
          setIsSubmitted("rejected");
          setIsPendingSaveExit(false);
          setIsPendingSaveNew(false);
        });
    }
  };

  const handlePrint = () => {
    history.push(`/expense-reciept/${voucher_id}`);
  };

  const isPending = isSubmitted === "pending";
  const isResolved = isSubmitted === "resolved";
  const isRejected = isSubmitted === "rejected";
  const ledgerProps = {
    ...coaProps,
    openLedger,
    handleCloseLedger,
    handleOpenLedger,
  };

  const expenseProps = {
    openExpense,
    expenseItems,
    submitted,
    handleCloseExpenseDialog,
    openExpenseDialog,
    handleCloseExpense,
    handleOpenExpense,
    handleCloseReceiptDialog,
    handleOpenReceiptDialog,
    receiptDialog,
    handleSubmitExpense,
    isPendingSaveNew,
    isPendingSaveExit,
    handlePrint,
    handleClose,
    voucher_id,
    isPending,
    isRejected,
    isResolved,
    itemProps,
    actions,
    ledgers,
    vendorList,
    ledgerProps,
    isBlocking,
    isBlockDialog,
    setOpenExpense,
    setIsBlockDialog,
    setIsBlocking,
    expenseState,
    state,
    handleChangeExpenseState,
    handleChangeState,
    handleChangePaymentMethod,
    payTypes,
    paytype,
    calculateGrandTotal,
    grand_total,
    handleAddRow,
    handleChangeMemo,
    handleRemoveSpecificRow,
    handleChangeAmount,
    rows,
    handleChangeAccount     
  };

  return { expenseProps };
};
