import React, { useState, useEffect, useRef } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as stockActions from "../../Redux/Actions/StockActions/StockActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import axios from "axios";
import config from "../../Helpers/config";
import { errorMessages } from "../../Helpers/ErrorMessages";
import { convertDate, titleCase } from "../../Utils/titleCaseFunction";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import { history } from "../../Helpers/history";
import { ReusableRouteTabs } from "../../Utils/Tabs/ReusableTabs";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  MuiPagination,
  MuiPaper,
} from "../../Utils/Tables/CustomTable";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import { Link } from "react-router-dom";
import { SelectItem } from "../../Utils/FormInputs/AutoCompleteInput";
import {
  useExcelReports,
  usePdfReports,
} from "../Invoice/CustomHooks/useExcelReports";
import { Badge } from "@material-ui/core";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, RightAlignedContainer } from "../../Utils/styledComponents";
import CsvDialog from "../../Utils/CsvDialog";
import { Alert } from "@material-ui/lab";
import { CSVLink } from "react-csv";
import { LoadingGif } from "../../Utils/Loader";
import { CustomSelect } from "../../Utils/FormInputs/SelectInput";

const headData = [
  { id: "sl", numeric: false, disablePadding: false, label: "SL" },
  {
    id: "medicineName",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },
  // {id: 'medicineType', numeric: false, disablePadding: false, label: 'Product Type'},
  // {id: 'inQuantity', numeric: false, disablePadding: false, label: 'In Quantity'},
  {
    id: "frequency",
    numeric: false,
    disablePadding: false,
    label: "Frequency",
  },
  { id: "stock", numeric: false, disablePadding: false, label: "Stock" },
  { id: "revenue", numeric: false, disablePadding: false, label: "Revenue" },
  {
    id: "total_quantity_sold",
    numeric: false,
    disablePadding: false,
    label: "Total Quantity Sold",
  },
  { id: "adjust", numeric: false, disablePadding: false, label: "Adjust" },
];

function StockReport(props) {
  const { actions } = props;
  const [state, setState] = useState({
    period_value: "daily",
    sort_value: "desc-total_quantity",
    from_date: convertDate(),
    to_date: convertDate(),
  });
  const [activePage, setActivePage] = useState(1);
  const [data, setData] = useState({
    stockList: [],
    loading: "idle",
    stock_count: 0,
  });

  const [search_value, setSearchValue] = useState("");
  const [allInitialStock, setAllIntialStock] = useState([]);
  const [intialStockCsv, setIntialStockCsv] = useState(null);
  const [error_message, setErrorMessage] = useState("");
  const [csvdialog, setCSVDialog] = useState(false);
  const [intialStock, setIntialStock] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [category_id, setCategoryId] = useState(null);
  const [categoryList, setCategories] = useState([]);

  const csvLink = useRef();

  const { period_value, sort_value, from_date, to_date } = state;
  const { stock_count, stockList, loading } = data;
  const allStock = !stockList ? [] : stockList;
  const { open, message, variant } = props.snackbars;
  const components = [
    { label: "Stock Report", path: "/stockreport" },
    { label: "Stock Report(Batch Wise)", path: "/stockbatchwise" },
  ];

  useEffect(() => {
    setData({ ...data, loading: "loading" });
    axios
      .get(
        sort_value.split("-")[0] === "asc"
          ? `${config.epharmUrl}/Creport/slow-moving-products`
          : `${config.epharmUrl}/Creport/fast-moving-products`,
        {
          params: {
            page: activePage,
            per_page: rowsPerPage,
            start_date: from_date,
            end_date: to_date,
            category_id: category_id ? category_id?.value : null,
            product_name: search_value ? search_value : null,
            // adding the sorting filters
            sort_order: sort_value.split("-")[0],
            order_by: sort_value.split("-")[1],
          },
        }
      )
      .then((response) => {
        const res = response?.data?.data;
        const count = response?.data?.total;
        setData({ stockList: res, stock_count: count, loading: "success" });
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setData({ ...data, loading: "error" });
      });
  }, [activePage, rowsPerPage, sort_value, category_id, search_value]);

  useEffect(() => {
    axios
      .get(`${config.epharmUrl}/Ccategory/manage_category`)
      .then((res) => {
        const data = res.data;
        const dt = data ? data : {};
        const categories = dt.category_list ? dt.category_list : [];
        const arr = categories.map((category) => ({
          value: category.category_id,
          label: titleCase(category.category_name),
        }));
        setCategories(arr);
      })
      .catch((error) => {
        errorMessages(error, null, actions);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${config.epharmUrl}/Cpurchase/check_initial_stock`)
      .then((res) => {
        const data = res.data;
        const dt = !data ? {} : data;
        const status = dt.status ? dt.status : "";
        setIntialStock(status);
      })
      .catch((err) => {
        errorMessages(err, null, actions);
      });
  }, []);

  const handleChangeCategory = (value) => {
    setCategoryId(value);
    setActivePage(1);
    setPage(0);
  };

  const handleCloseBar = () => {
    const { actions } = props;
    actions.snackbarActions.hideSnackBar();
  };

  const handleChangeValue = (event) => {
    const { value, name } = event.target;
    setState({ ...state, [name]: value });
    if (name === "sort_value") {
      setActivePage(1);
      setPage(0);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setActivePage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setActivePage(1);
  };

  const handleChangeSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const submitSort = (event) => {
    event.preventDefault();
    setData({ ...data, loading: "loading" });
    axios
      .get(
        sort_value.split("-")[0] === "asc"
          ? `${config.epharmUrl}/Creport/slow-moving-products`
          : `${config.epharmUrl}/Creport/fast-moving-products`,
        {
          params: {
            page: 1,
            per_page: rowsPerPage,
            start_date: from_date,
            end_date: to_date,
            category_id: category_id ? category_id?.value : null,
            product_name: search_value ? search_value : null,
            // adding the sorting filters
            sort_order: sort_value.split("-")[0],
            order_by: sort_value.split("-")[1],
          },
        }
      )
      .then((response) => {
        const res = response?.data?.data;
        const count = response?.data?.total;
        setActivePage(1);
        setPage(0);
        setData({ stockList: res, stock_count: count, loading: "success" });
      })
      .catch((error) => {
        errorMessages(error, null, actions);
        setData({ ...data, loading: "error" });
      });
  };

  const handleOpenCSVDialog = () => {
    setCSVDialog(true);
  };

  const handleCloseCSVDialog = () => {
    setCSVDialog(false);
    setErrorMessage("");
  };

  const fetchData = () => {
    axios
      .get(`${config.epharmUrl}/Cpurchase/initial_stock_csv`, {
        params: { download: true },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Intial_Stock_Template.csv`);
        document.body.appendChild(link);
        link.click();
        setErrorMessage("");
      })
      .catch((err) => {
        errorMessages(err, null, actions);
      });
  };

  const handleUploadCsv = (event) => {
    event.preventDefault();
    if (intialStockCsv) {
      const formData = new FormData();
      formData.append("upload_csv_file", intialStockCsv ? intialStockCsv : "");
      axios
        .post(`${config.epharmUrl}/Cpurchase/uploadCsv_initial_stock`, formData)
        .then((res) => {
          actions.snackbarActions.successSnackbar(
            "Intial stock csv uploaded Successfully"
          );
          if (error_message) setErrorMessage("");
          setTimeout(() => {
            history.push("/stockreport");
          }, 1000);
        })
        .catch((error) => {
          const err_msg =
            error.response && error.response.data
              ? error.response.data.message.split("-")[1]
              : "";
          setErrorMessage("Missing field in file: " + err_msg);
        });
    } else {
      setErrorMessage("Please select a csv or excel file to upload");
    }
  };

  const handleChangeStockCsv = (event) => {
    const file_extension = event.target.files[0].name.substr(
      event.target.files[0].name.length - 4
    );
    if (
      file_extension === ".csv" ||
      file_extension === ".xls" ||
      file_extension === "xlsx"
    ) {
      setIntialStockCsv(event.target.files[0]);
      if (error_message) setErrorMessage("");
    } else {
      event.target.value = null;
      setErrorMessage("Please upload only csv or excel (.xls or .xlsx) format");
    }
  };

  const currentObj = {
    file_name: "Stock Report",
    url:
      sort_value.split("-")[0] === "asc"
        ? `Creport/slow-moving-products/excel`
        : `Creport/fast-moving-products/excel`,
    params: {
      start_date: from_date,
      end_date: to_date,
      // adding the sorting filters
      sort_order: sort_value.split("-")[0],
      order_by: sort_value.split("-")[1],
      category_id: category_id ? category_id?.value : null,
    },
  };

  const objPDF = {
    file_name: "Stock Report",
    url:
      sort_value.split("-")[0] === "asc"
        ? `Creport/slow-moving-products/pdf`
        : `Creport/fast-moving-products/pdf`,
    params: {
      start_date: from_date,
      end_date: to_date,
      // adding the sorting filters
      sort_order: sort_value.split("-")[0],
      order_by: sort_value.split("-")[1],
      category_id: category_id ? category_id?.value : null,
    },
  };

  const { exportExcel, isLoading: isLoadingExcel } = useExcelReports(
    actions,
    currentObj
  );
  const { exportPDF, isLoading: isLoadingPDF } = usePdfReports(actions, objPDF);
  const setStockRequired = intialStock === 406;

  const isLoading = loading === "loading";
  const isSuccess = loading === "success";
  const isError = loading === "error";

  return (
    <div className="journals">
      <CsvDialog
        openDialog={csvdialog}
        handleCloseDialog={handleCloseCSVDialog}
        handleUploadCsv={handleUploadCsv}
        handleChangeUpload={handleChangeStockCsv}
        message="Intial_Stock"
      >
        <span>
          <button
            onClick={fetchData}
            className="btn pharmacy-primary-btn btn-sm"
          >
            Download Sample File
          </button>
          {error_message !== "" ? (
            <div style={{ marginTop: "10px" }}>
              <Alert severity="error" style={{ borderRadius: "10px" }}>
                {error_message}
              </Alert>
            </div>
          ) : null}
          <CSVLink
            data={allInitialStock}
            ref={csvLink}
            filename="IntialStock.csv"
            className="hidden"
          />
        </span>
      </CsvDialog>
      <ReusableDashboardHeader
        component="Stock Report"
        heading="Stock"
        subHeading="Stock Report"
        link={history.location.pathname}
      />
      <ReusableRouteTabs
        value={history.location.pathname}
        tabHeading={components}
      />
      <div className="general-ledger-header pb-0">
        <form onSubmit={submitSort}>
          <div className="row">
            <div className="col-md-4">
              <Form.Group as={Row}>
                <Label name="Sort by" sm={2} column />
                <Col sm={9}>
                  <SelectItem
                    onChange={handleChangeValue}
                    value_text="value"
                    value={sort_value}
                    name="sort_value"
                    label_text="text"
                    options={[
                      {
                        value: "desc-frequency",
                        text: "Fast Moving Stock (Frequency)",
                      },
                      {
                        value: "desc-total_revenue",
                        text: "Fast Moving Stock (Revenue)",
                      },
                      {
                        value: "desc-total_quantity",
                        text: "Fast Moving Stock (Quantity)",
                      },
                      {
                        value: "asc-frequency",
                        text: "Slow Moving/Dormant Stock (Frequency)",
                      },
                      {
                        value: "asc-total_revenue",
                        text: "Slow Moving/Dormant Stock (Revenue)",
                      },
                      {
                        value: "asc-total_quantity",
                        text: "Slow Moving/Dormant Stock (Quantity)",
                      },
                    ]}
                  />
                </Col>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group as={Row}>
                <Label name="Period" sm={2} column />
                <Col sm={9}>
                  <SelectItem
                    onChange={handleChangeValue}
                    value_text="value"
                    value={period_value}
                    name="period_value"
                    label_text="text"
                    options={[
                      { value: "daily", text: "Daily" },
                      {
                        value: "dateRange",
                        text: "Date Range",
                      },
                    ]}
                  />
                </Col>
              </Form.Group>
            </div>
            <div className="col-md-4">
              {(period_value === "daily" || period_value === "dateRange") && (
                <Form.Group as={Row}>
                  <Col sm="5">
                    <TextField
                      type="date"
                      name="from_date"
                      value={from_date}
                      onChange={handleChangeValue}
                    />
                  </Col>
                  <Col sm="5">
                    {period_value === "dateRange" ? (
                      <TextField
                        type="date"
                        name="to_date"
                        value={to_date}
                        onChange={handleChangeValue}
                      />
                    ) : (
                      <button type="submit" className=" btn pharmacy-btn mx-2">
                        Sort
                      </button>
                    )}
                  </Col>
                  <Col sm="2">
                    {period_value === "dateRange" ? (
                      <button type="submit" className=" btn pharmacy-btn mx-2">
                        Sort
                      </button>
                    ) : null}
                  </Col>
                </Form.Group>
              )}
            </div>
          </div>
        </form>
      </div>
      <PharmacySnackbar
        open={open}
        message={message}
        variant={variant}
        handleCloseBar={handleCloseBar}
      />
      <MuiPaper>
        <div className="row p-3">
          <div className="col-lg-4">
            <Form.Group as={Row}>
              <Label name="Category" column sm={2} />
              <Col sm={10}>
                <CustomSelect
                  value={category_id}
                  onChange={handleChangeCategory}
                  options={categoryList}
                />
              </Col>
            </Form.Group>
          </div>
          <div className="col-lg-6">
            <RightAlignedContainer>
              <button
                type="button"
                onClick={exportExcel}
                disabled={isLoadingExcel}
                className="btn btn-sm pharmacy-btn mb-2"
              >
                {isLoadingExcel ? "Exporting...." : "Export to Excel"}
              </button>
              <button
                style={{ marginLeft: "10px" }}
                type="button"
                onClick={exportPDF}
                disabled={isLoadingPDF}
                className="btn btn-sm pharmacy-btn mb-2"
              >
                Download PDF
              </button>
            </RightAlignedContainer>
          </div>
        </div>
        <EnhancedTableToolbar>
          {/* <span className="btn-group" style={{flex: '1 1 100%'}}>*/}
          {/*     <span>*/}
          {/*    <button className="btn btn-sm pharmacy-btn mr-1" onClick={exportExcel}>Excel</button>*/}

          {/*</span>*/}
          {/*  </span>*/}

          <Form.Control
            type="text"
            value={search_value ? titleCase(search_value) : ""}
            onChange={handleChangeSearch}
            className="form__control"
            placeholder="Search"
            style={{ float: "right" }}
          />

          <Container>
            {setStockRequired ? (
              <div className="text-right mb-3">
                <button
                  type="button"
                  onClick={handleOpenCSVDialog}
                  className="btn pharmacy-info-btn btn-sm mr-4"
                  style={{ marginBottom: "4px" }}
                >
                  <FontAwesomeIcon icon={faFileUpload} /> Upload Initial Stock
                </button>
              </div>
            ) : null}
          </Container>
        </EnhancedTableToolbar>
        <Table
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead headCells={headData} />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell align="center" colSpan={headData.length}>
                  <LoadingGif />
                </TableCell>
              </TableRow>
            ) : null}
            {isSuccess && allStock.length > 0 ? (
              allStock.slice(0, rowsPerPage).map((d, index) => {
                const sl = page * rowsPerPage + index + 1;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    className="mui-table-row"
                  >
                    <TableCell component="th" scope="row" padding="default">
                      <span>{sl}</span>
                    </TableCell>
                    <TableCell>
                      <span>
                        <Link
                          to={{ pathname: `/productlog/${d.product_id}` }}
                          style={{ color: "#09905c" }}
                        >
                          {d.status !== 0 ? (
                            <span>{titleCase(d.product_name)}</span>
                          ) : (
                            <Badge
                              badgeContent={"Deactivated"}
                              color="secondary"
                            >
                              <span>{titleCase(d.product_name)}</span>
                            </Badge>
                          )}
                        </Link>
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>{d.frequency}</span>
                    </TableCell>
                    <TableCell>
                      <span>{d.stock}</span>
                    </TableCell>
                    <TableCell>
                      <span>{d.total_revenue.toLocaleString()}</span>
                    </TableCell>
                    <TableCell>
                      <span>{d.total_quantity}</span>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: `/stockadjustment/${d.product_id}/${d.product_name}`,
                        }}
                      >
                        <button className="btn btn-sm pharmacy-info-btn">
                          Adjust Stock
                        </button>
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={headData.length}>
                  No records found
                </TableCell>
              </TableRow>
            )}
            {isError ? (
              <TableRow>
                <TableCell align="center" colSpan={headData.length}>
                  The server did not return a valid response
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
        <MuiPagination
          rowsPerPage={rowsPerPage}
          page={page}
          isTotalCount={true}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          total_count={stock_count}
          handleChangePage={handleChangePage}
          rows={[10, 50, 100, 200, 300, 500]}
          colSpan={headData.length}
        />
      </MuiPaper>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    stock: state.stock,
    snackbars: state.snackbars,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      stockActions: bindActionCreators(stockActions, dispatch),
      snackbarActions: bindActionCreators(snackbarActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StockReport);
