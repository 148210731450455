import axios from "axios";
import config from "../../../Helpers/config.json";
import { errorMessages } from "../../../Helpers/ErrorMessages";
import { convertDate } from "../../../Utils/titleCaseFunction";
import { useState } from "react";

export function useExcelReports(actions, obj = {}) {
  const [loading, setLoading] = useState("idle");
  const { url, file_name, params } = obj;
  const current_date = convertDate();
  const exportExcel = () => {
    // console.log("excel");
    const paramsObj = params ? params : {};
    setLoading("loading");
    axios
      .get(`${config.epharmUrl}/${url}`, {
        params: { ...paramsObj },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${file_name}_Report_${current_date}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        setLoading("success");
      })
      .catch((err) => {
        if (err?.response?.status === 406) {
          const errorMsg = {
            response: {
              status: 406,
              data: "No data to download",
            },
          };
          errorMessages(errorMsg, null, actions);
        } else {
          const errorMsg =
            err?.response?.data?.constructor?.name === "Blob" ? {} : err;
          errorMessages(errorMsg, null, actions);
        }
        setLoading("error");
      });
  };

  const isLoading = loading === "loading";
  const isSuccess = loading === "success";
  const isError = loading === "error";
  return { exportExcel, isSuccess, isError, isLoading };
}

export function usePdfReports(actions, obj = {}) {
  const [loading, setLoading] = useState("idle");
  const { url, file_name, params } = obj;
  const current_date = convertDate();

  const exportPDF = () => {
    // console.log("pdf");
    const paramsObj = params ? params : {};
    setLoading("loading");
    axios
      .get(`${config.epharmUrl}/${url}`, {
        params: { ...paramsObj },
        responseType: "blob",
      })
      .then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${file_name}_Report_${current_date}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        }

        setLoading("success");
      })
      .catch((err) => {
        if (err?.response?.status === 406) {
          const errorMsg = {
            response: {
              status: 406,
              data: "No data to download",
            },
          };
          errorMessages(errorMsg, null, actions);
        } else {
          const errorMsg =
            err?.response?.data?.constructor?.name === "Blob" ? {} : err;
          errorMessages(errorMsg, null, actions);
        }
        setLoading("error");
        console.log(err);
      });
  };
  const isLoading = loading === "loading";
  const isSuccess = loading === "success";
  const isError = loading === "error";
  return { exportPDF, isSuccess, isError, isLoading };
}
