import * as React from 'react';
import { useReactToPrint } from 'react-to-print';
import { makeStyles } from "@material-ui/core/styles";

const getPageMargins = () => {
    return `
    @media print {
        #posPrint{
        width : 80mm;
        height: 210mm;
        padding-left:16px;
        padding-right:16px;
        padding-bottom:36px;
        }
    }
    `;
};

const getA4PageMargins = () => {
    return `
        @media print {
            width: 210mm;
            height: 297mm;
            padding-left:16px;
            padding-right:16px;
            padding-bottom:36px;
        }
    `
}

const fontSize = () =>{

    return `body {
        color:black;
  }`;

}

const a4Styles = makeStyles({
    centreItems: {
        display: 'flex',
        justifyContent: 'center'
    },
    emergencyListView: {
        display: 'flex',
        justifyContent: 'center',
        //  width: '100%'
    },
});

function usePrint(title) {
    const componentRef = React.useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: title
    });

    return {componentRef, handlePrint, getPageMargins, getA4PageMargins, fontSize, a4Styles}
}

export {usePrint}
